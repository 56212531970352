import React from "react";

export const FBPants = ({ className }) => {
  return (
    <svg
      className={className}
      width="20pt"
      height="20pt"
      version="1.1"
      viewBox="0 0 1200 1200"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path fill="currentColor" d="m881.25 168.75v-93.75c0-4.9727-1.9766-9.7422-5.4922-13.258-3.5156-3.5156-8.2852-5.4922-13.258-5.4922h-525c-4.9727 0-9.7422 1.9766-13.258 5.4922-3.5156 3.5156-5.4922 8.2852-5.4922 13.258v93.75z" />
        <path fill="currentColor" d="m422.97 333.74-135.75-25.16c-15.09-2.8125-30.676 0.66797-43.133 9.6367-12.457 8.9688-20.703 22.645-22.824 37.848l-28.582 203.3c-2.0547 14.359 1.5312 28.953 10.004 40.727 8.4727 11.77 21.172 19.805 35.438 22.418l135.75 25.16c15.094 2.8125 30.68-0.67188 43.141-9.6367 12.461-8.9688 20.715-22.645 22.84-37.848l28.566-203.3c2.0508-14.359-1.5352-28.953-10.008-40.727-8.4727-11.77-21.176-19.805-35.441-22.418z" />
        <path fill="currentColor" d="m243.75 750h112.5c31.066 0 56.25 31.066 56.25 56.25v168.75c0 31.066-25.184 56.25-56.25 56.25h-112.5c-31.066 0-56.25-31.066-56.25-56.25v-168.75c0-31.066 25.184-56.25 56.25-56.25z" />
        <path fill="currentColor" d="m826.12 647.67 135.73-25.16c14.27-2.6055 26.973-10.637 35.449-22.406 8.4766-11.773 12.066-26.367 10.016-40.727l-28.59-203.32c-2.1211-15.203-10.371-28.875-22.824-37.84-12.457-8.9688-28.043-12.449-43.129-9.6406l-135.73 25.16c-14.27 2.6094-26.977 10.641-35.453 22.41-8.4766 11.77-12.062 26.367-10.016 40.727l28.566 203.32c2.1328 15.203 10.387 28.871 22.848 37.836 12.461 8.9609 28.043 12.445 43.133 9.6367z" />
        <path fill="currentColor" d="m843.75 1068.8c-24.855-0.027344-48.684-9.9141-66.258-27.492-17.578-17.574-27.465-41.402-27.492-66.258v-168.75c0.027344-24.855 9.9141-48.684 27.492-66.258 17.574-17.578 41.402-27.465 66.258-27.492h112.5c3.2031 0.15625 6.3945 0.47656 9.5625 0.96484-0.25-18.125-0.6875-35.891-1.3125-53.297l-131.55 24.375c-25.145 4.668-51.105-1.1367-71.863-16.074-20.758-14.938-34.516-37.707-38.078-63.031l-28.562-203.35c-3.4102-23.93 2.5742-48.254 16.699-67.871 14.125-19.613 35.293-33.004 59.07-37.359l135.74-25.148c0.44141-0.082032 0.89453-0.046876 1.3359-0.125-7.5742-27.836-14.461-50.008-19.559-65.336h-575.45c-5.0742 15.312-11.934 37.438-19.508 65.34 0.4375 0.074218 0.88281 0.042968 1.3125 0.12109l135.72 25.16-0.003907-0.003906c23.777 4.3594 44.945 17.75 59.066 37.367 14.121 19.621 20.098 43.945 16.684 67.875l-28.551 203.3c-3.543 25.328-17.289 48.109-38.043 63.051-20.754 14.945-46.715 20.758-71.859 16.09l-131.81-24.43c-0.60547 17.23-1.0156 35.062-1.2539 53.363h0.003906c3.2188-0.5 6.4609-0.82812 9.7148-0.98438h112.5c24.855 0.027344 48.684 9.9141 66.258 27.492 17.578 17.574 27.465 41.402 27.492 66.258v168.75c-0.027344 24.855-9.9141 48.684-27.492 66.258-17.574 17.578-41.402 27.465-66.258 27.492h-107.3c3.2617 36.188 5.7812 58.082 5.8125 58.41 0.53125 4.5703 2.7227 8.7812 6.1562 11.844 3.4336 3.0586 7.875 4.7461 12.473 4.7461h195.96c4.1367 0 8.1562-1.3672 11.434-3.8867 3.2812-2.5234 5.6328-6.0586 6.6914-10.059 3.7695-14.234 76.047-287.85 112.52-491.43 36.473 203.58 108.75 477.19 112.52 491.43 1.0586 4 3.4102 7.5352 6.6914 10.059 3.2773 2.5195 7.2969 3.8867 11.434 3.8867h195.96c4.5977 0 9.0352-1.6914 12.469-4.75 3.4336-3.0586 5.6211-7.2734 6.1523-11.84 0.046876-0.33594 2.5508-22.148 5.8125-58.41z" />
        <path fill="currentColor" d="m843.75 750h112.5c31.066 0 56.25 31.066 56.25 56.25v168.75c0 31.066-25.184 56.25-56.25 56.25h-112.5c-31.066 0-56.25-31.066-56.25-56.25v-168.75c0-31.066 25.184-56.25 56.25-56.25z" />
      </g>
    </svg>
  );
};

