import React, { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../context/AuthContext';
import { PathContext } from '../context/PathContext'
import { Cascader } from 'antd'
import { db } from '../Firebase-config'
import { doc, getDoc } from 'firebase/firestore'


function VDMultiDataTitleCascader (props) {
    const [dataTitle, setDataTitle] = useState([])
    const {currentUser} = useContext(AuthContext)
    const { selectedPath } = useContext(PathContext);

    useEffect(() => {
      const getTitleDoc = async () => {
      const docRef = doc(db, 'Teams', selectedPath, 'DataLabels', 'Labels');
      const docSnap = await getDoc(docRef);

      if (docSnap.exists() && docSnap.data().dataTitle !== undefined) {
          console.log("Document data:", docSnap.data());
          setDataTitle(docSnap.data().dataTitle)
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
          setDataTitle([{
            value: 'eg. Year',
            label: 'eg. Year',
            children: []
          }])
        }
     }
    
     getTitleDoc()
    
    },[selectedPath]);

    const filter = (inputValue, path) =>
    path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

  return (
    <>
      <div className='flex mx-auto'>
          <Cascader
          className='w-full h-fit ml-2'
          options={dataTitle}
          size='large'
          showSearch={{filter}}
          onChange={(e) => props.updateTitle(e)}
          showCheckedStrategy='SHOW_CHILD'
          multiple
          changeOnSelect
          maxTagCount="responsive"
          defaultValue={[]}
          placeholder={'Title Select'}
          />
      </div>
    </>
  )
}

export default VDMultiDataTitleCascader