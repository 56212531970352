import React from "react";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import EvaluxClearLogo from "../../img/EvaluxClearLogo.png";

export const PrivacyPolicy = () => {
  return (
    <motion.div
      intitial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="h-full w-full overflow-y-auto"
    >
      <nav className="sticky top-0 z-50 h-16 w-full flex bg-slate-800 text-white">
        <div className="w-fit h-fit mx-2 md:mx-4 my-auto">
          <img
            className="h-12 aspect-auto"
            src={EvaluxClearLogo}
            alt="Evalux Logo"
          />
        </div>
        <NavLink
          to="/"
          className="my-auto text-sm opacity-80"
          activeClassName="opacity-100"
        >
          Back to Home
        </NavLink>
      </nav>
      <div className="text-white px-[5%] md:px-[10%]">
        <h2>Privacy Policy</h2>
        <p>Last modified: February 11, 2024</p>
        <h3>Introduction</h3>
        <p>
          EvaluX LLC (the “Company,” “We,” “Us,” or “Our”) respects the user’s
          (“You” or “Your”) privacy and is committed to protecting it through
          Our compliance with this Privacy Policy (the “Policy”).
        </p>
        <p>
          This Policy describes the types of information We may collect from You
          or that You may provide when using the EvaluX application (the “App”)
          and Our practices for collecting, using maintaining, protecting, and
          disclosing that information.
        </p>
        <p>This Policy applies to information We collect:</p>
        <ul>
          <li>On this App;</li>
          <li>
            In email, text, and other electronic messages between You and this
            App; and
          </li>
          <li>
            When You interact with our advertising and application on
            third-party websites and services, if those applications or
            advertising include links to this Policy.
          </li>
        </ul>
        <p>It does not apply to information collected by:</p>
        <ul>
          <li>
            Us offline or through any other means, including on any other
            website operated by the Company or any third-party (including Our
            affiliates and subsidiaries, as applicable); or
          </li>
          <li>
            Any third-party (including Our affiliates and subsidiaries),
            including through any application or content (including advertising)
            that may link to or be accessible from (or on) the App.
          </li>
        </ul>
        <p>
          Our other websites, if applicable, and these third-parties may have
          their own privacy policies, which We encourage You to read before
          providing information on or through them.
        </p>
        <p>
          Please read this Policy carefully to understand Our policies and
          practices regarding Your information and how We will treat it. If You
          do not agree with Our policies and practices, You have the option to
          not utilize the App. By accessing or using the App, You agree to the
          terms of this Policy. This Policy may change from time to time (see
          Changes to Our Privacy Policy). Your continued use of the App after We
          make changes is deemed to be acceptance of those changes, so please
          check the Policy periodically for updates.
        </p>

        <section>
          <h3>Children Under the Age of 13</h3>
          <p>
            Our App is not intended for children under 13 years of age. No one
            under age 13 may provide any information to the App. We do not
            knowingly collect Personal Information (defined below) from children
            under 13. If You are under 13, do not use or provide any information
            on the App or on or through any of its features/register on the App,
            make any purchases through the App, use any of the interactive or
            public comment features of the App, or provide any information about
            Yourself to Us, including Your name, address, telephone number,
            email address, or any screen name or user name You may use. If We
            learn We have collected or received Personal Information from a
            child under 13, We will delete that information. If You believe We
            might have any information from or about a child under 13, please
            contact Us at cam.dangelo@evalu-x.com.
          </p>
          <p>
            California residents under 16 years of age may have additional
            rights regarding the collection and sale of their personal
            information. To learn more about California residents' privacy
            rights, visit{" "}
            <a href="https://www.oag.ca.gov/privacy/ccpa">
              https://www.oag.ca.gov/privacy/ccpa
            </a>{" "}
            and{" "}
            <a href="https://leginfo.legislature.ca.gov/faces/codes_displayText.xhtml?division=3.&part=4.&lawCode=CIV&title=1.81.5">
              https://leginfo.legislature.ca.gov/faces/codes_displayText.xhtml?division=3.&part=4.&lawCode=CIV&title=1.81.5
            </a>
            . California's "Shine the Light" law (Civil Code Section § 1798.83)
            permits users of our App that are California residents to request
            certain information regarding our disclosure of personal information
            to third parties for their direct marketing purposes. To make such a
            request, please send an email to cam.dangelo@evalu-x.com.
          </p>
        </section>
        <section>
          <h3>Information We Collect About You and How We Collect It</h3>
          <p>
            We collect several types of information from and about users of the
            App, including information:
          </p>
          <ul>
            <li>
              By which You may be personally identified, such as name, physical
              characteristics, postal address, telephone, or e-mail address
              (“Personal Information”);
            </li>
            <li>
              That is about You but individually does not identify You, such as
              demographic information; and
            </li>
            <li>
              About Your internet connection, the equipment You use to access
              Our App, and usage details.
            </li>
          </ul>
          <p>We collect this information:</p>
          <ul>
            <li>Directly from You when You provide it to Us;</li>
            <li>
              Automatically as You navigate through the App. Information
              collected automatically may include usage details, IP addresses,
              and information collected through cookies, web beacons, and other
              tracking technologies; and
            </li>
            <li>
              When applicable, from third-parties and other users such as team
              coaches.
            </li>
          </ul>
          <p>
            <strong>Information You Provide to Us.</strong>
          </p>
          <p>The information We collect on or through the App may include:</p>
          <ul>
            <li>
              Information that You provide by filling in forms on the App,
              including information provided at the time of registering to use
              the App, posting material, or requesting further services. We may
              also ask You for information when You report a problem with the
              App;
            </li>
            <li>
              Records and copies of Your correspondence (including email
              addresses), if You contact Us;
            </li>
            <li>
              Your responses to surveys that We might ask You to complete for
              research purposes;
            </li>
            <li>
              Details of transactions You carry out through the App and
              fulfillment of Your orders. You may be required to provide
              financial information before placing an order through the App; and
            </li>
            <li>Your search queries on the App.</li>
          </ul>
          <p>
            You may also provide information to be published or displayed on
            public areas of the App or transmitted to other users of the App or
            third-parties (collectively, “User Contributions”). Your User
            Contributions are posted on and transmitted to others at Your own
            risk. Please be aware that no security measures are perfect or
            impenetrable. Additionally, We cannot control the actions of other
            users of the App with whom You may choose to share Your User
            Contributions. Therefore, We cannot and do not guarantee that Your
            User Contributions will not be viewed by unauthorized persons.
          </p>
        </section>

        <section>
          <h3>
            Information We Collect Through Automatic Data Collection
            Technologies
          </h3>
          <p>
            As You navigate through and interact with the App, We may use
            automatic data collection technologies to collect certain
            information about Your equipment, browsing actions, and patterns,
            including:
          </p>
          <ul>
            <li>
              Usage Details. Details of Your visits to the App, including
              traffic data, location data, logs, and other communication data
              and the resources that You access and use on the App; and
            </li>
            <li>
              Device Information. Information about Your computer, internet
              connection, and mobile device, including Your IP address,
              operating system, phone model, mobile network, device’s telephone
              number, and browser type.
            </li>
          </ul>
          <p>
            We may also use these technologies to collect information about Your
            online activities over time and across third-party websites or other
            online services (behavioral tracking). Please contact Us at
            cam.dangelo@evalu-x.com if You do not want Us to collect this
            information and for information on how You can opt out of behavioral
            tracking on or through this App and how We respond to browser
            signals and other mechanisms that enable consumers to exercise
            choice about behavioral tracking.
          </p>
        </section>
        <section>
          <h3>Information Collection and Tracking Technologies</h3>
          <p>
            The information We collect automatically is statistical data and
            does not include Personal Information, but We may maintain it or
            associate it with Personal Information We collect in other ways or
            receive from third-parties. It helps Us to improve the App and to
            deliver better and more personalized services, including by enabling
            Us to:
          </p>
          <ul>
            <li>Estimate Our audience size and usage patterns;</li>
            <li>
              Store information about Your preferences and/or favorite listings
              on the App, allowing Us to customize the App according to Your
              individual interests;
            </li>
            <li>Speed up Your searches; and</li>
            <li>Recognize You when You return to the App.</li>
          </ul>
          <p>
            The technologies We use for this automatic data collection may
            include:
          </p>
          <ul>
            <li>
              Cookies (or browser cookies). A cookie is a small file placed on
              the hard drive of Your computer. You may refuse to accept browser
              cookies by activating the appropriate setting on Your browser.
              However, if You select this setting, You may be unable to access
              certain parts of the App. Unless You have adjusted Your browser
              setting so that it will refuse cookies, Our system will issue
              cookies when You direct Your browser to the App.
            </li>
            <li>
              Flash Cookies. Certain features of the App may use locally stored
              objects (or Flash cookies) to collect and store information about
              Your preferences and navigation to, from, and on the App. Flash
              cookies are not managed by the same browser settings as are used
              for browser cookies. For information about managing Your privacy
              and security settings for Flash cookies, contact Us at
              cam.dangelo@evalu-x.com.
            </li>
            <li>
              Web Beacons. Pages on the App and Our e-mails may contain small
              electronic files known as web beacons (also referred to as clear
              gifs, pixel tags, and single-pixel gifs) that permit the Company
              to count users who have visited those pages, opened an email, or
              for other related website statistics such as recording the
              popularity of certain website content and verifying system and
              server integrity.
            </li>
          </ul>
          <p>
            We do not collect Personal Information automatically, but We may tie
            this information to Personal Information about You that We collect
            from other sources or that You provide to Us.
          </p>
        </section>

        <section>
          <h3>Third-Party Information Collection</h3>
          <p>
            When You use the App or its content, certain third-parties may use
            automatic information collection technologies to collect information
            about You or Your device. These third-parties may include:
          </p>
          <ul>
            <li>Advertisers, ad networks, and ad servers; and</li>
            <li>Your service provider.</li>
          </ul>
          <p>
            Some content or applications, including advertisements, on the App
            are served by third-parties, including advertisers, ad networks, and
            servers, content providers, and application providers. These
            third-parties may use cookies (alone or in conjunction with web
            beacons or other tracking technologies) to collect information about
            You when You use this App. The information they collect may be
            associated with Your Personal Information or they may collect
            information, including Personal Information, about Your online
            activities over time and across different websites, apps, and other
            online services websites. They may use this information to provide
            You with interest-based (behavioral) advertising or other targeted
            content.
          </p>
          <p>
            We do not control these third-parties' tracking technologies or how
            they may be used. If You have any questions about an advertisement
            or other targeted content, You should contact the responsible
            provider directly. For information about how You can opt out of
            receiving targeted advertising from many providers, see “Choices
            About How We Use and Disclose Your Information.”
          </p>
        </section>
        <section>
          <h3>How We Use Your Information</h3>
          <p>
            We use information that We collect about You or that You provide to
            Us, including any Personal Information, to:
          </p>
          <ul>
            <li>Present Our App and its contents to You;</li>
            <li>
              Provide You with information, products, or services that You
              request from Us;
            </li>
            <li>Fulfill any other purpose for which You provide it;</li>
            <li>
              Provide You with notices about Your account and subscription,
              respond to comments, questions, and provide customer service;
            </li>
            <li>
              To carry out Our obligations and enforce Our rights arising from
              any contracts entered into between You and Us, including for
              billing and collection;
            </li>
            <li>
              Notify You about changes to the App or any products or services We
              offer or provide though it;
            </li>
            <li>
              Allow You to participate in interactive features on the App;
            </li>
            <li>
              Communicate about promotions, upcoming events, and other news
              about products and services offered by Us and Our selected
              partners;
            </li>
            <li>
              In any other way We may describe when You provide the information;
              and
            </li>
            <li>For any other purpose with Your consent.</li>
          </ul>
          <p>
            The usage information We collect helps Us to improve Our App and to
            deliver a better and more personalized experience by enabling Us to:
          </p>
          <ul>
            <li>Estimate Our audience size and usage patterns;</li>
            <li>
              Store information about Your preferences, allowing Us to customize
              Our App according to Your individual interests;
            </li>
            <li>Speed up Your searches; and</li>
            <li>Recognize when You use the App.</li>
          </ul>
          <p>
            We may also use Your information to contact You about Our own and
            third-parties' goods and services that may be of interest to You.
          </p>
          <p>
            We may use the information We collect to display advertisements to
            Our advertisers' target audiences. Even though We do not disclose
            Your Personal Information for these purposes without Your consent,
            if You click on or otherwise interact with an advertisement, the
            advertiser may assume that You meet its target criteria.
          </p>
        </section>

        <section>
          <h3>Disclosure of Your Information</h3>
          <p>
            We may disclose aggregated information about Our users, and
            information that does not identify any individual, without
            restriction.
          </p>
          <p>
            We may disclose Personal Information that We collect or You provide
            as described in this Policy:
          </p>
          <ul>
            <li>To Our subsidiaries and affiliates;</li>
            <li>
              To contractors, service providers, and other third-parties We use
              to support Our business and who are bound by contractual
              obligations to keep Personal Information confidential and use it
              only for the purposes for which We disclose it to them;
            </li>
            <li>
              To a buyer or other successor in the event of a merger,
              divestiture, restructuring, reorganization, dissolution, or other
              sale or transfer of some or all of the Company’s assets, whether
              as a going concern or as part of bankruptcy, liquidation, or
              similar proceeding, in which Personal Information held by the
              Company about the App’s users is among the assets transferred;
            </li>
            <li>
              To third-parties to market their products or services to You. We
              contractually require these third parties to keep Personal
              Information confidential and use it only for the purposes for
              which We disclose it to them;
            </li>
            <li>To fulfill the purpose for which You provide it;</li>
            <li>
              For any other purpose disclosed by Us when You provide the
              information; and
            </li>
            <li>With Your consent.</li>
          </ul>
          <p>We may also disclose Your Personal Information:</p>
          <ul>
            <li>
              To comply with any court order, law, or legal process, including
              to respond to any government or regulatory request;
            </li>
            <li>
              To enforce or apply Our Terms of Use and other agreements,
              including for billing and collection purposes; and
            </li>
            <li>
              If We believe disclosure is necessary or appropriate to protect
              the rights, property, or safety of the Company, Our customers, or
              others, including exchanging information with other companies and
              organizations for the purposes of fraud protection and credit risk
              reduction.
            </li>
          </ul>
        </section>
        <section>
          <h3>Choices About How We Use and Disclose Your Information</h3>
          <p>
            We strive to provide You with choices regarding the Personal
            Information You provide to Us. We have created mechanisms to provide
            You with the following control over Your information:
          </p>
          <ul>
            <li>
              Tracking Technologies and Advertising. You can set Your browser to
              refuse all or some browser cookies, or to alert You when cookies
              are being sent. To learn how You can manage Your Flash cookie
              settings, visit the Flash player settings page on Adobe’s website.
              If You disable or refuse cookies, please note that some parts of
              this application may then be inaccessible or not function
              properly.
            </li>
            <li>
              Promotional Offers from the Company. If You do not wish to have
              Your email address/contact information used by the Company to
              promote Our own or third-parties’ products or service may send Us
              an email asking to be omitted from disclosures of this nature. If
              We have sent You a promotional email, You may send Us a return
              email asking to be omitted from future email distributions.
            </li>
            <li>
              Targeted Advertising by the Company. If You do not want Us to use
              information that We collect or that You provide to Us to deliver
              advertisements according to Our advertisers' target-audience
              preferences, You can opt-out by emailing cam.dangelo@evalu-x.com.
            </li>
            <li>
              Disclosure of Your Information for Third-Party Advertising and
              Marketing. If You do not want Us to share Your Personal
              Information with unaffiliated or non-agent third-parties for
              advertising and marketing purposes, You can opt-out by emailing
              cam.dangelo@evalu-x.com.
            </li>
          </ul>
          <p>
            We do not control third-parties’ collection or use of Your
            information to serve interest-based advertising. However, these
            third-parties may provide You with ways to choose not to have Your
            information collected or used in this way. You can opt out of
            receiving targeted ads from members of the Network Advertising
            Initiative (“NAI”) on the NAI’s website.
          </p>
        </section>

        <section>
          <h3>Data Security</h3>
          <p>
            We have implemented measures designed to secure Your Personal
            Information from accidental loss and from unauthorized access, use,
            alteration, and disclosure. The safety and security of Your
            information also depends on You. Where We have given You (or where
            You have chosen) a password for access to certain parts of Our App,
            You are responsible for keeping this password confidential. We ask
            You not to share Your password with anyone.
          </p>
          <p>
            Unfortunately, the transmission of information via the internet is
            not completely secure. Although We take reasonable actions to
            protect Your Personal Information, We cannot guarantee the security
            of Your Personal Information transmitted to the App. Any
            transmission of Personal Information is at Your own risk. We are not
            responsible for circumvention of any privacy settings or security
            measures contained on the App.
          </p>
        </section>
        <section>
          <h3>Changes to Our Privacy Policy</h3>
          <p>
            It is Our policy to post any changes We make to our Privacy Policy
            on this page with a notice on the App’s home page that the Privacy
            Policy has been updated. If We make material changes to how We treat
            Our users’ Personal Information, We will notify You by email to the
            email address provided or through a notice on the home page for the
            App. The date the Privacy Policy was last revised is identified at
            the top of the page. You are responsible for ensuring We have an
            up-to-date active and deliverable email address for You, and for
            periodically visiting Our App and this Privacy Policy to check for
            any changes.
          </p>
        </section>
        <section>
          <h3>Contact Information</h3>
          <p>
            To ask questions or comment about this Privacy Policy and Our
            privacy practices, contact Us at cam.dangelo@evalu-x.com.
          </p>
        </section>
      </div>
    </motion.div>
  );
};
