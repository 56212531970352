import React, { useMemo } from "react";
import { ResponsivePie } from "@nivo/pie";

function VDPieChart({ PCData, selectedPositions, playerNames }) {
  const pieData = useMemo(() => {
    // Your logic to compute or modify ScoreSheet based on data.ScoreData
    const rawData = PCData?.length > 0 ? PCData : [{}]; // or some computed value

    if (
      playerNames?.length > 1 ||
      (playerNames?.length >= 1 &&
        selectedPositions[playerNames] === null &&
        rawData.length > 1)
    ) {
      const pieChartData = Object.entries(rawData).map(
        ([index, assignments]) => ({
          id: assignments.Name + ` (${assignments.position})`,
          Name: assignments.Name,
          position: assignments.position,
          label:
            assignments.Name +
            ` ${Math.round(
              (assignments.plusAssignments / assignments.totalAssignments) * 100
            )}%`,
          value: Math.round(
            (assignments.plusAssignments / assignments.totalAssignments) * 100
          ),
        })
      );

      const filteredData = pieChartData.filter((item) => {
        const playerName = item.Name;

        // Include the item if:
        // 1. No positions are selected.
        // 2. No selectedPosition playerNames match.
        // 3. A selectedPosition playerName matches and the position matches.
        return (
          !selectedPositions ||
          !selectedPositions[playerName] || // No position is selected for the playerName
          selectedPositions[playerName] === item.position
        );
      });

      return filteredData;
    } else {
      const pieChartData = Object.entries(rawData).map(
        ([index, assignments]) => [
          {
            id: `Positive ${Math.round(
              (assignments.plusAssignments / assignments.totalAssignments) * 100
            )}%`,
            Name: assignments.Name,
            position: assignments.position,
            label: "Positive",
            value: assignments.plusAssignments,
          },
          {
            id: `Negative ${Math.round(
              (assignments.minusAssignments / assignments.totalAssignments) *
                100
            )}%`,
            Name: assignments.Name,
            position: assignments.position,
            label: "Negative",
            value: assignments.minusAssignments,
          },
        ]
      );
      const filteredData = pieChartData.filter((innerArray) => {
        return innerArray.some((item) => {
          const playerName = item.Name;

          // Include the item if:
          // 1. No positions are selected.
          // 2. No selectedPosition playerNames match.
          // 3. A selectedPosition playerName matches, and the position matches.
          return (
            !selectedPositions ||
            !selectedPositions[playerName] || // No position is selected for the playerName
            selectedPositions[playerName] === item.position
          );
        });
      });

      return filteredData[0];
    }
  }, [PCData, selectedPositions]);

  const CenteredMetric = ({ dataWithArc, centerX, centerY }) => {
    let total = 0;
    dataWithArc.forEach((datum) => {
      total += datum.value;
    });
    if (pieData[0] && pieData[0].label === "Positive")
      return (
        <text
          x={centerX}
          y={centerY + 5}
          textAnchor="middle"
          // dominantBaseline="central"
          style={{
            fontSize: "20px",
            fontWeight: 600,
          }}
        >
          {total}
        </text>
      );
  };

  const legendProps = {
    anchor: "bottom",
    translateY: 50,
    direction: "row",
    itemWidth: 100,
    itemHeight: 26,
    itemsSpacing: 60,
    itemOpacity: 0.8,
    symbolShape: "square",
  };

  const seriesColors = [
	"#87D78C",
	"#F98C95",
	"#FBC661",
	"#D6CF61",
	"#AED571",
	"#66D6AA",
	"#57D1C6",
	"#69C9DB",
	"#8EBDE4",
	"#B7AFDF",
	"#D9A0CE",
	"#EF93B4",
	"#F58C77"
  ];
  

  return (
    <>
      <div className="relative w-fit h-0">
        <div className="relative font-bold shadow-md shadow-slate-600 px-2 top-2 left-2 w-fit rounded-md bg-slate-200">
          Assignment Grades
        </div>
      </div>
      <ResponsivePie
        data={pieData}
        margin={{ top: 40, right: 125, bottom: 80, left: 125 }}
        innerRadius={0.35}
        padAngle={1}
        theme={{ fontSize: 13 }}
        cornerRadius={3}
        layers={[
          "arcs",
          "arcLabels",
          "arcLinkLabels",
          "legends",
          CenteredMetric,
        ]}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{
          from: "color",
          modifiers: [["darker", 0.2]],
        }}
        arcLinkLabelsDiagonalLength={5}
        arcLinkLabelsStraightLength={10}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#000"
        arcLinkLabelsThickness={4}
        arcLinkLabelsColor={{ from: "color" }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
          from: "color",
          modifiers: [["darker", 2]],
        }}
        defs={[
          {
            id: "dots",
            type: "patternDots",
            background: "inherit",
            color: "rgba(255, 255, 255, 0.3)",
            size: 6,
            padding: 1,
            stagger: true,
          },
          {
            id: "lines",
            type: "patternLines",
            background: "inherit",
            color: "rgba(255, 255, 255, 0.3)",
            rotation: -45,
            lineWidth: 6,
            spacing: 10,
          },
        ]}
        fill={[
          {
            match: {
              id: "ruby",
            },
            id: "dots",
          },
          {
            match: {
              id: "c",
            },
            id: "dots",
          },
          {
            match: {
              id: "go",
            },
            id: "dots",
          },
          {
            match: {
              id: "python",
            },
            id: "dots",
          },
          {
            match: {
              id: "OL",
            },
            id: "lines",
          },
        ]}
        // legends={[
        //   {
        //     anchor: "bottom",
        //     direction: "row",
        //     justify: false,
        //     translateX: -20,
        //     translateY: 56,
        //     itemsSpacing: 75,
        //     itemWidth: 75,
        //     itemHeight: 18,
        //     itemTextColor: "#999",
        //     itemDirection: "left-to-right",
        //     itemOpacity: 1,
        //     symbolSize: 18,
        //     symbolShape: "circle",
        //     effects: [
        //       {
        //         on: "hover",
        //         style: {
        //           itemTextColor: "#000",
        //         },
        //       },
        //     ],
        //   },
        // ]}
        colors={seriesColors}
        legends={
          pieData?.length <= 2
            ? [
                {
                  ...legendProps,
                  anchor: "bottom",
                  direction: "row",
                  justify: false,
                  translateX: -10,
                  translateY: 56,
                  itemsSpacing: 75,
                  itemWidth: 75,
                  itemHeight: 18,
                  itemTextColor: "#999",
                  itemDirection: "left-to-right",
                  itemOpacity: 1,
                  symbolSize: 18,
                  symbolShape: "circle",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemTextColor: "#000",
                      },
                    },
                  ],
                },
              ]
            : [
                {
                  ...legendProps,
                  direction: "row",
                  justify: false,
                  translateX: -20,
                  translateY: 45,
                  itemsSpacing: 75,
                  itemWidth: 75,
                  itemHeight: 18,
                  itemTextColor: "#999",
                  itemDirection: "left-to-right",
                  itemOpacity: 1,
                  symbolSize: 18,
                  symbolShape: "circle",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemTextColor: "#000",
                      },
                    },
                  ],
                  data: pieData
                    ?.slice(0, Math.floor(pieData?.length / 2))
                    .map((cur, index) => ({
                      id: cur.id,
                      label: cur.id,
                      color: seriesColors.slice(
                        0,
                        Math.floor(pieData?.length / 2)
                      )[index],
                    })),
                },
                {
                  ...legendProps,
                  direction: "row",
                  justify: false,
                  translateX: -20,
                  translateY: 65,
                  itemsSpacing: 75,
                  itemWidth: 75,
                  itemHeight: 18,
                  itemTextColor: "#999",
                  itemDirection: "left-to-right",
                  itemOpacity: 1,
                  symbolSize: 18,
                  symbolShape: "circle",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemTextColor: "#000",
                      },
                    },
                  ],
                  data: pieData
                    ?.slice(Math.floor(pieData?.length / 2))
                    .map((cur, index) => ({
                      id: cur.id,
                      label: cur.id,
                      color: seriesColors.slice(
                        Math.floor(pieData?.length / 2)
                      )[index],
                    })),
                },
              ]
        }
      />
    </>
  );
}

export default VDPieChart;
