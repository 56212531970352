import React from "react";
import { useState, useEffect, useContext } from "react";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail,
  sendEmailVerification,
} from "firebase/auth";
import { auth, storage } from "../Firebase-config";
import { ref, getDownloadURL } from "firebase/storage";
import { NavLink, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { PathContext } from "../context/PathContext";
import { AnimatePresence, motion } from "framer-motion";
import { FcGoogle } from "react-icons/fc";
import { BiCopyright, BiLogInCircle, BiTask } from "react-icons/bi";
import { ConfigProvider, Modal, Spin } from "antd";
import FieldLPGraphic from "../img/FieldLPGraphic.jpg";
import UtahCandid from "../img/UtahCandid.jpg";
import WeatherSC from "../img/WeatherSC.png";
import RecruitSC from "../img/RecruitSC.png";
import ExcelBG from "../img/ExcelBG.jpg";
import BerksALogo from "../img/BerksALogo.png";
import PCALogo from "../img/PCALogo.png";
import EvaluxClearLogo from "../img/EvaluxClearLogo.png";
import { BsListOl, BsPencilSquare } from "react-icons/bs";
import { BsDatabaseFillLock } from "react-icons/bs";
import { MdOutlineAttachMoney } from "react-icons/md";
import { LuImport } from "react-icons/lu";
import { IoLogoFirebase } from "react-icons/io5";
import { FaXTwitter, FaPhone, FaHeadset } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { openNotificationWithIcon } from "../helperFunctions/openNotificationWithIcon";
import { Collapse } from "antd/es";
import { SiAwsorganizations } from "react-icons/si";
import { GiAmericanFootballPlayer } from "react-icons/gi";
import { GiWhistle } from "react-icons/gi";
import useDetectScroll from "@smakss/react-scroll-direction";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [matchingPassword, setMatchingPassword] = useState("");

  const [loading, setLoading] = useState(false);
  const [login, setLogin] = useState(false);
  const [showRegister, setShowRegister] = useState(false);

  const navigate = useNavigate();
  const { dispatch } = useContext(AuthContext);
  const { currentUser } = useContext(AuthContext);
  const { updatePath } = useContext(PathContext);

  const [showPasswords, setShowPasswords] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPasswords(!showPasswords);
  };

  useEffect(() => {
    if (currentUser && currentUser?.emailVerified) {
      navigate("/Home");
    }
  });

  const handleResendVerification = async () => {
    const user = auth.currentUser;
    await sendEmailVerification(user);
    openNotificationWithIcon(
      "success",
      "New Verification Email Sent",
      "",
      "top"
    );
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      // Sign in the user
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      if (currentUser && user.emailVerified) {
        await dispatch({ type: "LOGIN", payload: user });

        // Update the path
        updatePath(user.uid);
        navigate("/Home");
      } else {
        const btn = (
          <button
            className="text-red-500"
            type="primary"
            size="small"
            onClick={() => handleResendVerification()}
          >
            Resend Verification Email
          </button>
        );
        openNotificationWithIcon(
          "warning",
          "Email not verified",
          "Click this notification to send a new verification link.",
          "top",
          btn
        );
      }
    } catch (error) {
      // Show a notification with the error message
      openNotificationWithIcon("error", "Login Error", error.message, "bottom");
    }
  };

  const gmailProvider = new GoogleAuthProvider();

  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, gmailProvider);
      const user = result.user;
      await dispatch({ type: "LOGIN", payload: user });
      updatePath(user.uid);
      navigate("/Home");
    } catch (error) {
      openNotificationWithIcon("error", "Login Error", error.message, "bottom");
    }
    return;
  };

  const handleRegister = async () => {
    setEmail("");
    setPassword("");
    setMatchingPassword("");
    if (!email || email?.length === 0) {
      openNotificationWithIcon(
        "warning",
        "Enter an email address",
        "",
        "bottom"
      );
      return;
    }
    if (!password || password !== matchingPassword) {
      openNotificationWithIcon(
        "warning",
        "Passwords do not match",
        "",
        "bottom"
      );
      return;
    }
    try {
      setLoading(true);
      await createUserWithEmailAndPassword(auth, email, password);

      // Send email verification
      const user = auth.currentUser;
      await sendEmailVerification(user);

      openNotificationWithIcon(
        "success",
        "Registered!",
        "Check your email for verification",
        "top"
      );
      setShowRegister(false);
    } catch (error) {
      openNotificationWithIcon(
        "error",
        "Register Error",
        error.message,
        "bottom"
      );
    }
    setLoading(false);
  };

  const resetPassword = async () => {
    console.log(email);
    if (email) {
      try {
        await sendPasswordResetEmail(auth, email);
        openNotificationWithIcon(
          "info",
          "Reset Email Sent",
          "Check your inbox for further instruction",
          "top"
        );
      } catch (error) {
        openNotificationWithIcon(
          "error",
          "Password Reset Error",
          error.message,
          "bottom"
        );
      }
    } else {
      openNotificationWithIcon("error", "Invaild Email", "", "bottom");
    }
  };

  const [showVideo, setShowVideo] = useState("Evaluation");

  const handleVideos = (video) => {
    setShowVideo(video);
  };

  const [videoUrls, setVideoUrls] = useState([]);

  useEffect(() => {
    if (currentUser) {
      return;
    }

    const VDScreenCap =
      "https://firebasestorage.googleapis.com/v0/b/gradeapp-fddad.appspot.com/o/HandBrake.mp4?alt=media&token=6d9631f8-3b2f-45b5-8b49-fda5e57bcac7";
    const RecruitingScreenCap = "";
    const OpsScreenCap = "";

    // Array of video URLs
    const videoPaths = [VDScreenCap, RecruitingScreenCap, OpsScreenCap];

    // Fetch each video URL
    const fetchVideoUrls = async () => {
      const urls = await Promise.all(
        videoPaths.map(async (path) => {
          try {
            const videoRef = ref(storage, path);
            const url = await getDownloadURL(videoRef);
            return url;
          } catch (error) {
            console.error(`Error getting download URL for ${path}:`, error);
            return null;
          }
        })
      );

      setVideoUrls(urls.filter((url) => url !== null));
    };

    fetchVideoUrls();
  }, []);

  const VideoDisplay = () => {
    const getVideoComponent = (source) => (
      <video
        autoPlay
        loop
        muted
        controls="false"
        disableRemotePlayback
        playsInline
        className="video-content bg-slate-700 rounded-xl w-full h-full lg:min-w-[25vw] shadow-md shadow-black"
      >
        <source src={source} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    );

    return (
      <AnimatePresence mode="wait">
        {showVideo === "Evaluation" && (
          <motion.div
            key="Evaluation"
            className="feature-display"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5, type: "fade" }}
          >
            {getVideoComponent(videoUrls?.[0])}
          </motion.div>
        )}

        {showVideo === "Recruiting" && (
          <motion.div
            key="Recruiting"
            className="feature-display"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5, type: "fade" }}
          >
            {getVideoComponent(videoUrls?.[1])}
          </motion.div>
        )}

        {showVideo === "Operations" && (
          <motion.div
            key="Operations"
            className="feature-display"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5, type: "fade" }}
          >
            {getVideoComponent(videoUrls?.[2])}
          </motion.div>
        )}
      </AnimatePresence>
    );
  };

  const [showFeature, setShowFeatures] = useState("Roster Editor");

  const handleFeatures = (feature) => {
    setShowFeatures(feature);
  };

  const FeatureDisplay = () => {
    return (
      <AnimatePresence mode="wait">
        {showFeature === "Roster Editor" && (
          <motion.div
            key="Roster"
            className="feature-display flex flex-col md:h-full w-full h-fit text-xl font-inter"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5, type: "fade" }}
          >
            <div className="flex flex-col md:flex-row my-8 h-fit md:h-full">
              <ul className="m-auto text-white text-center h-fit my-auto w-full md:w-1/2">
                <li className="my-4">Track player info efficiently.</li>
                <li className="my-4">
                  Get team breakdowns by position & year.
                </li>
                <li className="my-4">Create recruiting profiles seamlessly.</li>
                <li className="my-4">
                  Automatically import players into tools.
                </li>
                <li className="my-4">Manage rosters without losing data.</li>
                <li className="my-4">Filter and search player data easily.</li>
              </ul>
              <div className="relative flex flex-col h-fit min-h-96 md:h-full w-fit mx-auto gap-2 md:w-1/2">
                <div className="md:absolute static w-full md:w-fit md:h-96 top-0 shadow-black shadow-md">
                  <img
                    className="w-full md:h-full rounded-lg"
                    src="https://firebasestorage.googleapis.com/v0/b/gradeapp-fddad.appspot.com/o/RosterTableSC.png?alt=media&token=0ada550f-72bd-4977-a02d-ae5210685f35"
                    alt=""
                  />
                </div>
                <div className="md:absolute static w-fit h-fit md:h-64 top-[45%] left-1/3 shadow-black shadow-md">
                  <img
                    className="w-full md:h-full rounded-lg"
                    src="https://firebasestorage.googleapis.com/v0/b/gradeapp-fddad.appspot.com/o/RosterPCSC.png?alt=media&token=15138329-f491-4af1-9020-f3ea35d69047"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </motion.div>
        )}

        {showFeature === "Depth Charts" && (
          <motion.div
            key="Depth Charts"
            className="feature-display flex flex-col md:h-full w-full h-fit text-xl font-inter"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5, type: "fade" }}
          >
            <div className="flex flex-col md:flex-row my-8 h-fit md:h-full">
              <ul className="m-auto text-white text-center w-full md:w-1/2">
                <li className="my-4">
                  Intuitively create depth charts with drag-and-drop.
                </li>
                <li className="my-4">
                  Generate printable sheets effortlessly.
                </li>
                <li className="my-4">
                  View or print multiple depth charts at once.
                </li>
              </ul>

              <div className="relative flex flex-col h-fit min-h-96 md:h-full w-fit mx-auto gap-2 md:w-1/2">
                <div className="md:absolute static h-fit w-fit md:h-72 top-0 rounded-lg shadow-black shadow-md">
                  <img
                    className="w-full md:h-full rounded-lg"
                    src="https://firebasestorage.googleapis.com/v0/b/gradeapp-fddad.appspot.com/o/DepthChartSC.png?alt=media&token=aaec4a2e-95d6-4396-a2b9-f6f08d2e5db3"
                    alt=""
                  />
                </div>
                <div className="md:absolute static h-fit w-fit md:h-64 top-[45%] left-1/3 rounded-lg shadow-black shadow-md">
                  <img
                    className="w-full md:h-full rounded-lg"
                    src="https://firebasestorage.googleapis.com/v0/b/gradeapp-fddad.appspot.com/o/PrintableDCSC.png?alt=media&token=b336b274-1916-431c-b62b-a693b1888ab0"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </motion.div>
        )}

        {showFeature === "Grade Sheet" && (
          <motion.div
            key="Grade Sheet"
            className="feature-display flex flex-col md:h-full w-full h-fit text-xl font-inter"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5, type: "fade" }}
          >
            <div className="flex flex-col md:flex-row my-8 h-fit md:h-full">
              <ul className="m-auto text-md text-white text-center h-fit my-auto px-10 w-full md:w-1/2">
                <li className="my-4">
                  Our standardized grading system is ment to be pick up and use
                </li>
                <li className="my-4">
                  Easily upload play data from CSV files.
                </li>
                <li className="my-4">
                  Add & remove selected players with a single click.
                </li>
                <li className="my-4">
                  Track player performance and assignment percentages in
                  real-time.
                </li>
                <li className="my-4">
                  Collaborate with multiple coaches editing plays simultaneously
                </li>
              </ul>
              <div className="relative flex flex-col h-fit min-h-96 md:h-full w-fit mx-auto gap-2 md:w-1/2">
                <div className="md:absolute static h-fit w-fit md:h-64 top-0 rounded-lg shadow-black shadow-md">
                  <img
                    className="w-full md:h-full rounded-lg"
                    src="https://firebasestorage.googleapis.com/v0/b/gradeapp-fddad.appspot.com/o/GradeSheetSC.png?alt=media&token=5be2cd05-5462-4853-9165-e9558d9943a6"
                    alt=""
                  />
                </div>
                <div className="md:absolute static h-fit w-fit md:h-64 top-[45%] left-1/4 shadow-black shadow-md">
                  <img
                    className="w-full md:h-full rounded-lg"
                    src="https://firebasestorage.googleapis.com/v0/b/gradeapp-fddad.appspot.com/o/GradeSheetTableSC.png?alt=media&token=858aa741-6c74-41c5-bd48-743ce09d3de7"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </motion.div>
        )}

        {showFeature === "Team Stats" && (
          <motion.div
            key="Team Stats"
            className="feature-display flex flex-col md:h-full w-full h-fit text-xl font-inter"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5, type: "fade" }}
          >
            <div className="flex flex-col md:flex-row my-8 h-fit md:h-full">
              <ul className="m-auto text-md px-10 text-white text-center h-fit my-auto w-full md:w-1/2">
                <li className="my-4">
                  Monitor combined team grades for player performance during
                  plays.
                </li>
                <li className="my-4">
                  Easily filter game data to pinpoint team strengths and
                  weaknesses.
                </li>
                <li className="my-4">
                  Compare multiple games to track team progress throughout the
                  season.
                </li>
                <li className="my-4">
                  Make informed decisions by eliminating guesswork.
                </li>
              </ul>
              <div className="relative flex flex-col h-fit min-h-96 md:h-full w-fit mx-auto gap-2 md:w-1/2">
                <div className="md:absolute static h-80 top-5 rounded-lg shadow-black shadow-md">
                  <img
                    className="w-full md:h-full rounded-lg"
                    src="https://firebasestorage.googleapis.com/v0/b/gradeapp-fddad.appspot.com/o/TeamStatsSC.png?alt=media&token=1d0b16ce-325e-4800-97fe-74a127b0a687"
                    alt=""
                  />
                </div>
                <div className="md:absolute static h-64 top-[45%] left-1/3  rounded-lg shadow-black shadow-md">
                  {/* <img
                    className="w-full md:h-full rounded-lg"
                    src="https://firebasestorage.googleapis.com/v0/b/gradeapp-fddad.appspot.com/o/RosterPCSC.png?alt=media&token=15138329-f491-4af1-9020-f3ea35d69047"
                    alt=""
                  /> */}
                </div>
              </div>
            </div>
          </motion.div>
        )}

        {showFeature === "Analytics" && (
          <motion.div
            key="Analytics"
            className="feature-display flex flex-col md:h-full w-full h-fit text-xl font-inter"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5, type: "fade" }}
          >
            <div className="flex flex-col md:flex-row my-8 h-fit md:h-full">
              <ul className="m-auto px-10 text-md text-white text-center h-fit my-auto w-full md:w-1/2">
                <li className="my-4">
                  View individual player grades, mulitple player grades, and
                  even compare grades across positions seamlessly.
                </li>
                <li className="my-4">
                  Easily compare grades for single games, multiple games, or the
                  entire season.
                </li>
                <li className="my-4">
                  Access clear graphs for intuitive data visualization.
                </li>
                <li className="my-4">
                  Provide players with a centralized platform to review grades
                  and comments after each game.
                </li>
              </ul>
              <div className="relative flex flex-col h-fit min-h-96 md:h-full w-fit mx-auto gap-2 md:w-1/2">
                <div className="md:absolute static h-fit w-fit md:h-80 top-0 rounded-lg shadow-black shadow-md">
                  <img
                    className="w-full md:h-full rounded-lg"
                    src="https://firebasestorage.googleapis.com/v0/b/gradeapp-fddad.appspot.com/o/VisualDataSC%20(2).png?alt=media&token=6827a5be-0d1a-467c-bb05-efef602f9c36"
                    alt=""
                  />
                </div>
                {/* <img
                  className="md:absolute static h-64 top-[45%] left-1/3  rounded-lg shadow-black shadow-md"
                  src="https://firebasestorage.googleapis.com/v0/b/gradeapp-fddad.appspot.com/o/RosterPCSC.png?alt=media&token=15138329-f491-4af1-9020-f3ea35d69047"
                  alt=""
                /> */}
              </div>
            </div>
          </motion.div>
        )}

        {showFeature === "WristCaller" && (
          <motion.div
            key="WristCaller"
            className="feature-display flex flex-col md:h-full w-full h-fit text-xl font-inter"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5, type: "fade" }}
          >
            <div className="flex flex-col md:flex-row my-8 h-fit md:h-full">
              <ul className="m-auto text-white text-center h-fit my-auto w-full md:w-1/2">
                <li className="my-4">
                  Tag plays using various criteria such as hash, down, distance,
                  situation, and more.
                </li>
                <li className="my-4">
                  WristCaller generates a printable wristband automatically.
                </li>
                <li className="my-4">
                  Utilize GameMode to manage the game as if you were playing a
                  video game.
                </li>
                <li className="my-4">Input play diagrams to preview plays.</li>
                <li className="my-4">
                  Filter your plays by tags to find the ideal play for any game
                  situation.
                </li>
              </ul>
              <div className="relative flex flex-col h-fit min-h-96 md:h-full w-fit mx-auto gap-2 md:w-1/2">
                <div className="md:absolute static w-full md:w-fit md:h-96 top-0 shadow-black shadow-md">
                  <img
                    className="w-full md:h-full rounded-lg"
                    src="https://firebasestorage.googleapis.com/v0/b/gradeapp-fddad.appspot.com/o/WristCallerSC.png?alt=media&token=1b927dec-364e-4497-8acd-f88bd775c0e8"
                    alt="Plays Calls & Tags"
                  />
                </div>
                <div className="md:absolute static w-fit h-fit md:h-64 top-[45%] left-1/3 shadow-black shadow-md">
                  <img
                    className="w-full md:h-full rounded-lg"
                    src="https://firebasestorage.googleapis.com/v0/b/gradeapp-fddad.appspot.com/o/GameModeSC.png?alt=media&token=1eeee7b1-1361-4462-87bf-51f705d3f57b"
                    alt="GameMode"
                  />
                </div>
              </div>
            </div>
          </motion.div>
        )}

        {showFeature === "Recruiting" && (
          <motion.div
            key="Recruiting"
            className="feature-display flex flex-col md:h-full w-full h-fit text-xl font-inter"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5, type: "fade" }}
          >
            <div className="flex flex-col md:flex-row my-8 h-fit md:h-full">
              <ul className="m-auto px-10 text-md text-white text-center h-fit my-auto w-full md:w-1/2">
                <li className="my-4">
                  Save time by transitioning away from laborious profile sifting
                  on Hudl and X.
                </li>
                <li className="my-4">
                  Access comprehensive recruiting profiles with essential data
                  provided by coaches for coaches.
                </li>
                <li className="my-4">
                  Bookmark top recruits, schools, camps, and trusted street
                  recruiters for easy access to top talent.
                </li>
                <li className="my-4">
                  Utilize Google Maps for effortless school location tracking in
                  your recruiting region and nationwide.
                </li>
              </ul>
              <div className="relative flex flex-col h-fit min-h-96 md:h-full w-fit mx-auto gap-2 md:w-1/2">
                <div className="md:absolute static h-fit md:w-fit md:h-80 top-0 rounded-lg shadow-black shadow-md">
                  <img
                    className="w-full md:h-full rounded-lg"
                    src="https://firebasestorage.googleapis.com/v0/b/gradeapp-fddad.appspot.com/o/RecruitingSC.png?alt=media&token=c6eafd39-7bc5-4678-b3f6-6b96c6ad77db"
                    alt=""
                  />
                </div>
                {/* <img
                  className="md:absolute static h-64 top-[45%] left-1/3  rounded-lg shadow-black shadow-md"
                  src="https://firebasestorage.googleapis.com/v0/b/gradeapp-fddad.appspot.com/o/RosterPCSC.png?alt=media&token=15138329-f491-4af1-9020-f3ea35d69047"
                  alt=""
                /> */}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    );
  };

  const FAQSection = () => {
    const faqItems = [
      {
        key: 1,
        label: "What benefits does the Pro Plan offer?",
        children: (
          <p className="font-semibold">
            The Pro Plan provides users with exclusive features such as the
            capability to edit team rosters, access to our professional grading
            system, the ability to create and update depth charts, the power to
            recruit players listed on our recruiting map, and more.
          </p>
        ),
      },
      {
        key: 2,
        label: "What happens to my data if I choose to cancel my Pro Plan?",
        children: (
          <p className="font-semibold">
            Access to your data remains free indefinitely. However, without an
            active Pro Plan, you will lose the ability to make edits to the data
            associated with your account.
          </p>
        ),
      },
      {
        key: 3,
        label: "Can players utilize Evalux?",
        children: (
          <p className="font-semibold">
            Yes, players can access your team's information by having their
            email listed on their roster profile. Player accounts operate in
            "View Only" mode, restricting them from making any edits to the
            data.
          </p>
        ),
      },
      {
        key: 4,
        label: "Is Evalux a replacement for hudl or XOs?",
        children: (
          <p className="font-semibold">
            No, Evalux is not a replacement for video services like hudl or XOs.
            We aim to provide cost-effective tools for individual coaches and
            entire teams without requiring the migration of existing video
            services. Our goal is to complement and enhance the functionality of
            these services.
          </p>
        ),
      },
      {
        key: 5,
        label: "Can I leave or transfer a team?",
        children: (
          <p className="font-semibold">
            Yes, team transfers can be facilitated through the team owner
            profile. Coaches and players can be easily added or removed via
            email. It's important to note that if a team is deleted, all
            associated team data will also be permanently removed from our
            servers.
          </p>
        ),
      },
      {
        key: 6,
        label: "Can I retain my player grades when leaving a team?",
        children: (
          <p className="font-semibold">
            Certainly. Our myTeam feature allows coaches to preserve player
            grades from any previously graded games. This feature enables
            coaches to maintain a record of players' grades throughout their
            careers. Notably, no team data is retained, ensuring organizations
            can confidently allow coaches to retain grades without compromising
            sensitive team information.
          </p>
        ),
      },
    ];

    return (
      <div className="w-full md:w-3/4 mx-auto bg-slate-200 rounded-lg">
        <Collapse className="text-white" size="large" items={faqItems} />
      </div>
    );
  };

  const SubheaderRotator = () => {
    const [lines, setLines] = useState([
      "Harness Division I Standards",
      "Evaluate Your Performance",
      "Preserve Your Legacy",
    ]);
    const [currentLineIndex, setCurrentLineIndex] = useState(0);
    const [showText, setShowText] = useState(true);

    useEffect(() => {
      const interval = setInterval(() => {
        setShowText(false);
        setTimeout(() => {
          setCurrentLineIndex((prevIndex) => (prevIndex + 1) % lines.length);
          setShowText(true);
        }, 500); // Adjust the timeout based on your transition duration
      }, 4000);

      return () => clearInterval(interval);
    }, [lines]);

    return (
      <div className="font-inter h-fit backdrop-blur-sm font-semibold text-2xl text-shadow shadow-black text-slate-100 rounded-md bg-[#9A9EA2] bg-opacity-30 p-3">
        Streamline Your Daily Operations <br />
        <div className="min-h-8">
          <AnimatePresence>
            {showText && (
              <motion.div
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: 50 }}
                key={lines[currentLineIndex]}
              >
                {lines[currentLineIndex]}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    );
  };

  return (
    <motion.div
      intitial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed overflow-auto scroll-smooth h-screen w-screen overflow-x-hidden bg-slate-800 bg-gradient-to-b from-slate-700 to-slate-950"
    >
      <img
        id="login"
        className="absolute z-[-1] left-0 top-0 w-full aspect-square sm:aspect-auto lg:mt-6 rounded-xl opacity-30"
        style={{
          WebkitMaskImage: "linear-gradient(to top, transparent, white)",
          maskImage: "linear-gradient(to top, transparent, white)",
        }}
        src={FieldLPGraphic}
        alt="Landing Page Graphic"
      />

      <nav className="sticky top-0 pt-2 z-50 h-16 w-full flex bg-slate-800">
        <div className="w-fit ml-2 md:ml-4 mb-1">
          <img
            className="h-12 aspect-auto"
            src={EvaluxClearLogo}
            alt="Evalux Logo"
          />
        </div>
        <ul className="flex p-2 ml-auto md:mr-12 font-Russo">
          <li className="hidden my-auto sm:block mx-[-6px] sm:mx-0 scale-75 sm:scale-90 md:scale-100 md:mx-3">
            <a href="#pricing" className="text-slate-200">
              Pricing
            </a>
          </li>
          <li className="hidden my-auto sm:block mx-[-6px] sm:mx-0 scale-75 sm:scale-90 md:scale-100 md:mx-3">
            <a href="#features" className="text-slate-200">
              Features
            </a>
          </li>
          <li className="hidden my-auto sm:block mx-[-6px] sm:mx-0 scale-75 sm:scale-90 md:scale-100 md:mx-3">
            <a href="#theTeam" className="text-slate-200">
              The Team
            </a>
          </li>
          <li className="mr-4 sm:mx-0 scale-100 sm:scale-90 md:scale-100 md:mx-3 bg-slate-200 hover:bg-green-500 p-2 pt-1 rounded-lg mb-1">
            <a
              onClick={() => setLogin((login) => !login)}
              href="#login"
              className="flex h-full w-full justify-center align-middle hover:text-white"
            >
              <span className="flex">Sign up / Login</span>
            </a>
          </li>
        </ul>
      </nav>
      <div className="items-center w-full overflow-hidden flex flex-col h-fit mx-auto mt-[1.5%]">
        <div className="flex flex-col min-h-[70vh] lg:flex-row w-full">
          <h1 className="text-7xl font-Anton text-shadow-lg shadow-black tracking-wide lg:text-9xl mt-[10%] min-h-full sm:mt-10 my-8 lg:mt-0 font-normal text-white w-full md:w-[70%] lg:w-1/2 pl-[5%]">
            WELCOME TO THE FUTURE OF FOOTBALL
          </h1>
          <div className="h-fit w-full lg:w-1/2 mt-4 md:mt-10 lg:mt-32 lg:pr-[10%]">
            <div
              className={`${
                login ? "lg:opacity-0 lg:-translate-y-full" : "static"
              } transition-all text-center w-[95%] md:w-[85%] mx-auto`}
            >
              <SubheaderRotator />
            </div>
            <div
              id="login"
              className={`relative lg:h-full w-full px-auto mx-auto lg:pr-[2%] transition-all mt-8 lg:mt-4 font-Russo ${
                login ? "h-96 lg:-translate-y-[200%]" : ""
              }`}
            >
              <div className="relative z-30 group text-3xl overflow-hidden transition-all mx-auto rounded-md shadow-md shadow-slate-800 h-fit w-[20rem] lg:w-[20rem] px-2 bg-yellow-400 hover:bg-green-500">
                <div className="group z-20 hover:animate-shine cursor-pointer absolute top-0 -inset-full h-full w-1/4 transform -skew-x-12 bg-gradient-to-r from-transparent to-white opacity-40 group-hover:animate-shine" />

                <button
                  onClick={() => setLogin((login) => !login)}
                  className="flex w-full justify-center align-middle hover:text-white"
                >
                  <span className="flex text-2xl p-2">Enter Here</span>
                </button>
              </div>
              <ConfigProvider
                theme={{
                  components: {
                    Modal: {
                      /* here is your component tokens */
                      contentBg: "opacity-0",
                    },
                  },
                }}
              >
                <Modal
                  className="h-[60vh] border-8 md:border-4 border-slate-950 bg-opacity-50 backdrop-blur-md bg-slate-300 rounded-xl"
                  centered
                  width={700}
                  open={showRegister}
                  onOk={() => setShowRegister(false)}
                  onCancel={() => setShowRegister(false)}
                  footer={false}
                >
                  <div className="relative mt-4 h-fit w-full bg-slate-800 rounded-b-md font-Russo">
                    <img
                      className="h-16 p-2"
                      src={EvaluxClearLogo}
                      alt="Evalux Logo"
                    />
                    <span className="absolute tracking-wider left-0 right-0 w-fit top-4 m-auto font-bold text-2xl text-white">
                      Sign Up
                    </span>
                  </div>

                  <div className="flex flex-col justify-center align-middle mx-2 md:mx-20 bg-slate-200 p-8 rounded-b-md font-Russo">
                    <input
                      onChange={(e) => setEmail(e.target.value)}
                      className="text-lg border shadow-black shadow-sm pl-1 py-5 rounded-sm h-8 border-black mb-4"
                      type="email"
                      placeholder="Email..."
                    />
                    <input
                      onChange={(e) => setPassword(e.target.value)}
                      className="text-lg border shadow-black shadow-sm pl-1 py-5 rounded-sm mt-1 h-8 border-black"
                      type={showPasswords ? "text" : "password"}
                      placeholder="Password..."
                    />
                    <input
                      onChange={(e) => setMatchingPassword(e.target.value)}
                      className="text-lg border shadow-black shadow-sm pl-1 py-5 rounded-sm mt-1 h-8 border-black"
                      type={showPasswords ? "text" : "password"}
                      placeholder="Retype Password..."
                    />
                    <button type="button" onClick={togglePasswordVisibility}>
                      {showPasswords ? "Hide Passwords" : "Show Passwords"}
                    </button>
                    <motion.button
                      className="w-32 h-fit mx-auto mt-7 p-3 font-bold rounded-md tracking-wide  shadow-black bg-slate-300"
                      type="button"
                      onClick={handleRegister}
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                    >
                      Register{" "}
                      {loading ? (
                        <div className="flex justify-center items-center h-50px">
                          <Spin size="large" />
                        </div>
                      ) : null}
                    </motion.button>
                  </div>
                </Modal>
              </ConfigProvider>

              <AnimatePresence>
                {login && (
                  <motion.div
                    initial={{ opacity: 0, scaleY: 0, y: -125, zIndex: 10 }}
                    animate={{ opacity: 1, scaleY: 1, y: 0, zIndex: 10 }}
                    exit={{ opacity: 0, scaleY: 0, y: -130, zIndex: 10 }}
                    className="absolute font-Russo right-0 left-0 z-50 my-auto pt-2 mx-auto rounded-md shadow-md shadow-slate-900 h-fit w-[90vw] sm:w-[60vw] md:w-[27rem] px-2 bg-slate-100"
                  >
                    <form
                      onSubmit={handleLogin}
                      className="pb-10 flex flex-col w-full px-8"
                    >
                      <input
                        onChange={(e) => setEmail(e.target.value)}
                        className="relative text-lg border shadow-black shadow-sm pl-1 py-5 rounded-sm h-8 border-black"
                        type="email"
                        placeholder="Email..."
                      />
                      <input
                        onChange={(e) => setPassword(e.target.value)}
                        className="text-lg border shadow-black shadow-sm pl-1 py-5 rounded-sm mt-1 h-8 border-black"
                        type="password"
                        placeholder="Password..."
                      />
                      <motion.button
                        className="mt-3 w-[90%] self-center tracking-wider py-1 font-bold rounded-md shadow-md shadow-black bg-slate-800 text-white"
                        type="submit"
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                      >
                        Login
                      </motion.button>

                      <div className="flex flex-col mx-auto mt-8 justify-center w-[85%] sm:w-[80%] md:w-4/6 h-24">
                        <div className="flex h-3 mt-2 mb-1">
                          <span className="w-full border-black border-b-2"></span>
                          <span className="w-1/3 font-semibold text-center">
                            or
                          </span>
                          <span className="w-full border-black border-b-2"></span>
                        </div>
                        <motion.button
                          className="flex flex-nowrap mt-3 py-1 justify-center align-middle w-full h-fit font-bold rounded-md shadow-md shadow-black bg-slate-200"
                          type="button"
                          onClick={signInWithGoogle}
                          whileHover={{ scale: 1.1 }}
                          whileTap={{ scale: 0.9 }}
                        >
                          <div className="scale-125 mx-auto mr-4 mt-1 h-4 w-4">
                            {" "}
                            <FcGoogle />{" "}
                          </div>
                          <span className="mx-auto ml-0">
                            Login With Google
                          </span>
                        </motion.button>
                        <motion.button
                          className="w-[80%] min-w-fit px-1 h-fit mx-auto mt-6 font-bold rounded-md  shadow-black bg-slate-300"
                          type="button"
                          onClick={() => setShowRegister(true)}
                          whileHover={{ scale: 1.1 }}
                          whileTap={{ scale: 0.9 }}
                        >
                          Register{" "}
                        </motion.button>
                        <motion.button
                          className="w-[80%] min-w-fit px-1 h-fit mx-auto mt-1 font-bold rounded-md shadow-black bg-slate-300"
                          type="button"
                          onClick={resetPassword}
                          whileHover={{ scale: 1.1 }}
                          whileTap={{ scale: 0.9 }}
                        >
                          Forgot Password?
                        </motion.button>
                      </div>
                    </form>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
        </div>
      </div>

      <div className="w-screen my-20 lg:my-5 h-fit font-Russo">
        <div className="w-full lg:w-2/3 h-fit mx-auto bg-slate-900 rounded-lg text-center shadow-md shadow-slate-800">
          <h2 className="flex flex-col md:flex-row w-full md:w-fit p-3 mx-auto text-3xl text-white justify-center align-middle">
            <span className="h-fit mt-3">No Support Staff?</span>{" "}
            <div className="h-20 md:h-16 w-fit mx-auto mt-2 md:my-0 md:ml-4 bg-slate-900 rounded-lg p-1">
              <img className="h-full" src={EvaluxClearLogo} />
            </div>
            <span className="h-fit mt-3">Has You Covered.</span>
          </h2>
        </div>
      </div>

      <div
        className="mx-auto w-[98%] md:w-[50vw] my-16"
        viewport={{ once: true, amount: 0.8 }}
        initial="offscreen"
        whileInView="onscreen"
      >
        <VideoDisplay />
      </div>

      {/*Feature Bento Box*/}
      <div className="md:grid font-Russo cursor-default flex flex-wrap lg:grid-cols-4 gap-3 mx-auto mt-20 w-[96%] md:w-[94%] h-fit rounded-xl transition-all">
        <div className="group opacity-70 hover:opacity-100 relative col-span-1 flex h-[13.5rem] w-full rounded-xl transition-all hover:scale-[1.02] shadow-lg shadow-black bg-slate-100 bg-opacity-50 border-white">
          <img
            className="w-full h-full shadow-slate-800 shadow-md ml-0 m-auto group-hover:opacity-90 opacity-60 rounded-xl"
            src={ExcelBG}
          />
          <div className="absolute left-1/4 top-1/4 w-[73%] text-right text-3xl text-white group-hover:bg-opacity-80 bg-slate-800 p-2 bg-opacity-50 rounded-lg">
            Ditch the Complicated Spreadsheets
          </div>
        </div>
        <div className="group opacity-70 hover:opacity-100 col-span-1 p-3 h-[13.5rem] w-full rounded-xl transition-all hover:scale-[1.02] shadow-lg shadow-black bg-slate-100 bg-opacity-50 border-white">
          <div className="flex flex-col justify-center align-middle h-full w-full bg-slate-800 p-2 rounded-xl group-hover:bg-opacity-90 group-hover:text-white bg-opacity-30 transition-all">
            <div className="m-auto w-fit h-fit">
              <LuImport className="scale-[6] mt-8 group-hover:text-white" />
            </div>
            <div className="m-auto mb-4 text-center text-lg">
              Simply import your play data
            </div>
          </div>
        </div>
        <div className="group col-span-2 opacity-70 hover:opacity-100 relative overflow-hidden h-[13.5rem] w-full rounded-xl hover:scale-[1.02] transition-all shadow-lg shadow-black bg-slate-100 bg-opacity-50 border-white">
          <span className="absolute z-10 w-1/2 md:w-1/3 top-1/2 md:top-[60%] text-center left-2 text-white text-lg font-normal bg-slate-800 p-2 bg-opacity-50 group-hover:bg-opacity-80 rounded-lg">
            Live Weather Forecast and Radar
          </span>
          <img
            className="w-full min-h-full rounded-xl object-cover group-hover:opacity-80 opacity-60 border-2 border-black"
            src={WeatherSC}
          />
        </div>
        <div className="group opacity-70 hover:opacity-100 col-span-2 relative overflow-hidden h-[13.5rem] max-h-[13.5rem] w-full rounded-xl hover:scale-[1.02] transition-all shadow-md shadow-black bg-slate-100 bg-opacity-50 border-white">
          <span className="absolute z-10 md:w-1/3 w-2/3 top-1/2 md:top-[60%] text-center left-2 md:right-3 text-white text-2xl bg-slate-500 p-2 bg-opacity-50 group-hover:bg-opacity-80 rounded-lg">
            Get Players Recruited
          </span>
          <img
            className="w-full h-full rounded-xl object-cover group-hover:opacity-100 opacity-80 border-2 border-black"
            src={RecruitSC}
          />
        </div>
        <div className="group opacity-70 hover:opacity-100 col-span-1 flex h-[13.5rem] w-full rounded-xl transition-all hover:scale-[1.02] shadow-md shadow-black hover:bg-opacity-70 bg-slate-100 bg-opacity-50 border-white">
          <div className="flex flex-col m-auto rounded-lg">
            <div className="flex mx-auto w-16 h-16">
              <IoLogoFirebase className="scale-[400%] text-yellow-400 z-10 m-auto bg-slate-800 rounded-lg" />
            </div>
            <div className="flex w-full -mt-5">
              <div className="flex w-16 h-16 mx-auto mb-2">
                <FcGoogle className="scale-[400%] z-10 m-auto bg-slate-800 rounded-lg" />
              </div>
              <div className="flex w-16 h-16 mx-auto">
                <BsDatabaseFillLock className="scale-[400%] text-white transition-all z-10 m-auto bg-slate-800 rounded-lg" />
              </div>
            </div>
            <span className="z-10 w-fit mx-auto max-w-[90%] text-center left-2 text-white text-lg bg-slate-800 p-2 bg-opacity-50 group-hover:bg-opacity-80 rounded-lg">
              Backend Storage Secured By Google{" "}
              <span className="group-hover:text-yellow-400">Firebase</span>
            </span>
          </div>
        </div>
        <div
          id="pricing"
          className="scroll-mt-24 col-span-1 opacity-70 hover:opacity-100 h-[13.5rem] w-full rounded-xl transition-all hover:scale-[1.02] shadow-lg shadow-black bg-slate-100 bg-opacity-50 border-white"
        >
          <div className="group h-full flex rounded-xl">
            <div className="relative flex h-full rounded-xl w-full bg-gradient-to-r from-slate-600 via-slate-800 to-slate-600 bg-opacity-30 group-hover:bg-opacity-70">
              <div className="absolute hidden group-hover:block w-full text-white text-center top-0">
                <span className="text-yellow-400">Pro Plan</span> Only
              </div>
              <div className="transition-all group-hover:translate-x-[-7rem] w-16 m-auto">
                <MdOutlineAttachMoney className="scale-[800%] sm:scale-[1000%] z-10 text-yellow-400 m-auto" />
              </div>
              <div className="absolute flex left-[15%] top-[38%] w-full transition-all opacity-0 group-hover:opacity-100 group-hover:translate-x-16 md:group-hover:translate-x-8">
                <p className="my-auto ml-2 text-white text-5xl sm:text-6xl">
                  15{" "}
                  <span className="text-4xl ml-[-.5rem] sm:ml-[-1rem]">
                    /month
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          id="features"
          className="scroll-mt-20 font-Russo col-span-4 mt-2 hover:bg-opacity-100 h-fit min-h-[40rem] md:h-[80vh] w-full rounded-xl transition-all shadow-md shadow-black bg-gradient-to-b from-slate-800 to-slate-500 px-2 md:px-12 bg-opacity-50 border-white"
        >
          <div className="flex h-fit w-full overflow-auto pt-12 justify-between rounded-lg">
            <div className="flex w-full min-w-[40rem]">
              <div
                className={`flex flex-col ${
                  showFeature === "Roster Editor" ? "opacity-100" : "opacity-50"
                } pt-6 md:pt-0 hover:opacity-100 transition-all w-full h-fit`}
              >
                <button onMouseEnter={() => handleFeatures("Roster Editor")}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="mx-auto w-10 h-10 text-green-400"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z"
                    />
                  </svg>
                  <span className="text-white tracking-wide">
                    Roster Editor
                  </span>
                </button>
                <span className="w-[80%] mt-1 border-t-2 border-green-400 mx-auto text-center text-white"></span>
              </div>
              <div
                className={`flex flex-col ${
                  showFeature === "Depth Charts" ? "opacity-100" : "opacity-50"
                } pt-6 md:pt-0 hover:opacity-100 transition-all w-full h-fit relative`}
              >
                <button onMouseEnter={() => handleFeatures("Depth Charts")}>
                  <span
                    className={`absolute -top-7 left-0 right-0 tracking-wide text-yellow-300 bg- ${
                      showFeature === "Depth Charts" ? "animate-bounce" : ""
                    }`}
                  >
                    Pro Plan
                  </span>
                  <BsListOl className="mt-2 mb-3 scale-[2] text-xl mx-auto text-green-400" />
                  <span className="text-center text-white tracking-wide">
                    Depth Charts
                  </span>
                </button>
                <span className="w-[80%] mt-1 border-t-2 border-green-400 mx-auto text-center text-white"></span>
              </div>
              <div
                className={`flex flex-col ${
                  showFeature === "Grade Sheet" ? "opacity-100" : "opacity-50"
                } pt-6 md:pt-0 hover:opacity-100 transition-all w-full h-fit relative`}
              >
                <button onMouseEnter={() => handleFeatures("Grade Sheet")}>
                  <span
                    className={`absolute -top-7 left-0 right-0 tracking-wide text-yellow-300 ${
                      showFeature === "Grade Sheet" ? "animate-bounce" : ""
                    }`}
                  >
                    Pro Plan
                  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className=" mx-auto w-10 h-10 mb-6 md:mb-0 text-green-400"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                    />
                  </svg>
                  <span className="h-fit text-center text-white tracking-wide">
                    Grade Sheet
                  </span>
                </button>
                <span className="w-[80%] mt-1 border-t-2 border-green-400 mx-auto text-center text-white"></span>
              </div>
              <div
                className={`flex flex-col ${
                  showFeature === "Team Stats" ? "opacity-100" : "opacity-50"
                } pt-6 md:pt-0 hover:opacity-100 transition-all w-full h-fit`}
              >
                <button onMouseEnter={() => handleFeatures("Team Stats")}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="mx-auto w-10 h-10 mb-6 md:mb-0 text-green-400"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
                    />
                  </svg>
                  <span className="h-fit text-center text-white tracking-wide">
                    Team Stats
                  </span>
                </button>
                <span className="w-[80%] mt-1 border-t-2 border-green-400 mx-auto text-center text-white"></span>
              </div>
              <div
                className={`flex flex-col ${
                  showFeature === "Analytics" ? "opacity-100" : "opacity-50"
                } pt-6 md:pt-0 hover:opacity-100 transition-all w-full h-fit`}
              >
                <button onMouseEnter={() => handleFeatures("Analytics")}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="mx-auto w-10 h-10 mb-6 md:mb-0 text-green-400"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z"
                    />
                  </svg>
                  <span className="h-fit text-center text-white tracking-wide">
                    Analytics
                  </span>
                </button>
                <span className="w-[80%] mt-1 border-t-2 border-green-400 mx-auto text-center text-white"></span>
              </div>
              <div
                className={`flex flex-col ${
                  showFeature === "WristCaller" ? "opacity-100" : "opacity-50"
                } pt-6 md:pt-0 hover:opacity-100 transition-all w-full h-fit`}
              >
                <button onMouseEnter={() => handleFeatures("WristCaller")}>
                  <FaHeadset className="w-9 h-9 mx-auto mb-7 md:mb-1 text-green-400 font-thin" />

                  <span className="h-fit text-center text-white tracking-wide">
                    WristCaller
                  </span>
                </button>
                <span className="w-[80%] mt-1 border-t-2 border-green-400 mx-auto text-center text-white"></span>
              </div>
              <div
                className={`flex flex-col ${
                  showFeature === "Recruiting" ? "opacity-100" : "opacity-50"
                } pt-6 md:pt-0 hover:opacity-100 transition-all w-full relative h-fit`}
              >
                <button onMouseEnter={() => handleFeatures("Recruiting")}>
                  <span
                    className={`absolute -top-7 left-0 right-0 tracking-wide text-yellow-300 ${
                      showFeature === "Recruiting" ? "animate-bounce" : ""
                    }`}
                  >
                    Pro Plan
                  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-10 h-10 mx-auto mb-6 md:mb-0 text-green-400"
                  >
                    <path d="M11.584 2.376a.75.75 0 01.832 0l9 6a.75.75 0 11-.832 1.248L12 3.901 3.416 9.624a.75.75 0 01-.832-1.248l9-6z" />
                    <path
                      fillRule="evenodd"
                      d="M20.25 10.332v9.918H21a.75.75 0 010 1.5H3a.75.75 0 010-1.5h.75v-9.918a.75.75 0 01.634-.74A49.109 49.109 0 0112 9c2.59 0 5.134.202 7.616.592a.75.75 0 01.634.74zm-7.5 2.418a.75.75 0 00-1.5 0v6.75a.75.75 0 001.5 0v-6.75zm3-.75a.75.75 0 01.75.75v6.75a.75.75 0 01-1.5 0v-6.75a.75.75 0 01.75-.75zM9 12.75a.75.75 0 00-1.5 0v6.75a.75.75 0 001.5 0v-6.75z"
                      clipRule="evenodd"
                    />
                    <path d="M12 7.875a1.125 1.125 0 100-2.25 1.125 1.125 0 000 2.25z" />
                  </svg>
                  <span className="text-center text-white tracking-wide">
                    Recruiting
                  </span>
                </button>
                <span className="w-[80%] mt-1 border-t-2 border-green-400 mx-auto text-center text-white"></span>
              </div>
            </div>
          </div>
          <div className="flex mt-4 h-fit md:h-3/4 w-full">
            <FeatureDisplay />
          </div>
        </div>
      </div>

      {/*Video Player */}
      {/* <div
        id="features"
        className="relative bg-gradient-to-b to-transparent from-transparent via-slate-900 transition-opacity z-10 rounded-3xl md:px-28 py-6 mt-12 md:mt-12 text-lg font-bold text-center h-fit"
      >
        <div className="flex flex-col lg:flex-row w-full h-fit lg:h-96">
          <div className="flex overflow-x-auto snap-mandatory snap-x px-8 lg:px-0 lg:overflow-hidden lg:flex-col h-fit lg:ml-16 w-full lg:w-1/3 z-10">
            <div
              onMouseEnter={() => handleVideos("Evaluation")}
              className={`flex flex-col transition-all cursor-pointer ${
                showVideo === "Evaluation"
                  ? "shadow-md shadow-slate-800 border-r-green-400 border-4 "
                  : "shadow-sm"
              } snap-center rounded-md min-w-[20rem] w-1/3 mx-3 my-4 py-4 h-fit bg-slate-900 shadow-sm shadow-white text-white hover:text-green-400 hover:border-green-400 font-bold text-xl`}
            >
              Evaluation
              <BsPencilSquare className="mx-auto scale-125 mt-2" />
            </div>
            <div
              onMouseEnter={() => handleVideos("Recruiting")}
              className={`flex flex-col transition-all cursor-pointer ${
                showVideo === "Recruiting"
                  ? "shadow-md shadow-slate-800 border-r-green-400 border-4 "
                  : "shadow-sm"
              } snap-center rounded-md min-w-[20rem] w-1/3 mx-3 my-4 py-4 h-fit bg-slate-900 shadow-sm shadow-white text-white hover:text-green-400 hover:border-green-400 font-bold text-xl`}
            >
              Recruiting
              <ImProfile className="mx-auto scale-125 mt-2" />
            </div>
            <div
              onMouseEnter={() => handleVideos("Operations")}
              className={`flex flex-col transition-all cursor-pointer ${
                showVideo === "Operations"
                  ? "shadow-md shadow-slate-800 border-r-green-400 border-4 "
                  : "shadow-sm"
              } rounded-md snap-center min-w-[20rem] w-1/3 mx-3 my-4 py-4 h-fit bg-slate-900 shadow-sm shadow-white text-white hover:text-green-400 hover:border-green-400 font-bold text-xl`}
            >
              Operations
              <BiTask className="mx-auto scale-[135%] mt-2" />
            </div>
          </div>
          <motion.div
            className="w-fit mx-auto lg:w-2/3 h-full"
            viewport={{ once: true, amount: 0.8 }}
            initial="offscreen"
            whileInView="onscreen"
          >
            <VideoDisplay />
          </motion.div>
        </div>
      </div> */}

      {/*Problem / Solution */}
      <div className="w-screen font-Russo h-fit mt-6 md:mt-12 lg:mt-20">
        <div className="w-full rounded-none md:w-5/6 lg:w-3/4 px-4 h-fit mx-auto bg-slate-100 bg-opacity-80 md:rounded-lg text-start pt-1 pb-4">
          <h3 className="font-semibold my-4 px-10 text-2xl text-center">
            Limited <span className="font-bold text-red-600">TIME</span>.
            Limited <span className="font-bold text-red-600">STAFF</span>.{" "}
            <br /> New software
            <span className="font-bold text-red-600">
              {" "}
              OVERWHELMING
            </span> and <span className="font-bold text-red-600">COMPLEX</span>.
          </h3>
          <div className="flex flex-col mt-2 px-2 md:flex-row">
            <div className="h-full w-fit p-2 flex mx-auto md:w-1/2 text-center">
              <img
                className="h-64 sm:h-72 w-fit shadow-md rounded-sm shadow-slate-800"
                src={
                  "https://firebasestorage.googleapis.com/v0/b/gradeapp-fddad.appspot.com/o/Utah%20Champs.webp?alt=media&token=592f07aa-5e48-41bf-bae2-c91d8398b151"
                }
              />
            </div>
            <div className="ml-auto w-fit mx-auto md:w-1/2 mt-8">
              <div className="font-bold text-xl">No More Excuses.</div>
              <h5 className="mb-4 text-lg font-inter font-semibold tracking-normal">
                Elevate your workflow to that of a championship program.
              </h5>
              <h2 className="font-bold text-xl">
                Streamlined, Elegant, Essential.
              </h2>
              <h3 className="text-lg font-inter font-semibold tracking-normal">
                Our user-friendly programs are designed for quick learning,
                seamless usage, and the elimination of unnecessary complexities.
                Say goodbye to hours of input or tallying data by hand – use our
                applications and get back to coaching faster.
              </h3>
            </div>
          </div>
        </div>
      </div>

      {/*Benefits */}
      <div className="w-screen h-fit mt-6 md:mt-12 lg:mt-20">
        <div className="w-screen overflow-x-auto rounded-none md:w-5/6 h-fit md:mx-auto border-2 pb-2 snap-x snap-proximity scrollbar-none">
          <div className="flex w-fit md:w-full gap-6 p-3 text-white pt-10 px-20 md:px-3 cursor-default">
            <div className="w-64 snap-center md:w-fit text-center">
              <SiAwsorganizations className="scale-[3.5] mb-10 text-yellow-300 w-fit mx-auto" />
              <h3 className="w-fit mx-auto mb-2 text-xl font-Russo">
                Minimize The Mess
              </h3>
              <p className="text-center opacity-50 hover:opacity-90 text-lg tracking-wide">
                Experience the convenience of having all crucial information in
                one centralized hub. Seamlessly integrate your roster, depth,
                grades, and recruiting data for a clutter-free experience.
              </p>
            </div>
            <div className="w-64 snap-center md:w-fit text-center">
              <GiAmericanFootballPlayer className="scale-[4] mb-10 text-yellow-300 w-fit mx-auto" />
              <h3 className="w-fit mx-auto mb-2 text-xl font-Russo">
                Maximize Your Personnel
              </h3>
              <p className="text-center opacity-50 hover:opacity-90 text-lg tracking-wide">
                Empower your decision-making with visual analytics at your
                fingertips. Our platform calculates data swiftly, presents it in
                an easy-to-read format, allowing you to identify and capitalize
                on the unique talents within your roster.
              </p>
            </div>
            <div className="w-64 snap-center md:w-fit text-center">
              <GiWhistle className="scale-[4.5] mb-8 mt-2 text-yellow-300 w-fit mx-auto" />
              <h3 className="w-fit mx-auto mb-2 text-xl font-Russo">
                Focus on Coaching, Not Logistics
              </h3>
              <p className="text-center opacity-50 hover:opacity-90 text-lg tracking-wide">
                Our user-friendly systems handle the logistics, giving you the
                freedom to concentrate on what you love – coaching. Spend more
                time refining your scheme and mentoring your team, while we take
                care of the rest.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col text-white px-2 md:px-8 mt-16 text-lg font-bold text-center w-full sm:w-[80%] mx-auto p-6 rounded-none md:rounded-xl sm:rounded-xl">
        <div className="text-5xl font-Russo mx-auto text-green-500 mb-4">
          FAQ
        </div>
        <FAQSection />
      </div>

      <div
        id="theTeam"
        className="flex flex-col sm:flex-row sm:px-8 mt-64 md:mt-24 text-lg text-center w-full h-fit"
      >
        <div className="text-white flex flex-col w-full sm:ml-[10%]">
          <div className="h-fit font-Russo">Founder & Developer</div>
          <div className="mt-[-13rem] h-fit w-[90%] mx-auto sm:mt-0 sm:ml-2 rounded-lg">
            <img src={UtahCandid} loading="lazy" alt="Founder" />
            <div className="font-Russo">Coach Cameron D'Angelo</div>
          </div>
        </div>
        <p className="mt-6 mx-auto text-white font-medium ml-4 my-auto mr-4 sm:mx-[5%]">
          With over 7 years of experience coaching collegiate football, I
          witnessed firsthand the inefficiencies present at every level. This
          motivated me to leverage my skills in web development to create a
          solution that could save our most valuable resource:{" "}
          <span className="text-orange-400">TIME</span>.
          <br />
          <br />
          Initially, I experimented in Excel, making the tedious task of data
          input into something much more efficient and enjoyable. While helpful,
          I was determined to create a tool that could benefit my fellow coaches
          as well. I sought to develop a user-friendly and intuitive platform
          that could provide the structure necessary for a successful Division I
          program to at all levels of the game. This led to the inception of{" "}
          <span className="text-green-500">EvaluX</span>.
          <div className="mt-12 md:mt-32 flex-col md:w-1/2">
            <p>Contact</p>
            <div className="border-b-2 border-white w-full mb-1"></div>
            <span className="flex">
              <FaPhone className="mt-1 m-2" />: 407-462-8727
            </span>
            <span className="flex">
              <MdEmail className="mt-1 m-2" />: cam.dangelo@evalu-x.com
            </span>
            <span className="flex">
              <FaXTwitter className="mt-1 m-2" />:{" "}
              <div className="flex flex-col items-start ml-1">
                <span>
                  {" "}
                  Business:{" "}
                  <a
                    href={`https://twitter.com/@EvaluXApp`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-lg hover:text-blue-400"
                  >
                    @EvaluXApp
                  </a>
                </span>
                <span>
                  {" "}
                  Personal:{" "}
                  <a
                    href={`https://twitter.com/@CoachDangelo55`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-lg hover:text-blue-400"
                  >
                    @CoachDangelo55
                  </a>
                </span>
              </div>
            </span>
          </div>
        </p>
      </div>

      <footer className="h-fit w-full mt-24 bg-slate-500 bg-opacity-80 text-white">
        <div className="flex flex-col items-center w-fit mx-auto">
          <div className="flex flex-col my-6">
            <div className="my-auto font-semibold">
              Generously funded in part by
            </div>
            <div className="flex p-2 bg-slate-100 rounded-lg">
              <img className="mx-2 h-24" src={BerksALogo} />
              <img className="h-12 my-auto" src={PCALogo} />
            </div>
          </div>
          <div className="flex my-2">
            <span className="mr-4">
              <NavLink
                to="/privacy"
                className="text-sm opacity-80"
                activeClassName="opacity-100"
              >
                Privacy Policy
              </NavLink>
            </span>
            <span>
              <NavLink
                to="/terms"
                className="text-sm opacity-80"
                activeClassName="opacity-100"
              >
                Terms of Use
              </NavLink>
            </span>
          </div>
          <div className="flex">
            <BiCopyright className="mr-1 mt-1" /> EvaluX LLC 2024
          </div>
        </div>
      </footer>
    </motion.div>
  );
}

export default Login;
