import React, { useState, useContext, useEffect } from "react";
import { motion } from "framer-motion";
import { RecruitingInputs } from "../SourceData/RecruitingData";
import { Button, ConfigProvider, Modal, Skeleton } from "antd";
import { AuthContext } from "../context/AuthContext";
import { PathContext } from "../context/PathContext";

export function RPMRecruitingProfile({
  handleClose,
  profileInfo,
  recruitingProfileActive,
}) {
  const [data, setData] = useState(profileInfo ? profileInfo : null);
  const [LOCOptions, setLOCOptions] = useState([]);
  const [multiSportOptions, setmultiSportOptions] = useState([]);

  const { currentUser } = useContext(AuthContext);
  const { selectedPath } = useContext(PathContext);

  useEffect(() => {
    const rawData = profileInfo ? [profileInfo] : [];
    if (profileInfo) {
      const locChecked = profileInfo.expectedLOC ? profileInfo.expectedLOC : [];
      const multisportChecked = profileInfo.multiSport
        ? profileInfo.multiSport
        : [];

      setLOCOptions(locChecked);
      setmultiSportOptions(multisportChecked);
    }

    setData(...rawData);
  }, [profileInfo]);

  const [expectedLOC, setExpectedLOC] = useState([]);
  const [multiSport, setMultiSport] = useState([]);

  const handleCheckboxChange = (e, label, option) => {
    console.log(
      `Label: ${label}, Option: ${option}, Checked: ${e.target.checked}`
    );

    const predefinedValues = [
      "NJCAA",
      "Sprint Football",
      "NAIA",
      "Division III",
      "Division II",
      "Division I FCS",
      "Division I FBS",
    ];

    if (predefinedValues.includes(option)) {
      if (e.target.checked) {
        // If the option is predefined and the checkbox is checked, add it to the appropriate array
        if (!expectedLOC.includes(option)) {
          setExpectedLOC((prevExpectedLOC) => [...prevExpectedLOC, option]);
        }
      } else {
        // If the option is predefined and the checkbox is unchecked, remove it from the appropriate array
        setExpectedLOC((prevExpectedLOC) =>
          prevExpectedLOC.filter((item) => item !== option)
        );
      }
    } else {
      if (e.target.checked) {
        // If the option is not predefined and the checkbox is checked, add it to the multiSport array
        if (!multiSport.includes(option)) {
          setMultiSport((prevMultiSport) => [...prevMultiSport, option]);
        }
      } else {
        // If the option is not predefined and the checkbox is unchecked, remove it from the multiSport array
        setMultiSport((prevMultiSport) =>
          prevMultiSport.filter((item) => item !== option)
        );
      }
    }
    setData({ ...data, expectedLOC, multiSport, userID: currentUser.uid });
  };

  console.log(data);

  const addInputsToData = (e) => {
    const id = e.target.id;
    const value = e.target.value;

    setData({ ...data, [id]: value, userID: currentUser.uid });
    console.log(data);
  };

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    // Simulate an asynchronous loading process
    const fakeLoadingProcess = setTimeout(() => {
      setIsLoaded(true);
    }, 2000); // Adjust the duration according to your actual loading time

    // Clean up the timeout to avoid memory leaks
    return () => clearTimeout(fakeLoadingProcess);
  }, []); // Empty dependency array means this effect runs once after the initial render

  return (
    <ConfigProvider
      theme={{
        components: {
          Modal: {
            /* here is your component tokens */
            contentBg: "opacity-50",
          },
        },
      }}
    >
      <Modal
        className="h-fit overflow-hidden my-auto"
        centered
        width={1300}
        open={recruitingProfileActive}
        onOk={handleClose}
        onCancel={handleClose}
        destroyOnClose={true}
        footer={[
          <Button key="back" onClick={handleClose}>
            Close
          </Button>,
        ]}
      >
        <div className="h-[80vh] py-4 pb-6 pt-0">
          <h1 className="font-bold pb-1 text-2xl md:ml-4 md:text-left ml-0 text-center">
            Recruiting Profile
          </h1>
          <div className="relative flex flex-col h-[98%] sm:h-[95%] flex-nowrap sm:pl-10 mt-2 overflow-y-auto scrollbar-hide overflow-x-hidden m-auto w-[98%] sm:w-[90%] border-8 shadow-sm shadow-black md:border-4 border-slate-950 bg-opacity-30 backdrop-blur-md bg-slate-500 rounded-xl">
            <div className="z-30 sticky top-0 flex h-[12rem] md:w-[96%] mr-auto p-4 md:pr-8 bg-gradient-to-tl from-slate-950 to-slate-800 rounded-b-lg shadow-sm shadow-black">
              <div className="flex flex-nowrap w-full h-full overflow-x-auto overflow-y-hidden">
                <div className="relative w-fit h-fit mt-2 md:mt-0 min-w-fit">
                  <img
                    className="h-24 w-fit md:h-40 md:ml-4 mr-3 min-w-fit bg-slate-100 rounded-xl"
                    src={profileInfo.img}
                    alt="profile"
                  />
                </div>
                <div className="flex flex-col w-fit text-2xl gap-2 font-semibold text-white">
                  <div className="text-xl">
                    <span>{profileInfo.position} </span>
                    <span># {profileInfo["jersey-number"]}</span>
                  </div>
                  <span className=" font-bold">{profileInfo["full-name"]}</span>
                  <div className="text-xl">
                    <span className="mr-4">{profileInfo.height}</span>
                    <span>{profileInfo.weight}</span>
                  </div>
                </div>
                <div className="text-white flex flex-col pl-[10%] pt-1 w-fit">
                  <div className="mb-1">
                    <span className="font-semibold">Class: </span>
                    <span className="">
                      {profileInfo.hsgradYear} ({profileInfo.year})
                    </span>
                  </div>
                  <div className="mb-1">
                    <span className="font-semibold">Twitter: </span>
                    <span className="">{profileInfo.Xtwitter}</span>
                  </div>
                  <div className="mb-1">
                    <span className="font-semibold">Email: </span>
                    <span className="">{profileInfo.email}</span>
                  </div>
                  <div className="mb-1">
                    <span className="font-semibold">Phone: </span>
                    <span className="">{profileInfo.phone}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="ml-2">
              {RecruitingInputs.map((input) => {
                if (!profileInfo) {
                  return (
                    <div className="rounded-sm h-12 m-1 mt-3" key={input.id}>
                      <label className="z-10 mr-12 rounded-sm rounded-l-none shadow-slate-800 shadow-sm text-center px-2 bg-[#f1efe9] font-bold">
                        {input.label}:
                      </label>
                      <div className="ml-1 pl-2 h-fit text-black shadow-slate-600 bg-white bg-opacity-90 hover:bg-opacity-100 shadow-sm w-full rounded-b-md min-w-fit">
                        <Skeleton.Input
                          className="py-1 h-fit"
                          active
                          size="small"
                        />
                      </div>
                    </div>
                  );
                } else {
                  const placeholder = profileInfo
                    ? profileInfo[input.id]
                    : null;
                  return (
                    <div
                      className="flex flex-col rounded-sm h-fit w-full m-1 mt-3"
                      key={input.id}
                    >
                      <label className="z-10 w-fit mr-12 rounded-sm rounded-l-none shadow-slate-800 shadow-sm text-center px-2 bg-[#f1efe9] font-bold">
                        {input.label}:
                      </label>
                      {input.type === "select" ? (
                        <select
                          className="cursor-pointer ml-1 pl-1 h-8 w-[30%] text-black shadow-slate-600 bg-white bg-opacity-90 hover:bg-opacity-100 shadow-sm rounded-b-md min-w-fit"
                          id={input.id}
                          onChange={addInputsToData}
                        >
                          <option selected disabled hidden>
                            {placeholder}
                          </option>
                          {input.options.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      ) : input.type === "checkbox" ? (
                        <div className="ml-1 pl-2 h-fit text-black shadow-slate-600 bg-white bg-opacity-90 hover:bg-opacity-100 shadow-sm w-1/4 rounded-b-md min-w-fit">
                          {input.options.map((option) => (
                            <div key={option}>
                              <input
                                type="checkbox"
                                id={`${input.id}-${option}`}
                                name={input.id}
                                value={option}
                                defaultChecked={
                                  LOCOptions.includes(option) ||
                                  multiSportOptions.includes(option)
                                } // You can use your own logic here
                                onChange={(e) =>
                                  handleCheckboxChange(e, input.label, option)
                                }
                              />
                              <label
                                htmlFor={`${input.id}-${option}`}
                                className="ml-2 pr-2"
                              >
                                {option}
                              </label>
                            </div>
                          ))}
                        </div>
                      ) : input.type === "string" ? (
                        <>
                          <textarea
                            className="ml-1 pl-2 h-10 min-h-fit w-[90%] md:w-2/3 whitespace-normal text-black shadow-slate-600 bg-white bg-opacity-90 hover:bg-opacity-100 shadow-sm rounded-b-md min-w-fit"
                            id={input.id}
                            type={input.type}
                            defaultValue={placeholder}
                            placeholder={input.placeholder}
                            onChange={addInputsToData}
                          />
                          {input.id === "filmLink" && isLoaded ? (
                            <div className="w-[95%] max-w-[36rem] mt-1 aspect-video relative">
                              <div
                                className="absolute top-0 left-0 w-full h-full"
                                dangerouslySetInnerHTML={{
                                  __html: placeholder,
                                }}
                              />
                            </div>
                          ) : null}
                        </>
                      ) : input.type === "number" ? (
                        <input
                          className="ml-1 pl-2 h-8 w-fit text-black shadow-slate-600 bg-white bg-opacity-90 hover:bg-opacity-100 shadow-sm rounded-b-md min-w-fit"
                          id={input.id}
                          type={input.type}
                          defaultValue={placeholder}
                          placeholder={input.placeholder}
                          onChange={addInputsToData}
                        />
                      ) : null}
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
      </Modal>
    </ConfigProvider>
  );
}
