import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { PathContext } from "../context/PathContext";
import { Cascader, Button, Input, Popconfirm, Space } from "antd";
import { motion } from "framer-motion";
import { db } from "../Firebase-config";
import { doc, getDoc, writeBatch } from "firebase/firestore";
import { AnimatePresence } from "framer-motion";
import { openNotificationWithIcon } from "../helperFunctions/openNotificationWithIcon";

export function DCTitleCascader(props) {
  const [dataTitle, setDataTitle] = useState([]);
  const [optionBar, setOptionBar] = useState(false);
  const [dataDocTitle, setDataDocTitle] = useState([]);
  const [cascaderDisabled, setCascaderDisabled] = useState(false);
  const [labelPlaceholder, setLabelPlaceholder] = useState([
    {
      value: "eg. Year",
      label: "eg. Year",
      children: [
        {
          value: "eg. Offense",
          label: "eg. Offense",
          children: [
            {
              value: "eg. 11p",
              label: "eg. 11p",
            },
          ],
        },
        {
          value: "eg. Defense",
          label: "eg. Defense",
        },
        {
          value: "eg. Special Teams",
          label: "eg. Special Teams",
        },
      ],
    },
  ]);

  const { currentUser } = useContext(AuthContext);
  const { selectedPath } = useContext(PathContext);

  const barOpen = () => {
    setOptionBar(true);
    setUpload(null);
    setCascaderDisabled(true);
    console.log(dataTitle);
  };

  const barClosed = async () => {
    console.log(dataDocTitle);
    const batch = writeBatch(db);
    setCascaderDisabled(false);
    setOptionBar(false);
    console.log(upload);
    if (upload) {
      const docRef = doc(db, "Teams", selectedPath, "DataLabels", "DCLabels");
      batch.set(docRef, upload);
      // const dataDocRef1 = doc(
      //   db,
      //   "Teams",
      //   selectedPath,
      //   "DepthChart",
      //   dataDocTitle
      // );
      // if (dataDocRef1) {
      //   batch.delete(dataDocRef1);
      // }
      try {
        await batch.commit();
        openNotificationWithIcon(
          "success",
          "Depth Chart Labels Updated",
          "",
          "top",
		  '',
          "2"
        );
        console.log("Batch operation complete");
      } catch (error) {
        openNotificationWithIcon("error", "error", error, "top", '', "3");
      }
    }
    console.log(upload);
  };

  useEffect(() => {
    const getTitleDoc = async () => {
      const docRef = doc(db, "Teams", selectedPath, "DataLabels", "DCLabels");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists() && docSnap.data().dataTitle !== undefined) {
        setDataTitle(docSnap.data().dataTitle);
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
        setDataTitle(labelPlaceholder);
      }
    };
    getTitleDoc();
  }, [selectedPath]);

  const [newOption, setNewOption] = useState([]);
  const [upload, setUpload] = useState();
  const [cascaderValue, setCascaderValue] = useState();

  const addOption = async () => {
    const initialTitle = () => {
      if (dataTitle === labelPlaceholder) {
        return [];
      } else {
        return dataTitle;
      }
    };
    let parent = initialTitle();

    if (cascaderValue !== undefined) {
      // Find the parent node of the new option
      for (let i = 0; i < cascaderValue.length; i++) {
        let node = parent.find((option) => option.value === cascaderValue[i]);
        if (node.children) {
          parent = node.children;
        } else {
          node.children = [];
          parent = node.children;
        }
      }
    }

    // Check for duplicates
    if (
      parent !== undefined &&
      parent.some((option) => option.value === newOption)
    ) {
      openNotificationWithIcon(
        "warning",
        "Duplicate Label",
        "Choose a different name.",
        "top",
		'',
        "3"
      );
      return;
    }

    // Add the new option
    parent.push({
      value: newOption,
      label: newOption,
      children: [],
    });
    openNotificationWithIcon(
      "success",
      "Option Added",
      "Close the menu to save changes.",
      "top",
	  '',
      "2"
    );

    setUpload({ dataTitle });
    setDataTitle([...dataTitle]);
  };

  const handleDeleteOption = async () => {
    let parent = dataTitle;

    if (cascaderValue !== undefined) {
      // Find the parent node of the new option
      for (let i = 0; i < cascaderValue.length; i++) {
        let node = parent.find((option) => option.value === cascaderValue[i]);
        if (node.children) {
          parent = node.children;
        } else {
          node.children = [];
          parent = node.children;
        }
      }
    }

    // Check for duplicates
    if (
      parent !== undefined &&
      parent.some((option) => option.value === newOption)
    ) {
      let node = parent.find((option) => option.value === newOption);

      // Check for multiple children
      if (node.children && node.children.length > 0) {
        openNotificationWithIcon(
          "error",
          "Cannot Delete Group Header",
          "Delete all events under the header first.",
          "top",
		  '',
          "3"
        );
        return;
      }

      const nonFalsyCascaderValue = !cascaderValue
        ? node.value
        : cascaderValue + "," + node.value;

      parent.splice(parent.indexOf(node), 1);
      setDataDocTitle(nonFalsyCascaderValue);
      setUpload({ dataTitle });
      setDataTitle([...dataTitle]);
      console.log(dataTitle);
      openNotificationWithIcon("success", "Label & Data Removed", "", "top", '', "2");
    } else {
      openNotificationWithIcon("error", "Label Does Not Exist!", "", "top", '', "2");
      return;
    }
  };

  const labelInputDefault = () => {
    if (dataTitle == labelPlaceholder) {
      return [];
    } else {
      return dataTitle;
    }
  };

  return (
    <div className="relative h-fit">
      <div className="flex w-full h-fit flex-col rounded-lg">
        <div className="flex flex-row">
          <Cascader
            className="h-full w-full"
            size="large"
            options={dataTitle}
            disabled={cascaderDisabled}
            onChange={(e) => props.updateTitle(e)}
            changeOnSelect
            onClear={(e) => props.updateTitle(e)}
            maxTagCount="responsive"
            placeholder="Select Depth Chart"
          />

          <Button
            className="h-8 w-fit ml-[-10px]"
            type="secondary"
            onClick={() => (optionBar ? barClosed() : barOpen())}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 text-white hover:text-yellow-300"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </Button>
        </div>

        <AnimatePresence>
          {optionBar && (
            <motion.div
              initial={{ opacity: 0, y: -10, scaleX: 0, scaleY: 0 }}
              animate={{ opacity: 1, y: 0, scaleX: 1, scaleY: 1 }}
              exit={{ opacity: 0, y: -10, scaleX: 0, scaleY: 0 }}
              className="absolute z-50 top-12 ml-[5%] mr-[10%] p-1 flex bg-white m-1 rounded-lg border-4 border-black"
            >
              <Space.Compact className="w-full">
                <div className="flex flex-col w-full">
                  <div className="flex text-center">
                    <div className="font-semibold ml-2">
                      Select All Labels that Apply
                    </div>
                    <div className="font-semibold mx-auto">
                      Type in the New Label
                    </div>
                  </div>
                  <Input
                    className="w-full"
                    addonBefore={
                      <Cascader
                        options={labelInputDefault()}
                        value={cascaderValue}
                        changeOnSelect
                        onChange={(e) => setCascaderValue(e)}
                        placeholder="Year / ODK / Personnel"
                      />
                    }
                    placeholder="11p"
                    onChange={(e) => setNewOption(e.target.value)}
                  />
                  <div className="flex w-fit mx-auto">
                    <Button
                      className="text-black w-fit bg-slate-200"
                      type="primary"
                      onClick={addOption}
                    >
                      Add Label
                    </Button>
                    <Popconfirm
                      okType="danger"
                      title="Delete this label and ALL associated DATA?"
                      onConfirm={handleDeleteOption}
                    >
                      <Button className="text-black" type="dashed">
                        Remove Label & Data
                      </Button>
                    </Popconfirm>
                  </div>
                </div>
              </Space.Compact>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}
