import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import { db, storage } from "../Firebase-config";
import {
  collection,
  doc,
  onSnapshot,
  setDoc,
  writeBatch,
  query,
  serverTimestamp,
  getDoc,
  where,
  updateDoc,
  getDocs,
  deleteDoc,
} from "firebase/firestore";
import { AuthContext } from "../context/AuthContext";
import {
  Table,
  Button,
  Space,
  Input,
  Popconfirm,
  Spin,
  Form,
  Modal,
} from "antd";
import { SearchOutlined, DeleteOutlined } from "@ant-design/icons";
import { BiArchiveIn } from "react-icons/bi";
import { motion } from "framer-motion";
import Highlighter from "react-highlight-words";
import { PathContext } from "../context/PathContext";
import { TbShirtSport } from "react-icons/tb";
import { TbReportMedical } from "react-icons/tb";
import { LuGraduationCap } from "react-icons/lu";
import { deleteObject, ref } from "firebase/storage";
import { RecruitingProfile } from "./RecruitingProfile";
import { openNotificationWithIcon } from "../helperFunctions/openNotificationWithIcon";
import { AiOutlineVideoCamera, AiOutlineVideoCameraAdd } from "react-icons/ai";
import { GiAmericanFootballHelmet, GiBinoculars } from "react-icons/gi";
import { BsCheckCircleFill } from "react-icons/bs";
import { SlderPads } from "../customIcons/SlderPads";
import { FBPants } from "../customIcons/FBPants";
import { TeamContext } from "../context/TeamProvider";

export function Roster({ onChartData, rosterFilter, showAlt }) {
  const [Roster, setRoster] = useState([]);
  const [OGRoster, setOGRoster] = useState([]);
  const [recruitingProfileActive, setRecruitingProfileActive] = useState(false);
  const [recruitingProfileID, setRecruitingProfileID] = useState();

  const addClose = () => setRecruitingProfileActive(false);
  const addOpen = () => setRecruitingProfileActive(true);

  //   const { currentUser } = useContext(AuthContext);
  const { selectedPath } = useContext(PathContext);
  const { teamRosterData } = useContext(TeamContext);
  const { handleSaveRosterData } = useContext(TeamContext);
  const { loading } = useContext(TeamContext);

  const deleteUser = async (key) => {
    const profileDoc = doc(db, "Teams", selectedPath, "PlayerProfiles", key);
    const docSnapshot = await getDoc(profileDoc);
    const profileData = docSnapshot.data();
    console.log(profileData);

    // Remove email from access control list
    const accessControlListRef = doc(db, "Teams", selectedPath);
    const acldocSnapshot = await getDoc(accessControlListRef);
    const accessControlList = acldocSnapshot.data().Players || []; // Retrieve the current access control list array
    console.log(accessControlList);

    const index = accessControlList.findIndex(
      (email) => email === profileData.email
    );
    if (index > -1) {
      accessControlList.splice(index, 1); // Remove the email from the access control list
      console.log(
        `Email removed from access control list: ${profileData.email}`
      );
    }

    const batch = writeBatch(db); // Create a batch write operation

    //Check if the roster contains public recruits else turn off the recruiting location
    if (profileData.publicRecruit === "Yes") {
      const originalRoster = OGRoster;
      const OGpublicRecruits = originalRoster.filter(
        (profiles) => profiles.publicRecruit === "Yes"
      );
      const publicRecruits = OGpublicRecruits.filter(
        (profiles) => profiles.id !== key
      );

      // Query the 'recruitingProfiles' collection to find and delete documents with the same ID
      const q = query(
        collection(db, "recruitingProfiles"),
        where("id", "==", key)
      );
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        batch.delete(doc.ref);
      });

      if (publicRecruits?.length <= 0) {
        batch.update(accessControlListRef, { recruitingLocation: "no" });
        openNotificationWithIcon(
          "info",
          "Team Removed from Recruiting Map",
          "Your team has been removed from the recruiting map, as it does not contain any public recruiting profiles. Set a profile to public and your team will automatically be re-added.",
          "top"
        );
      }
      const publicProfile = doc(db, "recruitingProfiles", key);
      console.log(publicProfile);
      batch.delete(publicProfile);
    }
    batch.update(accessControlListRef, { Players: accessControlList }); // Add update operation to batch
    batch.delete(profileDoc); // Add delete operation to batch

    const imageUrl = profileData.img; // Replace with the actual field storing the image URL
    if (
      imageUrl !==
      "https://firebasestorage.googleapis.com/v0/b/gradeapp-fddad.appspot.com/o/LeonardoProfilePlaceholder.jpg?alt=media&token=274d0788-2cea-4b54-aba6-2571e51717bd"
    ) {
      // Delete the image URL from Firebase Storage
      const imageRef = ref(storage, imageUrl);
      try {
        await deleteObject(imageRef);
        console.log("Image URL deleted from Firebase Storage");
      } catch (error) {
        console.error(
          "Error deleting image URL from Firebase Storage: ",
          error
        );
      }
    }

    // Commit the batch write operation
    try {
      await batch.commit();
      console.log("Batch update completed successfully");
      openNotificationWithIcon("success", "Player Removed", "", "top");
    } catch (error) {
      console.error("Error updating Firestore in batch: ", error);
      openNotificationWithIcon(
        "error",
        "Error Removing Player",
        error,
        "bottom"
      );
    }
  };

  const setActivePlayer = async (key) => {
    const batch = writeBatch(db); // Create a batch write operation

    const profileDoc = doc(db, "Teams", selectedPath, "PlayerProfiles", key);
    const docSnapshot = await getDoc(profileDoc);
    const profileData = docSnapshot.data();
    console.log(profileData);

    batch.update(profileDoc, { activePlayer: "Inactive" });

    // Remove email from access control list if the player is switched to inactive
    const accessControlListRef = doc(db, "Teams", selectedPath);
    const acldocSnapshot = await getDoc(accessControlListRef);
    const accessControlList = acldocSnapshot.data().Players || []; // Retrieve the current access control list array
    console.log(accessControlList);

    const index = accessControlList.findIndex(
      (email) => email === profileData.email
    );
    if (index > -1) {
      accessControlList.splice(index, 1); // Remove the email from the access control list
      console.log(
        `Email removed from access control list: ${profileData.email}`
      );
    }

    //Check if the roster contains public recruits else turn off the recruiting location
    if (profileData.publicRecruit === "Yes") {
      const originalRoster = OGRoster;
      const OGpublicRecruits = originalRoster.filter(
        (profiles) => profiles.publicRecruit === "Yes"
      );
      const publicRecruits = OGpublicRecruits.filter(
        (profiles) => profiles.id !== key
      );

      batch.update(profileDoc, { publicRecruit: "" });

      // Query the 'recruitingProfiles' collection to find and delete documents with the same ID
      // DON'T WANT TO SWITCH PROFILES TO NOT PUBLIC *** CAN FILTER BY YEAR WHEN FETCHING IN RECRUITING TAB
      //   const q = query(
      //     collection(db, "recruitingProfiles"),
      //     where("id", "==", key)
      //   );
      //   const querySnapshot = await getDocs(q);

      //   querySnapshot.forEach((doc) => {
      //     batch.delete(doc.ref);
      //   });

      if (publicRecruits?.length <= 0) {
        batch.update(accessControlListRef, { recruitingLocation: "no" });
        openNotificationWithIcon(
          "info",
          "Team Removed from Recruiting Map",
          "Your team has been removed from the recruiting map, as it does not contain any public recruiting profiles. Set a profile to public and your team will automatically be re-added.",
          "top"
        );
      }
    }
    batch.update(accessControlListRef, { Players: accessControlList }); // Add update operation to batch

    // Commit the batch write operation
    try {
      await batch.commit();
      console.log("Batch update completed successfully");
      openNotificationWithIcon(
        "success",
        "Player Moved to Inactive Roster",
        "Player has been moved to the Inactive roster",
        "top"
      );
    } catch (error) {
      console.error("Error updating Firestore in batch: ", error);
      openNotificationWithIcon(
        "success",
        "Error Moving to Inactive Roster",
        error,
        "top"
      );
    }
  };

  useEffect(() => {
    switch (rosterFilter) {
      case "OFF":
        setRoster([
          ...teamRosterData.filter((item) =>
            ["QB", "OL", "WR", "RB", "TE"].includes(item.position)
          ),
        ]);
        console.log(
          "teamData",
          teamRosterData.filter((item) =>
            ["QB", "OL", "WR", "RB", "TE"].includes(item.position)
          )
        );
        onChartData(
          teamRosterData.filter((item) =>
            ["QB", "OL", "WR", "RB", "TE"].includes(item.position)
          )
        );
        break;
      case "DEF":
        setRoster([
          ...teamRosterData.filter((item) =>
            ["DL", "LB", "CB", "SAF"].includes(item.position)
          ),
        ]);
        onChartData(
          teamRosterData.filter((item) =>
            ["DL", "LB", "CB", "SAF"].includes(item.position)
          )
        );
        break;
      case "ST":
        setRoster([
          ...teamRosterData.filter((item) =>
            ["P", "K", "LS"].includes(item.position)
          ),
        ]);
        onChartData(
          teamRosterData.filter((item) =>
            ["P", "K", "LS"].includes(item.position)
          )
        );
        break;
      case "QB":
      case "OL":
      case "WR":
      case "RB":
      case "TE":
      case "DL":
      case "LB":
      case "CB":
      case "SAF":
      case "P":
      case "K":
      case "LS":
        setRoster([
          ...teamRosterData.filter((item) =>
            rosterFilter.includes(item.position)
          ),
        ]);
        onChartData(
          teamRosterData.filter((item) => rosterFilter.includes(item.position))
        );
        break;
      case "Year":
        setRoster(
          [...teamRosterData].sort((a, b) => {
            const yearOrder = { Gr: 0, Sr: 1, Jr: 2, So: 3, Fr: 4 };
            const yearA = yearOrder[a.year] || 5;
            const yearB = yearOrder[b.year] || 5;
            return yearA - yearB;
          })
        );
        break;
      case "Scout":
        setRoster([
          ...teamRosterData.filter((player) => player.scoutTeam === "Y"),
        ]);
        onChartData(
          teamRosterData.filter((player) => player.scoutTeam === "Y")
        );
        break;
      case "Travel":
        setRoster([
          ...teamRosterData.filter((player) => player.scoutTeam !== "Y"),
        ]);
        onChartData(
          teamRosterData.filter((player) => player.scoutTeam !== "Y")
        );
        break;
      default:
		const sortedData = teamRosterData.sort((a, b) => {
			const fullNameA = a['full-name'].toLowerCase(); // Ensure case-insensitive sorting
			const fullNameB = b['full-name'].toLowerCase();
			return fullNameA.localeCompare(fullNameB);
		  });
        setRoster(sortedData);
        onChartData(sortedData);
    }
  }, [teamRosterData, rosterFilter]);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
            className=""
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const EditableContext = React.createContext(null);

  const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
  };

  const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSaveRosterData,
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    useEffect(() => {
      if (editing) {
        inputRef.current.focus();
      }
    }, [editing]);

    const toggleEdit = () => {
      setEditing(!editing);
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    };

    const save = async () => {
      try {
        const values = await form.validateFields();
        toggleEdit();
        if (values[dataIndex] !== undefined) {
          // Pass the dataIndex along with record and values
          handleSaveRosterData(
            record.id,
            { [dataIndex]: values[dataIndex] },
            dataIndex
          );
        } else {
          handleSaveRosterData(
            record.id,
            {
              ...record,
              [dataIndex]: dataIndex === "numericValue" ? 0 : null,
            },
            dataIndex
          );
        }
      } catch (errInfo) {
        console.log("Save failed:", errInfo);
      }
    };

    let childNode = children;
    if (editable) {
      childNode = editing ? (
        <Form.Item
          className="relative m-0 h-8 min-w-10"
          name={dataIndex}
          rules={[
            {
              required: false,
              message: `${title} is required.`,
            },
          ]}
        >
          <Input
            className="h-8 min-w-10 w-full"
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
          />
        </Form.Item>
      ) : (
        <div
          className="editable-cell-value-wrap -m-1 h-8 min-w-8 border border-transparent hover:border-gray-200 rounded-lg"
          onClick={toggleEdit}
        >
          {children}
        </div>
      );
    }

    return <td {...restProps}>{childNode}</td>;
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const mapColumns = (col) => {
    if (!col.editable) {
      return col;
    }
    const newCol = {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSaveRosterData,
      }),
    };
    if (col.children) {
      newCol.children = col.children.map(mapColumns);
    }
    return newCol;
  };

  const generalColumns = [
    {
      key: "jersey-number",
      dataIndex: "jersey-number",
      title: "#",
      width: 20,
      editable: true,
      sorter: (a, b) => a["jersey-number"] - b["jersey-number"],
    },
    {
      key: "position",
      dataIndex: "position",
      title: "Pos",
      width: 40,
      editable: true,
      filters: [
        {
          text: "Offense",
          value: "Offense",
          children: [
            {
              text: "QB",
              value: "QB",
            },
            {
              text: "OL",
              value: "OL",
            },
            {
              text: "TE",
              value: "TE",
            },
            {
              text: "RB",
              value: "RB",
            },
            {
              text: "WR",
              value: "WR",
            },
          ],
        },
        {
          text: "Defense",
          value: "Defense",
          children: [
            {
              text: "DL",
              value: "DL",
            },
            {
              text: "LB",
              value: "LB",
            },
            {
              text: "SAF",
              value: "SAF",
            },
            {
              text: "CB",
              value: "CB",
            },
          ],
        },
      ],
      filterMode: "tree",
      onFilter: (value, record) => record.position.includes(value),
    },
    {
      key: "altPosition",
      dataIndex: "altPosition",
      title: "AltPos",
      width: 40,
      editable: true,
      hidden: showAlt,
      filters: [
        {
          text: "Offense",
          value: "Offense",
          children: [
            {
              text: "QB",
              value: "QB",
            },
            {
              text: "OL",
              value: "OL",
            },
            {
              text: "TE",
              value: "TE",
            },
            {
              text: "RB",
              value: "RB",
            },
            {
              text: "WR",
              value: "WR",
            },
          ],
        },
        {
          text: "Defense",
          value: "Defense",
          children: [
            {
              text: "DL",
              value: "DL",
            },
            {
              text: "LB",
              value: "LB",
            },
            {
              text: "SAF",
              value: "SAF",
            },
            {
              text: "CB",
              value: "CB",
            },
          ],
        },
      ],
      filterMode: "tree",
      onFilter: (value, record) => record.altPosition?.includes(value),
    },
    {
      key: "full-name",
      dataIndex: "full-name",
      title: "Name",
      width: 150,
      editable: true,
	  fixed: 'left',
      ...getColumnSearchProps("full-name"),
    },
    {
      key: "year",
      dataIndex: "year",
      title: "Class",
      width: 40,
      editable: true,
      sorter: (a, b) => {
        // Define the custom order
        const order = { Sr: 1, Jr: 2, So: 3, Fr: 4 };

        // Extract the suffix (Sr, Jr, So, Fr) from the year field
        const suffixA = a.year.substring(a.year.length - 2);
        const suffixB = b.year.substring(b.year.length - 2);

        // Compare based on the custom order
        return order[suffixA] - order[suffixB];
      },
    },
    {
      key: "recruitingProfile",
      dataIndex: "redshirtrecrutingProfile",
      title: "Recruiting",
      render: (_, record) => {
        const id = record.id;
        return (
          <div className="flex ml-5">
            <motion.button
              onClick={() => {
                {
                  recruitingProfileActive ? addClose() : addOpen();
                }
                // setRecruitingProfileActive(true);
                setRecruitingProfileID(id);
              }}
            >
              <LuGraduationCap className="opacity-70 hover:opacity-100 text-black scale-125" />
            </motion.button>
          </div>
        );
      },
    },
    {
      key: "scoutTeam",
      dataIndex: "scoutTeam",
      title: "Scout",
      render: (_, record) => {
        const Yes = "opacity-80 hover:opacity-100 text-indigo-500 scale-125";
        const No = "opacity-60 hover:opacity-100 text-black scale-125";
        return (
          <div className="flex ml-7 text-">
            <motion.button
              onClick={() =>
                handleSaveRosterData(
                  record.id,
                  { scoutTeam: record.scoutTeam },
                  "scoutTeam"
                )
              }
            >
              <GiBinoculars className={record.scoutTeam === "Y" ? Yes : No} />
            </motion.button>
          </div>
        );
      },
    },
    {
      key: "redshirt",
      dataIndex: "redshirt",
      title: "Redshirt",
      render: (_, record) => {
        const Yes = "opacity-70 hover:opacity-100 text-red-500 scale-125";
        const No = "opacity-50 hover:opacity-100 text-black scale-125";
        return (
          <div className="flex mx-4">
            <motion.button
              onClick={() =>
                handleSaveRosterData(
                  record.id,
                  { redshirt: record.redshirt },
                  "redshirt"
                )
              }
            >
              <TbShirtSport className={record.redshirt === "Y" ? Yes : No} />
            </motion.button>
          </div>
        );
      },
    },
    {
      key: "medicalRedshirt",
      dataIndex: "medicalRedshirt",
      title: "Medical",
      render: (_, record) => {
        const Yes = "opacity-80 hover:opacity-100 text-blue-500 scale-125";
        const No = "opacity-50 hover:opacity-100 text-black scale-125";
        return (
          <div className="flex ml-0">
            <motion.button
              onClick={() =>
                handleSaveRosterData(
                  record.id,
                  { medicalRedshirt: record.medicalRedshirt },
                  "medicalRedshirt"
                )
              }
            >
              <TbReportMedical
                className={record.medicalRedshirt === "Y" ? Yes : No}
              />
            </motion.button>
          </div>
        );
      },
    },
    {
      key: "height",
      dataIndex: "height",
      title: "Ht",
      width: 25,
      editable: true,
      sorter: (a, b) => a.height.localeCompare(b.height),
    },
    {
      key: "weight",
      dataIndex: "weight",
      title: "Wt",
      width: 25,
      editable: true,
      sorter: (a, b) => a.weight.localeCompare(b.weight),
    },
    {
      key: "lockerNumber",
      dataIndex: "lockerNumber",
      title: "Lkr#",
      width: 25,
      editable: true,
      sorter: (a, b) => a.lockerNumber.localeCompare(b.lockerNumber),
    },

    {
      key: "Xtwitter",
      dataIndex: "Xtwitter",
      title: "Twitter",
      width: 150,
      editable: true,
      sortable: false,
    },
    {
      key: "email",
      dataIndex: "email",
      title: "Email",
      width: 200,
      editable: true,
      sortable: false,
    },
    {
      key: "phone",
      dataIndex: "phone",
      title: "Phone",
      width: 150,
      editable: true,
      sortable: false,
    },
    {
      key: "action",
      dataIndex: "action",
      title: "Actions",
      width: 100,
      render: (_, record) => {
        const id = record.id;
        return (
          <div className="flex">
            <motion.button onClick={() => setActivePlayer(id)}>
              <BiArchiveIn className="opacity-70 hover:opacity-100 scale-125" />
            </motion.button>
            <Popconfirm
              title="Sure to delete?"
              okType="danger"
              onConfirm={() => deleteUser(id)}
            >
              <DeleteOutlined className="opacity-70 hover:opacity-100 scale-125 ml-4" />
            </Popconfirm>
          </div>
        );
      },
    },
  ].filter((item) => !item.hidden);

  const selectedPlayer = Roster.find(
    (player) => player.id === recruitingProfileID
  );

  const rosterColumns = generalColumns.map(mapColumns);

  const levelAbbreviations = {
    "Division I FBS": "FBS",
    "Division I FCS": "FCS",
    "Division II": "D2",
    "Division III": "D3",
    NAIA: "NAIA",
    NJCAA: "JUCO",
    "Sprint Football": "Sprint",
  };

  const levelOrder = [
    "Division I FBS",
    "Division I FCS",
    "Division II",
    "Division III",
    "NAIA",
    "NJCAA",
    "Sprint Football",
  ];

  const [expandedRow, setExpandedRow] = useState(null);

  const recruitingColumns = [
    {
      key: "expectedLOC",
      dataIndex: "expectedLOC",
      title: "",
      width: 25,
      render: (text, record) => {
        const abbreviations = levelOrder
          .filter((value) => record.expectedLOC?.includes(value))
          .map((value) => levelAbbreviations[value]);

        return abbreviations.join("/");
      },
    },
    {
      key: "year",
      dataIndex: "year",
      title: "Year",
      width: 40,
      sorter: (a, b) => {
        // Define the custom order
        const order = { Sr: 1, Jr: 2, So: 3, Fr: 4 };

        // Extract the suffix (Sr, Jr, So, Fr) from the year field
        const suffixA = a.year.substring(a.year.length - 2);
        const suffixB = b.year.substring(b.year.length - 2);

        // Compare based on the custom order
        return order[suffixA] - order[suffixB];
      },
    },
    // {
    //   key: "score",
    //   dataIndex: "score",
    //   title: "Grade",
    //   render: (_, record) => {
    //     const id = record.id;
    //     return (
    //       <div className="flex ml-1">
    //         <motion.button onClick={null}>
    //           <GiRank3 className="font-bold text-green-600 scale-150" />
    //         </motion.button>
    //       </div>
    //     );
    //   },
    // },
    {
      key: "position",
      dataIndex: "position",
      title: "Pos",
      width: 40,
      filters: [
        {
          text: "Offense",
          value: "Offense",
          children: [
            {
              text: "QB",
              value: "QB",
            },
            {
              text: "OL",
              value: "OL",
            },
            {
              text: "TE",
              value: "TE",
            },
            {
              text: "RB",
              value: "RB",
            },
            {
              text: "WR",
              value: "WR",
            },
          ],
        },
        {
          text: "Defense",
          value: "Defense",
          children: [
            {
              text: "DL",
              value: "DL",
            },
            {
              text: "LB",
              value: "LB",
            },
            {
              text: "SAF",
              value: "SAF",
            },
            {
              text: "CB",
              value: "CB",
            },
          ],
        },
      ],
      filterMode: "tree",
      onFilter: (value, record) => record.position.includes(value),
    },
    {
      key: "full-name",
      dataIndex: "full-name",
      title: "Name",
      width: 150,
	  fixed: 'left',
      ...getColumnSearchProps("full-name"),
    },
    {
      key: "publicRecruit",
      dataIndex: "publicRecruit",
      title: "Public",
      width: 25,
      render: (_, record) => {
        const Yes =
          "opacity-90 hover:opacity-100 hover:text-green-200 text-green-400 scale-125";
        const No = "opacity-30 hover:opacity-100 text-black scale-125";
        return (
          <div className="flex w-fit mx-auto">
            <motion.button
              onClick={() =>
                handleSaveRosterData(
                  record.id,
                  { publicRecruit: record.publicRecruit },
                  "publicRecruit"
                )
              }
            >
              <BsCheckCircleFill
                className={record.publicRecruit === "Yes" ? Yes : No}
              />
            </motion.button>
          </div>
        );
      },
    },
    {
      key: "filmLink",
      dataIndex: "filmLink",
      title: "Film",
      render: (_, record) => {
        const id = record.id;

        const icon = _ ? (
          <AiOutlineVideoCamera className="opacity-70 text-black hover:opacity-100 scale-125" />
        ) : (
          <AiOutlineVideoCameraAdd className="opacity-70 text-slate-400 hover:opacity-100 scale-125" />
        );
        return (
          <div className="flex ml-1">
            <motion.button
              onClick={() => {
                if (expandedRow !== id) {
                  setExpandedRow(id);
                } else {
                  setExpandedRow(null);
                }
              }}
            >
              {icon}
            </motion.button>
          </div>
        );
      },
    },
    {
      key: "recruitingProfile",
      dataIndex: "redshirtrecrutingProfile",
      title: "Recruiting",
      render: (_, record) => {
        const id = record.id;
        return (
          <div className="flex ml-5">
            <motion.button
              onClick={() => {
                {
                  recruitingProfileActive ? addClose() : addOpen();
                }
                setRecruitingProfileID(id);
              }}
            >
              <LuGraduationCap className="opacity-70 hover:opacity-100 text-black scale-125" />
            </motion.button>
          </div>
        );
      },
    },
    {
      key: "height",
      dataIndex: "height",
      title: "Ht",
      editable: true,
      width: 25,
      sorter: (a, b) => a.year.localeCompare(b.year),
    },
    {
      key: "weight",
      dataIndex: "weight",
      title: "Wt",
      editable: true,
      width: 25,
      sorter: (a, b) => a.year.localeCompare(b.year),
    },
    {
      key: "gpa",
      dataIndex: "gpa",
      title: "GPA",
      editable: true,
      width: 25,
      sorter: (a, b) => a.year.localeCompare(b.year),
    },
    {
      key: "40time",
      dataIndex: "40time",
      title: "40",
      editable: true,
      width: 25,
      sorter: (a, b) => a.year.localeCompare(b.year),
    },
    {
      key: "bench",
      dataIndex: "bench",
      title: "Bench",
      editable: true,
      width: 25,
      sorter: (a, b) => a.year.localeCompare(b.year),
    },
    {
      key: "squat",
      dataIndex: "squat",
      title: "Squat",
      editable: true,
      width: 25,
      sorter: (a, b) => a.year.localeCompare(b.year),
    },
    {
      key: "powerClean",
      dataIndex: "powerClean",
      title: "Power Clean",
      editable: true,
      width: 25,
      sorter: (a, b) => a.year.localeCompare(b.year),
    },
    {
      id: "multiSport",
      label: "MultiSport Athlete",
      type: "checkbox",
      options: [
        "Basketball",
        "BaseBall",
        "Lacrosse",
        "Soccer",
        "Track and Field",
        "Wrestling",
        "Swimming",
        "Cross Country",
        "Water Polo",
        "Golf",
        "Ice Hockey",
        "Rugby",
        "Tennis",
      ],
    },
    {
      key: "injuries",
      dataIndex: "injuries",
      title: "Injuries",
      editable: true,
      width: 200,
      //   render: (_, record) => {
      //     const id = record.id;
      //     return (
      //       <div className="flex max-h-12 w-fit overflow-x-scroll">
      // 		{_}
      //       </div>
      //     );
      //   },
    },
    {
      key: "personality",
      dataIndex: "personality",
      title: "Personality",
      editable: true,
      width: 75,
    },
    {
      key: "family",
      dataIndex: "family",
      title: "Family",
      editable: true,
      width: 75,
    },
    {
      key: "collegePreference",
      dataIndex: "collegePreference",
      title: "College Pref",
      editable: true,
      width: 75,
    },
    {
      key: "officialOffers",
      dataIndex: "officialOffers",
      title: "Official Offers",
      editable: true,
      width: 75,
    },
    {
      key: "competitive",
      dataIndex: "competitive",
      title: "Competitive",
    //   editable: true,
      width: 25,
    },
    {
      key: "leadership",
      dataIndex: "leadership",
      title: "Leadership",
    //   editable: true,
      width: 25,
    },
    {
      key: "workEthic",
      dataIndex: "workEthic",
      title: "Work Ethic",
    //   editable: true,
      width: 25,
    },
    {
      key: "loveFootball",
      dataIndex: "loveFootball",
      title: "Love Football",
    //   editable: true,
      width: 25,
    },
    {
      key: "footballIQ",
      dataIndex: "footballIQ",
      title: "Football IQ",
    //   editable: true,
      width: 25,
    },
    {
      key: "Xtwitter",
      dataIndex: "Xtwitter",
      title: "Twitter",
      width: 150,
      editable: true,
      sortable: false,
    },
    {
      key: "email",
      dataIndex: "email",
      title: "Email",
      width: 200,
      sortable: false,
    },
    {
      key: "phone",
      dataIndex: "phone",
      title: "Phone",
      width: 150,
      sortable: false,
    },
    // {
    //   key: "action",
    //   dataIndex: "action",
    //   title: "Actions",
    //   width: 150,
    //   render: (_, record) => {
    // 	const id = record.id;
    // 	return (
    // 	  <div className="flex">
    // 		<motion.button onClick={() => setActivePlayer(id)}>
    // 		  <BiArchiveIn className="opacity-70 hover:opacity-100 scale-125" />
    // 		</motion.button>
    // 		<Popconfirm
    // 		  title="Sure to delete?"
    // 		  okType="danger"
    // 		  onConfirm={() => deleteUser(id)}
    // 		>
    // 		  <DeleteOutlined className="opacity-70 hover:opacity-100 scale-125 ml-4" />
    // 		</Popconfirm>
    // 	  </div>
    // 	);
    //   },
    // },
  ];

  const recruitColumns = recruitingColumns.map(mapColumns);

  const equipmentColumns = [
    {
      key: "year",
      dataIndex: "year",
      title: "Year",
      width: 40,
      sorter: (a, b) => a.year.localeCompare(b.year),
    },
    {
      key: "jersey-number",
      dataIndex: "jersey-number",
      title: "#",
      editable: true,
      width: 15,
      sorter: (a, b) => a["jersey-number"] - b["jersey-number"],
    },
    {
      key: "full-name",
      dataIndex: "full-name",
      title: "Name",
      width: 150,
	  fixed: 'left',
      ...getColumnSearchProps("full-name"),
    },
    {
      key: "helmet",
      dataIndex: "helmet",
      title: "Helmet",
      width: 30,
      render: (_, record) => {
        const Yes =
          "opacity-90 hover:opacity-100 hover:text-green-200 text-green-600 scale-[1.3]";
        const No = "opacity-50 hover:opacity-100 text-black scale-[1.3]";
        return (
          <div className="flex w-fit mx-auto">
            <motion.button
              onClick={() =>
                handleSaveRosterData(
                  record.id,
                  { helmet: record.helmet },
                  "helmet"
                )
              }
            >
              <GiAmericanFootballHelmet
                className={record.helmet === "Y" ? Yes : No}
              />
            </motion.button>
          </div>
        );
      },
    },
    {
      key: "shoulderPads",
      dataIndex: "shoulderPads",
      title: "Pads",
      width: 30,
      render: (_, record) => {
        const Yes =
          "opacity-90 hover:opacity-100 hover:text-green-200 text-green-600 scale-105";
        const No = "opacity-50 hover:opacity-100 text-black scale-105";
        return (
          <div className="flex w-fit mx-auto">
            <motion.button
              onClick={() =>
                handleSaveRosterData(
                  record.id,
                  { shoulderPads: record.shoulderPads },
                  "shoulderPads"
                )
              }
            >
              <SlderPads className={record.shoulderPads === "Y" ? Yes : No} />
            </motion.button>
          </div>
        );
      },
    },
    {
      key: "pants",
      dataIndex: "pants",
      title: "Pants",
      width: 30,
      render: (_, record) => {
        const Yes =
          "opacity-90 hover:opacity-100 hover:text-green-200 text-green-600 scale-90";
        const No = "opacity-40 hover:opacity-100 text-black scale-90";
        return (
          <div className="flex w-fit mx-auto">
            <motion.button
              onClick={() =>
                handleSaveRosterData(
                  record.id,
                  { pants: record.pants },
                  "pants"
                )
              }
            >
              <FBPants className={record.pants === "Y" ? Yes : No} />
            </motion.button>
          </div>
        );
      },
    },
    {
      key: "kneePads",
      dataIndex: "kneePads",
      title: "Knee Pads",
      render: (_, record) => {
        //   const Yes = "opacity-80 hover:opacity-100 text-blue-500 scale-125";
        //   const No = "opacity-50 hover:opacity-100 text-black scale-125";
        return (
          <div className="flex ml-0">
            <motion.button onClick={() => null}></motion.button>
          </div>
        );
      },
    },
  ];

  const EQColumns = equipmentColumns.map(mapColumns);

  //Player Contact Columns? More Indepth EQ Columns?

  const [selectedTab, setSelectedTab] = useState(0);
  const [columns, setColumns] = useState(rosterColumns);

  const TabButtons = () => {
    const tableTabs = ["General", "Recruiting", "Equipment"];
    const tableColumns = [rosterColumns, recruitColumns, EQColumns];

    const handleTabClick = (index) => {
      setSelectedTab(index);
      setColumns(tableColumns[index]);
    };

    return tableTabs.map((tab, index) => (
      <button
        key={index}
        onClick={() => handleTabClick(index)}
        className={`${
          selectedTab === index ? "bg-slate-400 text-white" : "bg-slate-300"
        } relative group w-fit h-fit font-bold p-1 pr-7 pl-3 hover:bg-slate-400 rounded-sm overflow-visible`}
      >
        {tab}
        <div
          className={`${
            selectedTab === index
              ? "border-r-slate-400 text-white"
              : "border-r-slate-300"
          } absolute group-hover:border-r-slate-400 w-0 h-0 -left-[21.5px] top-[1px] border-t-[15px] border-t-transparent border-r-[22px] border-b-[15px] border-b-transparent`}
        ></div>
        <div className="absolute w-0 h-0 right-0 top-[1px] border-t-[15px] border-t-transparent border-r-[22px] border-l-white border-white border-b-[15px] border-b-transparent"></div>
      </button>
    ));
  };

  useEffect(() => {
    setColumns(rosterColumns);
  }, [showAlt]);

  return (
    <div className="min-h-[30rem] w-full">
      <RecruitingProfile
        recruitingProfileActive={recruitingProfileActive}
        handleClose={() => addClose()}
        profileInfo={selectedPlayer ? selectedPlayer : ""}
      />
      {loading ? ( // render the loading animation while loading is true
        <div className="flex justify-center align-middle items-center h-[300px] w-full">
          <Spin size="large" />
        </div>
      ) : (
        // render the table when loading is false
        <div className="relative w-full">
          <div className="w-full h-fit flex gap-1 pt-2 pl-7">
            <TabButtons />
          </div>
          <Table
            className="w-full h-full px-2 py-2"
            size="small"
            components={components}
            columns={columns}
            dataSource={Roster}
            shouldCellUpdate={(record, prevRecord) => record !== prevRecord}
            rowKey={(record) => record.id}
            // scroll={{ x: "calc(30px + 90%)" }}
            scroll={{ x: "max-content" }}
            pagination={{ showSizeChanger: true, position: "bottom" }}
            expandable={{
              expandedRowKeys: expandedRow ? [expandedRow] : [],
              onExpand: (expanded, record) => {
                if (expanded) {
                  setExpandedRow(record.id);
                } else {
                  setExpandedRow(null);
                }
              },
              rowExpandable: (record) => {
                if (record.filmLink && selectedTab === 1) {
                  return true;
                } else {
                  return false;
                }
              },
              expandedRowRender: (record) => {
                if (record.filmLink) {
                  return (
                    <div className="w-[95%] h-fit max-w-[36rem] mt-1 aspect-video relative">
                      <div
                        className="absolute top-0 left-0 w-full h-full"
                        dangerouslySetInnerHTML={{ __html: record.filmLink }}
                      />
                    </div>
                  );
                }
              },
            }}
          />
        </div>
      )}
    </div>
  );
}
