import { Button, Dropdown, Modal, Select, Space } from "antd";
import React, { useState, useContext, useEffect } from "react";
import { UserInputs } from "../SourceData/UserData";
import { PathContext } from "../context/PathContext";
import { addDoc, collection } from "@firebase/firestore";
import { db } from "../Firebase-config";
import { DownOutlined } from "@ant-design/icons";

export const ImportRoster = ({ showRosterImport, handleClose }) => {
  const [mappedHeaders, setMappedHeaders] = useState(null);
  const [excelData, setExcelData] = useState(null);
  const [tableHeaders, setTableHeaders] = useState([]);

  const { selectedPath } = useContext(PathContext);

  const closeNClear = () => {
    setMappedHeaders(null);
    setExcelData(null);
    setTableHeaders([]);
    handleClose();
  };

  const importExcel = async (e) => {
    var XLSX = require("xlsx");
    const file = e.target.files[0];
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data, { type: "array" });

    const worksheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[worksheetName];

    const fileData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    const headers = fileData[0];

    const heads = headers.map((head) => ({
      title: head,
      key: head,
      dataIndex: head,
    }));

    setTableHeaders(heads);
    setExcelData(fileData);
  };

  const handleHeaderMapping = (tableHeader, excelHeader) => {
    console.log(excelHeader);
    console.log(tableHeader);
    setMappedHeaders((prevMappedHeaders) => ({
      ...prevMappedHeaders,
      [excelHeader]: tableHeader,
    }));
    console.log(mappedHeaders);
  };

  const transformAndMergeData = async () => {
    if (!excelData || !mappedHeaders) {
      console.log("Please upload an Excel file or Match 1 header first!");
      return;
    }

    const transformedData = excelData.slice(1).map((row) => {
      const playerObject = {};
      Object.entries(mappedHeaders).forEach(([excelHeader, inputId]) => {
        const index = tableHeaders.findIndex(
          (header) => header.key === excelHeader
        );
        if (index !== -1) {
          playerObject[inputId] = row[index];
        }
      });
      // Add the team ID to each player object
      playerObject["teamId"] = selectedPath;
      playerObject.activePlayer = "Active"; // Assuming selectedPath holds the team ID
      playerObject.img =
        "https://firebasestorage.googleapis.com/v0/b/gradeapp-fddad.appspot.com/o/LeonardoProfilePlaceholder.jpg?alt=media&token=274d0788-2cea-4b54-aba6-2571e51717bd";
      return playerObject;
    });

    // Add each player object to the correct team collection
    const batch = [];
    transformedData.forEach((player) => {
      batch.push(
        addDoc(collection(db, "Teams", selectedPath, "PlayerProfiles"), player)
      );
    });

    try {
      await Promise.all(batch);
      console.log("New Players", transformedData);
      console.log("Excel data uploaded successfully!");
      closeNClear();
    } catch (error) {
      console.error("Error uploading player data:", error);
    }
  };

  const items = [
    ...UserInputs.map((input) => ({
      value: input.id,
      label: input.label,
    })),
  ];

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <Modal
      className="h-screen pt-[30%] sm:pt-[20%] md:pt-[8%] overflow-hidden my-auto"
      centered
      width={800}
      open={showRosterImport}
      onCancel={closeNClear}
      destroyOnClose={true}
      footer={[
        <Button key="cancel" onClick={closeNClear}>
          Cancel
        </Button>,
        <Button
          key="import"
          type="primary"
          onClick={transformAndMergeData}
        >
          Import & Merge
        </Button>,
      ]}
    >
      <div className="flex flex-col max-h-[65vh] h-fit w-full overflow-y-auto mt-4">
        <h2 className="font-bold text-lg my-2 mt-4 mx-auto">Column Mapping</h2>
        <div className="mb-2 ml-2">
          <input
            type="file"
            onChange={importExcel}
            accept=".xlsx, .xls, .csv"
          />
        </div>
        {!excelData && (
          <div className="flex flex-col mb-4">
            <span>
              1. Upload a spreadsheet. Make sure the top row is your column
              headers.
            </span>
            <span>
              2. Match your headers on the left with our columns in the dropdown
              menu
            </span>
            <span>3. Click "Import & Merge" to add your data</span>
          </div>
        )}
        {tableHeaders.length > 0 && (
          <div>
            {tableHeaders.map((header) => (
              <div className="flex mb-1" key={header.key}>
                <div className="font-bold col-span-1 min-w-min ml-auto my-auto mr-1">
                  {header.title}:{" "}
                </div>
                <Select
                  className="mr-[60%]"
                  showSearch
                  defaultValue="Select Match"
                  optionFilterProp="children"
                  onChange={(e) => handleHeaderMapping(e, header.key)}
                  filterOption={filterOption}
                  style={{ width: 150 }}
                  options={items}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </Modal>
  );
};
