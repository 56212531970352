import React, { useRef } from "react";
import { motion } from "framer-motion";
import { Modal } from "antd";
import { useReactToPrint } from "react-to-print";

export function WCCallSheet({ data, setViewCallSheet, viewCallSheet }) {
  console.log("CSData-", data);
  const order = [
	'Pro', 'Con', 'Split', 'Splitter', 'Doubles', 
	'Dribble', 'Triple', 'Trips', 'Loco', 
	'Cinco', 'Army', 'Navy', 'Spread Army', 'Spread Navy'
  ];
  
  const sortedData = data.sort((a, b) => {
	// Find the index of the keywords in the predefined order
	const indexA = order.findIndex(keyword => a.playCall.includes(keyword));
	const indexB = order.findIndex(keyword => b.playCall.includes(keyword));
  
	// If both playCalls have a keyword in the predefined order, sort by that order
	if (indexA !== -1 && indexB !== -1) {
	  if (indexA !== indexB) {
		return indexA - indexB; // Sort by the predefined order
	  }
	  // If they belong to the same group, sort alphabetically within that group
	  return a.playCall.localeCompare(b.playCall);
	}
  
	// If one has a keyword and the other doesn't, prioritize the one with the keyword
	if (indexA !== -1) return -1;
	if (indexB !== -1) return 1;
  
	// If neither has a keyword from the predefined list, sort alphabetically
	return a.playCall.localeCompare(b.playCall);
  });
  
  
  const printInfo = data;

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const CSHeadersFront = [
    { label: "Green Zone", color: "bg-green-400", code: "Green Zone" },
    { label: "3rd & SHORT", color: "bg-yellow-200", code: "Short" },
    { label: "High Red Zone", color: "bg-red-400", code: "HRZ" },
    { label: "3rd & MEDIUM", color: "bg-yellow-300", code: "Medium" },
    { label: "Low Red Zone", color: "bg-red-200", code: "LRZ" },
    { label: "3rd & LONG", color: "bg-yellow-400", code: "Long" },
  ];

  const CSHeadersBack = [
    { label: "2 Minute", color: "bg-cyan-200", code: "2 Minute" },
    { label: "4 Minute", color: "bg-emerald-300", code: "4 Minute" },
	{ label: "Shot Plays", color: "bg-orange-300", code: "Shot Plays" },
	{ label: "Backed Up", color: "bg-blue-500", code: "Backed Up" },
    { label: "Goal Line", color: "bg-pink-300", code: "Goal Line" },
    { label: "Screens", color: "bg-indigo-300", code: "Screens" },
  ];

  const getPlayDetails = (play, side, position) => {
    const tagText = position === 'above' ? 'Run' : 'Pass';
    return {
      playName: play.Tags.some(tag => tag.text === tagText) && play.Tags.some(tag => tag.text === side) ? play.playCall : null,
    };
  };

  const filterPlays = (headerCode) => {
    return sortedData.filter(play => 
      play.Tags.some(tag => tag.text === headerCode) &&
      (play.Tags.some(tag => tag.text === 'Run') || play.Tags.some(tag => tag.text === 'Pass') || play.Tags.some(tag => tag.text === 'Screens'))
    );
  };

  // Helper function to filter and separate plays
const filterAndSeparatePlays = (headerCode) => {
	const plays = filterPlays(headerCode);
	
	const abovePlaysL = plays.filter(play => 
	  getPlayDetails(play, 'L', 'above').playName
	);
  
	const belowPlaysL = plays.filter(play => 
	  getPlayDetails(play, 'L', 'below').playName
	);

	const abovePlaysR = plays.filter(play => 
		getPlayDetails(play, 'R', 'above').playName
	  );
	
	const belowPlaysR = plays.filter(play => 
		getPlayDetails(play, 'R', 'below').playName
	  );
	return { abovePlaysL, belowPlaysL, abovePlaysR, belowPlaysR };
  };
  
  // Map through plays for rendering
  const renderPlays = (plays, side, position) => {
	return plays.map((play, playIndex) => {
	  const { playName } = getPlayDetails(play, side, position);
	  const isEven = playIndex % 2 === 0; // Check if index is even
  
	  return playName ? (
		<div
		  key={playIndex}
		  className={`flex flex-col w-full h-fit min-h-full ${position === 'below' && playIndex === 0 ? 'border-t border-slate-500 border-dotted mt-4' : ''} text-xxs ${isEven ? 'bg-slate-50' : ''}`}
		>
		  <div className="flex w-full overflow-hidden">
			<div className="flex h-fit my-auto w-[2rem] border-r mr-1 pl-1 font-semibold">
			  <div
				className={`${play.WristColor} rounded-full h-2 w-2 mt-1 mr-1`}
			  ></div>
			  <div id="playColor&Num">{play.WristNum}</div>
			</div>
			<div id="playName">{playName}</div>
		  </div>
		</div>
	  ) : null;
	});
  };  
  

  return (
	<Modal
	  className="h-fit pt-[10%] sm:pt-[5%] md:pt-3 pb-2"
	  centered
	  width={1300}
	  open={viewCallSheet}
	  onOk={() => setViewCallSheet()}
	  onCancel={() => setViewCallSheet()}
	  destroyOnClose={false}
	  footer={[
		<motion.button
		  key="printButton"
		  className="p-2 m-1 mb-2 h-fit hover:bg-teal-500 border-teal-500 border-2 text-white bg-slate-600 rounded-md font-bold shadow-gray-700 shadow-sm disabled:opacity-50"
		  whileHover={{ scale: 0.9 }}
		  whileTap={{ scale: 1.1 }}
		  onClick={handlePrint}
		>
		  Print
		</motion.button>,
	  ]}
	>
	  <div
		ref={componentRef}
		className="relative px-[3%] mt-1 flex flex-col h-fit min-h-fit w-full mx-auto py-4 bg-white rounded-md"
	  >
		{new Date().toLocaleString() + ""}
		<div className="w-full bg-slate-800 text-white text-xl font-bold text-center rounded-t-md">
		  {printInfo?.label}
		</div>
		<div className="flex h-fit min-h-[60vh] w-full gap-5 mt-3 mx-auto border-2 border-black">
		  <div className="grid grid-cols-2 w-full h-fit min-h-[60vh]">
			{CSHeadersFront.map((header, index) => {
			  const { abovePlaysL, abovePlaysR, belowPlaysL, belowPlaysR } = filterAndSeparatePlays(header.code);
			  
			  return (
				<div
				  key={index}
				  className="w-full h-fit min-h-full pb-1 content-start grid grid-cols-2 border-r"
				>
				  <div
					id={`header-${index}`}
					className={`h-fit w-full col-span-2 ${header.color} text-center text-lg font-bold`}
				  >
					{header.label}
				  </div>
				  <div className="h-fit border-b border-r text-center font-semibold underline">Left</div>
				  <div className="h-fit border-b text-center font-semibold underline">Right</div>
				  <div
					id={`left-${index}`}
					className="flex flex-col w-full h-fit min-h-full border-r"
				  >
					{renderPlays(abovePlaysL, 'L', 'above')}
					{renderPlays(belowPlaysL, 'L', 'below')}
				  </div>
				  <div
					id={`right-${index}`}
					className="flex flex-col w-full h-fit min-h-full"
				  >
					{renderPlays(abovePlaysR, 'R', 'above')}
					{renderPlays(belowPlaysR, 'R', 'below')}
				  </div>
				</div>
			  );
			})}
		  </div>
		</div>

		
		<div className="flex h-fit min-h-[60vh] mt-12 w-full gap-5 mx-auto border-2 border-black break-before-page">
		  <div className="grid grid-cols-2 w-full h-fit min-h-[60vh]">
			{CSHeadersBack.map((header, index) => {
			  const { abovePlaysL, abovePlaysR, belowPlaysL, belowPlaysR } = filterAndSeparatePlays(header.code);
			  
			  return (
				<div
				  key={index}
				  className="w-full h-fit min-h-full pb-1 content-start grid grid-cols-2 border-r"
				>
				  <div
					id={`header-${index}`}
					className={`h-fit w-full col-span-2 ${header.color} text-center text-lg font-bold`}
				  >
					{header.label}
				  </div>
				  <div className="h-fit border-b border-r text-center font-semibold underline">Left</div>
				  <div className="h-fit border-b text-center font-semibold underline">Right</div>
				  <div
					id={`left-${index}`}
					className="flex flex-col w-full h-fit min-h-full border-r"
				  >
					{renderPlays(abovePlaysL, 'L', 'above')}
					{renderPlays(belowPlaysL, 'L', 'below')}
				  </div>
				  <div
					id={`right-${index}`}
					className="flex flex-col w-full h-fit min-h-full"
				  >
					{renderPlays(abovePlaysR, 'R', 'above')}
					{renderPlays(belowPlaysR, 'R', 'below')}
				  </div>
				</div>
			  );
			})}
		  </div>
		</div>
	  </div>
	</Modal>
  );
}
