import React, { useState, useContext, useEffect } from "react";
import { motion } from "framer-motion";
import { RecruitingInputs } from "../SourceData/RecruitingData";
import { Button, ConfigProvider, Modal, Skeleton } from "antd";
import { ImFilePicture } from "react-icons/im";
import { db, storage } from "../Firebase-config";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import {
  updateDoc,
  collection,
  deleteDoc,
  query,
  where,
  getDocs,
  doc,
  setDoc,
} from "firebase/firestore";
import { AuthContext } from "../context/AuthContext";
import { PathContext } from "../context/PathContext";
import { openNotificationWithIcon } from "../helperFunctions/openNotificationWithIcon";
import Dragger from "antd/es/upload/Dragger";
import ImgCrop from "antd-img-crop";

export function RecruitingProfile({
  handleClose,
  profileInfo,
  recruitingProfileActive,
}) {
  const [data, setData] = useState(profileInfo ? profileInfo : []);
  const [LOCOptions, setLOCOptions] = useState([]);
  const [multiSportOptions, setmultiSportOptions] = useState([]);
  const [editProfilePic, setEditProfilePic] = useState(false);
  const [file, setFile] = useState();
  const [staggedPic, setStaggedPic] = useState(false);

  const { currentUser } = useContext(AuthContext);
  const { selectedPath } = useContext(PathContext);

  useEffect(() => {
    const rawData = profileInfo ? [profileInfo] : [];
    if (profileInfo) {
      const locChecked = profileInfo.expectedLOC ? profileInfo.expectedLOC : [];
      const multisportChecked = profileInfo.multiSport
        ? profileInfo.multiSport
        : [];

      setLOCOptions(locChecked);
      setmultiSportOptions(multisportChecked);
    }

    setData(...rawData);
  }, [profileInfo, file]);

  const [expectedLOC, setExpectedLOC] = useState([]);
  const [multiSport, setMultiSport] = useState([]);

  const handleCheckboxChange = (e, label, option) => {
    console.log(
      `Label: ${label}, Option: ${option}, Checked: ${e.target.checked}`
    );

    const predefinedValues = [
      "NJCAA",
      "Sprint Football",
      "NAIA",
      "Division III",
      "Division II",
      "Division I FCS",
      "Division I FBS",
    ];

    if (predefinedValues.includes(option)) {
      if (e.target.checked) {
        // If the option is predefined and the checkbox is checked, add it to the appropriate array
        if (!expectedLOC.includes(option)) {
          setExpectedLOC((prevExpectedLOC) => [...prevExpectedLOC, option]);
          setData((prevData) => ({
            ...prevData,
            expectedLOC: [...expectedLOC, option],
          }));
        }
      } else {
        // If the option is predefined and the checkbox is unchecked, remove it from the appropriate array
        setExpectedLOC((prevExpectedLOC) =>
          prevExpectedLOC.filter((item) => item !== option)
        );
        setData((prevData) => ({
          ...prevData,
          expectedLOC: prevData.expectedLOC.filter((item) => item !== option),
        }));
      }
    } else {
      if (e.target.checked) {
        // If the option is not predefined and the checkbox is checked, add it to the multiSport array
        if (!multiSport.includes(option)) {
          setMultiSport((prevMultiSport) => [...prevMultiSport, option]);
          setData((prevData) => ({
            ...prevData,
            multiSport: [...multiSport, option],
          }));
        }
      } else {
        // If the option is not predefined and the checkbox is unchecked, remove it from the multiSport array
        setMultiSport((prevMultiSport) =>
          prevMultiSport.filter((item) => item !== option)
        );
        setData((prevData) => ({
          ...prevData,
          multiSport: prevData.multiSport.filter((item) => item !== option),
        }));
      }
    }
    setData((prevData) => ({ ...prevData, userID: currentUser.uid }));
  };

  const addInputsToData = (e) => {
    const id = e.target.id;
    let value = e.target.value;

    if (id === "filmLink" && value.length > 10) {
      // Replace with your actual textarea ID
      // Check if the value is an iframe embed code
      if (isIframeEmbedCode(value)) {
        value = processIframeCode(value);
      } else {
        // Handle invalid input or provide feedback to the user
        console.log("Invalid iframe embed code");
        // Optionally, you could clear the value or set an error state
        // value = '';
        openNotificationWithIcon(
          "error",
          "Embedded Code is Incorrect",
          "The code pasted into the 'Film Link' area must be in HTML iframe format.",
          "bottom"
        );
        return;
      }
    }

    setData({ ...data, [id]: value, userID: currentUser.uid });
    console.log(data);
  };

  function isIframeEmbedCode(input) {
    // A simple check for iframe tags
    return /<iframe.*src=".*".*<\/iframe>/.test(input);
  }

  function processIframeCode(iframeCode) {
    if (iframeCode.includes("<iframe") && iframeCode.includes("</iframe>")) {
      let modifiedCode = iframeCode.replace(/width='[^']+'/, "width='100%'");
      modifiedCode = modifiedCode.replace(/height='[^']+'/, "height='100%'");
      return modifiedCode;
    }
    return iframeCode;
  }

  const uploadData = async (e) => {
    if (e) {
      e.preventDefault();
    }
    if (data) {
      try {
        if (data.publicRecruit === "Yes") {
          // Use setDoc to add/update the document in the 'recruitingProfiles' collection
          const recruitDocRef = doc(db, "recruitingProfiles", `${data.id}`);
          await setDoc(
            recruitDocRef,
            {
              ...data,
            },
            { merge: true }
          );
        } else {
          // Query the 'recruitingProfiles' collection to find and delete documents with the same ID
          const q = query(
            collection(db, "recruitingProfiles"),
            where("id", "==", data.id)
          );
          const querySnapshot = await getDocs(q);

          querySnapshot.forEach(async (doc) => {
            await deleteDoc(doc.ref);
          });
        }

        // Update the player profile with the new data
        await updateDoc(
          doc(db, "Teams", selectedPath, "PlayerProfiles", profileInfo.id),
          {
            ...data,
          }
        );
      } catch (err) {
        console.error(err);
      }
    } else {
      alert("No Data To Save");
    }
  };

  const handleConfirm = async () => {
    await uploadData();
    setEditProfilePic(false);
    setStaggedPic(false);
  };

  const uploadProps = {
    name: "file",
    multiple: false, // Set to true for multiple file uploads
    customRequest: async ({ file, onSuccess, onError }) => {
      const storageRef = ref(
        storage,
        `${selectedPath}/PlayerPics/${file.name}`
      );
      const defaultProfilePictureUrl =
        "https://firebasestorage.googleapis.com/v0/b/gradeapp-fddad.appspot.com/o/LeonardoProfilePlaceholder.jpg?alt=media&token=274d0788-2cea-4b54-aba6-2571e51717bd";

      // Check if data.img exists and is not the default picture
      if (data && data.img !== defaultProfilePictureUrl) {
        // Get a reference to the old image in Firebase Storage
        const oldImageRef = ref(
          storage,
          `${selectedPath}/PlayerPics/${data.img}`
        );

        try {
          // Delete the old image
          await deleteObject(oldImageRef);
          console.log("Old image deleted successfully");
        } catch (error) {
          console.error("Error deleting old image:", error);
        }
      }

      try {
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Handle upload progress
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log(`Upload is ${progress}% done`);
          },
          onError,
          async () => {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            onSuccess(downloadURL, file); // Success callback with downloadURL

            setData((prev) => ({ ...prev, img: downloadURL }));
          }
        );
      } catch (error) {
        onError(error);
      }
    },

    onSuccess(response) {
      console.log("File uploaded successfully:", response);
      setStaggedPic(true);
      // Update your data state with the downloadURL from response
    },
    onError(error) {
      console.error("Upload failed:", error);
    },
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Modal: {
            /* here is your component tokens */
            contentBg: "opacity-50",
          },
        },
      }}
    >
      <Modal
        className="h-screen pt-[7%] sm:pt-[5%] md:pt-[2%] overflow-hidden my-auto"
        classNames={{ body: "mt-4 -mx-6 md:-mx-8 -mb-6" }}
        centered
        width={1300}
        open={recruitingProfileActive}
        onOk={handleClose}
        onCancel={handleClose}
        destroyOnClose={true}
        footer={[
          <Button key="back" onClick={handleClose}>
            Cancel
          </Button>,
          <motion.button
            onClick={(e) => {
              uploadData(e);
              handleClose();
            }}
            className=" ml-2 p-[2px] hover:bg-teal-500 border-teal-500 border-2 text-white bg-slate-600 rounded-md font-bold shadow-gray-700 shadow-sm"
            key="submit"
          >
            Save Profile
          </motion.button>,
        ]}
      >
        <div className="h-[85vh] pt-0">
          <div className="relative transition-all flex flex-col pb-10 h-[98%] sm:h-[95%] flex-nowrap sm:pl-10 mt-2 overflow-y-auto scrollbar-hide overflow-x-hidden m-auto w-[98%] sm:w-[90%] border-8 shadow-sm shadow-black md:border-4 border-slate-950 bg-opacity-30 backdrop-blur-md bg-slate-950 rounded-xl">
            <div className="z-30 sticky -top-1 md:top-0 flex w-full lg:w-2/3 mx-auto lg:mr-0 lg:ml-auto p-2 lg:p-4 bg-gradient-to-tl from-slate-950 to-slate-800 rounded-b-lg shadow-sm shadow-black">
              <div className="flex flex-nowrap w-full h-full overflow-x-auto overflow-y-hidden">
                <div className="w-fit mr-2">
                  {editProfilePic ? (
                    <div className="relative w-fit h-full flex flex-row flex-nowrap">
                      <ConfigProvider
                        theme={{
                          components: {
                            Modal: {
                              /* here is your component tokens */
                              contentBg: "white",
                            },
                          },
                        }}
                      >
                        {!staggedPic ? (
                          <div className="h-24 w-24 md:h-40 md:w-40 bg-white rounded-lg shadow-sm shadow-slate-900">
                            <ImgCrop aspect={1} rotationSlider>
                              <Dragger {...uploadProps}>
                                Click to add a picture
                              </Dragger>
                            </ImgCrop>
                          </div>
                        ) : (
                          <img
                            className="h-24 w-24 md:h-40 md:w-40 md:ml-4 mr-3 min-w-fit bg-slate-100 rounded-xl"
                            src={data.img}
                            alt="profile"
                          />
                        )}
                      </ConfigProvider>
                      <div className="flex mt-auto gap-1 ml-1">
                        <Button
                          className="w-fit h-fit mx-auto p-1 px-2 font-semibold rounded-md"
                          onClick={() => {
                            handleConfirm();
                          }}
                        >
                          Confirm
                        </Button>
                        <Button
                          danger
                          className="w-6 h-fit text-center mx-auto p-1 px-2 font-semibold rounded-md"
                          onClick={() => {
                            setEditProfilePic(false);
                            setStaggedPic(false);
                          }}
                        >
                          X
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <button
                      onClick={() => setEditProfilePic(true)}
                      className="relative h-fit w-24 md:w-40 mt-2 md:mt-0 min-w-fit rounded-xl"
                    >
                      <div className="absolute top-1/4 opacity-0 hover:opacity-75 text-gray-50 h-full w-full">
                        <ImFilePicture className="h-1/2 w-1/2 m-auto " />
                      </div>
                      <div className="h-full w-full md:h-40 md:w-40 bg-slate-100 rounded-xl">
                        <img
                          className="h-full rounded-xl"
                          src={profileInfo.img}
                          alt="profile"
                        />
                      </div>
                    </button>
                  )}
                </div>
                <div className="flex flex-col w-fit text-2xl gap-2 font-semibold text-white">
                  <div className="text-xl">
                    <span>#{profileInfo["jersey-number"]} </span>
                    <span>{profileInfo.position} </span>
                  </div>
                  <span className=" font-bold">{profileInfo["full-name"]}</span>
                  <div className="text-xl">
                    <span className="mr-4">{profileInfo.height}</span>
                    <span>{profileInfo.weight}</span>
                  </div>
                </div>
                <div className="text-white flex flex-col pl-2 md:pl-[10%] pt-1 w-fit">
                  <div className="mb-1">
                    <span className="font-semibold">Class: </span>
                    <span className="">
                      {profileInfo.hsgradYear} ({profileInfo.year})
                    </span>
                  </div>
                  <div className="mb-1">
                    <span className="font-semibold">Twitter: </span>
                    <a
                      href={`https://twitter.com/${profileInfo.Xtwitter}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {profileInfo.Xtwitter}
                    </a>
                  </div>
                  <div className="mb-1">
                    <span className="font-semibold">Email: </span>
                    <span className="">{profileInfo.email}</span>
                  </div>
                  <div className="mb-1">
                    <span className="font-semibold">Phone: </span>
                    <span className="">{profileInfo.phone}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="ml-2 lg:-mt-20">
              {RecruitingInputs.map((input) => {
                if (!profileInfo) {
                  return (
                    <div className="rounded-sm h-12 m-1 mt-3" key={input.id}>
                      <label className="z-10 mr-12 rounded-sm rounded-l-none shadow-slate-800 shadow-sm text-center px-2 bg-[#f1efe9] font-bold">
                        {input.label}:
                      </label>
                      <div className="ml-1 pl-2 h-fit text-black shadow-slate-600 bg-white bg-opacity-90 hover:bg-opacity-100 shadow-sm w-full rounded-b-md min-w-fit">
                        <Skeleton.Input
                          className="py-1 h-fit"
                          active
                          size="small"
                        />
                      </div>
                    </div>
                  );
                } else {
                  const placeholder = profileInfo
                    ? profileInfo[input.id]
                    : null;
                  return (
                    <div
                      className="flex flex-col rounded-sm h-fit w-full m-1 mt-3"
                      key={input.id}
                    >
                      <label className="z-10 w-fit mr-12 rounded-sm rounded-l-none shadow-slate-800 shadow-sm text-center px-2 bg-[#f1efe9] font-bold">
                        {input.label}:
                      </label>
                      {input.type === "select" ? (
                        <select
                          className="cursor-pointer ml-1 pl-1 h-8 w-[30%] text-black shadow-slate-600 bg-white bg-opacity-90 hover:bg-opacity-100 shadow-sm rounded-b-md min-w-fit"
                          id={input.id}
                          onChange={addInputsToData}
                        >
                          <option selected disabled hidden>
                            {placeholder}
                          </option>
                          {input.options.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      ) : input.type === "checkbox" ? (
                        <div className="ml-1 pl-2 h-fit text-black shadow-slate-600 bg-white bg-opacity-90 hover:bg-opacity-100 shadow-sm w-1/4 rounded-b-md min-w-fit">
                          {input.options.map((option) => (
                            <div key={option}>
                              <input
                                type="checkbox"
                                id={`${input.id}-${option}`}
                                name={input.id}
                                value={option}
                                defaultChecked={
                                  LOCOptions.includes(option) ||
                                  multiSportOptions.includes(option)
                                } // You can use your own logic here
                                onChange={(e) =>
                                  handleCheckboxChange(e, input.label, option)
                                }
                              />
                              <label
                                htmlFor={`${input.id}-${option}`}
                                className="ml-2 pr-2"
                              >
                                {option}
                              </label>
                            </div>
                          ))}
                        </div>
                      ) : input.type === "string" ? (
                        <>
                          <textarea
                            className="ml-1 pl-2 pb-2 h-10 min-h-fit w-[90%] md:w-3/4 whitespace-normal text-black shadow-slate-600 bg-white bg-opacity-90 hover:bg-opacity-100 shadow-sm rounded-b-md min-w-fit"
                            id={input.id}
                            type={input.type}
                            defaultValue={placeholder}
                            placeholder={input.placeholder}
                            onChange={addInputsToData}
                          />
                          {input.id === "filmLink" && profileInfo.filmLink ? (
                            <div className="w-[95%] max-w-[36rem] mt-1 aspect-video relative">
                              <div
                                className="absolute top-0 left-0 w-full h-full"
                                dangerouslySetInnerHTML={{
                                  __html: placeholder,
                                }}
                              />
                            </div>
                          ) : null}
                        </>
                      ) : input.type === "number" ? (
                        <input
                          className="ml-1 pl-2 h-8 w-fit text-black shadow-slate-600 bg-white bg-opacity-90 hover:bg-opacity-100 shadow-sm rounded-b-md min-w-fit"
                          id={input.id}
                          type={input.type}
                          defaultValue={placeholder}
                          placeholder={input.placeholder}
                          onChange={addInputsToData}
                        />
                      ) : null}
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
      </Modal>
    </ConfigProvider>
  );
}
