import { HeartFilled, HeartOutlined } from "@ant-design/icons";
import { Button, Card, Drawer, FloatButton, Menu, Select } from "antd";
import { motion } from "framer-motion";
import React, { useState } from "react";
import { FiFilter } from "react-icons/fi";
import { BiBomb } from "react-icons/bi";
import { GiMagickTrick } from "react-icons/gi";
import { PiScreencast } from "react-icons/pi";
import { TiDelete } from "react-icons/ti";
import { FaRegCheckCircle } from "react-icons/fa";
import { WCDisplayCards } from "./WCDisplayCards";

export function WCGameMode({
  data,
  gameMode,
  setGameMode,
  fieldZoneButtons,
  situationButtons,
  playbookDatabase,
}) {
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [openKeys, setOpenKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [showQueue, setShowQueue] = useState(false);

  const isMobile = window.innerWidth < 768; // Check if the screen width is smaller than 768px

  const transformPlaybookToMenuItems = (playbookDatabase) => {
    const { Formations, Motions, Runs, Passes } = playbookDatabase;

    // Helper function to create submenu structure for formations
    const formatFormations = () => {
      const submenuMap = {};

      Formations.forEach((formation) => {
        const parts = formation.split(" ");
        const base = parts[0];
        const detail = parts.slice(1).join(" ");

        if (!submenuMap[base]) {
          submenuMap[base] = {
            key: base,
            label: base,
            children: [],
          };
        }

        if (detail) {
          submenuMap[base].children.push({
            key: `${base} ${detail}`,
            label: detail,
          });
        }
      });

      return Object.values(submenuMap);
    };

    const items = [
      {
        key: "Favorites",
        label: "Favorites",
        icon: (
          <>
            {selectedKeys.includes("Favorites") ? (
              <HeartFilled />
            ) : (
              <HeartOutlined />
            )}
          </>
        ),
      },
      {
        type: "divider",
      },
      {
        key: "Formations",
        label: "Formation",
		icon: <div>{isMobile ? 'Form' : null}</div>,
        children: formatFormations(),
      },
      {
        key: "Motion",
        label: "Motion",
        children: Motions.map((motion) => ({
          key: motion,
          label: motion,
        })),
		icon: <div>{isMobile ? 'Mot' : null}</div>,
      },
      {
        key: "Run",
        label: "Run",
        children: Runs.map((run) => ({
          key: run,
          label: run,
        })),
		icon: <div>{isMobile ? 'Run' : null}</div>,

      },
      {
        key: "Pass",
        label: "Pass",
        children: Passes.map((pass) => ({
          key: pass,
          label: pass,
        })),
		icon: <div>{isMobile ? 'Pass' : null}</div>,
      },
      {
        key: "RPO",
        label: "RPO",
		icon: <div>{isMobile ? 'RPO' : null}</div>,
      },
      {
        key: "Play Action",
        label: "Play Action",
		icon: <div>{isMobile ? 'PA' : null}</div>,
      },
      {
        type: "divider",
      },
      {
        key: "Concept",
        label: "Concept",
        type: "group",
        children: [
          {
            key: "Screens",
            label: "Screens",
            icon: <PiScreencast />,
          },
          {
            key: "Shot Plays",
            label: "Shot Plays",
            icon: <BiBomb />,
          },
          {
            key: "Trick Plays",
            label: "Trick Plays",
            icon: <GiMagickTrick />,
          },
        ],
      },
    ];

    return items;
  };

  const menuItems = transformPlaybookToMenuItems(playbookDatabase);

  const onFilterClick = (filterName) => {
    setSelectedFilters((prevSelectedFilters) => {
      if (prevSelectedFilters.includes(filterName)) {
        // Remove the button from the array if it was already selected
        return prevSelectedFilters.filter((name) => name !== filterName);
      } else {
        // Add the button to the array if it wasn't selected
        return [...prevSelectedFilters, filterName];
      }
    });
  };

  const onFilterSelectChange = (value, optionList) => {
    setSelectedFilters((currentSelectedFilters) => {
      // Remove any existing values from the array that are in optionList
      const newSelectedFilters = currentSelectedFilters.filter(
        (name) => !optionList.includes(name)
      );

      // Add the new values from the value array
      newSelectedFilters.push(...value);

      return newSelectedFilters;
    });
  };
  

  const onOpenChange = (newOpenKeys) => {
    const categoryLabels = ["Formations", "Motion"]; // Define category labels
    setOpenKeys(newOpenKeys);

    setSelectedKeys((prevSelectedKeys) => {
        // Assume newOpenKeys is available here as the latest state of what menus are open
        const submenuKeysToAdd = newOpenKeys.filter(
            (key) => !categoryLabels.includes(key)
        );
        const newKeySet = new Set(submenuKeysToAdd);

        // Filter previous selected keys to determine which should remain:
        return prevSelectedKeys
            .filter((key) => {
                // Retain key if:
                // 1. It is still in the new set of open keys (direct match in currently open submenus)
                // 2. Its corresponding submenu or any parent menu is still open (this requires a separate check to ensure we're not removing subcategories incorrectly)
                // 3. If isMobile, retain all keys
                return (
                    newKeySet.has(key) ||
                    Array.from(newKeySet).some(
                        (openKey) =>
                            key === openKey ||
                            key.startsWith(openKey + " ") ||
                            openKey.startsWith(key + " ")
                    ) ||
                    (isMobile && prevSelectedKeys.includes(key))
                );
            })
            .concat(
                submenuKeysToAdd.filter((key) => !prevSelectedKeys.includes(key))
            );
    });
    console.log("newOpenKeys", newOpenKeys);
    console.log("selectedKeys OnOpen", selectedKeys);
};


  const onSelect = ({ key }) => {
    setSelectedKeys((prevKeys) => {
      const index = prevKeys.indexOf(key);
      if (index > -1) {
        // Toggle key off if already selected
        return prevKeys.filter((k) => k !== key);
      } else {
        // Add new key if not currently selected
        return [...prevKeys, key];
      }
    });
    setOpenKeys((preOpen) => {
      const index = preOpen.indexOf(key);
      if (index > -1) {
        // Toggle key off if already selected
        return preOpen.filter((k) => k !== key);
      } else {
        // Add new key if not currently selected
        return [...preOpen, key];
      }
    });
  };

  const clearSelections = () => {
    setSelectedKeys([]);
    setOpenKeys([]);
    setSelectedFilters([]);
  };

  const [playQueue, setPlayQueue] = useState([]);
  const [callHistory, setCallHistory] = useState([]);

  const addToSuccessQueue = (call) => {
    const callWithStatus = { ...call, status: "success" };
    setCallHistory((prevCallHistory) => [...prevCallHistory, callWithStatus]);
  };

  const addToFailQueue = (call) => {
    const callWithStatus = { ...call, status: "fail" };
    setCallHistory((prevCallHistory) => [...prevCallHistory, callWithStatus]);
  };

  const addToQueue = (call) => {
    const inQueue = playQueue.some((play) => play.key === call.key);
    if (!inQueue) {
      setPlayQueue((prevPlayQueue) => [...prevPlayQueue, call]);
    } else if (inQueue) {
      setPlayQueue((prevPlayQueue) =>
        prevPlayQueue.filter((play) => play.key !== call.key)
      );
    }
  };

  const removeFromQueue = (key) => {
    setPlayQueue((prevPlayQueue) =>
      prevPlayQueue.filter((play) => play.key !== key)
    );
  };

  const removeFromCallHistory = (index) => {
    setCallHistory((prevCallHistory) =>
      prevCallHistory.filter((_, i) => i !== index)
    );
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="absolute overflow-auto top-0 left-0 z-50 h-screen mb-2 w-screen bg-slate-900"
    >
      <FloatButton
	  className="-mr-5 mb-14 md:mb-0 md:mr-0"
        icon={<FiFilter />}
        tooltip={<div>Clear Filters</div>}
        badge={{
          count: selectedFilters.length + selectedKeys.length,
        }}
        onClick={() => clearSelections()}
      />
      <div className="h-screen w-full">
        <div className="flex w-full h-1/6">
          <div className="w-2/5 md:w-1/5 flex flex-col">
            <Button className="w-1/2 min-w-fit" danger onClick={() => setGameMode(!gameMode)}>
              Exit
            </Button>
			<Button className="text-white font-Kanit bg-slate-700 bg-opacity-90 mx-auto mt-1 max-w-full truncate">{isMobile ? 'End Game' : 'End Game / Export Call History'}</Button>
            <div className="text-white font-Kanit bg-slate-800 opacity-90 p-3 mt-auto font-lg font-bold tracking-wide mb-1">Total Plays: <span className="mt-1 text-xl text-green-400">{data.length}</span></div>
            {/* <div className='bg-white p-3'>Run Plays: {data.length}</div>
				  <div className='bg-white p-3'>Pass Plays: {data.length}</div> */}
          </div>
          <div className="h-full w-4/5 flex">
            <div className="w-3/5 md:w-4/5 h-full bg-gradient-to-b from-slate-800 to-slate-900 shadow-sm shadow-slate-800 rounded-bl-sm">
              <div className="flex flex-col h-full w-full">
                <div className="flex flex-col md:flex-row relative scrollbar-hide overflow-auto h-fit max-w-full my-auto mx-auto group transition-all rounded-lg">
                  <div className="sticky max-h-12 flex top-0 left-0 md:ml-2 z-10 bg-slate-200 rounded-br-sm shadow-sm shadow-slate-500">
                    <div className="w-fit whitespace-nowrap px-3 m-auto font-Russo tracking-wide">
                      Field Zones
                    </div>
                  </div>
                  <div className="flex flex-col-reverse md:flex-row-reverse justify-center bg-slate-300 h-fit w-full md:w-fit md:min-h-8 shadow-sm shadow-slate-300 rounded-lg">
                    {fieldZoneButtons.map((button) => (
                      <Button
                        key={button.name}
                        onClick={() => onFilterClick(button.name)}
                        className={`${
                          selectedFilters.includes(button.name)
                            ? "shadow-inner shadow-slate-800"
                            : ""
                        } ${
                          button.className
                        } border-none bg-opacity-60 rounded-none transition-all block group-hover:opacity-100 last:rounded-r-md`}
                      >
                        <div className="-mt-[6px]">{button.name}</div>
                        <div className="-mt-[6px] text-xs text-slate-500">
                          {button.fieldPos}
                        </div>
                      </Button>
                    ))}
                  </div>
                </div>
                <div className="absolute md:static bottom-0 left-0 z-30 flex pl-2 w-full overflow-x-auto bg-slate-900 md:bg-transparent md:gap-2 h-28 overflow-y-hidden md:h-fit mb-auto">
                  <div className="max-w-80 w-full my-auto pb-3 bg-slate-700 text-white opacity-90 rounded-md p-1">
                    <div className="border-b border-white w-fit px-3 mx-auto mb-1 font-Russo tracking-wide">
                      Hash
                    </div>
                    <div className="flex w-full gap-2">
                      <Button
                        onClick={() => onFilterClick("L")}
                        className={`${
                          selectedFilters.includes("L")
                            ? "shadow-inner shadow-slate-800"
                            : ""
                        } w-1/3 font-semibold px-2 py-1`}
                      >
                        L
                      </Button>
                      <Button
                        onClick={() => onFilterClick("M")}
                        className={`${
                          selectedFilters.includes("M")
                            ? "shadow-inner shadow-slate-800"
                            : ""
                        } w-1/3 font-semibold px-2 py-1`}
                      >
                        M
                      </Button>
                      <Button
                        onClick={() => onFilterClick("R")}
                        className={`${
                          selectedFilters.includes("R")
                            ? "shadow-inner shadow-slate-800"
                            : ""
                        } w-1/3 font-semibold px-2 py-1`}
                      >
                        R
                      </Button>
                    </div>
                  </div>
                  <div className="max-w-80 w-full my-auto pb-3 flex flex-col bg-slate-700 text-white opacity-90 rounded-md p-1 mx-1">
                    <div className="border-b border-white w-fit px-2 mx-auto font-Russo tracking-wide">
                      Down
                    </div>
                    <Select
                      className="mt-1 w-5/6 mx-auto"
                      allowClear
                      onChange={(value) => {
                        if (value === "Control Downs") {
                          onFilterSelectChange(
                            [""],
                            ["1st", "2nd", "3rd", "4th"]
                          );
                        } else if (value !== undefined) {
                          onFilterSelectChange(
                            [value],
                            ["1st", "2nd", "3rd", "4th"]
                          );
                        } else {
                          onFilterSelectChange(
                            [],
                            ["1st", "2nd", "3rd", "4th"]
                          );
                        }
                      }}
                      options={[
                        {
                          value: "Control Downs",
                          label: "Control Downs",
                        },
                        {
                          value: "3rd",
                          label: "3rd",
                        },
                        {
                          value: "4th",
                          label: "4th",
                        },
                      ]}
                    />
                  </div>
                  <div className="max-w-80 w-full my-auto pb-4 flex flex-col bg-slate-700 text-white opacity-90 rounded-md mr-1">
                    <div className="border-b border-white w-fit px-2 mx-auto font-Russo tracking-wide">
                      Distance
                    </div>
                    <Select
                      className="mt-1 w-[90%] mx-auto"
                      allowClear
                      mode="tags"
                      onChange={(value) =>
                        onFilterSelectChange(value, [
                          "",
                          "Short",
                          "Medium",
                          "Long",
                          "Long +",
                        ])
                      }
                      options={[
                        {
                          value: "",
                          label: "",
                        },
                        {
                          value: "Short",
                          label: "Short (0-3)",
                        },
                        {
                          value: "Medium",
                          label: "Medium (3-7)",
                        },
                        {
                          value: "Long",
                          label: "Long (8-12)",
                        },
                        {
                          value: "Long +",
                          label: "Long + (12+)",
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col md:h-full w-1/5 min-w-fit overflow-hidden">
              {situationButtons
                .filter(
                  (button) =>
                    button.name !== "4th Down" && button.name !== "3rd Down"
                )
                .map((button) => (
                  <Button
                    key={button.name}
                    onClick={() => onFilterClick(button.name)}
                    className={`rounded-none h-1/4 bg-slate-800 bg-opacity-90 font-kanit font-bold tracking-wide last:rounded-b-md
					${
                      selectedFilters.includes(button.name)
                        ? "border-4 border-green-400 shadow-inner shadow-slate-800"
                        : ""
                    } ${button.className ? button.className : 'text-white'}`}
                  >
                    {button.name}
                  </Button>
                ))}
            </div>
          </div>
        </div>
        <div className="h-5/6 w-full">
          <div className="flex h-full">
            <div className="w-1/5 overflow-x-hidden relative flex flex-col h-full col-span-1 rounded-tr-sm overflow-y-auto scrollbar-none">
              <div className="sticky bg-slate-800 flex top-0 h-fit z-[60] rounded-tr-sm shadow-sm shadow-slate-900 pb-1">
                {/* <Button
                        className=""
                        type="primary"
                        onClick={toggleCollapsed}
                      >
                        {collapsed ? (
                          <MenuUnfoldOutlined />
                        ) : (
                          <MenuFoldOutlined />
                        )}
                      </Button> */}
                <div className="m-auto ml-1 md:ml-3 mr-1 font-Russo text-white whitespace-nowrap">{isMobile ? 'P :' : 'Personnel:'}</div>
                <Select
                  className="mt-1 mx-auto min-w-14 w-[95%] pr-1"
                  allowClear
                  mode="tags"
                  onChange={(value) =>
                    onFilterSelectChange(value, [
                      "00p",
                      "01p",
                      "02p",
                      "10p",
                      "11p",
                      "12p",
                      "13p",
                      "20p",
                      "21p",
                      "22p",
                      "23p",
                    ])
                  }
                  options={[
                    {
                      value: "00p",
                      label: "00",
                    },
                    {
                      value: "01p",
                      label: "01",
                    },
                    {
                      value: "02p",
                      label: "02",
                    },
                    {
                      value: "10p",
                      label: "10",
                    },
                    {
                      value: "11p",
                      label: "11",
                    },
                    {
                      value: "12p",
                      label: "12",
                    },
                    {
                      value: "13p",
                      label: "13",
                    },
                    {
                      value: "20p",
                      label: "20",
                    },
                    {
                      value: "21p",
                      label: "21",
                    },
                    {
                      value: "22p",
                      label: "22",
                    },
                    {
                      value: "23p",
                      label: "23",
                    },
                  ]}
                />
              </div>
              <div className="h-full min-h-fit">
                <Menu
                  className="h-fit min-h-full font-Kanit tracking-wide font-extralight"
                  theme="dark"
                  multiple
                  openKeys={openKeys}
                  onOpenChange={onOpenChange}
                  selectedKeys={selectedKeys}
                  onClick={onSelect}
                  inlineCollapsed={isMobile}
                  mode="inline"
                  items={menuItems}
                />
              </div>
            </div>
            <div className="w-4/5 flex flex-col h-full shadow-inner shadow-slate-black bg-gradient-to-t to-slate-700 from-slate-950 bg-opacity-70 overflow-y-auto scrollbar-thin">
              <Drawer
                title="Play Queue"
                size=""
                onClose={() => setShowQueue(!showQueue)}
                open={showQueue}
              >
                <div className="flex flex-col h-full w-full">
                  <div
                    className={`h-2/3 shadow-inner bg-gradient-to-b from-slate-800 to-slate-900 shadow-slate-500 flex flex-col gap-3 max-h-full overflow-y-auto scrollbar-hide w-full`}
                  >
                    {playQueue.map((item) => {
                      const rpRun = item.Tags.some((tag) => tag.text === "Run")
                        ? "Run"
                        : "";
                      const rpPass = item.Tags.some(
                        (tag) => tag.text === "Pass"
                      )
                        ? "Pass"
                        : "";
                      const rpRPO = item.Tags.some((tag) => tag.text === "RPO")
                        ? "RPO"
                        : "";
                      const rpScreen = item.Tags.some(
                        (tag) => tag.text === "Screens"
                      )
                        ? "Screen"
                        : "";
                      const rpColorRun = item.Tags.some(
                        (tag) => tag.text === "Run"
                      )
                        ? " bg-gradient-to-b to-green-400 from-green-200"
                        : "";
                      const rpColorPass = item.Tags.some(
                        (tag) => tag.text === "Pass"
                      )
                        ? "bg-gradient-to-b to-blue-400 from-blue-200"
                        : "";
                      const rpColorRPO = item.Tags.some(
                        (tag) => tag.text === "RPO"
                      )
                        ? "bg-gradient-to-b to-indigo-400 from-indigo-200"
                        : "";

                      const rpColorScreen = item.Tags.some(
                        (tag) => tag.text === "Screens"
                      )
                        ? "bg-gradient-to-b to-yellow-400 from-yellow-200"
                        : "";

                      return (
                        <Card
                          key={item.key}
                          title={
                            <p className="flex text-lg mt-6 font-Kanit tracking-wide">
                              <span>{item.playCall}</span>
                              <button
                                onClick={() => removeFromQueue(item.key)}
                                className="ml-auto mr-2 opacity-30 hover:opacity-100 hover:text-blue-400"
                              >
                                x
                              </button>
                            </p>
                          }
                          className={`scale-75 first:mt-0 -mt-8 w-full mx-auto h-fit bg-gradient-to-b from-white via-white to-slate-300 shadow-md shadow-black`}
                        >
                          <p className="absolute -top-[5px] -left-[38px] rounded-br-md rounded-tl-md bg-gradient-to-b from-white to-slate-100 flex justify-center align-middle ml-8 w-16 shadow-sm shadow-slate-500 px-1">
                            <div
                              className={`mt-2 h-3 w-3 rounded-full ${item.WristColor}`}
                            ></div>
                            <div className="text-lg font-bold font-Kanit ml-2">
                              {item.WristNum}
                            </div>
                          </p>
                          <p
                            className={`absolute -bottom-[5px] -right-1 rounded-tl-md rounded-br-md ${rpColorRun} ${rpColorPass} ${rpColorRPO} ${rpColorScreen} flex justify-center align-middle ml-8 w-12 shadow-sm shadow-slate-900 px-1`}
                          >
                            <div className="text-sm font-bold font-Kanit">
                              {!rpRPO && rpRun ? rpRun : ""}
                              {!rpRPO && rpPass ? rpPass : ""}
                              {rpRPO} {rpScreen}
                            </div>
                          </p>
                          <div className="flex w-full justify-center gap-2">
                            <button
                              onClick={() => addToFailQueue(item)}
                              className="flex border p-2 mr-2 opacity-30 hover:opacity-100 hover:text-red-400 hover:border-red-300"
                            >
                              <TiDelete className="scale-150 my-auto" />{" "}
                              <div className="ml-2">Fail</div>
                            </button>
							<button
                              onClick={() => addToSuccessQueue(item)}
                              className="flex border p-2 opacity-30 hover:opacity-100 hover:text-green-500 hover:border-green-500"
                            >
                              <FaRegCheckCircle className="scale-125 my-auto" />{" "}
                              <div className="ml-2">Success</div>
                            </button>
                          </div>
                        </Card>
                      );
                    })}
                  </div>
                  <div className={`relative mt-auto h-1/3 overflow-y-auto w-full pb-20 scrollbar-none`}>
                    <div className="sticky top-0 font-semibold w-full bg-white px-2 border-b border-black mb-1">
                      Call History
                    </div>
					<div className="flex flex-col-reverse">
                    {callHistory.map((play, index) => {
                      return (
                        <div className="flex mt-1" key={index}>
                          <div className=" mr-1 w-5 font-bold">{index + 1}.</div>
						  <p className="mr-2 w-8 rounded-br-md rounded-tl-md bg-gradient-to-b from-white to-slate-100 flex justify-center align-middle shadow-sm shadow-slate-500">
                              <div
                                className={`mt-2 h-2 w-2 rounded-full ${play.WristColor}`}
                              ></div>
                              <div className="text-sm font-bold font-Kanit ml-1">
                                {play.WristNum}
                              </div>
                            </p>
                          <div
                            className={
                              play.status === "success"
                                ? "text-green-500"
                                : play.status === "fail"
                                ? "text-red-400"
                                : ""
                            }
                          >
                            {play.playCall}
                          </div>
                          <button
                            onClick={() => removeFromCallHistory(index)}
                            className="ml-auto mr-2 opacity-30 hover:opacity-100"
                          >
                            x
                          </button>
                        </div>
                      );
                    })}
					</div>
                  </div>
                </div>
              </Drawer>
              <button
                onClick={() => setShowQueue(true)}
                className="absolute text-xl rounded-l-lg right-0 top-50 bg-slate-100 h-10 w-12 font-bold"
              >
                {"<"}
              </button>

              {/* Map data to cards to be displayed */}
              <WCDisplayCards data={data.filter((play) => play.hidden !== true)} selectedKeys={selectedKeys} playQueue={playQueue} addToQueue={addToQueue} playbookDatabase={playbookDatabase} selectedFilters={selectedFilters} />
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}
