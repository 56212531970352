import React, { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../context/AuthContext';
import { PathContext } from '../context/PathContext';
import { Cascader} from 'antd'
import { db } from '../Firebase-config'
import { doc, getDoc } from 'firebase/firestore'


function GSDataTitleCascader (props) {
    const [dataTitle, setDataTitle] = useState([])

    const {currentUser} = useContext(AuthContext)
    const { selectedPath } = useContext(PathContext)



    useEffect(() => {
      const getTitleDoc = async () => {
      const docRef = doc(db, 'Teams', selectedPath, 'DataLabels', 'Labels');
      const docSnap = await getDoc(docRef);

      if (docSnap.exists() && docSnap.data().dataTitle !== undefined) {
          setDataTitle(docSnap.data().dataTitle)
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
          setDataTitle([{
            value: 'eg. Year',
            label: 'eg. Year',
            children: []
          }])
        }
     }
    
     getTitleDoc()
    
    },[selectedPath]);

  return (
    <>
      <Cascader
      className='w-full h-fit'
      options={dataTitle}
      size='large'
      onChange={(e) => props.updateTitle(e)}
      showCheckedStrategy='SHOW_CHILD'
      changeOnSelect
      maxTagCount="responsive"
      defaultValue={[]}
      placeholder={'Title Select'}
      />
    </>
  )
}

export default GSDataTitleCascader