import { Tag, Tooltip } from "antd";
import { FaMinus, FaPlus } from "react-icons/fa";

export const offData = [
	{
	  key: "Play",
	  dataIndex: "Play",
	  title: "Play",
	  type: "number",
	  width: 65,
	  fixed: "left",
	},
	{
	  key: "full-name",
	  dataIndex: "full-name",
	  title: "Name",
	  type: "text",
	  width: 150,
	  ellipsis: {},
	  fixed: "left",
	  editable: true,
	  render: (name) => (
		<Tooltip placement="topLeft" title={name}>
		  {name}
		</Tooltip>
	  ),
	},
	{
	  key: "Pos",
	  dataIndex: "position",
	  title: "Pos",
	  type: "text",
	  width: 70,
	  editable: true,
	},
	{
	  key: "assignment",
	  dataIndex: "assignment",
	  title: "Assign",
	  type: "text",
	  width: 80,
	  editable: true,
	  render: (tag) => (
		<div className="w-fit h-fit m-auto">
		  <Tag color={tag === "+" ? "green" : "volcano"} key={tag}>
			{tag === "+" ? (
			  <FaPlus className="my-1" />
			) : (
			  <FaMinus className="my-1" />
			)}
		  </Tag>
		</div>
	  ),
	},
	/*Comments*/ {
	  key: "Comments",
	  dataIndex: "coach-comments",
	  title: "Comments",
	  type: "text",
	  width: 200,
	  editable: true,
	  ellipsis: {},
	  render: (comments) => (
		<Tooltip placement="topLeft" title={comments}>
		  {comments}
		</Tooltip>
	  ),
	},
  ];

export const qbData = [
  {
    key: "Play",
    dataIndex: "Play",
    title: "Play",
    type: "number",
    width: 65,
    fixed: "left",
  },
  {
    key: "full-name",
    dataIndex: "full-name",
    title: "Name",
    type: "text",
    width: 150,
    ellipsis: {},
    fixed: "left",
    editable: true,
    render: (name) => (
      <Tooltip placement="topLeft" title={name}>
        {name}
      </Tooltip>
    ),
  },
  {
    key: "Pos",
    dataIndex: "position",
    title: "Pos",
    type: "text",
    width: 70,
    editable: true,
  },
  {
    key: "assignment",
    dataIndex: "assignment",
    title: "Assign",
    type: "text",
    width: 80,
    editable: true,
    render: (tag) => (
      <div className="w-fit h-fit m-auto">
        <Tag color={tag === "+" ? "green" : "volcano"} key={tag}>
          {tag === "+" ? (
            <FaPlus className="my-1" />
          ) : (
            <FaMinus className="my-1" />
          )}
        </Tag>
      </div>
    ),
  },
  // Completion %
  {
    title: "Completion Percentage",
    key: "CP",
    editable: true,
    children: [
      {
        key: "COM",
        dataIndex: "completion",
        title: "COM",
        type: "number",
        width: 65,
        editable: true,
      },
      {
        key: "INC",
        dataIndex: "incompletion",
        title: "INC",
        type: "number",
        width: 65,
        editable: true,
      },
      {
        title: "Adjusted %",
        key: "ADJCP",
        editable: true,
        children: [
          {
            key: "ACC",
            dataIndex: "accurate",
            title: "ACC",
            type: "number",
            width: 65,
            editable: true,
          },
          {
            key: "INACC",
            dataIndex: "inaccurate",
            title: "INACC",
            type: "number",
            width: 65,
            editable: true,
          },
        ],
      },
    ],
  },
  /*Pos Production*/ {
    title: "Positive Production",
    key: "PP",
    editable: true,
    children: [
      {
        key: "TD",
        dataIndex: "touchdown",
        title: "TD",
        type: "number",
        width: 65,
        editable: true,
      },
      {
        key: "SCR",
        dataIndex: "scramble",
        title: "SCR",
        type: "number",
        width: 65,
        editable: true,
      },
      {
        key: "EXP",
        dataIndex: "explosivePlay",
        title: "EXP",
        type: "number",
        width: 65,
        editable: true,
      },
      {
        key: "REC",
        dataIndex: "reception",
        title: "REC",
        type: "number",
        width: 65,
        editable: true,
      },
    ],
  },
  /*Neg Production*/ {
    title: "Negative Production",
    key: "NP",
    editable: true,
    children: [
      {
        key: "MA",
        dataIndex: "missed-assignment",
        title: "MA",
        type: "number",
        width: 65,
        editable: true,
      },
      {
        key: "INT",
        dataIndex: "interception",
        title: "INT",
        type: "number",
        width: 65,
        editable: true,
      },
      {
        key: "FUM",
        dataIndex: "fumble",
        title: "FUM",
        type: "number",
        width: 65,
        editable: true,
      },
      {
        key: "SCK",
        dataIndex: "sack",
        title: "SCK",
        type: "number",
        width: 65,
        editable: true,
      },
      {
        key: "Drop",
        dataIndex: "drop",
        title: "Drop",
        type: "number",
        width: 65,
        editable: true,
      },
      {
        key: "Loaf",
        dataIndex: "loaf",
        title: "Loaf",
        type: "number",
        width: 65,
        editable: true,
      },
    ],
  },
  /*Comments*/ {
    key: "Comments",
    dataIndex: "coach-comments",
    title: "Comments",
    type: "text",
    width: 200,
    editable: true,
    ellipsis: {},
    render: (comments) => (
      <Tooltip placement="topLeft" title={comments}>
        {comments}
      </Tooltip>
    ),
  },
];

export const olData = [
  {
    key: "Play",
    dataIndex: "Play",
    title: "Play",
    type: "number",
    width: 65,
    fixed: "left",
  },
  {
    key: "full-name",
    dataIndex: "full-name",
    title: "Name",
    type: "text",
    width: 150,
    ellipsis: {},
    fixed: "left",
    editable: true,
    render: (name) => (
      <Tooltip placement="topLeft" title={name}>
        {name}
      </Tooltip>
    ),
  },
  {
    key: "Pos",
    dataIndex: "position",
    title: "Pos",
    type: "text",
    width: 70,
    editable: true,
  },
  {
    key: "assignment",
    dataIndex: "assignment",
    title: "Assign",
    type: "text",
    width: 80,
    editable: true,
    render: (tag) => (
      <div className="w-fit h-fit m-auto">
        <Tag color={tag === "+" ? "green" : "volcano"} key={tag}>
          {tag === "+" ? (
            <FaPlus className="my-1" />
          ) : (
            <FaMinus className="my-1" />
          )}
        </Tag>
      </div>
    ),
  },
  /*Pos Production*/ {
    title: "Positive Production",
    key: "PP",
    editable: true,
    children: [
      {
        key: "pancake",
        dataIndex: "pancake",
        title: "Pancake",
        type: "number",
        width: 65,
        editable: true,
      },
      {
        key: "stonewall",
        dataIndex: "stonewall",
        title: "Stonewall",
        type: "number",
        width: 65,
        editable: true,
      },
      {
        key: "displacement",
        dataIndex: "displacement",
        title: "Displacement",
        type: "number",
        width: 65,
        editable: true,
      },
      {
        key: "keyBlock",
        dataIndex: "keyBlock",
        title: "Key Block",
        type: "number",
        width: 65,
        editable: true,
      },
      {
        key: "2LvlBlock",
        dataIndex: "2LvlBlock",
        title: "2nd Lvl Block",
        type: "number",
        width: 65,
        editable: true,
      },
      {
        key: "finish",
        dataIndex: "finish",
        title: "Finish",
        type: "number",
        width: 65,
        editable: true,
      },
      {
        key: "TD",
        dataIndex: "touchdown",
        title: "TD",
        type: "number",
        width: 65,
        editable: true,
      },
    ],
  },
  /*Neg Production*/ {
    title: "Negative Production",
    key: "NP",
    editable: true,
    children: [
      {
        key: "MA",
        dataIndex: "missed-assignment",
        title: "MA",
        type: "number",
        width: 65,
        editable: true,
      },
      {
        key: "offBalance",
        dataIndex: "offBalance",
        title: "Balance",
        type: "number",
        width: 65,
        editable: true,
      },
      {
        key: "stance",
        dataIndex: "stance",
        title: "Stance",
        type: "number",
        width: 65,
        editable: true,
      },
      {
        key: "wiff",
        dataIndex: "wiff",
        title: "Wiff",
        type: "number",
        width: 65,
        editable: true,
      },
      {
        key: "Loaf",
        dataIndex: "loaf",
        title: "Loaf",
        type: "number",
        width: 65,
        editable: true,
      },
    ],
  },
  /*Comments*/ {
    key: "Comments",
    dataIndex: "coach-comments",
    title: "Comments",
    type: "text",
    width: 200,
    editable: true,
    ellipsis: {},
    render: (comments) => (
      <Tooltip placement="topLeft" title={comments}>
        {comments}
      </Tooltip>
    ),
  },
];

export const rbData = [
  {
    key: "Play",
    dataIndex: "Play",
    title: "Play",
    type: "number",
    width: 65,
    fixed: "left",
  },
  {
    key: "full-name",
    dataIndex: "full-name",
    title: "Name",
    type: "text",
    width: 150,
    ellipsis: {},
    fixed: "left",
    editable: true,
    render: (name) => (
      <Tooltip placement="topLeft" title={name}>
        {name}
      </Tooltip>
    ),
  },
  {
    key: "Pos",
    dataIndex: "position",
    title: "Pos",
    type: "text",
    width: 70,
    editable: true,
  },
  {
    key: "assignment",
    dataIndex: "assignment",
    title: "Assign",
    type: "text",
    width: 80,
    editable: true,
    render: (tag) => (
      <div className="w-fit h-fit m-auto">
        <Tag color={tag === "+" ? "green" : "volcano"} key={tag}>
          {tag === "+" ? (
            <FaPlus className="my-1" />
          ) : (
            <FaMinus className="my-1" />
          )}
        </Tag>
      </div>
    ),
  },
  /*Pos Production*/ {
    title: "Positive Production",
    key: "PP",
    editable: true,
    children: [
		{
			key: "EXP",
			dataIndex: "explosivePlay",
			title: "EXP",
			type: "number",
			width: 65,
			editable: true,
		  },
      {
        key: "brkTackle",
        dataIndex: "brkTackle",
        title: "BrkTKL",
        type: "number",
        width: 65,
        editable: true,
      },
      {
        key: "REC",
        dataIndex: "reception",
        title: "REC",
        type: "number",
        width: 65,
        editable: true,
      },
      {
        key: "TD",
        dataIndex: "touchdown",
        title: "TD",
        type: "number",
        width: 70,
        editable: true,
      },
      {
        key: "Pancake",
        dataIndex: "pancake",
        title: "Pancake",
        type: "number",
        width: 70,
        editable: true,
      },
      {
        key: "Extra Effort",
        dataIndex: "extra-effort",
        title: "Extra Effort",
        type: "number",
        width: 70,
        editable: true,
      },
      {
        key: "Sit-Awareness",
        dataIndex: "sit-awareness",
        title: "Sit-Aware",
        type: "number",
        width: 65,
        editable: true,
      },
    ],
  },
  /*Neg Production*/ {
    title: "Negative Production",
    key: "NP",
    editable: true,
    children: [
      {
        key: "missed-assignment",
        dataIndex: "missed-assignment",
        title: "MA",
        type: "number",
        width: 65,
        editable: true,
      },
      {
        key: "Footwork",
        dataIndex: "footwork",
        title: "Footwork",
        type: "number",
        width: 78,
        editable: true,
      },
      {
        key: "Pacing",
        dataIndex: "pacing",
        title: "Pacing",
        type: "number",
        width: 65,
        editable: true,
      },
      {
        key: "Drop",
        dataIndex: "drop",
        title: "Drop",
        type: "number",
        width: 65,
        editable: true,
      },
      {
        key: "Fumble",
        dataIndex: "fumble",
        title: "Fumble",
        type: "number",
        width: 65,
        editable: true,
      },
      {
        key: "Loaf",
        dataIndex: "loaf",
        title: "Loaf",
        type: "number",
        width: 65,
        editable: true,
      },
    ],
  },
  /*Comments*/ {
    key: "Comments",
    dataIndex: "coach-comments",
    title: "Comments",
    type: "text",
    width: 200,
    editable: true,
    ellipsis: {},
    render: (comments) => (
      <Tooltip placement="topLeft" title={comments}>
        {comments}
      </Tooltip>
    ),
  },
];

export const teData = [
  {
    key: "Play",
    dataIndex: "Play",
    title: "Play",
    type: "number",
    width: 65,
    fixed: "left",
  },
  {
    key: "full-name",
    dataIndex: "full-name",
    title: "Name",
    type: "text",
    width: 150,
    ellipsis: {},
    fixed: "left",
    editable: true,
    render: (name) => (
      <Tooltip placement="topLeft" title={name}>
        {name}
      </Tooltip>
    ),
  },
  {
    key: "Pos",
    dataIndex: "position",
    title: "Pos",
    type: "text",
    width: 70,
    editable: true,
  },
  {
    key: "assignment",
    dataIndex: "assignment",
    title: "Assign",
    type: "text",
    width: 80,
    editable: true,
    render: (tag) => (
      <div className="w-fit h-fit m-auto">
        <Tag color={tag === "+" ? "green" : "volcano"} key={tag}>
          {tag === "+" ? (
            <FaPlus className="my-1" />
          ) : (
            <FaMinus className="my-1" />
          )}
        </Tag>
      </div>
    ),
  },
  /*Pos Production*/ {
    title: "Positive Production",
    key: "PP",
    editable: true,
    children: [
		{
			key: "EXP",
			dataIndex: "explosivePlay",
			title: "EXP",
			type: "number",
			width: 65,
			editable: true,
		  },
      {
        key: "brkTackle",
        dataIndex: "brkTackle",
        title: "BrkTKL",
        type: "number",
        width: 65,
        editable: true,
      },
      {
        key: "REC",
        dataIndex: "reception",
        title: "REC",
        type: "number",
        width: 65,
        editable: true,
      },
      {
        key: "TD",
        dataIndex: "touchdown",
        title: "TD",
        type: "number",
        width: 70,
        editable: true,
      },
      {
        key: "Pancake",
        dataIndex: "pancake",
        title: "Pancake",
        type: "number",
        width: 70,
        editable: true,
      },
      {
        key: "Extra Effort",
        dataIndex: "extra-effort",
        title: "Extra Effort",
        type: "number",
        width: 70,
        editable: true,
      },
      {
        key: "Sit-Awareness",
        dataIndex: "sit-awareness",
        title: "Sit-Aware",
        type: "number",
        width: 65,
        editable: true,
      },
    ],
  },
  /*Neg Production*/ {
    title: "Negative Production",
    key: "NP",
    editable: true,
    children: [
      {
        key: "missed-assignment",
        dataIndex: "missed-assignment",
        title: "MA",
        type: "number",
        width: 65,
        editable: true,
      },
      {
        key: "Footwork",
        dataIndex: "footwork",
        title: "Footwork",
        type: "number",
        width: 78,
        editable: true,
      },
      {
        key: "Pacing",
        dataIndex: "pacing",
        title: "Pacing",
        type: "number",
        width: 65,
        editable: true,
      },
      {
        key: "Drop",
        dataIndex: "drop",
        title: "Drop",
        type: "number",
        width: 65,
        editable: true,
      },
      {
        key: "Fumble",
        dataIndex: "fumble",
        title: "Fumble",
        type: "number",
        width: 65,
        editable: true,
      },
      {
        key: "Loaf",
        dataIndex: "loaf",
        title: "Loaf",
        type: "number",
        width: 65,
        editable: true,
      },
    ],
  },
  /*Comments*/ {
    key: "Comments",
    dataIndex: "coach-comments",
    title: "Comments",
    type: "text",
    width: 200,
    editable: true,
    ellipsis: {},
    render: (comments) => (
      <Tooltip placement="topLeft" title={comments}>
        {comments}
      </Tooltip>
    ),
  },
];

export const wrData = [
  {
    key: "Play",
    dataIndex: "Play",
    title: "Play",
    type: "number",
    width: 65,
    fixed: "left",
  },
  {
    key: "full-name",
    dataIndex: "full-name",
    title: "Name",
    type: "text",
    width: 150,
    ellipsis: {},
    fixed: "left",
    editable: true,
    render: (name) => (
      <Tooltip placement="topLeft" title={name}>
        {name}
      </Tooltip>
    ),
  },
  {
    key: "Pos",
    dataIndex: "position",
    title: "Pos",
    type: "text",
    width: 70,
    editable: true,
  },
  {
    key: "assignment",
    dataIndex: "assignment",
    title: "Assign",
    type: "text",
    width: 80,
    editable: true,
    render: (tag) => (
      <div className="w-fit h-fit m-auto">
        <Tag color={tag === "+" ? "green" : "volcano"} key={tag}>
          {tag === "+" ? (
            <FaPlus className="my-1" />
          ) : (
            <FaMinus className="my-1" />
          )}
        </Tag>
      </div>
    ),
  },
  /*Pos Production*/ {
    title: "Positive Production",
    key: "PP",
    editable: true,
    children: [
		{
			key: "EXP",
			dataIndex: "explosivePlay",
			title: "EXP",
			type: "number",
			width: 65,
			editable: true,
		  },
      {
        key: "brkTackle",
        dataIndex: "brkTackle",
        title: "BrkTKL",
        type: "number",
        width: 65,
        editable: true,
      },
      {
        key: "REC",
        dataIndex: "reception",
        title: "REC",
        type: "number",
        width: 65,
        editable: true,
      },
      {
        key: "TD",
        dataIndex: "touchdown",
        title: "TD",
        type: "number",
        width: 70,
        editable: true,
      },
      {
        key: "Pancake",
        dataIndex: "pancake",
        title: "Pancake",
        type: "number",
        width: 70,
        editable: true,
      },
      {
        key: "Extra Effort",
        dataIndex: "extra-effort",
        title: "Extra Effort",
        type: "number",
        width: 70,
        editable: true,
      },
      {
        key: "Sit-Awareness",
        dataIndex: "sit-awareness",
        title: "Sit-Aware",
        type: "number",
        width: 65,
        editable: true,
      },
    ],
  },
  /*Neg Production*/ {
    title: "Negative Production",
    key: "NP",
    editable: true,
    children: [
      {
        key: "missed-assignment",
        dataIndex: "missed-assignment",
        title: "MA",
        type: "number",
        width: 65,
        editable: true,
      },
      {
        key: "Footwork",
        dataIndex: "footwork",
        title: "Footwork",
        type: "number",
        width: 78,
        editable: true,
      },
      {
        key: "Pacing",
        dataIndex: "pacing",
        title: "Pacing",
        type: "number",
        width: 65,
        editable: true,
      },
      {
        key: "Drop",
        dataIndex: "drop",
        title: "Drop",
        type: "number",
        width: 65,
        editable: true,
      },
      {
        key: "Fumble",
        dataIndex: "fumble",
        title: "Fumble",
        type: "number",
        width: 65,
        editable: true,
      },
      {
        key: "Loaf",
        dataIndex: "loaf",
        title: "Loaf",
        type: "number",
        width: 65,
        editable: true,
      },
    ],
  },
  /*Comments*/ {
    key: "Comments",
    dataIndex: "coach-comments",
    title: "Comments",
    type: "text",
    width: 200,
    editable: true,
    ellipsis: {},
    render: (comments) => (
      <Tooltip placement="topLeft" title={comments}>
        {comments}
      </Tooltip>
    ),
  },
];
