import { Card } from "antd";
import React, { useEffect, useState } from "react";
import { FiMinusCircle } from "react-icons/fi";
import { MdOutlineAddCircleOutline } from "react-icons/md";

export const WCDisplayCards = ({
  data,
  selectedKeys,
  playQueue,
  addToQueue,
  playbookDatabase,
  selectedFilters,
}) => {
	const filterDataBySelectedKeys = (
		data,
		selectedKeys,
		playbookDatabase,
		selectedFilters
	  ) => {
		const { Formations, Runs, Passes } = playbookDatabase;
	  
		// If no keys or filters are selected, return sorted data by playCall
		if (!selectedKeys.length && !selectedFilters.length) {
		  return data.sort((a, b) => a.playCall.localeCompare(b.playCall));
		}
	  
		// Identify if 'Favorites' is among the selected keys
		const isFavoritesFiltered = selectedKeys.includes("Favorites");
		const filteredKeys = selectedKeys.filter((key) => key !== "Favorites");
	  
		// Remove general keys if a more specific key is present
		const finalKeys = filteredKeys.filter(
		  (key) =>
			!filteredKeys.some(
			  (otherKey) => key !== otherKey && otherKey.includes(key)
			)
		);
	  
		const formationKeys = finalKeys.filter((key) => Formations.includes(key));
		const runKeys = finalKeys.filter((key) => Runs.includes(key));
		const passKeys = finalKeys.filter((key) => Passes.includes(key));
		const personnelKeys = selectedFilters.filter((key) => key.endsWith('p'));
		const hashKeys = selectedFilters.filter((key) => ['L', 'M', 'R'].includes(key));
		const downKeys = selectedFilters.filter((key) => ['1st', '2nd', '3rd', '4th'].includes(key));
		const distanceKeys = selectedFilters.filter((key) => ['Short', 'Medium', 'Long', 'Long +'].includes(key));
		const fieldZoneKeys = selectedFilters.filter((key) => ['Backed Up', 'Coming Out', 'Green Zone', 'High RedZone', 'Low RedZone', 'Goal Line'].includes(key));
		const situationKeys = selectedFilters.filter((key) => ['P10', '2 Minute', '4 Minute', 'Overtime'].includes(key));
		const otherKeys = finalKeys.filter(
		  (key) =>
			key !== "Favorites" &&
			!formationKeys.includes(key) &&
			!runKeys.includes(key) &&
			!passKeys.includes(key) &&
			!personnelKeys.includes(key)
		);
	  
		// Helper function to check if a play includes any of the selected formations
		const includesAnyFormation = (play, formationKeys) => {
		  return formationKeys.some((key) => play.playCall.includes(key));
		};
	  
		// Helper function to check if a play includes any of the selected runs
		const includesAnyRun = (play, runKeys) => {
		  return runKeys.some((key) => play.playCall.includes(key));
		};
	  
		// Helper function to check if a play includes any of the selected passes
		const includesAnyPass = (play, passKeys) => {
		  return passKeys.some((key) => play.playCall.includes(key));
		};
	  
		// Helper function to check if a play matches any of the selected filters in Tags.text
		const matchesAnyFilter = (play, filterKeys) => {
		  return filterKeys.some((key) =>
			play.Tags && play.Tags.some((tag) => tag.text.includes(key))
		  );
		};
	  
		// Helper function to check if a play matches exactly the selected keys in Tags.text
		const exactMatchFilter = (play, filterKeys) => {
		  return filterKeys.some(
			(key) => play.Tags && play.Tags.some((tag) => tag.text === key)
		  );
		};
	  
		// Helper function to check if a play matches other selected keys
		const matchesOtherKeys = (play, otherKeys) => {
		  return otherKeys.some((key) => {
			return (
			  play.playCall.includes(key) ||
			  (play.Tags && play.Tags.some((tag) => tag.text.includes(key)))
			);
		  });
		};
	  
		// Function to apply all filters to the data
		const applyFilters = (play) => {
		  const formationMatches =
			formationKeys.length === 0 || includesAnyFormation(play, formationKeys);
		  const runMatches =
			runKeys.length === 0 || includesAnyRun(play, runKeys);
		  const passMatches =
			passKeys.length === 0 || includesAnyPass(play, passKeys);
		  const personnelMatches =
			personnelKeys.length === 0 || matchesAnyFilter(play, personnelKeys);
		  const hashMatches =
			hashKeys.length === 0 || exactMatchFilter(play, hashKeys);
		  const downMatches =
			downKeys.length === 0 || matchesAnyFilter(play, downKeys);
		  const distanceMatches =
			distanceKeys.length === 0 || matchesAnyFilter(play, distanceKeys);
		  const fieldZoneMatches =
			fieldZoneKeys.length === 0 || matchesAnyFilter(play, fieldZoneKeys);
		  const situationMatches =
			situationKeys.length === 0 || matchesAnyFilter(play, situationKeys);
		  const otherMatches =
			otherKeys.length === 0 || matchesOtherKeys(play, otherKeys);
	  
		  // Ensure the play matches at least one filter in each selected group
		  return (
			formationMatches &&
			runMatches &&
			passMatches &&
			personnelMatches &&
			hashMatches &&
			downMatches &&
			distanceMatches &&
			fieldZoneMatches &&
			situationMatches &&
			otherMatches
		  );
		};
	  
		// Filter the data based on all selected keys and filters
		let filteredData = data.filter(applyFilters);
	  
		// Apply favorites filter if needed
		if (isFavoritesFiltered) {
		  filteredData = filteredData.filter((play) => play.favoritePlay);
		}
	  
		// Sort the filtered data alphabetically by playCall
		return filteredData.sort((a, b) => a.playCall.localeCompare(b.playCall));
	  };
	  
	  const [filteredData, setFilteredData] = useState([]);
	  
	  // Function to filter data based on selected keys
	  const filterData = () => {
		const gameModeData = [...data];
		const filtered = filterDataBySelectedKeys(
		  gameModeData,
		  selectedKeys,
		  playbookDatabase,
		  selectedFilters
		);
		setFilteredData(filtered);
	  };
	  
	  // Use useEffect to update filtered data when data, selectedKeys, or selectedFilters change
	  useEffect(() => {
		filterData();
	  }, [data, selectedKeys, selectedFilters]);
	  

  return (
    <div className="w-full h-full pb-32 pt-4 pl-4 content-start p-2 flex flex-wrap gap-3 overflow-auto scrollbar-hide">
      {filteredData.map((item) => {
        const rpRun = item.Tags.some((tag) => tag.text === "Run") ? "Run" : "";
        const rpPass = item.Tags.some((tag) => tag.text === "Pass")
          ? "Pass"
          : "";
        const rpRPO = item.Tags.some((tag) => tag.text === "RPO") ? "RPO" : "";
        const rpScreen = item.Tags.some((tag) => tag.text === "Screens")
          ? "Screen"
          : "";
        const rpColorRun = item.Tags.some((tag) => tag.text === "Run")
          ? " bg-gradient-to-b to-green-400 from-green-200"
          : "";
        const rpColorPass = item.Tags.some((tag) => tag.text === "Pass")
          ? "bg-gradient-to-b to-blue-400 from-blue-200"
          : "";
        const rpColorRPO = item.Tags.some((tag) => tag.text === "RPO")
          ? "bg-gradient-to-b to-indigo-400 from-indigo-200"
          : "";

        const rpColorScreen = item.Tags.some((tag) => tag.text === "Screens")
          ? "bg-gradient-to-b to-yellow-400 from-yellow-200"
          : "";

        const inQueue = playQueue.some((play) => play.key === item.key);

        return (
          <button className="w-fit h-fit hover:scale-105 transition-all" onClick={() => addToQueue(item)}>
            <Card
              key={item.key}
              title={<p className="text-base lg:text-lg mt-6 font-Kanit">{item.playCall}</p>}
              className={`w-64 lg:w-80 h-fit ${
                inQueue ? "border-green-400" : "  border-opacity-0"
              } bg-gradient-to-b border-4 from-white via-white to-slate-300 shadow-md shadow-black`}
            >
              <p className="absolute -top-[5px] -left-[38px] rounded-br-md rounded-tl-md bg-gradient-to-b from-white to-slate-100 flex justify-center align-middle ml-8 w-16 shadow-sm shadow-slate-500 px-1">
                <div
                  className={`mt-2 h-3 w-3 rounded-full ${item.WristColor}`}
                ></div>
                <div className="text-base lg:text-lg font-bold font-Kanit ml-2">
                  {item.WristNum}
                </div>
                <div className="my-auto">
                  {inQueue ? (
                    <FiMinusCircle className="ml-1 text-red-300 hover:text-red-400" />
                  ) : (
                    <MdOutlineAddCircleOutline className="ml-1 text-slate-500 hover:text-slate-700" />
                  )}
                </div>
              </p>
              <p
                className={`absolute -bottom-[5px] -right-1 rounded-tl-md rounded-br-md ${rpColorRun} ${rpColorPass} ${rpColorRPO} ${rpColorScreen} flex justify-center align-middle ml-8 w-12 shadow-sm shadow-slate-900 px-1`}
              >
                <div className="text-sm font-bold font-Kanit">
                  {!rpRPO && rpRun ? rpRun : ""}
                  {!rpRPO && rpPass ? rpPass : ""}
                  {rpRPO} {rpScreen}
                </div>
              </p>
              <div className=""></div>
              {item.image ? <img src={item.image} alt='Play Diagram' className="w-full shadow-sm shadow-slate-800 rounded-md" /> : null}
            </Card>
          </button>
        );
      })}
    </div>
  );
};
