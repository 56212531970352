import React, { useState, useEffect } from "react";
import { ResponsiveBar } from "@nivo/bar";
import { Button } from "antd";

function VDStackedBarChart({ BGData, selectedPositions }) {
  const [data, setData] = useState([]);
  const [intitialData, setInitialData] = useState([]);
  const [maxValue, setMaxValue] = useState("auto");
  const [containerWidth, setContainerWidth] = useState('2');
  const [selectedColor, setSelectedColor] = useState();


  useEffect(() => {
    const rawData = BGData ? [...BGData] : null;

	const groupedData = rawData?.sort((a, b) => {
		const nameA = a.Name.toLowerCase();
		const nameB = b.Name.toLowerCase();
	  
		if (nameA < nameB) return -1;
		if (nameA > nameB) return 1;
		return 0;
	  });

    const filteredData = groupedData.filter((item) => {
      const playerName = item.Name;

      // Include the item if:
      // 1. No positions are selected.
      // 2. No selectedPosition playerNames match.
      // 3. A selectedPosition playerName matches and the position matches.
      return (
        !selectedPositions ||
        !selectedPositions[playerName] || // No position is selected for the playerName
        selectedPositions[playerName] === item.position
      );
    });

	const graphWidth = filteredData.length * 12

    setData(filteredData);
	console.log('BCData', filteredData)
	setContainerWidth(graphWidth + 'rem')
    setInitialData(filteredData);

    // Specify the keys to exclude
    const excludedKeys = [
      "minusAssignments",
      "plusAssignments",
      "percentAssignment",
      "totalAssignments",
      "Completion %",
      "ADJ Completion %",
      "COM",
      "INCOM",
      "ACC",
      "INACC",
      "totalAssignments",
    ];

    // Check if all values are negative for non-excluded keys and set maxValue to 0 if so
    const allNegative = filteredData.every((item) => {
      const numericValues = Object.entries(item)
        .filter(
          ([key, value]) =>
            typeof value === "number" && !excludedKeys.includes(key)
        )
        .map(([key, value]) => value);

      return numericValues.every((value) => value < 0);
    });

    const maxValue = allNegative
      ? 0
      : Math.max(
          ...filteredData.flatMap((item) =>
            Object.entries(item)
              .filter(
                ([key, value]) =>
                  typeof value === "number" && !excludedKeys.includes(key)
              )
              .map(([key, value]) => value)
          )
        );

    setMaxValue(maxValue);
  }, [BGData, selectedPositions]);

  const excludedKeys = [
    "group",
    "Name",
    "img",
    "percentAssignment",
    "playerName",
    "position",
    "playerId",
    "full-name",
    "plusAssignments",
    "minusAssignments",
    "totalAssignments",
    "Completion %",
    "ADJ Completion %",
    "COM",
    "INCOM",
    "ACC",
    "INACC",
  ];

  const uniqueKeys =
    BGData.length > 0
      ? data.reduce((keys, player) => {
          const playerKeys = Object.keys(player).filter(
            (key) => !excludedKeys.includes(key) && !keys.includes(key)
          );

          return keys.concat(playerKeys);
        }, [])
      : [""];

  // Ensure uniqueness by converting the array to a Set and then back to an array
  const uniqueKeysArray = Array.from(new Set(uniqueKeys));

  const handleResetClick = () => {
    // Reset the data to the initial state
    setData(intitialData);
    setMaxValue("auto");
	setSelectedColor()
  };

  const handleBarClick = (dataLabel) => {
    // Filter the data to show only the clicked key
    const clickedKey = dataLabel.label;
    const filteredData = data
      .map((d) => ({
        group: d.group,
        [clickedKey]: d[clickedKey],
      }))
      .filter((d) => d[clickedKey]);

    console.log("selectedStat", filteredData);

    // Check if all values are negative and set maxValue to 0 if so
    const allNegative = filteredData.every((d) => d[clickedKey] < 0);
    const maxValue = allNegative
      ? 0
      : Math.max(...filteredData.map((d) => d[clickedKey]));

    // Update the data prop and maxValue state with the filtered data
    setData(filteredData);
    setMaxValue(maxValue);

    // Find the index of the clicked dataLabel in the original data array
    const clickedIndex = data.findIndex(d => d.label === dataLabel.label);

    // Set the selected color to the seriesColor of the same index
    setSelectedColor([seriesColors[clickedIndex]]);
};


  const seriesColors = [
	"#FBC661",
	"#D6CF61",
	"#AED571",
	"#87D78C",
	"#66D6AA",
	"#57D1C6",
	"#69C9DB",
	"#8EBDE4",
	"#B7AFDF",
	"#D9A0CE",
	"#EF93B4",
	"#F98C95",
	"#F58C77"
  ];
  
  
  return (
    <div
	style={{ width: `${containerWidth}` }}  
	className={`h-[99%] min-w-[95%]`}>
      <div className="relative w-fit h-0">
        <div className="relative font-bold shadow-md shadow-slate-600 px-2 top-2 left-2 w-fit rounded-md bg-slate-200">
          Production Totals
        </div>
      </div>
	  <div className="h-full w-full overflow-hidden">
      <ResponsiveBar
        data={data}
        keys={uniqueKeysArray}
        indexBy="group"
        groupMode="grouped"
        minValue={"auto"}
        maxValue={maxValue}
        borderRadius={3}
        margin={{ top: 50, right: 115, bottom: 50, left: 40 }}
        layout="vertical"
		colors={selectedColor ? selectedColor : seriesColors}
        borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
        }}
        enableGridX={false}
        enableGridY={true}
        enableLabel={true}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        legends={[
          {
            dataFrom: data,
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: "left-to-right",
            itemOpacity: 0.85,
            symbolSize: 20,
            onClick: (labelData, event) => {
              handleBarClick(labelData);
            },
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        layers={[
          "grid",
          "axes",
          "bars",
          "markers",
          "legends",
          ({ bars, xScale, yScale }) => (
            <line
              x1={xScale.range()[0]}
              x2={xScale.range()[1]}
              y1={yScale(0)}
              y2={yScale(0)}
              stroke="#000"
              strokeDasharray="2 2"
            />
          ),
        ]}
      />
	  </div>
      <div className="relative shadow-md w-fit h-fit flex flex-col justify-center align-middle bg-slate-300 bottom-9 ml-auto mr-1 sm:scale-100 scale-75 sm:mr-4 rounded-lg">
        <Button onClick={handleResetClick}>Reset Filters</Button>
      </div>
    </div>
  );
}

export default VDStackedBarChart;
