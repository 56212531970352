import { Button, Modal } from "antd";
import { motion } from "framer-motion";
import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import { db } from "../Firebase-config";
import { doc, writeBatch } from "firebase/firestore";
import { PathContext } from "../context/PathContext";
import { openNotificationWithIcon } from "../helperFunctions/openNotificationWithIcon";

export const AddYear = ({ showAddYear, handleClose, rosterInfo }) => {
  const { selectedPath } = useContext(PathContext);

  let inactivePlayers = [];
  const addPlayerYear = async () => {
    try {
      const updatedRoster = rosterInfo.map((player) => {
        if (player.hasOwnProperty("year")) {
          switch (player.year) {
            case "Fr":
              player.year = "So";
              break;
            case "So":
              player.year = "Jr";
              break;
            case "Jr":
              player.year = "Sr";
              break;
            case "Sr":
              player.year = "Sr";
              player.activePlayer = "Inactive";
              inactivePlayers.push(player["full-name"]);
              break;
            default:
              // Skip if no valid year
              break;
          }
        }
        return player;
      });

      // Update Firestore documents
      const batch = writeBatch(db);

      updatedRoster.forEach((player) => {
        if (player.hasOwnProperty("id")) {
          const playerRef = doc(
            db,
            "Teams",
            selectedPath,
            "PlayerProfiles",
            player.id
          );
          batch.update(playerRef, player);
        }
      });

      await batch.commit();

      openNotificationWithIcon(
        "success",
        "All players class years have been updated",
        `Players moved to Inactive: ${inactivePlayers.join(", ")}`,
        "top"
      );

      handleClose();
    } catch (error) {
      console.error("Error updating Firestore:", error);

      openNotificationWithIcon(
        "error",
        "Error updating Firestore",
        `${error}`,
        "top"
      );
    }
  };

  return (
    <Modal
      className="h-screen pt-[30%] sm:pt-[20%] md:pt-[10%] overflow-hidden my-auto"
      centered
      width={800}
      open={showAddYear}
      onCancel={handleClose}
      destroyOnClose={true}
      footer={[
        <Button key="cancel" onClick={handleClose}>
          Cancel
        </Button>,
      ]}
    >
      <div className="flex flex-col h-full w-full">
        <p className="mx-auto w-[90%] text-center">
          Click the button below to move the year up for every player on roster.
          Note: Seniors will be moved to the Inactive Roster.
        </p>
        <motion.button
          className="mx-auto mt-6 mb-1 h-fit w-fit text-2xl p-2 bg-[#f1efe9] rounded-xl font-bold shadow-gray-700 hover:shadow-inner shadow-sm"
          onClick={() => addPlayerYear()}
        >
          Year Up
        </motion.button>
      </div>
    </Modal>
  );
};
