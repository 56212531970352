import React from "react";
import {
  SortableContext,
  rectSortingStrategy,
} from "@dnd-kit/sortable";

import { DCSortableItem } from "./DCSortableItem";

const handleBucketDragOver = (event) => {
    console.log('Bucket Drag Over', event);
    // Handle the drag over event here
  };

function DCSortableContainer ({ containerId, items }) {
  let groupedItems = items;

  if (containerId === 'bucketContainer') {
    // Group items by position if containerId is 'bucketContainer'
    groupedItems = items.reduce((acc, currentItem) => {
      const position = currentItem.position;
      if (!acc[position]) {
        acc[position] = [];
      }
      acc[position].unshift(currentItem);
      return acc;
    }, {});
  }

  return (
    <SortableContext onDragOver={handleBucketDragOver} id={containerId} items={items} strategy={rectSortingStrategy}>
      {containerId === 'bucketContainer' ?
        Object.keys(groupedItems).map(position => (
          <div className="w-full mb-1" key={position}>
			<div className="w-full bg-white h-fit shadow-md mb-2">
            <h3 className="w-fit text-lg mx-auto font-Russo">{position}</h3> {/* Header for each position */}
			</div>
            <SortableContext onDragOver={handleBucketDragOver} id={containerId} items={groupedItems[position]} strategy={rectSortingStrategy}>
              <div className="flex flex-wrap w-full">
			  {groupedItems[position].map((player, index) => (
                <DCSortableItem
                  key={player?.name}
                  id={player?.name}
                  containerId={containerId}
                  disabled={player?.noDrag}
                  index={index + 1}
                  {...player}
                />
              ))}
			  </div>
            </SortableContext>
          </div>
        ))
      :
        items.map((player, index) => (
          <DCSortableItem
            key={player?.name}
            id={player?.name}
            containerId={containerId}
            disabled={player?.noDrag}
            index={index}
            {...player}
          />
        ))
      }
    </SortableContext>
  );
}

export default DCSortableContainer;

