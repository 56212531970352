import React, { useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { PathContext } from "../context/PathContext";
import { motion } from "framer-motion";
import EvaluxClearLogo from "../img/EvaluxClearLogo.png";
import { Input } from "antd";
import { RPlayerMap } from "../components/RPlayerMap";
import { LogoPageHeader } from "../components/LogoPageHeader";
import { HeaderScrollVisibility } from "../hooks/HeaderScrollVisibility";

export function Recruit() {
  const [showInbox, setShowInbox] = useState(false);
  const [showSendReport, setShowSendReport] = useState(false);
  const [showPlayerMap, setShowPlayerMap] = useState(true);

  const scrollableDivRef = HeaderScrollVisibility();

  return (
    <motion.div
      initial={{
        y: -500,
      }}
      animate={{ y: 8, opacity: 1 }}
      exit={{ y: 1000, opacity: 0 }}
    >
      <div
        ref={scrollableDivRef}
        className="absolute top-0 left-0 h-screen w-screen overflow-y-auto overflow-x-hidden"
      >
        <LogoPageHeader title={"Recruit"} />

        <div className="relative h-fit md:h-[70vh] pt-9 md:pt-2 py-2 w-[98%] mx-auto bg-slate-100 bg-opacity-20 rounded-lg rounded-t-none sm:rounded-t-lg sm:rounded-tr-none">
          <div className="absolute top-[-2rem] left-2 w-[50%] md:w-[35%] flex flex-row">
            {/* <motion.button
                className="text-white hover:font-semibold hover:bg-opacity-30 mx-auto px-5 py-1 bg-slate-100 bg-opacity-20 rounded-t-lg"
                onClick={() => {
                  setShowInbox(!showInbox);
                }}
              >
                Inbox
              </motion.button>
              <motion.button
                className="text-white hover:font-semibold hover:bg-opacity-30 mx-auto px-5 py-1 bg-slate-100 bg-opacity-20 rounded-t-lg"
                onClick={() => {
                  setShowSendReport(!showSendReport);
                }}
              >
                Send Report
              </motion.button>
              <motion.button
                className="text-white hover:font-semibold hover:bg-opacity-30 mx-auto px-5 py-1 bg-slate-100 bg-opacity-20 rounded-t-lg"
                onClick={null}
              >
                Player Search
              </motion.button>
              <motion.button
                className="text-white hover:font-semibold hover:bg-opacity-30 mx-auto px-5 py-1 bg-slate-100 bg-opacity-20 rounded-t-lg"
                onClick={null}
              >
                Player Map
              </motion.button> */}
          </div>

          <div className="flex w-full px-1 md:h-full">
            {showPlayerMap && <RPlayerMap />}
          </div>
        </div>
      </div>
    </motion.div>
  );
}
