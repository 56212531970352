import React, { useEffect, useMemo, useState } from "react";
import { ResponsivePie } from "@nivo/pie";
import { Button } from "antd";

export const RosterPieChart = ({ rosterData, onFilterChange }) => {
  const [filter, setFilter] = useState(0);
  const [positionData, setPositionData] = useState([]);

  const DEFPositionData = useMemo(() => {
    const positionCountDL = rosterData.filter(
      (item) => item.position === "DL"
    ).length;
    const DL = {
      id: "DL",
      label: "DL",
      value: positionCountDL,
    };
    const positionCountLB = rosterData.filter(
      (item) => item.position === "LB"
    ).length;
    const LB = {
      id: "LB",
      label: "LB",
      value: positionCountLB,
    };
    const positionCountSAF = rosterData.filter(
      (item) => item.position === "SAF"
    ).length;
    const SAF = {
      id: "SAF",
      label: "SAF",
      value: positionCountSAF,
    };
    const positionCountCB = rosterData.filter(
      (item) => item.position === "CB"
    ).length;
    const CB = {
      id: "CB",
      label: "CB",
      value: positionCountCB,
    };

    return [DL, LB, SAF, CB];
  }, [rosterData]);

  const OFFPositionData = useMemo(() => {
    const positionCountQB = rosterData.filter(
      (item) => item.position === "QB"
    ).length;
    const QB = {
      id: "QB",
      label: "QB",
      value: positionCountQB,
    };
    const positionCountOL = rosterData.filter(
      (item) => item.position === "OL"
    ).length;
    const OL = {
      id: "OL",
      label: "OL",
      value: positionCountOL,
    };
    const positionCountTE = rosterData.filter(
      (item) => item.position === "TE"
    ).length;
    const TE = {
      id: "TE",
      label: "TE",
      value: positionCountTE,
    };
    const positionCountRB = rosterData.filter(
      (item) => item.position === "RB"
    ).length;
    const RB = {
      id: "RB",
      label: "RB",
      value: positionCountRB,
    };
    const positionCountWR = rosterData.filter(
      (item) => item.position === "WR"
    ).length;
    const WR = {
      id: "WR",
      label: "WR",
      value: positionCountWR,
    };

    return [QB, OL, TE, RB, WR];
  }, [rosterData]);

  const STPositionData = useMemo(() => {
    const positionCountK = rosterData.filter(
      (item) => item.position === "K"
    ).length;
    const K = {
      id: "K",
      label: "K",
      value: positionCountK,
    };
    const positionCountP = rosterData.filter(
      (item) => item.position === "P"
    ).length;
    const P = {
      id: "P",
      label: "P",
      value: positionCountP,
    };

    return [K, P];
  }, [rosterData]);

  const yearData = useMemo(() => {
    const CountFr = rosterData.filter((item) => item.year === "Fr").length;
    const Fr = {
      id: "Fr",
      label: "Fr",
      value: CountFr,
    };
    const CountSo = rosterData.filter((item) => item.year === "So").length;
    const So = {
      id: "So",
      label: "So",
      value: CountSo,
    };
    const CountJr = rosterData.filter((item) => item.year === "Jr").length;
    const Jr = {
      id: "Jr",
      label: "Jr",
      value: CountJr,
    };
    const CountSr = rosterData.filter((item) => item.year === "Sr").length;
    const Sr = {
      id: "Sr",
      label: "Sr",
      value: CountSr,
    };
    return [Fr, So, Jr, Sr];
  }, [rosterData]);

  const scoutTeamData = useMemo(() => {
    const scoutData = rosterData.filter((item) => item.scoutTeam === "Y");

    const positionCountDL = scoutData.filter(
      (item) => item.position === "DL"
    ).length;
    const DL = {
      id: "DL",
      label: "DL",
      value: positionCountDL,
    };
    const positionCountLB = scoutData.filter(
      (item) => item.position === "LB"
    ).length;
    const LB = {
      id: "LB",
      label: "LB",
      value: positionCountLB,
    };
    const positionCountSAF = scoutData.filter(
      (item) => item.position === "SAF"
    ).length;
    const SAF = {
      id: "SAF",
      label: "SAF",
      value: positionCountSAF,
    };
    const positionCountCB = scoutData.filter(
      (item) => item.position === "CB"
    ).length;
    const CB = {
      id: "CB",
      label: "CB",
      value: positionCountCB,
    };

    const positionCountQB = scoutData.filter(
      (item) => item.position === "QB"
    ).length;
    const QB = {
      id: "QB",
      label: "QB",
      value: positionCountQB,
    };
    const positionCountOL = scoutData.filter(
      (item) => item.position === "OL"
    ).length;
    const OL = {
      id: "OL",
      label: "OL",
      value: positionCountOL,
    };
    const positionCountTE = scoutData.filter(
      (item) => item.position === "TE"
    ).length;
    const TE = {
      id: "TE",
      label: "TE",
      value: positionCountTE,
    };
    const positionCountRB = scoutData.filter(
      (item) => item.position === "RB"
    ).length;
    const RB = {
      id: "RB",
      label: "RB",
      value: positionCountRB,
    };
    const positionCountWR = scoutData.filter(
      (item) => item.position === "WR"
    ).length;
    const WR = {
      id: "WR",
      label: "WR",
      value: positionCountWR,
    };

    const positionCountK = scoutData.filter(
      (item) => item.position === "K"
    ).length;
    const K = {
      id: "K",
      label: "K",
      value: positionCountK,
    };
    const positionCountP = scoutData.filter(
      (item) => item.position === "P"
    ).length;
    const P = {
      id: "P",
      label: "P",
      value: positionCountP,
    };

    return [DL, LB, SAF, CB, QB, OL, TE, RB, WR, K, P];
  }, [rosterData]);

  const travelTeamData = useMemo(() => {
    const travelData = rosterData.filter((item) => item.scoutTeam !== "Y");

    const positionCountDL = travelData.filter(
      (item) => item.position === "DL"
    ).length;
    const DL = {
      id: "DL",
      label: "DL",
      value: positionCountDL,
    };
    const positionCountLB = travelData.filter(
      (item) => item.position === "LB"
    ).length;
    const LB = {
      id: "LB",
      label: "LB",
      value: positionCountLB,
    };
    const positionCountSAF = travelData.filter(
      (item) => item.position === "SAF"
    ).length;
    const SAF = {
      id: "SAF",
      label: "SAF",
      value: positionCountSAF,
    };
    const positionCountCB = travelData.filter(
      (item) => item.position === "CB"
    ).length;
    const CB = {
      id: "CB",
      label: "CB",
      value: positionCountCB,
    };

    const positionCountQB = travelData.filter(
      (item) => item.position === "QB"
    ).length;
    const QB = {
      id: "QB",
      label: "QB",
      value: positionCountQB,
    };
    const positionCountOL = travelData.filter(
      (item) => item.position === "OL"
    ).length;
    const OL = {
      id: "OL",
      label: "OL",
      value: positionCountOL,
    };
    const positionCountTE = travelData.filter(
      (item) => item.position === "TE"
    ).length;
    const TE = {
      id: "TE",
      label: "TE",
      value: positionCountTE,
    };
    const positionCountRB = travelData.filter(
      (item) => item.position === "RB"
    ).length;
    const RB = {
      id: "RB",
      label: "RB",
      value: positionCountRB,
    };
    const positionCountWR = travelData.filter(
      (item) => item.position === "WR"
    ).length;
    const WR = {
      id: "WR",
      label: "WR",
      value: positionCountWR,
    };

    const positionCountK = travelData.filter(
      (item) => item.position === "K"
    ).length;
    const K = {
      id: "K",
      label: "K",
      value: positionCountK,
    };
    const positionCountP = travelData.filter(
      (item) => item.position === "P"
    ).length;
    const P = {
      id: "P",
      label: "P",
      value: positionCountP,
    };

    return [DL, LB, SAF, CB, QB, OL, TE, RB, WR, K, P];
  }, [rosterData]);

  const fullTeamData = [
    ...OFFPositionData,
    ...DEFPositionData,
    ...STPositionData,
  ];

  useEffect(() => {
    switch (filter) {
      case 0:
        setPositionData(fullTeamData);
        break;
      case 1:
        setPositionData(OFFPositionData);
        break;
      case 2:
        setPositionData(DEFPositionData);
        break;
      case 3:
        setPositionData(STPositionData);
        break;
      case 4:
        setPositionData(yearData);
        break;
      case 5:
        setPositionData(scoutTeamData);
        break;
      case 6:
        setPositionData(travelTeamData);
        break;
      default:
        setPositionData([
          ...OFFPositionData,
          ...DEFPositionData,
          ...STPositionData,
        ]);
        onFilterChange();
    }
  }, [
    filter,
    OFFPositionData,
    DEFPositionData,
    STPositionData,
    yearData,
    scoutTeamData,
    travelTeamData,
  ]);

  const data = [...positionData];

  const CenteredMetric = ({ dataWithArc, centerX, centerY }) => {
    let total = 0;
    dataWithArc.forEach((datum) => {
      total += datum.value;
    });
    return (
      <text
        x={centerX}
        y={centerY}
        textAnchor="middle"
        // dominantBaseline="central"
        style={{
          fontSize: "20px",
          fontWeight: 600,
        }}
      >
        {total}
      </text>
    );
  };

  const handlePositionFilter = (pos) => {
    switch (pos) {
      case "QB":
      case "OL":
      case "TE":
      case "RB":
      case "WR":
        setFilter(1);
        break;
      case "DL":
      case "LB":
      case "CB":
      case "SAF":
        setFilter(2);
        break;
      case "P":
      case "K":
      case "LS":
        setFilter(3);
        break;
      default:
        setFilter(5);
    }
  };

  return (
    <>
      <ResponsivePie
        data={data}
        onClick={(node) => {
          const years = ["Sr", "Jr", "So", "Fr"];
          if (years.includes(node.id)) {
            return;
          }
          onFilterChange(node.id);
          handlePositionFilter(node.id);
          console.log(node);
        }}
        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
        innerRadius={0.5}
        padAngle={0.7}
        theme={{ fontSize: 13 }}
        cornerRadius={3}
        layers={[
          "arcs",
          "arcLabels",
          "arcLinkLabels",
          "legends",
          CenteredMetric,
        ]}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{
          from: "color",
          modifiers: [["darker", 0.2]],
        }}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#000"
        arcLinkLabelsThickness={4}
        arcLinkLabelsColor={{ from: "color" }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
          from: "color",
          modifiers: [["darker", 2]],
        }}
        defs={[
          {
            id: "dots",
            type: "patternDots",
            background: "inherit",
            color: "rgba(255, 255, 255, 0.3)",
            size: 6,
            padding: 1,
            stagger: true,
          },
          {
            id: "lines",
            type: "patternLines",
            background: "inherit",
            color: "rgba(255, 255, 255, 0.3)",
            rotation: -45,
            lineWidth: 6,
            spacing: 10,
          },
        ]}
        fill={[
          {
            match: {
              id: "ruby",
            },
            id: "dots",
          },
          {
            match: {
              id: "c",
            },
            id: "dots",
          },
          {
            match: {
              id: "go",
            },
            id: "dots",
          },
          {
            match: {
              id: "python",
            },
            id: "dots",
          },
          {
            match: {
              id: "OL",
            },
            id: "lines",
          },
        ]}
        // legends={[
        //   {
        //     anchor: "bottom",
        //     direction: "row",
        //     justify: false,
        //     translateX: 0,
        //     translateY: 56,
        //     itemsSpacing: 0,
        //     itemWidth: 50,
        //     itemHeight: 18,
        //     itemTextColor: "#999",
        //     itemDirection: "left-to-right",
        //     itemOpacity: 1,
        //     symbolSize: 12,
        //     symbolShape: "circle",
        //     effects: [
        //       {
        //         on: "hover",
        //         style: {
        //           itemTextColor: "#000",
        //         },
        //       },
        //     ],
        //     textWrap: true,
        //   },
        // ]}
      />
      <div className="relative shadow-md w-fit h-fit mx-auto flex flex-col justify-center align-middle py-1 bg-slate-200 top-[-40px] rounded-lg sm:px-5">
        <div className="flex flex-wrap justify-center align-middle mx-auto">
          <div className="flex justify-center">
            <Button
			className="bg-slate-50 shadow-sm shadow-slate-400"
			type="text"
              onClick={() => {
                setFilter(0);
                onFilterChange("TEAM");
              }}
            >
              TEAM
            </Button>
            <Button
			className="bg-slate-50 shadow-sm shadow-slate-400"
			type="text"
              onClick={() => {
                setFilter(1);
                onFilterChange("OFF");
              }}
            >
              OFFENSE
            </Button>
            <Button
			className="bg-slate-50 shadow-sm shadow-slate-400"
			type="text"
              onClick={() => {
                setFilter(2);
                onFilterChange("DEF");
              }}
            >
              DEFENSE
            </Button>
            <Button
			className="bg-slate-50 shadow-sm shadow-slate-400"
			type="text"
              onClick={() => {
                setFilter(3);
                onFilterChange("ST");
              }}
            >
              SPECIAL TEAMS
            </Button>
          </div>
        </div>
        <div className="flex mx-auto justify-center h-7">
          <Button
		  className="bg-slate-50 shadow-sm shadow-slate-400 scale-75"
		  type="text"
            onClick={() => {
              setFilter(4);
              onFilterChange("Year");
            }}
          >
            GRAD YEAR
          </Button>
          <Button
		  className="bg-slate-50 shadow-sm shadow-slate-400 scale-75"
		  type="text"
            onClick={() => {
              setFilter(6);
              onFilterChange("Travel");
            }}
          >
            TRAVEL TEAM
          </Button>
          <Button
		  className="bg-slate-50 shadow-sm shadow-slate-400 scale-75"
		  type="text"
            onClick={() => {
              setFilter(5);
              onFilterChange("Scout");
            }}
          >
            SCOUT TEAM
          </Button>
        </div>
      </div>
    </>
  );
};
