import React, { useRef } from "react";
import { motion } from "framer-motion";
import { Card, Modal } from "antd";
import EvaluxClearLogo from "../img/EvaluxClearLogo.png";
import { useReactToPrint } from "react-to-print";
import DCSortableContainer from "./DCSortableContainer";

export function DCPrint({ tabs, setShowPrint, showPrint }) {
	console.log('DCP-TABS', tabs )
  const printInfo = tabs;

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const DraggableContainer = ({ container, tabIndex, scale }) => {
	console.log(container)

    const containerDepth = tabs[tabIndex].data.filter(
      (player) => player?.odp === container.id
    );

	const containerItems = containerDepth.length > 0
  ? containerDepth
      .filter((player) => player.odp === container.id)
      .sort((a, b) => a.odn - b.odn) // Sort in descending order by odn
  : [
      {
        key: "placeholder",
        id: container.id,
        noDrag: true,
        name: "",
        number: "",
        position: "placeholder",
        odp: container.id,
        odn: 0,
      },
    ];

    return (
      <div
        className={`absolute flex flex-col transform transition-all touch-none p-1 w-40 h-fit`}
        style={{
			left: container.position.x * 100 + '%',
			top: container.position.y * 100 + '%',
			transform: `scale(${scale})`,
        }}
      >
        <div className="flex">
          <div className="h-full w-full flex p-3 bg-green-200 rounded-lg">
            <div
              className="h-full text-xl mx-auto w-fit min-w-1 font-bold bg-green-200 text-center border-white"
            >{container.id}</div>
          </div>
        </div>
        <DCSortableContainer
          className="w-16 h-fit text-center touch-none"
          containerId={container.id}
          items={containerItems}
        ></DCSortableContainer>
      </div>
    );
  };

  return (
    <Modal
      className="h-fit pt-[10%] sm:pt-[5%] md:pt-[3%]"
      centered
      width={1300}
      open={showPrint}
      onOk={() => setShowPrint()}
      onCancel={() => setShowPrint()}
      destroyOnClose={true}
      footer={[
        <motion.button
          key="printButton"
          className="p-2 m-1 mb-2 h-fit hover:bg-teal-500 border-teal-500 border-2 text-white bg-slate-600 rounded-md font-bold shadow-gray-700 shadow-sm disabled:opacity-50"
          whileHover={{ scale: 0.9 }}
          whileTap={{ scale: 1.1 }}
          onClick={handlePrint}
        >
          Print
        </motion.button>,
      ]}
	  title={[
        <motion.button
          key="printButton"
          className="p-2 m-1 mb-2 h-fit hover:bg-teal-500 border-teal-500 border-2 text-white bg-slate-600 rounded-md font-bold shadow-gray-700 shadow-sm disabled:opacity-50"
          whileHover={{ scale: 0.9 }}
          whileTap={{ scale: 1.1 }}
          onClick={handlePrint}
        >
          Print
        </motion.button>,
      ]}
	  
    >
      <div
        ref={componentRef}
        className="relative mt-5 flex flex-col h-fit min-h-fit w-full mx-auto bg-white rounded-md"
      >
		{new Date().toLocaleString() + ""}
        <div className="w-full bg-slate-800 text-white text-xl font-bold text-center rounded-t-md">
          {printInfo?.label}
        </div>
        <div className="flex h-fit w-full">
          <div className="flex px-1 flex-col w-full h-fit">
            {printInfo?.map((tab, index) => 
			(
              <div key={index} className="mb-4 w-full min-h-[27rem] break-inside-avoid" >
                <div className="w-full bg-slate-800 text-white text-2xl p-1 font-bold text-center rounded-t-md">
                  {tab.label}
                </div>
				<div className={`relative w-full h-full min-h-[30rem] flex -mt-9 scale-[.85]`}> 
                <div className="mx-auto flex px-1 flex-col w-full h-full overflow-visible bg-white rounded-b-md">

				  {tab.containers?.map((container) => (
                  <DraggableContainer
                    key={container.id}
					scale={tab.scale}
					tabIndex={index}
                    id={container.id}
                    type={container.type}
                    container={container}
                    containerId={container.id}
                  />
                ))}
                </div>
				</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
}
