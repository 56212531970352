import React, { useState, useEffect, useRef, useContext } from "react";
import { db } from "../Firebase-config";
import {
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  setDoc,
  writeBatch,
  query,
  serverTimestamp,
  getDoc,
  where,
  updateDoc,
} from "firebase/firestore";
import { AuthContext } from "../context/AuthContext";
import { Table, Button, Space, Input, Popconfirm, Spin, Form } from "antd";
import { motion } from "framer-motion";
import { SearchOutlined, DeleteOutlined } from "@ant-design/icons";
import { BiArchiveOut } from "react-icons/bi";
import Highlighter from "react-highlight-words";
import { PathContext } from "../context/PathContext";
import { TbReportMedical, TbShirtSport } from "react-icons/tb";

export function RosterInActive({ onChartData, rosterFilter }) {
  const [Roster, setRoster] = useState([]);
  const [OGRoster, setOGRoster] = useState([]);
  const [editedPlayerProfile, setEditedPlayerProfile] = useState(null);
  const [loading, setLoading] = useState(true);

  const { currentUser } = useContext(AuthContext);
  const { selectedPath } = useContext(PathContext);

  const deleteUser = async (key) => {
    const profileDoc = doc(db, "Teams", selectedPath, "PlayerProfiles", key);
    const docSnapshot = await getDoc(profileDoc);
    const profileData = docSnapshot.data();
    console.log(profileData);

    await deleteDoc(profileDoc);

    // Remove email from access control list if the player is switched to inactive
    const accessControlListRef = doc(db, "Teams", selectedPath);
    const acldocSnapshot = await getDoc(accessControlListRef);
    const accessControlList = acldocSnapshot.data().Players || []; // Retrieve the current access control list array
    console.log(accessControlList);

    const index = accessControlList.findIndex(
      (email) => email === profileData.email
    );
    if (index > -1) {
      accessControlList.splice(index, 1); // Remove the email from the access control list
      console.log(
        `Email removed from access control list: ${profileData.email}`
      );
    }

    const batch = writeBatch(db); // Create a batch write operation
    batch.update(accessControlListRef, { Players: accessControlList }); // Add update operation to batch

    // Commit the batch write operation
    try {
      await batch.commit();
      console.log("Batch update completed successfully");
    } catch (error) {
      console.error("Error updating Firestore in batch: ", error);
    }
  };

  const setActivePlayer = async (key) => {
    const batch = writeBatch(db); // Create a batch write operation

    const profileDoc = doc(db, "Teams", selectedPath, "PlayerProfiles", key);
    const docSnapshot = await getDoc(profileDoc);
    const profileData = docSnapshot.data();
    console.log(profileData);

    batch.update(profileDoc, { activePlayer: "Active" });

    // Add email to access control list if the player is switched to active
    if (profileData.email) {
      const accessControlListRef = doc(db, "Teams", selectedPath);
      const acldocSnapshot = await getDoc(accessControlListRef);
      const accessControlList = acldocSnapshot.data().Players || []; // Retrieve the current access control list array
      console.log(accessControlList);

      const index = accessControlList.findIndex(
        (email) => email === profileData.email
      );
      if (index === -1) {
        accessControlList.push(profileData.email); // Add the email to the access control list
        console.log(`Email added to access control list: ${profileData.email}`);
      }

    batch.update(accessControlListRef, { Players: accessControlList }); // Add update operation to batch
	}

    // Commit the batch write operation
    try {
      await batch.commit();
      console.log("Batch update completed successfully");
    } catch (error) {
      console.error("Error updating Firestore in batch: ", error);
    }
  };

  useEffect(() => {
    const rosterCollectionRef = query(
      collection(db, "Teams", selectedPath, "PlayerProfiles"),
      where("activePlayer", "==", "Inactive")
    );
    const unsubscribe = onSnapshot(
      rosterCollectionRef,
      (snapShot) => {
        let list = [];
        snapShot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data(), isEditing: false });
        });
        setRoster(list);
        setOGRoster(list);
        onChartData(list);
        setLoading(false); // set loading to false after data has been retrieved
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [selectedPath]);

  useEffect(() => {
    const Roster = [...OGRoster];
    const rosterDocRef = doc(
      db,
      "Teams",
      selectedPath,
      "Roster",
      "teamRosterInactive"
    );
    setDoc(rosterDocRef, { Roster, dateUpdated: serverTimestamp() });
  }, [OGRoster, selectedPath]);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
            className="text-black"
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const EditableContext = React.createContext(null);

  const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
  };

  const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    useEffect(() => {
      if (editing) {
        inputRef.current.focus();
      }
    }, [editing]);

    const toggleEdit = () => {
      setEditing(!editing);
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    };

    const save = async () => {
      try {
        const values = await form.validateFields();
        toggleEdit();
        if (values[dataIndex] !== undefined) {
          handleSave(record, {
            ...record,
            ...values,
          });
        } else {
          handleSave(record, {
            ...record,
            [dataIndex]: dataIndex === "numericValue" ? 0 : null,
          });
        }
      } catch (errInfo) {
        console.log("Save failed:", errInfo);
      }
    };

    let childNode = children;
    if (editable) {
      childNode = editing ? (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          rules={[
            {
              required: false,
              message: `${title} is required.`,
            },
          ]}
        >
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
      ) : (
        <div
          className="editable-cell-value-wrap"
          style={{
            paddingRight: 24,
          }}
          onClick={toggleEdit}
        >
          {children}
        </div>
      );
    }

    return <td {...restProps}>{childNode}</td>;
  };

  const handleSave = async (record, row) => {
    // Handle saving logic here
    console.log("Record:", record);
    console.log("Row:", row);

    const batch = writeBatch(db); // Create a batch write operation

    const updatedData = Roster.map((item) => {
      if (item["full-name"] === row["full-name"]) {
        return { ...item, ...row };
      }
      return item;
    });

    setEditedPlayerProfile(record);
    console.log(record);

    setRoster(updatedData);
    console.log(updatedData);

    // Update the player profile in Firestore
    const docId = `${row["jersey-number"]} ${row["full-name"]}`;
    const docRef = doc(
      collection(db, "Teams", selectedPath, "PlayerProfiles"),
      docId
    );
    batch.update(docRef, row); // Add update operation to batch
    console.log(`PlayerProfile updated for player with id: ${docId}`);

    // Update access control list in Firestore
    const accessControlListRef = doc(db, "Teams", selectedPath);
    const docSnapshot = await getDoc(accessControlListRef);
    const accessControlList = docSnapshot.data().Players || []; // Retrieve the current access control list array

    const index = accessControlList.findIndex(
      (player) => player["full-name"] === row["full-name"]
    );
    if (index > -1) {
      accessControlList[index]["email"] = row["email"]; // Update the email field of the object
    }
    batch.update(accessControlListRef, { Players: accessControlList }); // Add update operation to batch
    console.log(
      `Access control list updated for player with email: ${row["email"]}`
    );

    // Commit the batch write operation
    try {
      await batch.commit();
      console.log("Batch update completed successfully");
    } catch (error) {
      console.error("Error updating Firestore in batch: ", error);
    }
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const mapColumns = (col) => {
    if (!col.editable) {
      return col;
    }
    const newCol = {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
    if (col.children) {
      newCol.children = col.children.map(mapColumns);
    }
    return newCol;
  };

  useEffect(() => {
    const originalRoster = OGRoster;

    switch (rosterFilter) {
      case "OFF":
        const OFFFilteredRoster = OGRoster.filter((item) => {
          // Check if the 'position' property of the object includes any of the specified values
          return ["QB", "OL", "WR", "RB", "TE"].includes(item.position);
        });
        setRoster(OFFFilteredRoster);
        break;
      case "DEF":
        const DEFFilteredRoster = OGRoster.filter((item) => {
          // Check if the 'position' property of the object includes any of the specified values
          return ["DL", "LB", "CB", "SAF"].includes(item.position);
        });
        setRoster(DEFFilteredRoster);
        break;
      case "ST":
        const STFilteredRoster = OGRoster.filter((item) => {
          // Check if the 'position' property of the object includes any of the specified values
          return ["P", "K", "LS"].includes(item.position);
        });
        setRoster(STFilteredRoster);
        break;
      case "QB":
      case "OL":
      case "WR":
      case "RB":
      case "TE":
      case "DL":
      case "LB":
      case "CB":
      case "SAF":
      case "P":
      case "K":
      case "LS":
        const POSFilteredRoster = OGRoster.filter((item) => {
          // Check if the 'position' property of the object includes any of the specified values
          return rosterFilter.includes(item.position);
        });
        setRoster(POSFilteredRoster);
        break;
      case "Year":
        const sortedRoster = [...OGRoster].sort((a, b) => {
          // Map year values to a numerical order
          const yearOrder = { Gr: 0, Sr: 1, Jr: 2, So: 3, Fr: 4 };
          const yearA = yearOrder[a.year] || 5; // Default to 5 if not found in the map
          const yearB = yearOrder[b.year] || 5; // Default to 5 if not found in the map

          return yearA - yearB;
        });

        setRoster(sortedRoster);
        break;

      default:
        setRoster(originalRoster);
        break;
    }
  }, [rosterFilter, OGRoster]);

  const columns = [
    {
      key: "jersey-number",
      dataIndex: "jersey-number",
      title: "#",
      width: 35,
      editable: true,
      sorter: (a, b) => a["jersey-number"] - b["jersey-number"],
    },
    {
      key: "position",
      dataIndex: "position",
      title: "Pos",
      width: 40,
      editable: true,
      filters: [
        {
          text: "Offense",
          value: "Offense",
          children: [
            {
              text: "QB",
              value: "QB",
            },
            {
              text: "OL",
              value: "OL",
            },
            {
              text: "TE",
              value: "TE",
            },
            {
              text: "RB",
              value: "RB",
            },
            {
              text: "WR",
              value: "WR",
            },
          ],
        },
        {
          text: "Defense",
          value: "Defense",
          children: [
            {
              text: "DL",
              value: "DL",
            },
            {
              text: "LB",
              value: "LB",
            },
            {
              text: "SAF",
              value: "SAF",
            },
            {
              text: "CB",
              value: "CB",
            },
          ],
        },
      ],
      filterMode: "tree",
      onFilter: (value, record) => record.position.includes(value),
    },
    {
      key: "full-name",
      dataIndex: "full-name",
      title: "Name",
      width: 150,
      editable: true,
      ...getColumnSearchProps("full-name"),
    },
    {
      key: "year",
      dataIndex: "year",
      title: "Year",
      width: 40,
      editable: true,
      sorter: (a, b) => a.year.localeCompare(b.year),
    },
    {
      key: "redshirt",
      dataIndex: "redshirt",
      title: "Redshirt",
      render: (_, record) => {
        const Yes = "opacity-70 hover:opacity-100 text-red-500 scale-125";
        const No = "opacity-50 hover:opacity-100 text-black scale-125";
        return (
          <div className="flex ml-9">
            {/* <motion.button onClick={() => setRedShirtPlayer(record)}>
              <TbShirtSport className={record.redshirt === "Y" ? Yes : No} />
            </motion.button> */}
            <motion.button onClick={null}>
              <TbShirtSport className={record.redshirt === "Y" ? Yes : No} />
            </motion.button>
          </div>
        );
      },
    },
    {
      key: "medicalRedshirt",
      dataIndex: "medicalRedshirt",
      title: "Medical",
      render: (_, record) => {
        const Yes = "opacity-80 hover:opacity-100 text-blue-500 scale-125";
        const No = "opacity-50 hover:opacity-100 text-black scale-125";
        return (
          <div className="flex ml-0">
            {/* <motion.button onClick={() => setMedicalRedShirtPlayer(record)}>
              <TbReportMedical
                className={record.medicalRedshirt === "Y" ? Yes : No}
              />
            </motion.button> */}
            <motion.button onClick={null}>
              <TbReportMedical
                className={record.medicalRedshirt === "Y" ? Yes : No}
              />
            </motion.button>
          </div>
        );
      },
    },
    {
      key: "height",
      dataIndex: "height",
      title: "Ht",
      width: 25,
      editable: true,
      sorter: (a, b) => a.year.localeCompare(b.year),
    },
    {
      key: "weight",
      dataIndex: "weight",
      title: "Wt",
      width: 25,
      editable: true,
      sorter: (a, b) => a.year.localeCompare(b.year),
    },
    // {
    //   key: "recruitingProfile",
    //   dataIndex: "redshirtrecrutingProfile",
    //   title: "Recruiting",
    //   render: (_, record) => {
    //     const id = record.id;
    //     return (
    //       <div className="flex ml-5">
    //         <motion.button
    //           onClick={() => {
    //             {
    //               recruitingProfileActive ? addClose() : addOpen();
    //             }
    //             // setRecruitingProfileActive(true);
    //             setRecruitingProfileID(id);
    //           }}
    //         >
    //           <LuGraduationCap className="opacity-70 hover:opacity-100 text-black scale-125" />
    //         </motion.button>
    //       </div>
    //     );
    //   },
    // },
    {
      key: "Xtwitter",
      dataIndex: "Xtwitter",
      title: "Twitter",
      width: 150,
      editable: true,
      sortable: false,
    },
    {
      key: "email",
      dataIndex: "email",
      title: "Email",
      width: 200,
      editable: true,
      sortable: false,
    },
    {
      key: "phone",
      dataIndex: "phone",
      title: "Phone",
      width: 150,
      editable: true,
      sortable: false,
    },
    {
      key: "action",
      dataIndex: "action",
      title: "Actions",
      width: 150,
      render: (_, record) => {
        const id = record.id;
        return (
          <div className="flex">
            <motion.button onClick={() => setActivePlayer(id)}>
              <BiArchiveOut className="opacity-70 hover:opacity-100 scale-125" />
            </motion.button>
            <Popconfirm
              title="Sure to delete?"
              okType="danger"
              onConfirm={() => deleteUser(id)}
            >
              <DeleteOutlined className="opacity-70 hover:opacity-100 scale-125 ml-4" />
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const rosterColumns = columns.map(mapColumns);

  return (
    <div className="min-h-[30rem] w-full">
      {loading ? ( // render the loading animation while loading is true
        <div className="flex justify-center align-middle items-center h-[300px] w-full">
          <Spin size="large" />
        </div>
      ) : (
        // render the table when loading is false
        <Table
          className="w-[97%] h-full mx-2 py-2"
          size="small"
          components={components}
          columns={rosterColumns}
          dataSource={Roster}
          shouldCellUpdate={(record, prevRecord) => record !== prevRecord}
          rowKey={(record) => record.id}
          scroll={{ x: "calc(30px + 90%)" }}
          pagination={{ showSizeChanger: true, position: "bottom" }}
        />
      )}
    </div>
  );
}
