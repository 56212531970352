import { Card, Table } from "antd";
import React, { useState } from "react";
import { motion } from "framer-motion";
import { RPMRecruitingProfile } from "./RPMRecruitingProfile";
import { LuGraduationCap } from "react-icons/lu";
import {
  AiFillHeart,
  AiFillVideoCamera,
  AiOutlineHeart,
  AiOutlineVideoCamera,
} from "react-icons/ai";
import { AuthContext } from "../context/AuthContext";
import { TbCards, TbTable } from "react-icons/tb";
import { FaPhone, FaTable, FaXTwitter } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";

export function RPMRecruitCards({
  location,
  removeLocation,
  favoritePlayer,
  usersData,
}) {
  const cardData = location ? location : [];
  const { Meta } = Card;

  const [recruitingProfileActive, setRecruitingProfileActive] = useState(false);
  const [recruitingProfileID, setRecruitingProfileID] = useState();
  const [expandedRow, setExpandedRow] = useState(null);
  const [tableCardsToggle, setTableCardsToggle] = useState(false);

  const addClose = () => setRecruitingProfileActive(false);
  const addOpen = () => setRecruitingProfileActive(true);

  console.log("Recruit cards:", cardData);

  const selectedPlayer = cardData?.data.find(
    (player) => player.id === recruitingProfileID
  );

  const handleRemoveClick = () => {
    if (cardData) {
      // Call the removeLocation function with the location's id
      removeLocation(cardData.Id);
    }
  };

  const handleFavoritePlayer = (id) => {
    favoritePlayer(id);
  };

  const levelAbbreviations = {
    "Division I FBS": "FBS",
    "Division I FCS": "FCS",
    "Division II": "D2",
    "Division III": "D3",
    NAIA: "NAIA",
    NJCAA: "JUCO",
    "Sprint Football": "Sprint",
  };

  const levelOrder = [
    "Division I FBS",
    "Division I FCS",
    "Division II",
    "Division III",
    "NAIA",
    "NJCAA",
    "Sprint Football",
  ];

  const recruitColumns = [
    {
      key: "favoriteRecruit",
      dataIndex: "favoriteRecruit",
      title: "",
      width: 35,
      fixed: "left",
      render: (_, record) => {
        const id = record.id;
        const isFavorite = usersData?.favoritePlayers.includes(id)
          ? true
          : false;
        return (
          <div className="flex mx-auto">
            <motion.button onClick={() => handleFavoritePlayer(id)}>
              <motion.div
                initial={isFavorite ? "filled" : "outlined"}
                animate={isFavorite ? "filled" : "outlined"}
                variants={{
                  outlined: { scale: 1, opacity: 1 },
                  filled: { scale: 1.2, opacity: 1, color: "#E0245E" },
                }}
                transition={{
                  type: "spring",
                  stiffness: 260,
                  damping: 20,
                }}
              >
                {isFavorite ? (
                  <AiFillHeart className="text-red-500 hover:text-black" />
                ) : (
                  <AiOutlineHeart className="text-black hover:text-red-300" />
                )}
              </motion.div>
            </motion.button>
          </div>
        );
      },
    },
    {
      key: "expectedLOC",
      dataIndex: "expectedLOC",
      title: "",
      width: 55,
      render: (text, record) => {
        const abbreviations = levelOrder
          .filter((value) => record.expectedLOC?.includes(value))
          .map((value) => levelAbbreviations[value]);

        return abbreviations.join("/");
      },
    },
    {
      key: "year",
      dataIndex: "year",
      title: "Year",
      width: 40,
      sorter: (a, b) => a.year.localeCompare(b.year),
    },
    // {
    //   key: "score",
    //   dataIndex: "score",
    //   title: "Grade",
    //   render: (_, record) => {
    //     const id = record.id;
    //     return (
    //       <div className="flex ml-1">
    //         <motion.button onClick={null}>
    //           <GiRank3 className="font-bold text-green-600 scale-150" />
    //         </motion.button>
    //       </div>
    //     );
    //   },
    // },
    {
      key: "position",
      dataIndex: "position",
      title: "Pos",
      width: 40,
      filters: [
        {
          text: "Offense",
          value: "Offense",
          children: [
            {
              text: "QB",
              value: "QB",
            },
            {
              text: "OL",
              value: "OL",
            },
            {
              text: "TE",
              value: "TE",
            },
            {
              text: "RB",
              value: "RB",
            },
            {
              text: "WR",
              value: "WR",
            },
          ],
        },
        {
          text: "Defense",
          value: "Defense",
          children: [
            {
              text: "DL",
              value: "DL",
            },
            {
              text: "LB",
              value: "LB",
            },
            {
              text: "SAF",
              value: "SAF",
            },
            {
              text: "CB",
              value: "CB",
            },
          ],
        },
      ],
      filterMode: "tree",
      onFilter: (value, record) => record.position.includes(value),
    },
    {
      key: "jersey-number",
      dataIndex: "jersey-number",
      title: "#",
      width: 35,
      sorter: (a, b) => a["jersey-number"] - b["jersey-number"],
    },
    {
      key: "full-name",
      dataIndex: "full-name",
      title: "Name",
      width: 150,
      //   ...getColumnSearchProps("full-name"),
    },
    {
      key: "height",
      dataIndex: "height",
      title: "Ht",
      width: 25,
      sorter: (a, b) => a.year.localeCompare(b.year),
    },
    {
      key: "weight",
      dataIndex: "weight",
      title: "Wt",
      width: 25,
      sorter: (a, b) => a.year.localeCompare(b.year),
    },
    {
      key: "filmLink",
      dataIndex: "filmLink",
      title: "Film",
      render: (_, record) => {
        const id = record.id;

        const icon = _ ? (
          <AiOutlineVideoCamera className="opacity-70 text-black hover:opacity-100 scale-125" />
        ) : (
          <AiOutlineVideoCamera className="opacity-70 text-slate-400 hover:opacity-100 scale-125" />
        );
        return (
          <div className="flex ml-1">
            <motion.button
              onClick={() => {
                if (expandedRow !== record.id) {
                  setExpandedRow(record.id);
                } else {
                  setExpandedRow(null);
                }
              }}
            >
              {icon}
            </motion.button>
          </div>
        );
      },
    },
    {
      key: "recruitingProfile",
      dataIndex: "redshirtrecrutingProfile",
      title: "Recruiting",
      render: (_, record) => {
        const id = record.id;
        return (
          <div className="flex ml-5">
            <motion.button
              onClick={() => {
                {
                  recruitingProfileActive ? addClose() : addOpen();
                }
                setRecruitingProfileID(id);
              }}
            >
              <LuGraduationCap className="opacity-70 hover:opacity-100 text-black scale-125" />
            </motion.button>
          </div>
        );
      },
    },
    {
      key: "Xtwitter",
      dataIndex: "Xtwitter",
      title: "Twitter",
      width: 150,
      editable: true,
      sortable: false,
    },
    {
      key: "email",
      dataIndex: "email",
      title: "Email",
      width: 200,
      sortable: false,
    },
    {
      key: "phone",
      dataIndex: "phone",
      title: "Phone",
      width: 150,
      sortable: false,
    },
  ];

  return (
    <>
      <RPMRecruitingProfile
        recruitingProfileActive={recruitingProfileActive}
        handleClose={() => addClose()}
        profileInfo={selectedPlayer ? selectedPlayer : ""}
      />
      <Card
        title={
          <div className="flex">
            <div className="m-1 mr-2">
              <img className="h-12 rounded-sm" src={cardData?.img} />
            </div>
            <span className="my-auto text-lg">{cardData?.name}</span>
            <button
              className="my-auto ml-auto mr-10 flex gap-1 hover:text-blue-400"
              onClick={() => setTableCardsToggle(!tableCardsToggle)}
            >
              {tableCardsToggle ? (
                <>
                  <TbCards className="mt-1 scale-125 hover:scale-150" />
                  Cards
                </>
              ) : (
                <>
                  <TbTable className="mt-1 font-thin scale-125 hover:scale-150" />
                  Table
                </>
              )}
              {/* <TbCards className="mt-1 scale-125 hover:scale-150" />
              <div className="">{tableCardsToggle ? "Cards" : "Table"}</div> */}
            </button>
          </div>
        }
        size="small"
        extra={
          <button onClick={handleRemoveClick} className="hover:text-red-500">
            X
          </button>
        }
        actions={[
          <div className="h-10 w-full ml-auto mr-4">
            <div className="h-fit w-full flex overflow-x-auto scrollbar-none px-2">
              <div className="h-full whitespace-nowrap font-semibold mr-2">
                Recruiting Contact:
              </div>
              <div className="flex gap-1">
                <div className="mx-auto whitespace-nowrap">
                  {cardData.recruitingCoach} /
                </div>
                <div className="whitespace-nowrap">
                  {cardData.recruitingEmail} /
                </div>
                <div className="whitespace-nowrap">
                  {cardData.recruitingPhone}
                </div>
              </div>
            </div>
            <div className="h-fit w-full flex overflow-x-auto scrollbar-none px-2">
              <div className="h-full whitespace-nowrap font-semibold mr-2">
                Address:{" "}
              </div>{" "}
              {cardData.address}
            </div>
          </div>,
        ]}
      >
        {tableCardsToggle ? (
          <Table
            className="w-full"
            pagination={{ position: ["none"] }}
            virtualized
            size="small"
            columns={recruitColumns}
            dataSource={cardData?.data}
            rowKey={(record) => record.id}
            scroll={{ x: "max-content" }}
            expandable={{
              expandedRowKeys: expandedRow ? [expandedRow] : [],
              onExpand: (expanded, record) => {
                if (expanded) {
                  setExpandedRow(record.id);
                } else {
                  setExpandedRow(null);
                }
              },
              rowExpandable: (record) => {
                if (record.filmLink) {
                  return true;
                } else {
                  return false;
                }
              },
              expandedRowRender: (record) => {
                if (record.filmLink) {
                  return (
                    <div className="w-[95%] h-fit max-w-[36rem] mt-1 border aspect-video relative">
                      <div
                        className="absolute top-0 left-0 w-full h-full"
                        dangerouslySetInnerHTML={{ __html: record.filmLink }}
                      />
                    </div>
                  );
                }
              },
            }}
          ></Table>
        ) : (
          <div className="flex flex-wrap h-[22rem] overflow-y-auto text-center scrollbar-thin">
            {cardData?.data.map((player, index) => {
              const full_name = player["full-name"]; // Replace with your actual full name
              const nameParts = full_name.split(" "); // Split the full name into parts by space
              const firstName = nameParts[0]; // Get the first name
              const lastName = nameParts.slice(1).join(" "); // Get the last name by joining the remaining parts
              const isFavorite = usersData?.favoritePlayers.includes(player.id)
                ? true
                : false;

              const abbreviations = levelOrder
                .filter((value) => player.expectedLOC?.includes(value))
                .map((value) => levelAbbreviations[value])
                .join(" / ");

              return (
                <div key={index} className="group group/item scale-90">
                  <Card
                    key={player.id}
                    id={player.id}
                    className="w-52 -my-2 -mx-1 h-[22rem] [perspective:1000px] [transform-style:preserve-3d] group-hover/item:[transform:rotateY(180deg)] transition-all duration-300 mb-2 p-1 shadow-sm shadow-slate-600 m-1"
                    cover={
                      <div className="-mb-6 group-hover/item:opacity-0 transition-all">
                        <img
                          style={{
                            WebkitMaskImage:
                              "linear-gradient(to top, transparent, white)",
                            maskImage:
                              "linear-gradient(to top, transparent, white)",
                          }}
                          className="w-52 h-48 hover:opacity-100 opacity-80 transition-all"
                          alt="example"
                          src={player.img}
                        />
                      </div>
                    }
                    actions={[]}
                  >
                    <Meta
                      title={
                        <div className="flex w-full group-hover/item:opacity-0 transition-all">
                          <div className="absolute top-0 left-0 p-1 px-2 backdrop-blur-md rounded-md text-base font-bold border-2 border-white w-fit my-auto">
                            {player.year}
                          </div>
                          <div className="absolute z-20 top-0 right-0 p-1 px-2 backdrop-blur-md rounded-md text-base font-bold border-2 border-white w-fit my-auto">
                            <motion.button
                              onClick={() => handleFavoritePlayer(player.id)}
                            >
                              <motion.div
                                initial={isFavorite ? "filled" : "outlined"}
                                animate={isFavorite ? "filled" : "outlined"}
                                variants={{
                                  outlined: { scale: 1, opacity: 1 },
                                  filled: {
                                    scale: 1.2,
                                    opacity: 1,
                                    color: "#E0245E",
                                  },
                                }}
                                transition={{
                                  type: "spring",
                                  stiffness: 260,
                                  damping: 20,
                                }}
                              >
                                {isFavorite ? (
                                  <AiFillHeart className="text-red-500 hover:text-black" />
                                ) : (
                                  <AiOutlineHeart className="text-black hover:text-red-300" />
                                )}
                              </motion.div>
                            </motion.button>
                          </div>
                          <div className="flex flex-col w-fit mx-auto">
                            <div className="relative my-auto text-lg">
                              {firstName}
                            </div>
                            <div className="text-2xl w-fit font-bold ml-1 -mt-1">
                              {lastName}
                            </div>
                          </div>
                        </div>
                      }
                      description={
                        <div className="flex flex-col group-hover/item:opacity-0 transition-all">
                          <div className="flex w-fit mx-auto">
                            <div className="mr-2 ">
                              #{player["jersey-number"]}
                            </div>
                            {player.position}
                            {player.altPosition
                              ? " / " + player.altPosition
                              : null}
                          </div>
                          <div className="flex justify-center">
                            <div>{player.height}</div>
                            <div className="ml-2">{player.weight}</div>
                          </div>
                          <div className="text-base mt-1 text-green-400 font-bold">
                            {abbreviations}
                          </div>
                        </div>
                      }
                    />
                    <div className="absolute inset-0 h-full w-full group-hover/item:[transform:rotateY(180deg)] group-hover/item:opacity-100 transition-all opacity-0 [transform:rotateY(180deg)]">
                      <div className="flex flex-col w-full mx-auto">
                        <div className="absolute z-20 top-10 right-0 p-1 px-2 backdrop-blur-md rounded-md text-base font-bold border-2 border-white w-fit my-auto">
                          <motion.button
                            onClick={() => handleFavoritePlayer(player.id)}
                          >
                            <motion.div
                              initial={isFavorite ? "filled" : "outlined"}
                              animate={isFavorite ? "filled" : "outlined"}
                              variants={{
                                outlined: { scale: 1, opacity: 1 },
                                filled: {
                                  scale: 1.2,
                                  opacity: 1,
                                  color: "#E0245E",
                                },
                              }}
                              transition={{
                                type: "spring",
                                stiffness: 260,
                                damping: 20,
                              }}
                            >
                              {isFavorite ? (
                                <AiFillHeart className="text-red-500 hover:text-black" />
                              ) : (
                                <AiOutlineHeart className="text-black hover:text-red-300" />
                              )}
                            </motion.div>
                          </motion.button>
                        </div>
                        <div className="flex align-middle justify-center bg-slate-800 pb-1 text-white relative w-full text-lg rounded-lg rounded-t-sm">
                          <div className="text-xl h-fit w-fit font-semibold">
                            {firstName}
                          </div>
                          <div className="ml-1 text-2xl w-fit font-bold">
                            {lastName}
                          </div>
                        </div>
                        <div className="flex flex-col items-start">
                          <div className="flex ml-1">
                            <span className="font-semibold mr-1 text-slate-500">
                              HS:
                            </span>
                            <div>{player.highSchool}</div>
                          </div>
                          <div className="flex ml-1">
                            <span className="font-semibold mr-1 text-slate-500">
                              YR:
                            </span>
                            <div>{player.hsgradYear}</div>
                            <div className="ml-[2px]">{`(${player.year})`}</div>
                          </div>
                          <div className="flex ml-1">
                            <span className="font-semibold mr-1 text-slate-500">
                              POS:
                            </span>
                            <div>{player.position}</div>
                            <div>
                              {player.altPosition
                                ? " / " + player.altPosition
                                : null}
                            </div>
                          </div>
                          <div className="flex ml-2">
                            <span className="flex mr-1">
                              <FaXTwitter className="mt-1 mr-[1px]" />:
                            </span>
                            <a
                              href={`https://twitter.com/${player.Xtwitter}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-xs"
                            >
                              {player.Xtwitter}
                            </a>
                          </div>
                          <div className="flex ml-2">
                            <span className="flex mr-1">
                              <FaPhone className="mt-1 mr-[1px]" />:
                            </span>
                            <div className="text-xs">{player.phone}</div>
                          </div>
                          <div className="flex ml-2 w-full scrollbar-hide overflow-x-auto">
                            <span className="flex mr-1">
                              <MdEmail className="mt-1 mr-[1px]" />:
                            </span>
                            <div className="text-xs">{player.email}</div>
                          </div>
                          <div className="flex mt-1 w-full divide-x-2 text-xs text-slate-500">
                            <div className="flex justify-center w-1/3">
                              Leadership
                            </div>
                            <div className="flex justify-center w-1/3">
                              Loves Ball
                            </div>
                            <div className="flex justify-center w-1/3">
                              Work Ethic
                            </div>
                          </div>
                          <div className="flex w-full divide-x-2">
                            <div className="flex justify-center w-1/3">
                              {player.leadership}
                            </div>
                            <div className="flex justify-center w-1/3">
                              {player.loveFootball === "10 (A Football Life)"
                                ? "10"
                                : player.loveFootball}
                            </div>
                            <div className="flex justify-center w-1/3">
                              {player.workEthic === "10 (First in, Last Out)"
                                ? "10"
                                : player.workEthic}
                            </div>
                          </div>
                          <div className="flex justify-center align-middle bg-slate-800 bg-opacity-90 pb-1 w-full text-xs px-auto font-semibold text-green-400 -mb-1 rounded-b-md">
                            {abbreviations}
                          </div>
                          {player.filmLink ? (
                            <div className="w-[95%] mx-auto max-w-[36rem] mt-1 rounded-md aspect-video relative">
                              <div
                                className="absolute top-0 left-0 w-full h-full"
                                dangerouslySetInnerHTML={{
                                  __html: player.filmLink,
                                }}
                              />
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              );
            })}
          </div>
        )}
      </Card>
    </>
  );
}
