import {
  Combobox,
  ComboboxInput,
  ComboboxList,
  ComboboxOption,
  ComboboxPopover,
} from "@reach/combobox";
import React from "react";
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";

export function RPlaces({ setCurrentLocation }) {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();

  const handleSelect = async (val) => {
    setValue(val, false)
    clearSuggestions();

    const results = await getGeocode({address: val})
    const {lat, lng} = await getLatLng(results[0])
    console.log({lat, lng})

    setCurrentLocation({lat, lng})
  }

  return (
    <Combobox className="w-[95%] pt-0 sm:pt-1 mx-auto" onSelect={handleSelect}>
      <ComboboxInput
        className="relative text-black rounded-md w-full p-1"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        disabled={!ready}
        placeholder="Search for a School"
      />
      <ComboboxPopover>
        <ComboboxList className="absolute z-30 w-96 bg-white mt-1 p-1 rounded-md">
          {status === "OK" &&
            data.map(({ place_id, description }) => (
              <ComboboxOption key={place_id} value={description} className="bg-slate-200 hover:cursor-pointer m-1 p-1 rounded-xl" />
            ))}
        </ComboboxList>
      </ComboboxPopover>
    </Combobox>
  );
}
