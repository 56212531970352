import React, { useContext, useMemo, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { motion } from "framer-motion";
import { TeamContext } from "../context/TeamProvider";
import { AiFillPrinter } from "react-icons/ai";


function VDScoreSheet({
  ScoreData,
  gradeData,
  setSelectedPositions,
  selectedPositions,
}) {
  const { teamSelected } = useContext(TeamContext);

  const ScoreSheet = useMemo(() => {
    // Your logic to compute or modify ScoreSheet based on data.ScoreData
    return ScoreData; // or some computed value
  }, [ScoreData]);

  // Group data by player name
  const groupedData = ScoreSheet?.reduce((acc, player) => {
    const playerName = player.Name;

    if (!acc[playerName]) {
      acc[playerName] = [];
    }

    acc[playerName].push(player);

    return acc;
  }, {});

  const handlePositionToggle = (playerName, position) => {
    setSelectedPositions((prevSelectedPositions) => ({
      ...prevSelectedPositions,
      [playerName]:
        position === prevSelectedPositions[playerName] ? null : position,
    }));
  };

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  console.log(groupedData);

  const MappedData = React.memo(
    ({ groupedData, selectedPositions, handlePositionToggle }) => {
      const excludedKeys = [
        "Name",
        "img",
        "position",
        "playerId",
        "totalAssignments",
        "plusAssignments",
        "minusAssignments",
        "percentAssignment",
        "Completion %",
        "ADJ Completion %",
        "COM",
        "INCOM",
        "ACC",
        "INACC",
        "group",
      ];

      const CalculateCompletionPercentage = (filteredPlayer) => {
        const playerData = filteredPlayer?.filteredPlayer;

        if (playerData.position !== "QB") {
          return;
        }
        const compPercent = Math.round(
          (playerData?.COM / (playerData.COM + playerData?.INCOM)) * 100
        );

        const compPercentadj = Math.round(
          (playerData?.ACC / (playerData.ACC + playerData?.INACC)) * 100
        );

        let bgCompColor;

        if (compPercent > 0 && compPercent < 50) {
          bgCompColor = "bg-red-300";
        } else if (compPercent >= 50 && compPercent < 70) {
          bgCompColor = "bg-yellow-300";
        } else if (compPercent >= 70) {
          bgCompColor = "bg-green-300";
        } else {
          // Handle other cases or set a default color
          bgCompColor = "bg-gray-200";
        }

        let bgAdjCompColor;

        if (compPercentadj > 0 && compPercentadj < 50) {
          bgAdjCompColor = "bg-red-300";
        } else if (compPercentadj >= 50 && compPercentadj < 70) {
          bgAdjCompColor = "bg-yellow-300";
        } else if (compPercentadj >= 70) {
          bgAdjCompColor = "bg-green-300";
        } else {
          // Handle other cases or set a default color
          bgAdjCompColor = "bg-gray-200";
        }

        return (
          <>
            <div
              className={`absolute shadow-sm shadow-slate-900 z-30 top-[-0.5rem] right-[-1.5rem] w-fit ${bgCompColor} self-center text-center rounded-md`}
            >
              <div className="relative z-10">
                <div className="absolute cursor-default z-20 transition-all bottom-0 md:bottom-[0rem] right-[-1.5rem] flex h-fit w-16 md:w-24 opacity-0 hover:opacity-100 hover:translate-x-8">
                  <div
                    className={`mt-auto ml-auto px-1 z-0 ${bgCompColor} rounded-lg`}
                  >
                    {playerData.COM !== 0 ? playerData.COM : "0"} /{" "}
                    {playerData.COM + playerData.INCOM}
                  </div>
                </div>
                <span className="font-semibold p-1 cursor-default">
                  {compPercent}%
                </span>
              </div>
            </div>
            <div
              className={`absolute shadow-sm shadow-slate-700 scale-90 z-20 top-[1rem] right-[-2rem] w-fit ${bgAdjCompColor} self-center text-center rounded-md`}
            >
              <div className="relative z-10">
                <div className="absolute scale-90 cursor-default z-10 transition-all bottom-0 md:bottom-[0rem] right-[-1.5rem] flex h-fit w-16 md:w-24 opacity-0 hover:opacity-100 hover:translate-x-8">
                  <div
                    className={`mt-auto ml-auto px-1 z-0 ${bgAdjCompColor} rounded-md`}
                  >
                    {playerData.ACC !== 0 ? playerData.ACC : "0"} /{" "}
                    {playerData.ACC + playerData.INACC}
                  </div>
                </div>
                <span className="font-semibold p-1 cursor-default">
                  {compPercentadj}%
                </span>
              </div>
            </div>
          </>
        );
      };

      return (
        <>
          {Object.entries(groupedData).map(([name, playersGroup], index) => (
            <div className="relative" key={name + index}>
              {/* Render toggle buttons for each position */}
              <div className="absolute top-2 right-2 sm:right-full -mr-2 flex flex-col mb-2 z-20 sm:z-0">
                {playersGroup.map((player, positionIndex) => (
                  <button
                    key={positionIndex}
                    className={`mb-1 pl-1 pr-2 py-2 border font-semibold font-Russo pt-1 rounded-l-lg h-8 w-8 sm:w-12 ${
                      selectedPositions[player.Name] === player.position
                        ? "shadow-slate-700 shadow-sm bg-blue-500 text-white"
                        : "shadow-slate-700 shadow-sm bg-gray-200 text-gray-700"
                    }`}
                    onClick={() =>
                      handlePositionToggle(player.Name, player.position)
                    }
                  >
                    {player.position}
                  </button>
                ))}
              </div>

              {/* Filter players based on selected position */}
              {selectedPositions[name]
                ? playersGroup
                    .filter(
                      (player) => player.position === selectedPositions[name]
                    )
                    .map((filteredPlayer, filteredPlayerIndex) => {
                      // Helper function to calculate positive sum
                      const calculatePositiveSum = (filteredPlayer) => {
                        let positiveSum = 0;
                        Object.entries(filteredPlayer).forEach(
                          ([key, value]) => {
                            if (
                              !excludedKeys.includes(key) &&
                              value !== undefined &&
                              value >= 0
                            ) {
                              positiveSum += value;
                            }
                          }
                        );

                        return positiveSum;
                      };

                      // Helper function to calculate negative sum
                      const calculateNegativeSum = (filteredPlayer) => {
                        let negativeSum = 0;
                        Object.entries(filteredPlayer).forEach(
                          ([key, value]) => {
                            if (
                              !excludedKeys.includes(key) &&
                              value !== undefined &&
                              value <= 0
                            ) {
                              negativeSum += value;
                            }
                          }
                        );
                        return negativeSum * -1;
                      };

                      return (
                        <div key={filteredPlayerIndex}>
                          <div
                            className="flex flex-col font-Kanit relative sm:flex-row w-[99%] bg-white shadow-sm shadow-black rounded-md mx-1 my-1"
                            key={filteredPlayerIndex}
                          >
                            <div className="flex flex-col text-center justify-center py-2 sm:min-h-[8rem] sm:py-auto w-full sm:w-1/6 px-2 bg-slate-200 font-bold">
                              <div className="flex relative h-full w-fit ml-4 mb-[-2rem] sm:mb-auto md:m-auto">
                                <img
                                  className="h-16 md:h-16 lg:h-20 rounded-md"
                                  src={filteredPlayer.img}
                                  alt={"profilePicture"}
                                />
                                <CalculateCompletionPercentage
                                  filteredPlayer={filteredPlayer}
                                />
                              </div>
							  <div className="flex h-1/2">
                              <div className="flex font-Russo font-light text-xl md:text-lg w-fit ml-auto mr-16 sm:mx-auto md:mt-2">
                                {filteredPlayer.Name}
                              </div>
                            </div>
                            </div>
                            <div className="flex relative text-sm sm:text-base flex-row w-full h-full min-h-[6rem] sm:min-h-[10rem] border-2">
                              <div className="flex flex-col w-1/3 overflow-hidden">
                                <div className="h-fit text-base text-ellipsis bg-slate-200 px-2 shadow-sm font-bold text-center">
                                  Assignment
                                </div>
                                <div className="flex my-auto self-center text-left sm:text-center">
                                  <div className="flex">
                                    {filteredPlayer.plusAssignments !== 0
                                      ? filteredPlayer.plusAssignments
                                      : "0"}{" "}
                                    / {filteredPlayer.totalAssignments} ={" "}
                                    <span className="font-semibold pl-1">
                                      {filteredPlayer.percentAssignment !== 0
                                        ? filteredPlayer.percentAssignment
                                        : "0"}
                                      %
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="relative flex flex-col justify-center align-middle w-1/3 border pb-6">
                                {Object.entries(filteredPlayer).map(
                                  ([key, value]) => {
                                    if (
                                      excludedKeys.includes(key) ||
                                      value === undefined ||
                                      value <= 0
                                    ) {
                                      return null;
                                    }

                                    return (
                                      <div className="flex text-sm sm:text-lg text-nowrap" key={key}>
                                        <div className="ml-auto pr-2">
                                          {value}
                                        </div>
                                        <label className="mr-auto">{key}</label>
                                      </div>
                                    );
                                  }
                                )}
                                {/* Render positive sum at the bottom */}
                                <div className="absolute bottom-0 w-full text-center left-0 right-0 flex bg-slate-200 border-r-2 border-white">
                                  <div className="mx-auto">
                                    {calculatePositiveSum(filteredPlayer)} /{" "}
                                    {filteredPlayer.totalAssignments} ={" "}
                                    <span className="font-semibold">
                                      {(
                                        (calculatePositiveSum(filteredPlayer) /
                                          filteredPlayer.totalAssignments) *
                                        100
                                      ).toFixed(0)}
                                      %
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="flex text-sm sm:text-base relative flex-col justify-center align-middle w-1/3 pb-6 border">
                                {Object.entries(filteredPlayer).map(
                                  //Negative Production with Position Labels
                                  ([key, value]) => {
                                    if (
                                      excludedKeys.includes(key) ||
                                      value === undefined ||
                                      value >= 0
                                    ) {
                                      return null;
                                    }
                                    return (
                                      <div className="flex" key={key}>
                                        <div className="ml-auto pr-2">
                                          {value * -1}
                                        </div>
                                        <label className="mr-auto">{key}</label>
                                      </div>
                                    );
                                  }
                                )}
                                {/* Render negative sum at the bottom */}
                                <div className="absolute bottom-0 w-full text-center left-0 right-0 flex bg-slate-200 border-l-2 border-white">
                                  <div className="mx-auto">
                                    {calculateNegativeSum(filteredPlayer)} /{" "}
                                    {filteredPlayer.totalAssignments} ={" "}
                                    <span className="font-semibold">
                                      {(
                                        (calculateNegativeSum(filteredPlayer) /
                                          filteredPlayer.totalAssignments) *
                                        100
                                      ).toFixed(0)}
                                      %
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                : playersGroup.map((player, playerIndex) => {
                    // Helper function to calculate positive sum (Poitive Production Calculation)
                    const calculatePositiveSum = (player) => {
                      let positiveSum = 0;

                      Object.entries(player).forEach(([key, value]) => {
                        if (
                          !excludedKeys.includes(key) &&
                          value !== undefined &&
                          value >= 0
                        ) {
                          positiveSum += value;
                        }
                      });
                      return positiveSum;
                    };

                    // Helper function to calculate negative sum (Negative Production Calculation)
                    const calculateNegativeSum = (player) => {
                      let negativeSum = 0;
                      Object.entries(player).forEach(([key, value]) => {
                        if (
                          !excludedKeys.includes(key) &&
                          value !== undefined &&
                          value <= 0
                        ) {
                          negativeSum += value;
                        }
                      });
                      return negativeSum * -1;
                    };

                    return (
                      <div key={playerIndex}>
                        <div
                          className="flex flex-col font-Kanit relative sm:flex-row w-[99%] bg-white shadow-sm shadow-black mx-1 my-1"
                          key={playerIndex}
                        >
                          <div className="flex flex-col text-center justify-center py-2 sm:min-h-[8rem] sm:py-auto w-full sm:w-1/6 px-2 bg-slate-200 font-bold">
                            <div className="flex relative h-full w-fit ml-4 mb-[-2rem] sm:mb-auto md:m-auto">
                              <img
                                className="h-16 md:h-16 lg:h-20 rounded-md mt-auto"
                                src={player.img}
                                alt={"profilePicture"}
                              />
                              <CalculateCompletionPercentage
                                filteredPlayer={player}
                              />
                            </div>
                            <div className="flex h-1/2">
                              <div className="flex font-Russo font-light text-xl md:text-lg w-fit ml-auto mr-16 sm:mx-auto md:mt-2">
                                {player.Name}
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-row w-full h-full min-h-[6rem] sm:min-h-[10rem] border-2">
                            <div className="flex flex-col w-1/3 overflow-hidden">
                              <div className="h-fit text-ellipsis bg-slate-200 px-2 shadow-sm font-bold text-center">
                                Assignment
                              </div>
                              <div className="flex my-auto self-center text-center text-sm sm:text-lg">
                                <div className="flex">
                                  {player.plusAssignments !== 0
                                    ? player.plusAssignments
                                    : "0"}{" "}
                                  / {player.totalAssignments} ={" "}
                                  <span className="font-semibold pl-1">
                                    {player.percentAssignment !== 0
                                      ? player.percentAssignment
                                      : "0"}
                                    %
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="relative flex flex-col justify-center align-middle w-1/3 border pb-6">
                              {Object.entries(player).map(([key, value]) => {
                                //Positive Production Keys
                                if (
                                  excludedKeys.includes(key) ||
                                  value === undefined ||
                                  value <= 0
                                ) {
                                  return null;
                                }

                                return (
                                  <div className="flex w-fit ml-auto text-sm sm:text-base mx-auto text-nowrap" key={key}>
                                    <div className="ml-0 pr-2">{value}</div>
                                    <label className="mr-auto">{key}</label>
                                  </div>
                                );
                              })}
                              {/* Render positive sum at the bottom */}
                              <div className="absolute bottom-0 w-full text-center left-0 right-0 flex bg-slate-200 border-r-2 border-white">
                                <div className="mx-auto text-sm sm:text-base">
                                  {calculatePositiveSum(player)} /{" "}
                                  {player.totalAssignments} ={" "}
                                  <span className="font-semibold text-sm sm:text-base">
                                    {(
                                      (calculatePositiveSum(player) /
                                        player.totalAssignments) *
                                      100
                                    ).toFixed(0)}
                                    %
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="flex relative text-sm sm:text-base flex-col justify-center align-middle w-1/3 pb-6 border">
                              {Object.entries(player).map(([key, value]) => {
                                // Negative Production Keys
                                if (
                                  excludedKeys.includes(key) ||
                                  value === undefined ||
                                  value >= 0
                                ) {
                                  return null;
                                }
                                return (
                                  <div className="flex w-fit ml-auto mx-auto text-sm sm:text-base" key={key}>
                                    <div className="ml-0 pr-2">
                                      {value * -1}
                                    </div>
                                    <label className="mr-auto">{key}</label>
                                  </div>
                                );
                              })}
                              {/* Render negative sum at the bottom */}
                              <div className="absolute bottom-0 w-full text-center left-0 right-0 flex bg-slate-200 border-l-2 border-white">
                                <div className="mx-auto">
                                  {calculateNegativeSum(player)} /{" "}
                                  {player.totalAssignments} ={" "}
                                  <span className="font-semibold">
                                    {(
                                      (calculateNegativeSum(player) /
                                        player.totalAssignments) *
                                      100
                                    ).toFixed(0)}
                                    %
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
            </div>
          ))}
        </>
      );
    }
  );

  const arePropsEqual = (prevProps, nextProps) => {
    // Compare only the selectedPositions prop
    return prevProps.selectedPositions === nextProps.selectedPositions;
  };

  const MappedDataMemoized = React.memo(MappedData, arePropsEqual);

  const [printScores, setPrintScores] = useState();

  return (
    <div className="relative w-full md:w-4/5 h-fit">
      <motion.button
        key="printButton"
        className="absolute bottom-full rounded-t-lg right-4 md:right-10 p-1 h-fit hover:bg-slate-900 border-slate-400 border-2 text-white bg-slate-700 font-bold shadow-gray-700 shadow-sm disabled:opacity-50"
        whileHover={{ scale: 0.95 }}
        whileTap={{ scale: 1.1 }}
        onClick={() => {
          handlePrint();
          setPrintScores(true);
        }}
      >
        <AiFillPrinter className="text-base sm:text-3xl text-white" />
      </motion.button>
      <div
        ref={componentRef}
        className="ssprint relative flex flex-col w-[95%] bg-slate-400 rounded-md shadow-sm ml-auto pr-1 mx-auto"
      >
        <div
          className={`print-only hidden w-full text-black pt-2 mt-10`}
        >
          <div className="absolute top-2 right-2">
            {new Date().toLocaleString() + ""}
          </div>
          <div className="flex flex-col">
            <div className="font-Russo text-6xl border-b-2">
              {teamSelected?.teamName}
            </div>
            <span className="text-xl font-light pl-6 mb-6 pt-1 whitespace-break-spaces">
              {gradeData.map(
                (game, index) =>
                  game.gradeLabel + (index < gradeData.length - 1 ? " + " : "")
              )}
            </span>
          </div>
        </div>
        <div className="flex shadow-sm font-Kanit shadow-black rounded-md bg-white my-1 mx-2">
          <div className="hidden sm:block w-1/6 text-center font-semibold pl-2 my-auto">
            Name
          </div>
          <div className="w-1/3 text-center font-semibold my-auto">
            Technical
          </div>
          <div className="rounded-sm text-center border w-1/3 px-3 border-slate-400 bg-green-200 font-bold">
            Positive Production
          </div>
          <div className="rounded-sm text-center border h-full w-1/3 px-2 border-slate-400 bg-red-200 font-bold">
            Negative Production
          </div>
        </div>
        <MappedDataMemoized
          groupedData={groupedData}
          selectedPositions={selectedPositions}
          handlePositionToggle={handlePositionToggle}
        />
      </div>
    </div>
  );
}

export default VDScoreSheet;
