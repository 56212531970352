export const RecruitingInputs = [
    {
        id: 'publicRecruit',
        label: 'Make this recruit profile public?',
        type: 'select',
        options: ['Yes', 'No'],
    },
	{
        id: 'filmLink',
        label: 'Film Link',
        type: 'string',
        placeholder: 'Add the embedded video code',
    },
    {
        id: 'expectedLevel',
        label: 'Expected Level of Competition',
        type: 'checkbox',
        options: ['NJCAA', 'Sprint Football', 'NAIA', 'Division III', 'Division II', 'Division I FCS', 'Division I FBS'],
    },
    {
        id: 'gpa',
        label: 'GPA',
        type: 'number',
        placeholder: 3.75
    },
    {
        id: '40time',
        label: '40 Time',
        type: 'number',
        placeholder: 4.7
    },
    {
        id: 'bench',
        label: 'Bench',
        type: 'number',
        placeholder: 225
    },
    {
        id: 'squat',
        label: 'Squat',
        type: 'number',
        placeholder: 405
    },
    {
        id: 'powerClean',
        label: 'Power Clean',
        type: 'number',
        placeholder: 275
    },
    {
        id: 'multiSport',
        label: 'MultiSport Athlete',
        type: 'checkbox',
        options: ['Basketball', 'BaseBall', 'Lacrosse', 'Soccer', 'Track and Field', 'Wrestling', 'Swimming', 'Cross Country', 'Water Polo', 'Golf', 'Ice Hockey', 'Rugby', 'Tennis' ],
    },
    {
        id: 'injuries',
        label: 'Notable Injuries',
        type: 'string',
        placeholder: 'Torn Left ACL in 2020, etc...'
    },
    {
        id: 'personality',
        label: 'Personality',
        type: 'string',
        placeholder: 'Charismatic, Confident, Enjoys Social Situations, etc...'
    },
    {
        id: 'family',
        label: 'Family',
        type: 'string',
        placeholder: 'Single parent household, youngest of 2 sisters, gets along very well with middle sister, etc...'
    },
    {
        id: 'collegePreference',
        label: 'Player College Preference',
        type: 'string',
        placeholder: 'UCF, UF, Valdosta State, Georgia Tech, etc...'
    },
    {
        id: 'officialOffers',
        label: 'Official Offers',
        type: 'string',
        placeholder: 'Valdosta State, Georgia Tech, FAU, etc...'
    },
    {
        id: 'competitive',
        label: 'Competitive',
        type: 'select',
        options: ['Yes', 'No'],        
    },
    {
        id: 'leadership',
        label: 'Leadership',
        type: 'select',
        options: ['Yes', 'No'],
    },
    {
        id: 'workEthic',
        label: 'Work Ethic',
        type: 'select',
        options: ['1 (Bare Minimum)', 2, 3, 4, 5, 6, 7, 8, 9, '10 (First in, Last Out)',],
    },
    {
        id: 'loveFootball',
        label: 'Love for Football',
        type: 'select',
        options: ['1 (Pressured to Play)', 2, 3, 4, 5, 6, 7, 8, 9, '10 (A Football Life)'],
    },
    {
        id: 'footballIQ',
        label: 'Football IQ',
        type: 'select',
        options: ['1 (Needs help every play)', 2, 3, 4, 5, 6, 7, 8, 9, '10 (On-Field Coach)'],
    },
]