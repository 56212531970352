import React, { useState, useEffect } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom';
import Login from './Login'
import { Home } from './Home';
import { GradeSheet } from './GradeSheet';
import { RosterEditor } from './RosterEditor';
import { SeasonSelect } from './SeasonSelect';
import { VisualData } from './VisualData';
import { DepthCharts } from './DepthCharts';
import { useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { AuthContext } from '../context/AuthContext';
import { PathProvider } from '../context/PathContext';
import { useContext } from 'react';
import { Header } from '../NavBars/Header';
import { LeftNav } from '../NavBars/LeftNav';
import { Recruit } from './Recruit';
import { HomeMobile } from './HomeMobile';
import { PrivacyPolicy } from '../Terms/PPolicy/PrivacyPolicy';
import { TermsOfUse } from '../Terms/PPolicy/TermsOfUse';
import { TeamProvider } from '../context/TeamProvider';
import { WristCaller } from './WristCaller';

export function AnimatedRoutes() {

  const {currentUser} = useContext(AuthContext)
  const location = useLocation()
  const { pathname } = useLocation();

  const [landingPage, setLandingPage] = useState(false)
  const [proMember, setProMember] = useState();

  const isMobile = window.innerWidth < 1036; // Check if the screen width is smaller than 768px

  useEffect(() => {
    console.log(pathname)
    if (pathname === '/' || pathname === '/privacy' || pathname === '/terms' ){
    setLandingPage(true)
  } else {
    setLandingPage(false)
  }
},[pathname])

useEffect(() => {
	if (!currentUser) return;
	const getProMember = async () => {
		const role = currentUser?.reloadUserInfo?.customAttributes;
		console.log(role);
		if (role === '{"stripeRole":"Pro"}') {
		  setProMember(true);
		} else {
		  setProMember(false);
		}
	  };
	  getProMember();
}, [currentUser])


  const RequireAuth = ({ children }) => {
    return currentUser ? children : <Navigate to='/' state={{ from: location}} replace />;
  };

  return (
      <PathProvider>
		<TeamProvider>
			{!landingPage && currentUser && <Header proMember={proMember} />}
			{!landingPage && currentUser && <LeftNav />}
			<AnimatePresence mode='wait' initial={false}>
				<Routes location={location} key={location.pathname} >
					<Route path='/' element={<Login />} />
						{isMobile ? 
						(<Route path="/Home" element={<RequireAuth><HomeMobile proMember={proMember} /></RequireAuth>} />) 
							: (<Route path='/Home' element={<RequireAuth><Home proMember={proMember} /></RequireAuth>} />
								)}
						<Route path='/RosterEditor' element={<RequireAuth><RosterEditor /></RequireAuth>} />
						<Route path='/DepthCharts' element={<RequireAuth><DepthCharts /></RequireAuth>} />
						<Route path='/SeasonSelect' element={<RequireAuth><SeasonSelect /></RequireAuth>} />
						<Route path='/VisualData' element={<RequireAuth><VisualData /></RequireAuth>} />
						{proMember === true && 
						<React.Fragment>
							<Route path='/GradeSheet' element={<RequireAuth><GradeSheet /></RequireAuth>} />
							<Route path='/Recruit' element={<RequireAuth><Recruit /></RequireAuth>} />
							<Route path='/WristCaller' element={<RequireAuth><WristCaller /></RequireAuth>} />
						</React.Fragment>
						}
							<Route path='/privacy' element={<PrivacyPolicy />} />
							<Route path='/terms' element={<TermsOfUse />} />
				</Routes>
			</AnimatePresence>
		  </TeamProvider>
      </PathProvider>
  )
}
