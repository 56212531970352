import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AnimatePresence, motion } from "framer-motion";
import { HeaderScrollVisibility } from "../hooks/HeaderScrollVisibility";
import { LogoPageHeader } from "../components/LogoPageHeader";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Modal,
  Popconfirm,
  Select,
  Space,
  Spin,
  Table,
  Tag,
} from "antd";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import { IoCameraOutline } from "react-icons/io5";
import Highlighter from "react-highlight-words";
import { openNotificationWithIcon } from "../helperFunctions/openNotificationWithIcon";
import { WCGameMode } from "../components/WCGameMode";
import { PathContext } from "../context/PathContext";
import { doc, getDoc, serverTimestamp, setDoc } from "@firebase/firestore";
import { db, storage } from "../Firebase-config";
import { WCTitleCascader } from "../components/WCTitleCascader";
import { WCPrint } from "../components/WCPrint";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import ImgCrop from "antd-img-crop";
import Dragger from "antd/es/upload/Dragger";
import { AuthContext } from "../context/AuthContext";
import { BiHide } from "react-icons/bi";
import { WCCallSheet } from "../components/WCCallSheet";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";


export function WristCaller() {
  const scrollableDivRef = HeaderScrollVisibility();

  const { selectedPath } = useContext(PathContext);
  const { currentUser } = useContext(AuthContext);

  const tagColorMapping = {
    L: { color: "#72e694", abbreviation: "L" },
    M: { color: "#72e694", abbreviation: "M" },
    R: { color: "#72e694", abbreviation: "R" },
    "3rd Down": { color: "orange", abbreviation: "3rd" },
    "4th Down": { color: "volcano", abbreviation: "4th" },
    Short: { color: "#eab676", abbreviation: "SY" },
    Medium: { color: "#eab676", abbreviation: "Med" },
    Long: { color: "#eab676", abbreviation: "Long" },
    "Long +": { color: "#eab676", abbreviation: "Long +" },
    Pass: { color: "cyan", abbreviation: "Pass" },
    Run: { color: "green", abbreviation: "Run" },
    RPO: { color: "purple", abbreviation: "RPO" },
    "Play Action": { color: "#27DEB3", abbreviation: "PA" },
    "Shot Plays": { color: "#27DEB3", abbreviation: "Shot" },
    "Trick Plays": { color: "#27DEB3", abbreviation: "Trick" },
    Screens: { color: "#27DEB3", abbreviation: "Screen" },
    P10: { color: "#4C4D4B", abbreviation: "P10" },
    Overtime: { color: null, abbreviation: "OT" },
    "2 Minute": { color: "volcano", abbreviation: "2 Min" },
    "4 Minute": { color: "#76b5c5", abbreviation: "4 Min" },
    "Backed Up": { color: "blue", abbreviation: "BU" },
    "Coming Out": { color: "gold", abbreviation: "CO" },
    "Green Zone": { color: "green", abbreviation: "GZ" },
    "High RedZone": { color: "red", abbreviation: "HRZ" },
    "Low RedZone": { color: "red", abbreviation: "LRZ" },
    "Goal Line": { color: "purple", abbreviation: "GL" },
    "00p": { color: null, abbreviation: "00P" },
    "01p": { color: null, abbreviation: "01P" },
    "01p": { color: null, abbreviation: "02P" },
    "10p": { color: null, abbreviation: "10P" },
    "11p": { color: null, abbreviation: "11P" },
    "12p": { color: null, abbreviation: "12P" },
    "13p": { color: null, abbreviation: "13P" },
    "20p": { color: null, abbreviation: "20P" },
    "21p": { color: null, abbreviation: "21P" },
    "22p": { color: null, abbreviation: "22P" },
    "23p": { color: null, abbreviation: "23P" },
  };

  const tagOrder = [
    "Pass",
    "Run",
    "RPO",
    "1st",
    "2nd",
    "3rd",
    "4th",
    "3rd Down",
    "4th Down",
    "Short",
    "Medium",
    "Long",
    "Long +",
    "L",
    "M",
    "R",
    "Play Action",
    "Shot Plays",
    "Trick Plays",
    "Screens",
    "P10",
    "Overtime",
    "2 Minute",
    "4 Minute",
    "Backed Up",
    "Coming Out",
    "Green Zone",
    "High RedZone",
    "Low RedZone",
    "Goal Line",
    "00p",
    "01p",
    "02p",
    "10p",
    "11p",
    "12p",
    "13p",
    "20p",
    "21p",
    "22p",
    "23p",
  ];

  const [playBookTitle, setPlayBookTitle] = useState();
  const [addPlayModalOpen, setAddPlayModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pbChanged, setPBChanged] = useState(false);

  const handleClose = () => {
    setAddPlayModalOpen(false);
  };

  const getPlaybook = () => {
    if (!playBookTitle) {
      openNotificationWithIcon(
        "warning",
        "Select a Depth Chart to Open",
        "Use the cascader to select the depth chart you want to view.",
        "top"
      );
      return;
    }

    setLoading(true);

    const fetchPlaybook = async () => {
      const dataLabel = playBookTitle?.join();

      const pbQuery = doc(db, "Teams", selectedPath, "PlaySheets", dataLabel);

      const pbSnapshot = await getDoc(pbQuery);
      if (pbSnapshot.exists()) {
        const fetchedData = pbSnapshot.data();
        setData(fetchedData?.data);
        setPlaybookDatabase(fetchedData?.playbookDatabase);
        console.log(fetchedData);
        openNotificationWithIcon(
          "success",
          "Call Sheet Found",
          "",
          "top",
          "",
          2
        );
      }
    };
    fetchPlaybook();
    setLoading(false); // set loading to false after data has been retrieved
    setPBChanged(false);
    setHighlightFetch(false);
  };

  const handleSavePlaybook = async () => {
    if (!data || data.length < 1) {
      openNotificationWithIcon(
        "warning",
        "No Data",
        "No plays are detected, continue only if you want the call sheet uploaded to be save without any plays",
        "top"
      );
      return;
    }
    console.log(playBookTitle);
    const dataLabel = playBookTitle ? playBookTitle.join() : null;
    try {
      await setDoc(doc(db, "Teams", selectedPath, "PlaySheets", dataLabel), {
        data,
        playbookDatabase,
        dateUpdated: serverTimestamp(),
      });
      openNotificationWithIcon("success", "Call Sheet Uploaded", "", "top");
      setPBChanged(false);
    } catch (error) {
      openNotificationWithIcon(
        "error",
        "Unable to Upload, Try again.",
        "",
        "top"
      );
      console.log(error);
    }
  };

  const [data, setData] = useState([]);
  const [playbookDatabase, setPlaybookDatabase] = useState({
    Formations: [],
    Motions: [],
    Runs: [],
    Passes: [],
  });

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
            className="text-black"
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      if (dataIndex === "Tags") {
        return record[dataIndex].some((tag) =>
          tag.text.toLowerCase().includes(value.toLowerCase())
        );
      }
      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const colorOptions = [
    {
      value: "bg-blue-400",
      label: <div className="bg-blue-400 rounded-full h-3 w-3 m-auto"></div>,
    },
    {
      value: "bg-red-400",
      label: <div className="bg-red-400 rounded-full h-3 w-3 m-auto"></div>,
    },
    {
      value: "bg-yellow-400",
      label: <div className="bg-yellow-400 rounded-full h-3 w-3 m-auto"></div>,
    },
  ];

  const changeWristColor = (key, newColor) => {
    setData((prevData) =>
      prevData.map((record) => {
        if (record.key === key) {
          // Check if there's a conflicting number for the new color
          const conflictingNumbers = prevData
            .filter((r) => r.WristColor === newColor)
            .map((r) => parseInt(r.WristNum, 10));

          let newWristNum = 1;
          while (conflictingNumbers.includes(newWristNum)) {
            newWristNum++;
          }

          // Return the record with the updated color and number
          return {
            ...record,
            WristColor: newColor,
            WristNum: newWristNum.toString(),
          };
        }
        return record;
      })
    );
    setSelectingColor(null);
  };

  const handleFavoritePlay = (key) => {
    setData((prevData) =>
      prevData.map((record) => {
        if (record.key === key) {
          return { ...record, favoritePlay: !record.favoritePlay };
        }
        return record;
      })
    );
  };

  const editCallPic = (record) => {
    if (record === expandedRow) {
      setExpandedRow(null);
    } else {
      setExpandedRow(record);
    }
  };

  // Function to generate a unique number for a given WristColor
  const generateUniqueNumber = (color) => {
    const colorRecords = data.filter(
      (record) => record.WristColor === color && !record.hidden
    );
    const usedNumbers = colorRecords
      .map((record) => record.WristNum)
      .filter((num) => num !== null)
      .sort((a, b) => a - b);

    let uniqueNumber = 1;
    for (let num of usedNumbers) {
      if (num !== uniqueNumber) break;
      uniqueNumber++;
    }
    return uniqueNumber;
  };

  const [selectingColor, setSelectingColor] = useState();

  // Function to handle hiding/unhiding a play
  const handleHidePlay = (key) => {
    setData((prevData) => {
      return prevData.map((record) => {
        if (record.key === key) {
          if (record.hidden) {
            record.hidden = false;
            record.WristNum = generateUniqueNumber(record.WristColor);
          } else {
            record.hidden = true;
            record.WristNum = null;
          }
        }
        return record;
      });
    });
  };

  const testColumns = [
    {
      key: "favoritePlay",
      dataIndex: "favoritePlay",
      title: "",
      width: 35,
      fixed: "left",
      sorter: (a, b) => {
        // Check favorite status first; if both are the same, sort alphabetically by playCall
        if (a.favoritePlay === b.favoritePlay) {
          return a.playCall.localeCompare(b.playCall);
        }
        // If not the same, sort such that favorites always come first
        return a.favoritePlay ? -1 : 1;
      },
      render: (_, record) => {
        const isFavorite = record.favoritePlay ? true : false;
        return (
          <div className="flex mx-auto">
            <motion.button onClick={() => handleFavoritePlay(record.key)}>
              <motion.div
                initial={isFavorite ? "filled" : "outlined"}
                animate={isFavorite ? "filled" : "outlined"}
                variants={{
                  outlined: { scale: 1, opacity: 1 },
                  filled: { scale: 1.2, opacity: 1, color: "#E0245E" },
                }}
                transition={{
                  type: "spring",
                  stiffness: 260,
                  damping: 20,
                }}
              >
                {isFavorite ? (
                  <AiFillHeart className="text-red-500 hover:text-black" />
                ) : (
                  <AiOutlineHeart className="text-black hover:text-red-300" />
                )}
              </motion.div>
            </motion.button>
          </div>
        );
      },
    },
    {
      key: "WristColor",
      dataIndex: "WristColor",
      title: "",
      width: 10,
      sorter: (a, b) => {
        // Compare wrist colors directly
        const colorComparison = a.WristColor.localeCompare(b.WristColor);
        if (colorComparison !== 0) {
          return colorComparison;
        }
        // If wrist colors are the same, sort alphabetically by playCall
        return a.playCall.localeCompare(b.playCall);
      },
      render: (_, record) => {
        return (
          <>
            {selectingColor === record.key ? (
              <div key={record.key} className="flex items-center relative">
                <Select
                  value={record.WristColor}
                  defaultOpen
                  onChange={(value) => changeWristColor(record.key, value)}
                  className={`absolute z-30 top-0 -left-4 h-4 w-fit opacity-90`}
                  onBlur={() => setSelectingColor(null)}
                  onSelect={() => setSelectingColor(null)}
                  open={record.key == selectingColor ? true : false}
                  options={colorOptions}
                ></Select>
              </div>
            ) : (
              <button
                onClick={() => setSelectingColor(record.key)}
                className={`${record.WristColor} rounded-full h-3 w-3 m-auto`}
              ></button>
            )}
          </>
        );
      },
    },
    {
      key: "WristNum",
      dataIndex: "WristNum",
      title: "#",
      width: 10,
    },
    {
      key: "playCall",
      dataIndex: "playCall",
      title: "Play Call",
      width: 200,
      editable: true,
      ...getColumnSearchProps("playCall"),
    },
    {
      key: "Tags",
      dataIndex: "Tags",
      title: "Tags",
      width: 300,
      ...getColumnSearchProps("Tags"),
      render: (_, record) => (
        <div className="flex w-full overflow-x-auto scrollbar-hide">
          {tagOrder
            .map((tagKey) => {
              const tag = record.Tags.find((t) => t.text === tagKey);
              return tag ? (
                <button onClick={() => removeTag(record, tag)}>
                  <Tag
                    className="my-[2px]"
                    key={tag.text}
                    color={tagColorMapping[tag.text].color || null}
                  >
                    {`${tagColorMapping[tag.text].abbreviation}`}
                  </Tag>
                </button>
              ) : null;
            })
            .filter((tagComponent) => tagComponent !== null)}
        </div>
      ),
    },
    {
      key: "action",
      dataIndex: "action",
      title: "",
      width: 20,
      render: (_, record) => {
        const key = record.key;
        return (
          <div className="flex justify-between gap-3">
            <button
              className={record.hidden ? "opacity-100" : "opacity-50"}
              onClick={() => handleHidePlay(key)}
            >
              <BiHide className="scale-110 hover:scale-125" />
            </button>
            <button
              className={record.image ? "text-black" : "text-slate-400"}
              onClick={() => editCallPic(key)}
            >
              <IoCameraOutline className="scale-110 hover:scale-125" />
            </button>
            <Popconfirm
              title="Sure to delete?"
              okType="danger"
              onConfirm={() => handleDeletePlay(key, record.imageName)}
            >
              <DeleteOutlined className="opacity-70 hover:opacity-100 scale-125" />
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const [selectedButtons, setSelectedButtons] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]); // This holds the keys of the selected rows

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      setSelectedRowKeys(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.play === "Disabled User",
      // Column configuration not to be checked
      name: record.playCall,
    }),
  };

  const addSelectedTags = () => {
    const newData = data.map((row) => {
      if (selectedRowKeys.includes(row.key)) {
        // Create tags for the selected buttons with their corresponding colors
        const newTags = selectedButtons.map((buttonText) => ({
          text: buttonText,
          color: tagColorMapping[buttonText] || "#7B7B7B", // 'defaultColor' is a placeholder for unmapped tags
        }));

        // Combine existing tags with the new ones, avoiding duplicates
        const combinedTags = [...row.Tags, ...newTags].reduce(
          (acc, current) => {
            const x = acc.find((item) => item.text === current.text);
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          },
          []
        );

        return {
          ...row,
          Tags: combinedTags,
        };
      }
      return row;
    });

    setData(newData);
  };

  const removeTag = (record, tagToRemove) => {
    // Update the data by mapping over it and adjusting the Tags array
    const newData = data.map((item) => {
      if (item.key === record.key) {
        // Filter out the tag to be removed
        const updatedTags = item.Tags.filter(
          (tag) => tag.text !== tagToRemove.text
        );
        return { ...item, Tags: updatedTags };
      }
      return item; // Return unchanged for other records
    });

    setData(newData); // Update state with modified data
  };

  const removeTags = () => {
    const newData = data.map((row) => {
      // Check if the row's key is in the selectedRowKeys array
      if (selectedRowKeys.includes(row.key)) {
        return {
          ...row,
          Tags: [], // Set Tags to an empty array, removing all existing tags
        };
      }
      return row; // Return the row unchanged if its key is not in selectedRowKeys
    });

    setData(newData); // Update your state with the new data
  };

  const onButtonClick = (buttonName) => {
    setSelectedButtons((prevSelectedButtons) => {
      if (prevSelectedButtons.includes(buttonName)) {
        // Remove the button from the array if it was already selected
        return prevSelectedButtons.filter((name) => name !== buttonName);
      } else {
        // Add the button to the array if it wasn't selected
        return [...prevSelectedButtons, buttonName];
      }
    });
  };

  const onSelectChange = (value, optionList) => {
    setSelectedButtons((currentSelectedButtons) => {
      // Remove any existing select values from the array
      const newSelectedButtons = currentSelectedButtons.filter(
        (name) => !optionList.includes(name)
      );
      // Check if the value is not the empty string (or any other placeholder you might use)
      if (value !== "") {
        newSelectedButtons.push(...value);
      }
      return newSelectedButtons;
    });
  };

  const resetButtons = () => {
    setSelectedButtons([]);
  };

  useEffect(() => {
    console.log("selectedButtons", selectedButtons);
  }, [selectedButtons]);

  const fieldZoneButtons = [
    { name: "Backed Up", fieldPos: "(0 : -10)", className: "bg-blue-400" },
    { name: "Coming Out", fieldPos: "(-10 : -25)", className: "bg-yellow-400" },
    { name: "Green Zone", fieldPos: "(-25 : 25)", className: "bg-green-400" },
    { name: "High RedZone", fieldPos: "(25 : 15)", className: "bg-red-300" },
    { name: "Low RedZone", fieldPos: "(15 : 3)", className: "bg-red-500" },
    { name: "Goal Line", fieldPos: "(3 : TD)", className: "bg-purple-400" },
  ];

  const situationButtons = [
    { name: "P10", className: "" },
    { name: "3rd Down", className: "" },
    { name: "4th Down", className: "" },
    { name: "2 Minute", className: "text-red-400" },
    {
      name: "4 Minute",
      className: "text-blue-400 focus:shadow-inner focus:shadow-slate-800",
    },
    { name: "Overtime", className: "" },
  ];

  const playTypeButtons = [
    { name: "Run", className: "" },
    { name: "Pass", className: "" },
    { name: "RPO", className: "" },
    { name: "Play Action", className: "" },
    { name: "Shot Plays", className: "" },
    { name: "Trick Plays", className: "" },
    { name: "Screens", className: "" },
  ];

  const [newPlayCall, setNewPlayCall] = useState("");
  const [formation, setFormation] = useState("");
  const [motions, setMotions] = useState("");
  const [run, setRun] = useState("");
  const [pass, setPass] = useState("");

  // Memoize sorted arrays
  const sortedFormations = useMemo(
    () => playbookDatabase.Formations?.sort((a, b) => b.length - a.length),
    [playbookDatabase.Formations]
  );
  const sortedMotions = useMemo(
    () => playbookDatabase.Motions?.sort((a, b) => b.length - a.length),
    [playbookDatabase.Motions]
  );
  const sortedRuns = useMemo(
    () => playbookDatabase.Runs?.sort((a, b) => b.length - a.length),
    [playbookDatabase.Runs]
  );
  const sortedPasses = useMemo(
    () => playbookDatabase.Passes?.sort((a, b) => b.length - a.length),
    [playbookDatabase.Passes]
  );

  useEffect(() => {
    const updateFields = (input) => {
      const inputLower = input.toLowerCase();

      // Function to find the longest match
      const findLongestMatch = (sortedArray) => {
        for (const item of sortedArray) {
          if (inputLower.includes(item.toLowerCase())) {
            return item;
          }
        }
        return "";
      };

      // Update states with the longest match
      setFormation(findLongestMatch(sortedFormations));
      setMotions(findLongestMatch(sortedMotions));
      setRun(findLongestMatch(sortedRuns));
      setPass(findLongestMatch(sortedPasses));
    };

    updateFields(newPlayCall);
  }, [newPlayCall, sortedFormations, sortedMotions, sortedRuns, sortedPasses]);

  const [isRunChecked, setIsRunChecked] = useState(false);
  const [isPassChecked, setIsPassChecked] = useState(false);
  const [isRPOChecked, setIsRPOChecked] = useState(false);
  const [isScreenChecked, setIsScreenChecked] = useState(false);

  const handleAddPlay = () => {
    const wristColor = "bg-blue-400"; // This can be dynamic based on your logic

    // Check if playCall is a duplicate
    const exists = data.some(
      (play) => play.playCall.toLowerCase() === newPlayCall.toLowerCase()
    );

    if (!exists) {
      // Construct the Tags array based on checkbox states
      const tags = [];
      if (isRunChecked) tags.push({ text: "Run", color: "green" });
      if (isPassChecked) tags.push({ text: "Pass", color: "blue" });
      if (isRPOChecked) tags.push({ text: "RPO", color: "purple" });
      if (isScreenChecked) tags.push({ text: "Screens", color: "yellow" });

      const newPlay = {
        key: newPlayCall + data.length + 1,
        WristNum: data.length + 1,
        WristColor: wristColor,
        playCall: newPlayCall,
        formation: formation,
        motions: motions,
        run: run,
        pass: pass,
        favoritePlay: false,
        Tags: tags,
      };

      setData((prevData) => [...prevData, newPlay]);
      setIsRunChecked(false);
      setIsPassChecked(false);
      setIsRPOChecked(false);
      setIsScreenChecked(false);
      openNotificationWithIcon("success", "Play Added", "", "top");
    } else {
      openNotificationWithIcon(
        "error",
        "Duplicate Play",
        "This play already exists in your playbook",
        "top"
      );
    }

    // Update playbookDatabase
    setPlaybookDatabase((prevDatabase) => {
      const newFormations = new Set(prevDatabase.Formations);
      const newMotions = new Set(prevDatabase.Motions);
      const newRuns = new Set(prevDatabase.Runs);
      const newPasses = new Set(prevDatabase.Passes);

      if (formation && !prevDatabase.Formations.includes(formation))
        newFormations.add(formation);
      if (motions && !prevDatabase.Motions.includes(motions))
        newMotions.add(motions);
      if (run && !prevDatabase.Runs.includes(run)) newRuns.add(run);
      if (pass && !prevDatabase.Passes.includes(pass)) newPasses.add(pass);

      return {
        Formations: Array.from(newFormations),
        Motions: Array.from(newMotions),
        Runs: Array.from(newRuns),
        Passes: Array.from(newPasses),
      };
    });
  };

  const handleDeletePlay = (key, filePath) => {
    setData((prevData) => prevData.filter((play) => play.key !== key));
    if (filePath) {
      deleteFile(filePath);
    }
  };

  const [gameMode, setGameMode] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [highlightFetch, setHighlightFetch] = useState(false);

  useEffect(() => {
    console.log("Selected Keys", selectedKeys);
    console.log("seletedFilters", selectedFilters);
  }, [selectedKeys, selectedFilters]);

  const updatePBTitle = (title) => {
    setPlayBookTitle(title);
    setHighlightFetch(true);
  };

  const [showPrint, setShowPrint] = useState(false);

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const reassignWristInfo = () => {
    const colors = ["bg-blue-400", "bg-red-400", "bg-yellow-400"];
    const maxPlaysPerColor = 50;

    // Generate shuffled arrays of numbers for each color group
    const shuffledNumbers = {};
    colors.forEach((color) => {
      const numbers = Array.from(
        { length: maxPlaysPerColor },
        (_, index) => index + 1
      );
      shuffledNumbers[color] = shuffleArray(numbers);
    });

    const assignedPairs = new Set(); // Set to track assigned WristColor-WristNum pairs

    const newData = data.map((play, index) => {
      let colorIndex = index % colors.length;
      let color = colors[colorIndex];
      let number = shuffledNumbers[color][index % maxPlaysPerColor].toString();
      let pair = `${color}-${number}`;

      // Check for duplicates
      while (assignedPairs.has(pair)) {
        // If duplicate found, change color or number
        colorIndex = (colorIndex + 1) % colors.length;
        color = colors[colorIndex];
        number = shuffledNumbers[color][index % maxPlaysPerColor].toString();
        pair = `${color}-${number}`;
      }

      // Add the unique pair to the set
      assignedPairs.add(pair);

      // Assign WristColor and WristNum
      return {
        ...play,
        WristColor: color,
        WristNum: number,
      };
    });

    setData(newData);
  };

  const handleRemoveSuggestion = (pbArray, callToRemove) => {
    // Create a new array by filtering out the callToRemove
    const updatedArray = playbookDatabase[pbArray].filter(
      (call) => call !== callToRemove
    );

    // Update the state with the new array under the correct property
    setPlaybookDatabase({
      ...playbookDatabase,
      [pbArray]: updatedArray,
    });
  };

  //Save Reminder
  //   useEffect(() => {
  //     if (playbookDatabase.length < 1 || data.length < 1) {
  //       return;
  //     }
  //     setPBChanged(true);
  //   }, [playbookDatabase, data]);

  const [expandedRow, setExpandedRow] = useState();

  const clearCallSheetData = (keepPB) => {
    if (keepPB) {
      setData([]);
      console.log("PBDB Saved");
    } else {
      setData([]);
      setPlaybookDatabase({
        Formations: [],
        Motions: [],
        Runs: [],
        Passes: [],
      });
      console.log("PBDB reset");
    }
  };

  const uploadProps = {
    name: "file",
    multiple: false, // Set to true for multiple file uploads
    customRequest: async ({ file, onSuccess, onError }) => {
      const storageRef = ref(
        storage,
        `${selectedPath}/${playBookTitle}/${file.name}`
      );
      try {
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Handle upload progress
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log(`Upload is ${progress}% done`);
          },
          onError,
          async () => {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            onSuccess(downloadURL, file); // Success callback with downloadURL

            const newData = data.map((item) => {
              if (item.key === expandedRow) {
                // Update item with image URL and imageName only after successful upload
                return { ...item, image: downloadURL, imageName: file.name };
              }
              return item;
            });

            // Update data state after successful upload and downloadURL retrieval
            setData(newData);
          }
        );
      } catch (error) {
        onError(error);
      }
    },

    onSuccess(response) {
      console.log("File uploaded successfully:", response);
      // Update your data state with the downloadURL from response
    },
    onError(error) {
      console.error("Upload failed:", error);
    },
  };

  const deleteFile = async (filePath) => {
    try {
      const storageRef = ref(
        storage,
        `${selectedPath}/${playBookTitle}/${filePath}`
      );
      await deleteObject(storageRef);
      console.log(`File deleted successfully: ${filePath}`);
      const newData = data.map((item) => {
        if (item.key === expandedRow) {
          // Update item with image URL and imageName only after successful upload
          return { ...item, image: null, imageName: null };
        }
        return item;
      });

      // Update data state after successful upload and downloadURL retrieval
      setData(newData);
    } catch (error) {
      console.error("Error deleting file:", error);
    }
  };

  // Preprocess the newPlayCall outside the function to avoid redundancy
  const fullLowerNewPlayCall = newPlayCall.toLowerCase().trim();
  const newPlayCallParts = fullLowerNewPlayCall.split(" ");

  // Calculate similarity score for sorting
  const calculateSimilarity = (call) => {
    const fullLowerCall = call.toLowerCase().trim();

    // Check for an exact match or a match followed by a space
    if (
      fullLowerCall === fullLowerNewPlayCall ||
      fullLowerCall === fullLowerNewPlayCall + " "
    ) {
      return 1000; // Maximum score for an exact match
    }

    let score = 0;
    // Boost for containing the motion as a part
    if (fullLowerNewPlayCall.includes(fullLowerCall)) {
      score += 500;
    }

    // Check if the call starts with newPlayCall and consider the space following it
    if (fullLowerCall.startsWith(fullLowerNewPlayCall + " ")) {
      score += 500;
      score -= fullLowerCall.length - (fullLowerNewPlayCall.length + 1);
    }

    // Optimize loop by summing scores based on parts
    score += newPlayCallParts.reduce(
      (acc, part) => acc + (fullLowerCall.includes(part) ? part.length : 0),
      0
    );
    return score;
  };

  // Generalized rendering function for categories
  const renderCategory = (categoryName, categoryData) => {
    return categoryData
      .map((call) => ({ call, score: calculateSimilarity(call) })) // Map to include similarity score
      .sort((a, b) => b.score - a.score) // Sort by score descending
      .map(({ call }) => (
        <div className="flex w-32 pr-2 hover:bg-slate-50" key={call}>
          {call}
          <button
            onClick={() => handleRemoveSuggestion(categoryName, call)}
            className="text-sm hover:text-blue-400 ml-auto"
          >
            x
          </button>
        </div>
      ));
  };

  const [showHiddenPlays, setShowHiddenPlays] = useState(false);
  const [showTagButtons, setShowTagButtons] = useState(false);

  const handleShowTags = () => {
    resetButtons();
    setShowTagButtons(!showTagButtons);
  };

  const EditableContext = React.createContext(null);

  const EditableRow = React.memo((props) => {
    const [form] = Form.useForm();
    const [editingIndex, setEditingIndex] = useState(null);
    const toggleEdit = useCallback(
      (dataIndex) => {
        setEditingIndex(dataIndex === editingIndex ? null : dataIndex);
      },
      [editingIndex]
    );

    const contextValue = useMemo(
      () => ({
        form,
        editingIndex,
        toggleEdit,
      }),
      [form, editingIndex, toggleEdit]
    );

    return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={contextValue}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
  });

  const EditableCell = React.memo(
    ({
      title,
      editable,
      children,
      dataIndex,
      record,
      handleSave,
      ...restProps
    }) => {
      const { form, editingIndex, toggleEdit } = useContext(EditableContext);
      const [isOpen, setIsOpen] = useState(false);
      const inputRef = useRef(null);

      useEffect(() => {
        if (editingIndex === dataIndex) {
          form.setFieldsValue({ [dataIndex]: record[dataIndex] });
          setIsOpen(true);
          inputRef.current.focus();
        } else {
          setIsOpen(false);
        }
      }, [editingIndex, dataIndex, record, form]);

      const save = useCallback(async () => {
        try {
          const values = await form.validateFields();
          const updatedRecord = { ...record, ...values };
          handleSave(data, {
            ...updatedRecord,
            [dataIndex]:
              values[dataIndex] !== undefined
                ? values[dataIndex]
                : dataIndex === "numericValue"
                ? 0
                : null,
          });
        } catch (errInfo) {
          console.log("Save failed:", errInfo);
        } finally {
          setIsOpen(false);
        }
      }, [dataIndex, form, handleSave, record]);

      const handleClick = () => {
        if (editable) {
          toggleEdit(dataIndex);
        }
      };

      // Render different content based on whether the cell is editable
      let childNode = children;

      if (editable) {
        const inputNode = (
          <Input
            style={{ minWidth: 50, margin: 0 }}
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
          />
        );

        childNode = editingIndex ? (
          <Form.Item
            style={{ margin: 0 }}
            name={dataIndex}
            rules={[{ required: false, message: `${title} is required.` }]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          <div
            className="editable-cell-value-wrap -m-1 h-8 min-w-8 border border-transparent hover:border-gray-200 rounded-lg"
            style={{ paddingRight: 20 }}
            onClick={handleClick}
          >
            {children}
          </div>
        );
      }

      return <td {...restProps}>{childNode}</td>;
    }
  );

  // Assuming this code is in the same component where `handleSave` is defined.

  const handleSave = (playData, row) => {
    // Find the index of the row to update
    const index = playData.findIndex((item) => item["key"] === row["key"]);

    // If the row exists in the playData array, update it
    if (index !== -1) {
      const updatedPlayData = [...playData];
      updatedPlayData[index] = { ...updatedPlayData[index], ...row };
      setData(updatedPlayData);
    } else {
      // If the row doesn't exist, add it to the playData array
      setData([...playData, row]);
    }
  };

  const components = useMemo(
    () => ({
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    }),
    [data]
  );

  const mapColumns = (col) => {
    if (!col.editable) {
      return col;
    }
    const newCol = {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
    if (col.children) {
      newCol.children = col.children.map(mapColumns);
    }
    console.log(newCol);
    return newCol;
  };

  const wbColumns = testColumns.map(mapColumns);

  const [ viewCallSheet ,setViewCallSheet ] = useState(false)

  return (
    <motion.div
      className="h-screen w-full overflow-auto scrollbar-hide"
      initial={{ y: -1500 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: 1000, opacity: 0 }}
      ref={scrollableDivRef}
    >
      <div className="h-fit w-full mx-auto lg:mr-4 lg:ml-auto md:w-[85%] lg:w-[98%]">
        <LogoPageHeader title={"WristCaller"} />
        <Modal
          className="h-screen w-fit pt-[8%]"
          centered
          width={1100}
          open={addPlayModalOpen}
          onOk={handleClose}
          onCancel={handleClose}
          destroyOnClose
          footer={[
            <Button key="back" onClick={handleClose}>
              Close
            </Button>,
            <Button className="" onClick={handleAddPlay}>
              Add Play
            </Button>,
          ]}
        >
          <div className="w-full h-full">
            <div className="w-full mt-12 min-h-48 mx-auto">
              <div className="w-2/3 rounded-md mx-auto p-5 bg-slate-200 border border-slate-300">
                <div className="font-Russo text-xl">Enter Full Play Call</div>
                <Input
                  onChange={(e) => setNewPlayCall(e.target.value)}
                  type="text"
                  placeholder="Full Call"
                />
              </div>
              <div className="flex mt-2 w-fit mx-auto gap-5">
                <Checkbox
                  checked={isRunChecked}
                  onChange={() => setIsRunChecked(!isRunChecked)}
                >
                  Run
                </Checkbox>
                <Checkbox
                  checked={isPassChecked}
                  onChange={() => setIsPassChecked(!isPassChecked)}
                >
                  Pass
                </Checkbox>
                <Checkbox
                  checked={isRPOChecked}
                  onChange={() => setIsRPOChecked(!isRPOChecked)}
                >
                  RPO
                </Checkbox>
                <Checkbox
                  checked={isScreenChecked}
                  onChange={() => setIsScreenChecked(!isScreenChecked)}
                >
                  Screen
                </Checkbox>
              </div>
            </div>
            <div className="h-1 my-4 w-full border-b-2 border-black"></div>
            <div className="w-fit flex flex-col mx-auto">
              <div>Fill in or edit the fields below before adding a play</div>
              <div className="flex">
                <div className="relative w-full h-full">
                  <Input
                    className=""
                    value={formation}
                    onChange={(e) => setFormation(e.target.value)}
                    type="text"
                    placeholder="Formation"
                  />
                  <div
                    className={`absolute top-full left-2 rounded-lg hover:h-fit px-2 mt-1 pb-3 h-6 bg-white overflow-hidden`}
                  >
                    {renderCategory("Formations", playbookDatabase.Formations)}
                  </div>
                </div>
                <div className="w-full relative">
                  <Input
                    className=""
                    value={motions}
                    onChange={(e) => setMotions(e.target.value)}
                    type="text"
                    placeholder="Motion"
                  />
                  <div
                    className={`absolute top-full left-2 rounded-lg hover:h-fit px-2 mt-1 pb-3 h-6 bg-white overflow-hidden`}
                  >
                    {renderCategory("Motions", playbookDatabase.Motions)}
                  </div>
                </div>
                <div className="w-full relative">
                  <Input
                    className=""
                    value={run}
                    onChange={(e) => setRun(e.target.value)}
                    type="text"
                    placeholder="Run"
                  />
                  <div
                    className={`absolute top-full left-2 rounded-lg hover:h-fit px-2 mt-1 pb-3 h-6 bg-white overflow-hidden`}
                  >
                    {renderCategory("Runs", playbookDatabase.Runs)}
                  </div>
                </div>
                <div className="w-full relative">
                  <Input
                    className=""
                    value={pass}
                    onChange={(e) => setPass(e.target.value)}
                    type="text"
                    placeholder="Pass"
                  />
                  <div
                    className={`absolute top-full left-2 rounded-lg hover:h-fit px-2 mt-1 pb-3 h-6 bg-white overflow-hidden`}
                  >
                    {renderCategory("Passes", playbookDatabase.Passes)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        <WCPrint
          setShowPrint={setShowPrint}
          showPrint={showPrint}
          data={data.filter((play) => play.hidden !== true)}
        />

		<WCCallSheet
			viewCallSheet={viewCallSheet} 
			setViewCallSheet={setViewCallSheet}
			data={data.filter((play) => play.hidden !== true)}
		/>

        {gameMode && (
          <WCGameMode
            data={data}
            gameMode={gameMode}
            setGameMode={setGameMode}
            fieldZoneButtons={fieldZoneButtons}
            situationButtons={situationButtons}
            playbookDatabase={playbookDatabase}
          />
        )}

        <div
          className={`mb-10 shadow-md shadow-b-black relative py-4 pt-4 px-2 text-center bg-slate-100 bg-opacity-20 transition-all mx-auto my-2 mt-0 ${
            showTagButtons ? "min-h-[46rem]" : ""
          } h-fit rounded-lg rounded-t-none sm:rounded-t-lg`}
        >
          {/*Edit Mode*/}
          <div className="grid grid-cols-1 md:grid-cols-3 h-full">
            <div className="col-span-1 mr-2">
              <div className="flex flex-col sticky z-30 md:top-20 justify-center align-middle bg-slate-100 bg-opacity-90 rounded-md mt-5">
                <div className="relative z-30 flex flex-col bg-slate-700 rounded-t-md w-full text-white shadow-sm shadow-black px-5 pt-2">
                  <div className="w-full mx-auto">
                    <WCTitleCascader
                      updateTitle={updatePBTitle}
                      clearCallSheetData={clearCallSheetData}
                    />
                  </div>
                  <div className="flex my-1 overflow-x-auto scrollbar-hide w-full gap-3">
                    <Button
                      className={`${
                        showTagButtons ? "shadow-inner shadow-slate-700" : ""
                      } ml-auto`}
                      onClick={handleShowTags}
                    >
                      Edit Tags
                    </Button>
                    <Button
                      className={`${
                        highlightFetch
                          ? "border-2 border-yellow-400 animate-pulse"
                          : ""
                      } ml-auto`}
                      onClick={() => getPlaybook()}
                    >
                      Load PB
                    </Button>
                    <Button
                      className={`${pbChanged ? "animate-pulse" : ""}`}
                      onClick={() => handleSavePlaybook()}
                    >
                      Save Playbook
                    </Button>
                  </div>
                </div>
                <AnimatePresence>
                  {showTagButtons && (
                    <motion.div
                      className="absolute w-[92vw] md:w-[28rem] top-20 pt-4 mt-1 text-black left-1 bg-slate-100 opacity-100 lg:bg-opacity-90"
                      initial={{ opacity: 0, scaleY: 0, y: -250 }}
                      animate={{ opacity: 1, scaleY: 1, y: -5 }}
                      exit={{ opacity: 0, scaleY: 0, y: -250 }}
                    >
                      <div className="flex w-full">
                        <div className="flex flex-col w-full gap-2">
                          <div className="w-full mx-auto">
                            <div className="border-b border-slate-900 w-fit px-3 mx-auto mb-1 font-Russo tracking-wide">
                              Hash
                            </div>
                            <div className="flex w-full gap-2 ml-2">
                              <Button
                                onClick={() => onButtonClick("L")}
                                className={`${
                                  selectedButtons.includes("L")
                                    ? "shadow-inner shadow-slate-800"
                                    : ""
                                } w-1/3 font-semibold px-2 py-1`}
                              >
                                L
                              </Button>
                              <Button
                                onClick={() => onButtonClick("M")}
                                className={`${
                                  selectedButtons.includes("M")
                                    ? "shadow-inner shadow-slate-800"
                                    : ""
                                } w-1/3 font-semibold px-2 py-1`}
                              >
                                M
                              </Button>
                              <Button
                                onClick={() => onButtonClick("R")}
                                className={`${
                                  selectedButtons.includes("R")
                                    ? "shadow-inner shadow-slate-800"
                                    : ""
                                } w-1/3 font-semibold px-2 py-1`}
                              >
                                R
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div className="w-full flex gap-2">
                          <div className="flex flex-col w-full">
                            <div className="border-b border-black w-fit mx-auto font-Russo tracking-wide">
                              Personnel
                            </div>
                            <Select
                              className="mt-1 mx-auto w-5/6"
                              allowClear
                              mode="tags"
                              onChange={(value) =>
                                onSelectChange(value, [
                                  "00p",
                                  "01p",
                                  "02p",
                                  "10p",
                                  "11p",
                                  "12p",
                                  "13p",
                                  "20p",
                                  "21p",
                                  "22p",
                                  "23p",
                                ])
                              }
                              options={[
                                {
                                  value: "00p",
                                  label: "00",
                                },
                                {
                                  value: "01p",
                                  label: "01",
                                },
                                {
                                  value: "02p",
                                  label: "02",
                                },
                                {
                                  value: "10p",
                                  label: "10",
                                },
                                {
                                  value: "11p",
                                  label: "11",
                                },
                                {
                                  value: "12p",
                                  label: "12",
                                },
                                {
                                  value: "13p",
                                  label: "13",
                                },
                                {
                                  value: "20p",
                                  label: "20",
                                },
                                {
                                  value: "21p",
                                  label: "21",
                                },
                                {
                                  value: "22p",
                                  label: "22",
                                },
                                {
                                  value: "23p",
                                  label: "23",
                                },
                              ]}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex h-22">
                        <div className="w-full justify-center align-middle flex flex-col">
                          <div className="mx-auto border-b border-black w-fit font-Russo tracking-wide">
                            Distance
                          </div>
                          <Select
                            className="mt-1 w-5/6 mx-auto"
                            allowClear
                            mode="tags"
                            onChange={(value) =>
                              onSelectChange(value, [
                                "",
                                "Short",
                                "Medium",
                                "Long",
                                "Long +",
                              ])
                            }
                            options={[
                              {
                                value: "",
                                label: "",
                              },
                              {
                                value: "Short",
                                label: "Short",
                              },
                              {
                                value: "Medium",
                                label: "Medium",
                              },
                              {
                                value: "Long",
                                label: "Long",
                              },
                              {
                                value: "Long +",
                                label: "Long +",
                              },
                            ]}
                          />
                        </div>
                      </div>

                      <div className="">
                        <div className="border-b border-slate-900 w-fit px-3 mt-2 mx-auto font-Russo tracking-wide">
                          Field Zones ?
                        </div>
                        <div className="flex flex-wrap justify-center gap-2 mt-1">
                          <div className="flex flex-wrap justify-center gap-2 mt-1">
                            {fieldZoneButtons.map((button) => (
                              <Button
                                key={button.name}
                                onClick={() => onButtonClick(button.name)}
                                className={`${
                                  selectedButtons.includes(button.name)
                                    ? "shadow-inner shadow-slate-800"
                                    : ""
                                } ${button.className} bg-opacity-60`}
                              >
                                {button.name}
                              </Button>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col mt-2">
                        <div className="border-b border-slate-900 w-fit px-3 mx-auto mb-1 font-Russo tracking-wide">
                          Situation
                        </div>
                        <div className="flex flex-wrap justify-center gap-2 mt-1 px-1">
                          {situationButtons.map((button) => (
                            <Button
                              key={button.name}
                              onClick={() => onButtonClick(button.name)}
                              className={`${
                                selectedButtons.includes(button.name)
                                  ? "shadow-inner shadow-slate-800"
                                  : ""
                              } ${button.className}`}
                            >
                              {button.name}
                            </Button>
                          ))}
                        </div>
                      </div>
                      <div className="flex flex-col mt-2 mb-2">
                        <div className="border-b border-slate-900 w-fit px-3 mx-auto mb-1 font-Russo tracking-wide">
                          Play Type
                        </div>
                        <div className="flex flex-wrap justify-center gap-2 mt-1">
                          {playTypeButtons.map((button) => {
                            return (
                              <Button
                                key={button.name}
                                onClick={() => onButtonClick(button.name)}
                                className={`${
                                  selectedButtons.includes(button.name)
                                    ? "shadow-inner shadow-slate-800"
                                    : ""
                                } ${button.className}`}
                              >
                                {button.name}
                              </Button>
                            );
                          })}
                        </div>
                      </div>
                      <div className="absolute flex w-full py-1 bg-slate-700 bg-opacity-90 rounded-b-md gap-2 px-4 shadow-sm shadow-slate-900">
                        <Button className="mr-auto" onClick={resetButtons}>
                          ResetButtons
                        </Button>
                        <Button onClick={removeTags}>Remove All Tags</Button>
                        <Button
                          className=""
                          type="primary"
                          onClick={addSelectedTags}
                        >
                          Add Tags
                        </Button>
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </div>
            <div className="col-span-2 h-fit">
              <div className="min-h-[32rem] w-full h-fit bg-white rounded-lg shadow-inner shadow-gray-800">
                <div className="relative mt-2 text-white flex gap-4 w-full overflow-x-scroll scrollbar-none px-2 py-1 bg-slate-200 rounded-t-md bg-opacity-30">
                  <Button onClick={() => setAddPlayModalOpen(true)}>
                    Add New Play
                  </Button>
                  <Button onClick={() => reassignWristInfo()}>
                    Reassign Numbers
                  </Button>
                  {/* <Button onClick={clearCallSheetData}>Clear Call Sheet</Button> */}
                  <Button
                    className={`${
                      showHiddenPlays ? "shadow-inner shadow-black" : ""
                    }`}
                    onClick={() => setShowHiddenPlays(!showHiddenPlays)}
                  >
                    Hidden Plays
                  </Button>
				  <Button
                    className="ml-auto"
                    onClick={() => setViewCallSheet(true)}
                  >
                    View Call Sheet <HiOutlineClipboardDocumentList />
                  </Button>
                  <Button
                    className=""
                    onClick={() => setShowPrint(true)}
                  >
                    View Wrist Band
                  </Button>
                  <Button
                    className="sticky right-0"
                    type="primary"
                    onClick={() => setGameMode(!gameMode)}
                  >
                    Game Mode
                  </Button>
                </div>
                {loading ? (
                  <Spin />
                ) : (
                  <Table
                    className="w-full h-full px-2 pb-2"
                    components={components}
                    pagination={{
                      showSizeChanger: true,
                      position: "bottom",
                      defaultPageSize: 100,
                    }}
                    size="small"
                    rowSelection={{
                      type: "checkbox",
                      ...rowSelection,
                    }}
                    rowClassName={(record) =>
                      record.hidden ? "opacity-30" : ""
                    }
                    scroll={{ x: "max-content", y: 570 }}
                    columns={wbColumns}
                    dataSource={
                      showHiddenPlays
                        ? data
                        : data.filter((play) => play.hidden !== true)
                    }
                    expandable={{
                      expandedRowKeys: expandedRow ? [expandedRow] : [],
                      onExpand: (expanded, record) => {
                        if (expanded) {
                          setExpandedRow(record.key);
                        } else {
                          setExpandedRow(null);
                        }
                      },
                      rowExpandable: (record) => true,
                      expandedRowRender: (record) => {
                        return (
                          <div className="w-[95%] h-fit max-w-[36rem] mt-1 aspect-video relative">
                            {record.image ? (
                              <>
                                <img
                                  className="h-fit border border-black rounded-sm"
                                  src={record.image}
                                />
                                <div>
                                  <Button
                                    className="ml-auto"
                                    danger
                                    onClick={() => deleteFile(record.imageName)}
                                  >
                                    Delete
                                  </Button>
                                </div>
                              </>
                            ) : (
                              <div className="h-full w-full flex">
                                {/* <div className="m-auto">Add an image</div> */}
                                <div className="w-full h-full rounded-xl shadow-sm shadow-slate-600 mx-auto">
                                  <ImgCrop aspect={21 / 9} rotationSlider>
                                    <Dragger {...uploadProps}>
                                      Click to add a picture
                                    </Dragger>
                                  </ImgCrop>
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      },
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}
