import { Tag, Tooltip } from "antd";
import { FaPlus, FaMinus } from "react-icons/fa";


export const defData = [
  {
    key: "Play",
    dataIndex: "Play",
    title: "Play",
    type: "number",
    width: 65,
    fixed: "left",
	shouldCellUpdate: (record, prevRecord) =>
		record.Play !== prevRecord.Play,
  },
  {
    key: "full-name",
    dataIndex: "full-name",
    title: "Name",
    type: "text",
    width: 150,
    ellipsis: {},
    fixed: "left",
    render: (name) => (
      <Tooltip placement="topLeft" title={name}>
        {name}
      </Tooltip>
    ),
	shouldCellUpdate: (record, prevRecord) =>
        record["full-name"] !== prevRecord["full-name"],
  },
  {
    key: "Pos",
    dataIndex: "position",
    title: "Pos",
    type: "text",
    width: 70,
    editable: true,
	shouldCellUpdate: (record, prevRecord) =>
		record.position !== prevRecord.position,
  },
  {
    key: "assignment",
    dataIndex: "assignment",
    title: "Assign",
    type: "text",
    width: 80,
	editable: true,
    render: (tag) => (
      <div className="w-fit h-fit m-auto">
        <Tag color={tag === "+" ? "green" : "volcano"} key={tag}>
          {tag === '+' ? <FaPlus className="my-1" /> : <FaMinus className="my-1" />}
        </Tag>
      </div>
    ),
	shouldCellUpdate: (record, prevRecord) =>
		record.assignment !== prevRecord.assignment,
  },
  /*Pos Production*/ {
    title: "Positive Production",
    key: "PP",
    editable: true,
    children: [
      {
        key: "TKL",
        dataIndex: "tackle",
        title: "TKL",
        type: "number",
        width: 65,
        editable: true,
		shouldCellUpdate: (record, prevRecord) =>
		record.tackle !== prevRecord.tackle,
      },
      {
        key: "SCK",
        dataIndex: "sack",
        title: "SCK",
        type: "number",
        width: 65,
        editable: true,
		shouldCellUpdate: (record, prevRecord) =>
		record.sack !== prevRecord.sack,
      },
      {
        key: "Assist",
        dataIndex: "assist",
        title: "ASST",
        type: "number",
        width: 70,
        editable: true,
		shouldCellUpdate: (record, prevRecord) =>
		record.assist !== prevRecord.assist,
      },
      {
        key: "TFL",
        dataIndex: "TFL",
        title: "TFL",
        type: "number",
        width: 65,
        editable: true,
		shouldCellUpdate: (record, prevRecord) =>
		record.TFL !== prevRecord.TFL,
      },
      {
        key: "Hurry",
        dataIndex: "hurry",
        title: "Hurry",
        type: "number",
        width: 70,
        editable: true,
		shouldCellUpdate: (record, prevRecord) =>
		record.hurry !== prevRecord.hurry,
      },
      {
        key: "INT",
        dataIndex: "interception",
        title: "INT",
        type: "number",
        width: 65,
        editable: true,
		shouldCellUpdate: (record, prevRecord) =>
		record.interception !== prevRecord.interception,
      },
      {
        key: "FF",
        dataIndex: "forced-fumble",
        title: "FF",
        type: "number",
        width: 65,
        editable: true,
		shouldCellUpdate: (record, prevRecord) =>
		record['forced-fumble'] !== prevRecord['forced-fumble'],
      },
      {
        key: "FumRec",
        dataIndex: "fumble-recovery",
        title: "FumRec",
        type: "number",
        width: 68,
        editable: true,
		shouldCellUpdate: (record, prevRecord) =>
		record['fumble-recovery'] !== prevRecord['fumble-recovery'],
      },
      {
        key: "TD",
        dataIndex: "touchdown",
        title: "TD",
        type: "number",
        width: 65,
        editable: true,
		shouldCellUpdate: (record, prevRecord) =>
		record.touchdown !== prevRecord.touchdown,
      },
      {
        key: "PBU",
        dataIndex: "pass-breakup",
        title: "PBU",
        type: "number",
        width: 65,
        editable: true,
		shouldCellUpdate: (record, prevRecord) =>
		record['pass-breakup'] !== prevRecord['pass-breakup'],
      },
    ],
  },
  /*Neg Production*/ {
    title: "Negative Production",
    key: "NP",
    editable: true,
    children: [
      {
        key: "MA",
        dataIndex: "missed-assignment",
        title: "MA",
        type: "number",
        width: 65,
        editable: true,
		shouldCellUpdate: (record, prevRecord) =>
		record['missed-assignment'] !== prevRecord['missed-assignment'],
      },
      {
        key: "CE",
        dataIndex: "critical-error",
        title: "CE",
        type: "number",
        width: 65,
        editable: true,
		shouldCellUpdate: (record, prevRecord) =>
		record['critical-error'] !== prevRecord['critical-error'],
      },
      {
        key: "MT",
        dataIndex: "missed-tackle",
        title: "MT",
        type: "number",
        width: 65,
        editable: true,
		shouldCellUpdate: (record, prevRecord) =>
		record['missed-tackle'] !== prevRecord['missed-tackle'],
      },
      {
        key: "Loaf",
        dataIndex: "loaf",
        title: "Loaf",
        type: "number",
        width: 65,
        editable: true,
		shouldCellUpdate: (record, prevRecord) =>
		record.loaf !== prevRecord.loaf,
      },
    ],
  },
  /*Comments*/ {
    key: "Comments",
    dataIndex: "coach-comments",
    title: "Comments",
    type: "text",
    // fixed: "right",
    width: 200,
    editable: true,
    ellipsis: {},
    render: (comments) => (
      <Tooltip placement="topLeft" title={comments}>
        {comments}
      </Tooltip>
    ),
	shouldCellUpdate: (record, prevRecord) =>
		record['coach-comments'] !== prevRecord['coach-comments'],
  },
];
