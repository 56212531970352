import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { Cascader } from "antd";
import { PathContext } from "../context/PathContext";
import { TeamContext } from "../context/TeamProvider";

function PlayerCascader(props) {
  const [profileData, setProfileData] = useState(null);
  const [playerOptions, setPlayerOptions] = useState([]);

  const { currentUser } = useContext(AuthContext);
  const { selectedPath } = useContext(PathContext);
  const { teamRosterData } =useContext(TeamContext)

  //     {
  //         key: '1',
  //         label: 'Defense',
  //         value: 'defense',
  //         children: [
  //           {
  //             key: '1-1',
  //             label: 'DL',
  //             value: 'DL',
  //             children: DL,
  //           },
  //           {
  //             key: '1-2',
  //             label: 'LB',
  //             value: 'LB',
  //             children: LB,
  //             },
  //             {
  //                 key: '1-3',
  //                 label: 'SAF',
  //                 value: 'SAF',
  //                 children: SAF,
  //             },
  //             {
  //                 key:'1-4',
  //                 label: 'CB',
  //                 value: 'CB',
  //                 children: CB,
  //             },
  //         ],
  //       },
  //     {
  //       label: 'Offense',
  //       value: 'offense',
  //       children: [
  //         {
  //           label: 'QB',
  //           value: 'QB',
  //           children: QB,
  //         },
  //         {
  //             label: 'OL',
  //             value: 'OL',
  //             children: OL,
  //           },
  //           {
  //             label: 'TE',
  //             value: 'TE',
  //             children: TE,
  //           },
  //           {
  //             label: 'RB',
  //             value: 'RB',
  //             children: RB,
  //           },
  //           {
  //             label: 'WR',
  //             value: 'WR',
  //             children: WR,
  //           },
  //       ],
  //     },
  //   ];

  const filter = (inputValue, path) =>
    path.some(
      (option) =>
        option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
    );

	useEffect(() => {
		const rosterPlayers = () => {
		  setProfileData(teamRosterData)
	  
		  // Helper function to filter and map players for a given position
		  const positionMapper = (position) => {
			const result = teamRosterData.filter(obj => (obj.position === position || obj.altPosition === position) && obj.scoutTeam !== 'Y');
			return result.map(person => ({
			  label: person['full-name'],
			  value: person['full-name'],
			  id: person['id'], // Add id if needed
			}));
		  };
	  
		  const playerOptions = [
			{
			  label: 'Defense',
			  value: 'defense',
			  children: [
				{ label: 'DL', value: 'DL', children: positionMapper('DL') },
				{ label: 'LB', value: 'LB', children: positionMapper('LB') },
				{ label: 'SAF', value: 'SAF', children: positionMapper('SAF') },
				{ label: 'CB', value: 'CB', children: positionMapper('CB') },
			  ],
			},
			{
			  label: 'Offense',
			  value: 'offense',
			  children: [
				{ label: 'QB', value: 'QB', children: positionMapper('QB') },
				{ label: 'OL', value: 'OL', children: positionMapper('OL') },
				{ label: 'TE', value: 'TE', children: positionMapper('TE') },
				{ label: 'RB', value: 'RB', children: positionMapper('RB') },
				{ label: 'WR', value: 'WR', children: positionMapper('WR') },
			  ],
			},
		  ];
	  
		  // Use playerOptions as needed
		  setPlayerOptions(playerOptions);
		};
	  
		rosterPlayers();
	  }, [selectedPath, teamRosterData]);
	  

  return (
    <Cascader
      className="sticky z-30 top-5 w-full h-fit mt-2 shadow-sm shadow-slate-800 rounded-lg"
      options={playerOptions}
      size="large"
      multiple
      showCheckedStrategy="SHOW_CHILD"
      showSearch={{ filter }}
      onChange={(e) => props.onPlayerValue({ e, profileData })}
      expandTrigger="hover"
      maxTagCount="responsive"
      placeholder="Select Players"
      status={null}
    />
  );
}

export default PlayerCascader;
