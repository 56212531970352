import React, { useEffect, useContext, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { PathContext } from "../context/PathContext";
import { db, storage } from "../Firebase-config";
import { addDoc, collection } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { Button, ConfigProvider, Modal } from "antd";
import { openNotificationWithIcon } from "../helperFunctions/openNotificationWithIcon";
import Dragger from "antd/es/upload/Dragger";
import ImgCrop from "antd-img-crop";

export const AddPlayerModal = ({
  inputs,
  title,
  handleClose,
  addProfileModalOpen,
}) => {
  const [data, setData] = useState();

  const { currentUser } = useContext(AuthContext);
  const { selectedPath } = useContext(PathContext);

  const addInputsToRoster = (e) => {
    const id = e.target.id;
    const value = e.target.value;

    console.log(data);

    setData({ ...data, [id]: value, userID: currentUser.uid });
  };

  useEffect(() => {
    const defaultProfilePictureUrl =
      "https://firebasestorage.googleapis.com/v0/b/gradeapp-fddad.appspot.com/o/LeonardoProfilePlaceholder.jpg?alt=media&token=274d0788-2cea-4b54-aba6-2571e51717bd";
    setData((prev) => ({ ...prev, img: defaultProfilePictureUrl }));
  }, [addProfileModalOpen]);

  const addToRoster = async (e) => {
    e.preventDefault();
    if (data && data.activePlayer) {
      try {
        await addDoc(collection(db, "Teams", selectedPath, "PlayerProfiles"), {
          ...data,
          teamId: selectedPath,
        });
      } catch (err) {
        console.log(err);
      }
    } else if (data["full-name"] && data.position) {
      data.activePlayer = "Active";
      try {
        await addDoc(collection(db, "Teams", selectedPath, "PlayerProfiles"), {
          ...data,
          teamId: selectedPath,
        });
        openNotificationWithIcon("success", "Player Added", "", "top");
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleAddPlayer = async (e) => {
    await addToRoster(e);
    handleClose();
  };

  const uploadProps = {
    name: "file",
    multiple: false, // Set to true for multiple file uploads
    customRequest: async ({ file, onSuccess, onError }) => {
      const storageRef = ref(
        storage,
        `${selectedPath}/PlayerPics/${file.name}`
      );

      try {
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Handle upload progress
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log(`Upload is ${progress}% done`);
          },
          onError,
          async () => {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            onSuccess(downloadURL, file); // Success callback with downloadURL

            setData((prev) => ({ ...prev, img: downloadURL }));
          }
        );
      } catch (error) {
        onError(error);
      }
    },

    onSuccess(response) {
      console.log("File uploaded successfully:", response);
      // Update your data state with the downloadURL from response
    },
    onError(error) {
      console.error("Upload failed:", error);
    },
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Modal: {
            /* here is your component tokens */
            contentBg: "opacity-0",
          },
        },
      }}
    >
      <Modal
        className="h-screen w-fit pt-[1%]"
        classNames={{ body: "-mx-6 md:-mx-8 backdrop-blur-md" }}
        centered
        width={1200}
        open={addProfileModalOpen}
        onOk={handleClose}
        onCancel={handleClose}
        footer={[
          <Button key="back" onClick={handleClose}>
            Cancel
          </Button>,
          <Button
            className="shadow-gray-700 shadow-sm"
			type="primary"
            key="submit"
            onClick={(e) => {
              handleAddPlayer(e);
            }}
          >
            Add Player
          </Button>,
        ]}
      >
        <div className="max-h-[87vh] scrollbar-hide overflow-y-auto border-8 shadow-sm shadow-black md:border-4 border-slate-950 bg-opacity-30 backdrop-blur-md bg-slate-950 p-2 mt-2 rounded-lg">
          <div>
            <h1 className="font-bold mt-6 text-3xl text-center">
              <div className="h-fit w-fit text-white mx-auto px-4">
                {title}
              </div>
            </h1>
          </div>
          <form
            id="addPlayerForm"
            onSubmit={addToRoster}
            className="relative w-full flex flex-row flex-wrap sm:w-[95%] h-fit lg:h-full mx-auto my-auto "
          >
            <div className="static md:sticky top-0 flex flex-col headshot-upload h-fit my-10 md:h-[80%] w-full md:w-1/3">
              {data &&
              data?.img !==
                "https://firebasestorage.googleapis.com/v0/b/gradeapp-fddad.appspot.com/o/LeonardoProfilePlaceholder.jpg?alt=media&token=274d0788-2cea-4b54-aba6-2571e51717bd" ? (
                <div className="w-fit h-fit mx-auto rounded-xl bg-white shadow-sm shadow-slate-900">
                  <img className="w-64 h-64 rounded-xl" src={data.img} />
                </div>
              ) : (
                <ConfigProvider
                  theme={{
                    components: {
                      Modal: {
                        /* here is your component tokens */
						contentBg: 'white',
                      },
                    },
                  }}
                >
                  <div className="w-64 h-40 rounded-xl bg-white shadow-sm shadow-slate-900 mx-auto">
                    <ImgCrop aspect={1} rotationSlider>
                      <Dragger {...uploadProps}>Click to add a picture</Dragger>
                    </ImgCrop>
                  </div>
                </ConfigProvider>
              )}
            </div>
            <div className="flex overflow-y-auto flex-row items-center flex-wrap text-black h-fit self-start p-2 w-full md:w-2/3">
              {inputs.map((input) => (
                <div
                  className="flex flex-col w-fit rounded-sm h-12 m-1 mt-3"
                  key={input.id}
                >
                  <label className="z-20 w-fit rounded-sm shadow-slate-800 shadow-sm text-center px-2 bg-[#f1efe9] font-bold">
                    {input.label}:
                  </label>
                  {input.type === "select" ? (
                    <select
                      className="pl-2 w-64 placeholder:text-black placeholder:opacity-50 shadow-slate-600 shadow-sm rounded-sm"
                      id={input.id}
                      onChange={addInputsToRoster}
                    >
                      <option disabled value="">
                        {input.placeholder}
                      </option>
                      {input.options.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <input
                      className="pl-2 w-64 placeholder:text-black placeholder:opacity-50 shadow-slate-600 shadow-sm rounded-sm"
                      id={input.id}
                      type={input.type}
                      placeholder={input.placeholder}
                      onChange={addInputsToRoster}
                    />
                  )}
                </div>
              ))}
            </div>
          </form>
        </div>
      </Modal>
    </ConfigProvider>
  );
};
