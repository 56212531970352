import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { PathContext } from "../context/PathContext";
import { motion } from "framer-motion";
import { Table, Spin, Cascader, Tag, Progress, Button } from "antd";
import { db } from "../Firebase-config";
import {
  where,
  documentId,
  collection,
  getDocs,
  query,
} from "firebase/firestore";
import MultiDataTitleCascader from "../components/MultiDataTitleCascader ";
import { openNotificationWithIcon } from "../helperFunctions/openNotificationWithIcon";
import { defData } from "../PositionColumns/DefensiveStatColumns";
import {
  offData,
  qbData,
  olData,
  teData,
  rbData,
  wrData,
} from "../PositionColumns/OffensiveStatColumns";
import { LogoPageHeader } from "../components/LogoPageHeader";
import { FaMinus, FaPlus } from "react-icons/fa";
import { HeaderScrollVisibility } from "../hooks/HeaderScrollVisibility";

export function SeasonSelect() {
  const [gradeTitle, setGradeTitle] = useState();
  const [selectedPlayData, setSelectedPlayData] = useState([]);
  const [selectedPlayerData, setSelectedPlayerData] = useState([]);
  const [fullPlayerData, setFullPlayerData] = useState([]);
  const [selectedColDefs, setSelectedColDefs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [expandedRow, setExpandedRow] = useState(null);

  const { currentUser } = useContext(AuthContext);
  const { selectedPath } = useContext(PathContext);

  const updateGradeTitle = (title) => {
    setGradeTitle(title);
    console.log("Grade", title);
  };

  const clearData = () => {
    setSelectedColDefs([]);
    setSelectedPlayData([]);
    setCurrentDataSource([]);
    setShowScores(false);
  };

  useEffect(() => {
    clearData();
  }, [selectedPath]);

  const collectionPath = collection(db, "Teams", selectedPath, "EventData");
  const batches = [];
  const pbatches = [];

  const getData = async () => {
    if (!gradeTitle) {
      openNotificationWithIcon("warning", "Select an Event", "", "top");
      return;
    }
    setLoading(true); // set loading to true befre data has been retrieved
    const gradeLabel = [...gradeTitle];

    while (gradeLabel.length) {
      // firestore limits batches to 10
      const batch = gradeLabel.splice(0, 10);
      // turn each array into a string to correctly fetch each file
      const textBatch = batch.map((array) => array.join() + " PlayData");

      // add the batch request to to a queue
      batches.push(
        (async () => {
          const results = await getDocs(
            query(collectionPath, where(documentId(), "in", [...textBatch]))
          );
          return results.docs.map((result) => ({
            id: result.id,
            ...result.data(),
          }));
        })()
      );
    }

    // after all of the data is fetched, return it
    const content = await Promise.all(batches);
    const flattenedData = content.flat();

    console.log(flattenedData);
    const columnHeaders = flattenedData[0].colDefs;
    // Check if columnHeaders contains an object with the key "Play"
    const hasPlayColumn = columnHeaders.some(
      (header) => header.dataIndex === "Play"
    );

    // If "Play" column doesn't exist, add it to the beginning of the array
    if (!hasPlayColumn) {
      columnHeaders.unshift({
        dataIndex: "Play",
        key: "Play",
        title: "Play",
      });
    }
    const playData = flattenedData.map((data) => data.dataExcel);
    const combinedPlayData = playData.flat();
    console.log("colHeaders:", columnHeaders);
    console.log("combindData:", combinedPlayData);

    const columnsWithFilters = columnHeaders.map((header) => {
      const key = header.key;
      const filters = [...new Set(combinedPlayData.map((data) => data[key]))]
        .filter((value) => value !== null && value !== undefined) // Remove falsy values
        .map((value) => ({
          text: value,
          value: value,
        }))
        .sort((a, b) => {
          if (!isNaN(a.text) && !isNaN(b.text)) {
            // Compare numbers directly
            return parseFloat(a.text) - parseFloat(b.text);
          }
          // Compare strings alphabetically
          return a.text.toString().localeCompare(b.text.toString());
        });

      return {
        key: key,
        dataIndex: header.dataIndex,
        title: header.title,
        width: 40,
        filters: filters,
        filterMode: "menu",
        filterSearch: true,
        onFilter: (value, record) => {
          const recordValue = record[key];
          if (typeof recordValue === "number") {
            // Exact match for numbers
            return recordValue === parseInt(value);
          } else if (typeof recordValue === "string") {
            // Substring match for strings
            return recordValue.includes(value);
          }
          return false; // Handle other types if needed
        },
      };
    });

    console.log("NEW", columnsWithFilters);

    setSelectedColDefs(columnsWithFilters);
    console.log("SCD", selectedColDefs);
    setSelectedPlayData(combinedPlayData);
    setCurrentDataSource(combinedPlayData);
    setLoading(false); // set loading to false after data has been retrieved
  };

  const getPlayerData = async () => {
    if (!gradeTitle) {
      return;
    }
    const gradeLabel = [...gradeTitle];

    while (gradeLabel.length) {
      // firestore limits batches to 10
      const batch = gradeLabel.splice(0, 10);
      // turn each array into a string to correctly fetch each file
      const textBatch = batch.map((array) => array.join() + " PlayerData");

      // add the batch request to to a queue
      pbatches.push(
        (async () => {
          const results = await getDocs(
            query(collectionPath, where(documentId(), "in", [...textBatch]))
          );
          return results.docs.map((result) => ({
            id: result.id,
            ...result.data(),
          }));
        })()
      );
    }

    // after all of the data is fetched, return it
    const content = await Promise.all(pbatches);
    console.log(content);
    const flattenedData = content.flat();

    console.log(flattenedData);
    const playData = flattenedData.map((data) => data.playerData);
    const combinedPlayerData = playData.flat();
    console.log("CPD", combinedPlayerData);

    setSelectedPlayerData(combinedPlayerData);
    setFullPlayerData(combinedPlayerData);
  };

  const [playerCols, setPlayerCols] = useState(null);

  const colOptions = [
    {
      key: "1",
      label: "Defense",
      value: "defense",
    },
    {
      label: "Offense",
      value: "offense",
      children: [
        {
          label: "QB",
          value: "QB",
        },
        {
          label: "OL",
          value: "OL",
        },
        {
          label: "TE",
          value: "TE",
        },
        {
          label: "RB",
          value: "RB",
        },
        {
          label: "WR",
          value: "WR",
        },
      ],
    },
  ];

  const columnTitle = (title) => {
    // Assuming title is an array with two elements, e.g., ['defense', 'DL']
    if (title[0] === "defense") {
      const filteredPlayers = fullPlayerData.filter((player) =>
        ["DL", "LB", "SAF", "CB"].includes(player.position)
      );
      setPlayerCols([...defData]);
      setSelectedPlayerData([...filteredPlayers]);
    } else if (title[0] === "offense" && title[1] === undefined) {
      const filteredPlayers = fullPlayerData.filter((player) =>
        ["QB", "OL", "TE", "WR", "RB"].includes(player.position)
      );
      setPlayerCols([...offData]);
      setSelectedPlayerData([...filteredPlayers]);
    } else if (title[1] === "QB") {
      const filteredPlayers = fullPlayerData.filter(
        (player) => player.position === "QB"
      );
      setPlayerCols([...qbData]);
      setSelectedPlayerData([...filteredPlayers]);
    } else if (title[1] === "OL") {
      const filteredPlayers = fullPlayerData.filter(
        (player) => player.position === "OL"
      );
      setPlayerCols([...olData]);
      setSelectedPlayerData([...filteredPlayers]);
    } else if (title[1] === "TE") {
      const filteredPlayers = fullPlayerData.filter(
        (player) => player.position === "TE"
      );
      setPlayerCols([...teData]);
      setSelectedPlayerData([...filteredPlayers]);
    } else if (title[1] === "RB") {
      const filteredPlayers = fullPlayerData.filter(
        (player) => player.position === "RB"
      );
      setPlayerCols([...rbData]);
      setSelectedPlayerData([...filteredPlayers]);
    } else if (title[1] === "WR") {
      const filteredPlayers = fullPlayerData.filter(
        (player) => player.position === "WR"
      );
      setPlayerCols([...wrData]);
      setSelectedPlayerData([...filteredPlayers]);
    } else {
      // Handle the case when title doesn't match any expected values
      setPlayerCols(null);
      setSelectedPlayerData([...fullPlayerData]); // Reset to original data
    }
  };

  const calculateAssignmentTotals = (playerData) => {
    let plusCount = 0;
    let minusCount = 0;

    playerData.forEach((player) => {
      plusCount += player.assignment === "+" ? 1 : 0;
      minusCount += player.assignment === "-" ? 1 : 0;
    });

    return { plusCount, minusCount };
  };

  const [currentDataSource, setCurrentDataSource] = useState();
  const [showScores, setShowScores] = useState(false);
  const [overallGrade, setOverallGrade] = useState();

  const TableTotals = ({ currentDataSource }) => {
    if (!currentDataSource) {
      return;
    }
    // Function to calculate assignment totals for each unique play
    const calculateTotalsByPlay = (filteredPlayerData) => {
      const totalsByPlay = {};
      console.log(filteredPlayerData);

      filteredPlayerData.forEach((player) => {
        const play = player.Play;
        const numberOnly =
          typeof play !== "string"
            ? player.Play
            : parseInt(play.match(/\d+/)[0]);
        const gameID = player.gameID;

        const key = `${play}-${gameID}`;
        if (!totalsByPlay[key]) {
          totalsByPlay[key] = { plusCount: 0, minusCount: 0, Play: "" };
        }

        totalsByPlay[key].plusCount += player.assignment === "+" ? 1 : 0;
        totalsByPlay[key].minusCount += player.assignment === "-" ? 1 : 0;
        totalsByPlay[key].Play = numberOnly;
      });

      return totalsByPlay;
    };

    // Filter playerData based on unique plays in currentDataSource
    const filteredPlayerData = selectedPlayerData.filter((player) =>
      currentDataSource.some(
        (record) =>
          record.Play === player.Play && record.gameID === player.gameID
      )
    );

    // Calculate assignment totals for each unique play
    const totalsByPlay = calculateTotalsByPlay(filteredPlayerData);
    console.log("TBP", totalsByPlay);

    // Track unique gameID values
    const uniqueGameIDs = Array.from(
      new Set(Object.keys(totalsByPlay).map((key) => key.split("-")[1]))
    );

    // Calculate overall average and percentages
    const percentageAverages = Object.keys(totalsByPlay).map((key) => {
      const { plusCount, minusCount } = totalsByPlay[key];
      const totalAssignments = plusCount + minusCount;
      return totalAssignments
        ? Math.round((plusCount / totalAssignments) * 100)
        : 0;
    });

    const overallAverage = percentageAverages.length
      ? Math.round(
          percentageAverages.reduce((sum, percentage) => sum + percentage, 0) /
            percentageAverages.length
        )
      : 0;

    setOverallGrade(overallAverage);

    return (
      <div className="h-fit max-h-[75vh] max-w-[60vw] overflow-auto transition-all">
        <div className="flex h-full w-fit">
          {/* Display tables for each gameID */}
          {uniqueGameIDs.map((uniqueGameID) => {
            // Prepare data for the current table
            const dataSource = [];
            const plays = Object.keys(totalsByPlay).filter((key) =>
              key.endsWith(`-${uniqueGameID}`)
            );

            plays.forEach((key) => {
              const { plusCount, minusCount, Play } = totalsByPlay[key];
              const totalAssignments = plusCount + minusCount;
              const percentage = totalAssignments
                ? Math.round((plusCount / totalAssignments) * 100)
                : 0;
              const play = Play;
              dataSource.push({
                key,
                gameID: uniqueGameID,
                play,
                assignment:
                  totalAssignments > 0
                    ? plusCount > minusCount
                      ? "+"
                      : "-"
                    : "",
                plusCount,
                minusCount,
                percentage,
              });
            });

            // Define columns for Ant Design table
            const columns = [
              {
                title: "Play",
                dataIndex: "play",
                key: "play",
                render: (text) => (
                  <span className="w-16 text-right mr-2">{text}</span>
                ),
              },
              {
                title: (
                  <div>
                    <FaPlus className="scale-90 mr-2" />
                  </div>
                ),
                dataIndex: "plusCount",
                key: "plusCount",
              },
              {
                title: (
                  <div>
                    <FaMinus className="scale-90 mr-2" />
                  </div>
                ),
                dataIndex: "minusCount",
                key: "minusCount",
              },
              {
                title: "%",
                dataIndex: "percentage",
                key: "percentage",
                render: (tag) => (
                  <div className="w-9 h-fit m-auto">
                    <Tag
                      className="w-full text-center"
                      color={
                        tag < 70 ? "volcano" : tag < 85 ? "warning" : "green"
                      }
                      key={tag}
                    >
                      {tag}
                    </Tag>
                  </div>
                ),
              },
            ];

            return (
              <div
                className="relative z-50 flex h-fit w-fit overflow-y-auto max-h-[65vh] m-1 mx-2 bg-slate-300 scrollbar-thumb-white scrollbar-thumb-rounded-lg scrollbar-thin scrollbar-track-transparent"
                key={uniqueGameID}
              >
                <p
                  style={{ writingMode: "vertical-lr" }}
                  className="sticky top-0 rotate-180 text-center h-full w-fit shadow-sm shdow-black bg-slate-200 font-semibold"
                >{`${uniqueGameID}`}</p>
                <div className="w-fit h-fit">
                  <Table
                    size="small"
                    className="w-fit"
                    columns={columns}
                    dataSource={dataSource}
                    bordered
                    pagination={false}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const onChange = (pagination, filters, sorter, extra) => {
    if (extra.action === "filter") {
      setCurrentDataSource(extra.currentDataSource);
    }
    console.log("params", pagination, filters, sorter, extra);
  };

  const scrollableDivRef = HeaderScrollVisibility();

  const conicColors = {
    "0%": "#FE7353",
    "60%": "#E2BA14",
    "100%": "#8DF25D",
  };

  return (
    <motion.div
      className="relative h-screen w-screen overflow-x-hidden overflow-y-auto"
      initial={{
        y: -500,
      }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: 1000, opacity: 0 }}
      ref={scrollableDivRef}
    >
      <div className="sticky z-50 top-0">
        <div
          className={`absolute right-0 transition-all top-[10vh] w-fit h-fit min-h-96 max-h-[80vh] bg-slate-100 shadow-sm shadow-slate-800 rounded-l-md p-2 ${
            showScores ? "" : "translate-x-full"
          }`}
        >
          <button
            onClick={() => setShowScores(!showScores)}
            className="absolute z-20 border border-gray-500 border-r-transparent -top-[1px] -left-5 font-extrabold text-2xl bg-slate-100 text-center rounded-l-lg w-fit h-12 py-1"
          >
            <span className="p-1 m-auto">{showScores ? ">" : "<"}</span>
          </button>
          <TableTotals currentDataSource={currentDataSource} />
        </div>
      </div>

      <LogoPageHeader title={"Team Stats"} />

      <div className=" bg-slate-100 bg-opacity-20 rounded-md rounded-t-none lg:rounded-t-lg py-2 mb-24 mx-[1%]">
        <div className="flex w-fit mx-auto">
          <div className="flex flex-col pt-3 pl-4 pb-4 mr-2">
            <MultiDataTitleCascader updateTitle={updateGradeTitle} />
            <Cascader
              className="w-[90%] mt-2 h-fit rounded-lg shadow-black shadow-sm"
              size="large"
              options={colOptions}
              allowClear={false}
              onChange={(title) => {
                columnTitle(title);
              }}
              expandTrigger="hover"
              maxTagCount="responsive"
              placeholder="Select Position Columns"
              changeOnSelect
              // status={columnFilterError}
            />
          </div>

          <div className="flex flex-col sm:flex-row justify-center align-middle h-fit w-fit p-2 my-auto rounded-lg bg-slate-200">
            <Button
              className="mx-2 font-bold my-1 w-fit px-4 self-center bg-slate-100 shadow-black shadow-sm p-1 rounded-md"
              onClick={() => {
                getData();
                getPlayerData();
              }}
            >
              Update
            </Button>
            <Button
              className="mx-2 font-bold my-1 w-fit px-4 self-center bg-slate-100 shadow-black shadow-sm p-1 rounded-md"
              onClick={clearData}
            >
              Clear
            </Button>
          </div>
        </div>

        <div className="relative mt-16 md:mt-2 mx-auto sm:mx-auto p-1 border-2 rounded-lg shadow-inner h-fit w-full sm:w-[98%] bg-slate-100">
          {/* Display the overall average outside the table */}
          <div className="absolute bottom-full left-5 w-fit pr-24">
            <div className="bg-white shadow-inner shadow-slate-800 p-2 mb-[2px] rounded-t-md">
              <p className="font-Kanit font-semibold">{`Overall Average:`}</p>
              <Progress
                size={[200, 15]}
                percent={overallGrade}
                strokeColor={conicColors}
              />
            </div>
          </div>
          <div className="w-full z-20 scrollbar-hide">
            {loading ? ( // render the loading animation while loading is true
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "300px",
                }}
              >
                <Spin size="large" />
              </div>
            ) : (
              <>
                <Table
                  className="relative w-full sm:w-[96vw] md:w-[93.5vw] mx-auto"
                  onChange={onChange}
                  virtualized
                  size="small"
                  pagination={{ showSizeChanger: true, position: "bottom" }}
                  columns={selectedColDefs}
                  dataSource={selectedPlayData}
                  rowKey={(record) => `${record.Play}-${record.gameID}`}
                  scroll={{ x: "max-content" }}
                  expandable={{
                    expandRowByClick: true,
                    expandedRowKeys: expandedRow ? [expandedRow] : [],
                    onExpand: (expanded, record) => {
                      if (expanded) {
                        setExpandedRow(`${record.Play}-${record.gameID}`);
                      } else {
                        setExpandedRow(null);
                      }
                    },
                    rowExpandable: (record) => true,
                    expandedRowRender: (record) => {
                      if (0 != null) {
                        const playerData = selectedPlayerData.filter(
                          (player) =>
                            player.Play === record.Play &&
                            player.gameID === record.gameID
                        );

                        // Calculate assignment totals
                        const { plusCount, minusCount } =
                          calculateAssignmentTotals(playerData);

                        return (
                          <div className="sticky left-2 w-[87vw] md:w-[91vw] h-fit">
                            {/* Render your player data table */}
                            <Table
                              className=""
                              bordered
                              rowKey={(data) => data["full-name"]}
                              scroll={{ x: "max-content" }}
							  size="small"
							  pagination={{ pageSize: 11  }}
                              columns={playerCols}
                              dataSource={playerData}
                            />

                            {/* Display assignment totals */}
                            <div>
                              <p>Total '+' Assignments: {plusCount}</p>
                              <p>Total '-' Assignments: {minusCount}</p>
                            </div>
                          </div>
                        );
                      } else {
                        // setColumnFilterError('error')
                        // setTimeout(()=>{
                        //   setColumnFilterError('')
                        //   }, 4000)
                        return (
                          <>
                            <p className="text-center bg-red-300 font-bold border border-2-black p-auto p-1">
                              Update 'Position Columns' above to show player
                              data!
                            </p>

                            <Table
                              bordered
                              rowKey={(data) => data}
                              //   scroll={{ x: '100%' }} // Adjust the scroll width to 100%
                              columns={null}
                              dataSource={null}
                            />
                          </>
                        );
                      }
                    },
                  }}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </motion.div>
  );
}
