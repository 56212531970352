import React, { useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { AiOutlineUserAdd } from "react-icons/ai";
import { Roster } from "../components/Roster";
import { useState } from "react";
import { AddPlayerModal } from "../components/AddPlayerModal";
import { UserInputs } from "../SourceData/UserData";
import { RosterPieChart } from "../components/RosterPieChart";
import { RosterInActive } from "../components/RosterInActive";
import { LogoPageHeader } from "../components/LogoPageHeader";
import { TbTableExport, TbTableImport } from "react-icons/tb";
import { HeaderScrollVisibility } from "../hooks/HeaderScrollVisibility";
import { ImportRoster } from "../components/ImportRoster";
import { AddYear } from "../components/AddYear";
import { Button } from "antd";

export function RosterEditor() {
  const [addProfileModalOpen, setAddProfileModalOpen] = useState(false);
  const [filter, setFilter] = useState(null);
  const [rosterData, setRosterData] = useState([]);
  const [showAddYear, setShowAddYear] = useState(false);
  const [showAlt, setShowAlt] = useState(true);
  const [showRosterImport, setShowRosterImport] = useState(false);

  const [active, toggleRoster] = useState(true);

  const addClose = () => setAddProfileModalOpen(false);
  const addOpen = () => setAddProfileModalOpen(true);
  const addImportClose = () => setShowRosterImport(false);
  const addImportOpen = () => setShowRosterImport(true);

  const handleExportExcel = () => {
    var XLSX = require("xlsx");
    // Specify columns to ignore
    const columnsToIgnore = [
      "id",
      "userID",
      "teamId",
      "isEditing",
      "img",
      "activePlayer",
    ];

    // Create a new array of objects excluding ignored columns
    const filteredRoster = rosterData.map((item) => {
      const filteredItem = { ...item };

      columnsToIgnore.forEach((column) => {
        delete filteredItem[column];
      });

      return filteredItem;
    });

    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Add a worksheet with the filtered data
    const ws = XLSX.utils.json_to_sheet(filteredRoster);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "RosterData");

    // Save the workbook as an Excel file
    XLSX.writeFile(wb, "EvaluXRosterData.xlsx");
  };

  const scrollableDivRef = HeaderScrollVisibility();

  const [showPie, setShowPie] = useState(true);

  return (
    <motion.div
      initial={{ y: -500 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: 1000, opacity: 0 }}
      className="h-screen w-screen scroll-smooth overflow-y-auto overflow-x-hidden"
      ref={scrollableDivRef}
    >
      <LogoPageHeader title={"Roster Editor"} />

      <ImportRoster
        showRosterImport={showRosterImport}
        handleClose={() => addImportClose()}
      />
      <AddYear
        showAddYear={showAddYear}
        handleClose={() => setShowAddYear(false)}
        rosterInfo={rosterData}
      />

      <div className="h-fit relative mb-10 min-h-[35rem] w-[99%] mx-auto flex flex-col lg:flex-row bg-slate-100 bg-opacity-20 rounded-lg rounded-t-none sm:rounded-t-lg">
        <AnimatePresence mode="sync">
          {showPie && (
            <motion.div
              initial={{ x: -500 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -500, opacity: 0 }}
              transition={{ type: "spring", bounce: 0.25, duration: 0.5 }}
              key={"PieChart"}
              className="mx-auto z-10 sticky md:static lg:sticky top-16 mb-10 sm:mb-4 lg:pl-4 scrollbar-hide lg:w-[46%] w-[98%] h-fit"
            >
              <div className="z-50 mt-4 sm:mx-auto md:w-[95%] lg:w-full h-64 md:h-80 bg-slate-100 rounded-xl shadow-md">
                <RosterPieChart
                  rosterData={rosterData}
                  onFilterChange={(handleFilterChange) =>
                    setFilter(handleFilterChange)
                  }
                />
              </div>
              <div className="mt-10 flex flex-col py-3 w-full mx-auto md:w-[95%] lg:w-[90%] bg-slate-200 rounded-lg shadow-sm shadow-slate-800">
                <div className="flex flex-col mb-2">
                  <div className="w-fit mx-auto font-Russo tracking-wider">
                    Controls
                  </div>
                  <div className="font-semibold w-1/2 mx-auto border-b border-black"></div>
                </div>
                <div className="grid grid-cols-3 gap-1 px-2 w-full">
                  <Button
                    className="w-full"
                    onClick={() => handleExportExcel()}
                  >
                    <div className="flex w-fit mx-auto">
                      Export <TbTableExport className="mt-1 ml-2" />
                    </div>
                  </Button>
                  <Button className="w-full" onClick={() => addImportOpen()}>
                    <div className="flex w-fit mx-auto">
                      Import <TbTableImport className="mt-1 ml-2" />
                    </div>
                  </Button>
                  <Button
                    className="w-full"
                    onClick={() => setShowAddYear(true)}
                  >
                    <span className="flex m-auto">+1 Year</span>
                  </Button>
                  <Button
                    className={`w-full shadow-slate-700 ${
                      showAlt ? "" : "shadow-inner"
                    } rounded-md`}
                    onClick={() => setShowAlt(!showAlt)}
                  >
                    <span className="flex m-auto">Alt Position</span>
                  </Button>
                </div>
              </div>
            </motion.div>
          )}
          <div className="realtive z-40  w-full md:min-w-[70%] mx-auto h-fit">
            {active && (
              <motion.div
                key={"ActiveRoster"}
                className="transition-all z-40 h-full pb-6 scroll-smooth overflow-y-scroll scrollbar-hide lg:w-full"
              >
                <AddPlayerModal
                  inputs={UserInputs}
                  title="Add To Roster"
                  addProfileModalOpen={addProfileModalOpen}
                  handleClose={addClose}
                />
                <div className="flex w-full">
                  <div className=" ml-[5%] left-0 bottom-full ">
                    <button
                      whileHover={{ scale: 0.9 }}
                      whileTap={{ scale: 1.1 }}
                      onClick={() => setShowPie(!showPie)}
                      className="add-profile text-sm flex mt-3 h-fit w-fit min-w-fit p-1 transition-all hover:bg-white bg-[#f1efe9] rounded-xl rounded-b-none font-bold shadow-gray-700 shadow-sm"
                    >
                      {showPie ? "Hide" : "Show"}
                    </button>
                  </div>
                  <div className="flex h-fit w-fit ml-auto mr-[10%]">
                    <button
                      onClick={() =>
                        addProfileModalOpen ? addClose() : addOpen()
                      }
                      className="add-profile flex mt-2 h-fit w-fit min-w-fit p-1 transition-all hover:bg-white bg-[#f1efe9] rounded-xl rounded-b-none font-bold shadow-gray-700 shadow-sm"
                    >
                      <AiOutlineUserAdd className="ml-1 scale-150 my-auto" />
                      <span className="mx-2">Add To Roster</span>
                    </button>

                    <button
                      className="flex transition-all hover:bg-green-100 align-end w-fit min-w-fit ml-6 mt-2 p-1 bg-green-200 rounded-xl rounded-b-none font-bold shadow-gray-700 shadow-sm"
                      onClick={() => toggleRoster((active) => !active)}
                    >
                      Active Roster
                    </button>
                  </div>
                </div>
                <div className="h-fit min-h-[36rem] mx-auto w-[98%] sm:w-[95%] bg-white rounded-lg shadow-inner shadow-gray-800">
                  <Roster
                    onChartData={(data) => setRosterData(data)}
                    rosterFilter={filter}
                    setShowAddYear={setShowAddYear}
                    showAddYear={showAddYear}
                    showAlt={showAlt}
                    showRosterImport={showRosterImport}
                  />
                </div>
              </motion.div>
            )}
            {!active && (
              <div className="transition-all z-40 h-full pb-6 scroll-smooth overflow-y-scroll scrollbar-hide lg:w-full">
                <motion.button
                  whileHover={{ scale: 0.9 }}
                  whileTap={{ scale: 1.1 }}
                  className="flex align-end w-fit min-w-fit ml-auto mr-[10%] mt-2 p-1 bg-orange-200 rounded-xl rounded-b-none font-bold shadow-gray-700 shadow-sm"
                  onClick={() => toggleRoster((active) => !active)}
                >
                  Inactive Roster
                </motion.button>
                <div className="h-fit min-h-[36rem] mx-auto w-[98%] sm:w-[95%] bg-white rounded-lg shadow-inner shadow-gray-800">
                  <RosterInActive
                    onChartData={(data) => setRosterData(data)}
                    rosterFilter={filter}
                  />
                </div>
              </div>
            )}
          </div>
        </AnimatePresence>
      </div>
    </motion.div>
  );
}
