import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { PathContext } from "../context/PathContext";
import { Cascader, Button, Input, Popconfirm, Space } from "antd";
import { motion } from "framer-motion";
import { db } from "../Firebase-config";
import { doc, getDoc, writeBatch } from "firebase/firestore";
import { AnimatePresence } from "framer-motion";

function MyTeamDataTitleCascader(props) {
  const [dataTitle, setDataTitle] = useState([]);
  const [optionBar, setOptionBar] = useState(false);
  const [dataDocTitle, setDataDocTitle] = useState([]);
  const [cascaderDisabled, setCascaderDisabled] = useState(false);

  const { currentUser } = useContext(AuthContext);
  const { selectedPath } = useContext(PathContext);

  const barOpen = () => {
    setOptionBar(true);
    setUpload(null);
    setCascaderDisabled(true);
    console.log(dataTitle);
  };

  const barClosed = async () => {
    const batch = writeBatch(db);
    setCascaderDisabled(false);
    setOptionBar(false);
    console.log(upload);
    if (upload && Object.entries(upload).length > 0) {
      const docRef = doc(db, "Teams", currentUser.uid, "DataLabels", "Labels");
      batch.set(docRef, upload);
      const dataDocRef1 = doc(
        db,
        "Teams",
        currentUser.uid,
        "EventData",
        dataDocTitle + " PlayData"
      );
      const dataDocRef2 = doc(
        db,
        "Teams",
        currentUser.uid,
        "EventData",
        dataDocTitle + " PlayerData"
      );
      batch.delete(dataDocRef1);
      batch.delete(dataDocRef2);
      try {
        await batch.commit();
        alert("Labels Updated");
        console.log("Batch operation complete");
      } catch (error) {
        console.error("Error executing batch operation:", error);
      }
    }
    console.log(upload);
  };

  useEffect(() => {
    const getTitleDoc = async () => {
      const docRef = doc(db, "Teams", currentUser.uid, "DataLabels", "Labels");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists() && docSnap.data().dataTitle !== undefined) {
        setDataTitle(docSnap.data().dataTitle);
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
        setDataTitle([
          {
            value: "eg. Year",
            label: "eg. Year",
            children: [],
          },
        ]);
      }
    };
    getTitleDoc();
  }, [selectedPath]);

  const [newOption, setNewOption] = useState([]);
  const [upload, setUpload] = useState({});
  const [cascaderValue, setCascaderValue] = useState();

  const addOption = async () => {
    let parent = dataTitle;

    if (cascaderValue !== undefined) {
      // Find the parent node of the new option
      for (let i = 0; i < cascaderValue.length; i++) {
        let node = parent.find((option) => option.value === cascaderValue[i]);
        if (node.children) {
          parent = node.children;
        } else {
          node.children = [];
          parent = node.children;
        }
      }
    }

    // Check for duplicates
    if (
      parent !== undefined &&
      parent.some((option) => option.value === newOption)
    ) {
      alert("Duplicate Label!");
      return;
    }

    // Add the new option
    parent.push({
      value: newOption,
      label: newOption,
      children: [],
    });
    alert("Option Added. Close the menu to save changes.");

    setUpload({ dataTitle });
    setDataTitle([...dataTitle]);
  };

  const handleDeleteOption = async () => {
    let parent = dataTitle;

    if (cascaderValue !== undefined) {
      // Find the parent node of the new option
      for (let i = 0; i < cascaderValue.length; i++) {
        let node = parent.find((option) => option.value === cascaderValue[i]);
        if (node.children) {
          parent = node.children;
        } else {
          node.children = [];
          parent = node.children;
        }
      }
    }

    // Check for duplicates
    if (
      parent !== undefined &&
      parent.some((option) => option.value === newOption)
    ) {
      let node = parent.find((option) => option.value === newOption);

      // Check for multiple children
      if (node.children && node.children.length > 0) {
        alert(
          "Cannot Delete Group Header. Try Deleting all events under the header first."
        );
        return;
      }

      parent.splice(parent.indexOf(node), 1);
      setDataDocTitle(cascaderValue + "," + node.value);
      setUpload({ dataTitle });
      setDataTitle([...dataTitle]);
      console.log(dataTitle);
      alert("Label & Data removed, close the menu to save changes");
    } else {
      alert("Label Does Not Exist!");
      return;
    }
  };

  return (
    <>
      <div className="flex mx-auto">
        <Cascader
          className="w-72 h-fit ml-2 shadow-sm shadow-black rounded-lg"
          options={dataTitle}
          disabled={cascaderDisabled}
          onChange={(e) => props.updateTitle(e)}
          changeOnSelect
          maxTagCount="responsive"
          defaultValue={[]}
          placeholder={"Title Select"}
        />

        <Button
          className="h-fit ml-[-10px]"
          type="secondary"
          onClick={() => (optionBar ? barClosed() : barOpen())}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6 text-white hover:text-yellow-300"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </Button>
      </div>

      <AnimatePresence>
        {optionBar && (
          <motion.div
            initial={{ opacity: 0, y: -10, scaleX: 0, scaleY: 0 }}
            animate={{ opacity: 1, y: 0, scaleX: 1, scaleY: 1 }}
            exit={{ opacity: 0, y: -10, scaleX: 0, scaleY: 0 }}
            className="mx-auto w-full flex justify-center items-center bg-white m-1 rounded-lg"
          >
            <Space.Compact>
              <div className="flex flex-col w-full">
                <div className="flex">
                  <div className="font-semibold ml-2">
                    Select All Labels that Apply
                  </div>
                  <div className="font-semibold mx-auto">
                    Type in the New Label
                  </div>
                </div>
                <Input
                  className="w-full"
                  addonBefore={
                    <Cascader
                      options={dataTitle}
                      value={cascaderValue}
                      changeOnSelect
                      onChange={(e) => setCascaderValue(e)}
                      placeholder="Year / Season / Week"
                    />
                  }
                  placeholder="Game 3"
                  onChange={(e) => setNewOption(e.target.value)}
                />
                <div className="flex w-fit mx-auto">
                  <Button
                    className="text-black w-fit bg-slate-200"
                    type="primary"
                    onClick={addOption}
                  >
                    Add Label
                  </Button>
                  <Popconfirm
                    okType="danger"
                    title="Delete this label and ALL associated DATA?"
                    onConfirm={handleDeleteOption}
                  >
                    <Button className="text-black" type="dashed">
                      Remove Label & Data
                    </Button>
                  </Popconfirm>
                </div>
              </div>
            </Space.Compact>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}

export default MyTeamDataTitleCascader;
