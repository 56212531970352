import React, { useContext, useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import {
  DndContext,
  DragOverlay,
  pointerWithin,
  useDraggable,
} from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import { db } from "../Firebase-config";
import { doc, setDoc, serverTimestamp, getDoc } from "firebase/firestore";
import { PathContext } from "../context/PathContext";
import DCSortableContainer from "../components/DCSortableContainer";
import { Card, ConfigProvider, Drawer, Input, Skeleton, Tabs } from "antd";
import { DCTitleCascader } from "../components/DCTitleCascader";
import { openNotificationWithIcon } from "../helperFunctions/openNotificationWithIcon";
import { AiFillPrinter } from "react-icons/ai";
import { DCPrint } from "../components/DCPrint";
import { LogoPageHeader } from "../components/LogoPageHeader";
import { TeamContext } from "../context/TeamProvider";

export function DepthCharts() {
  const { selectedPath } = useContext(PathContext);

  const [dropContainerId, setDropContainerId] = useState("");
  const [depthPlayers, setDepthPlayers] = useState([]);
  const [DCDataTitle, setDCDataTitle] = useState();
  const [loading, setLoading] = useState(false);
  const [activeId, setActiveId] = useState(null);
  const [activePlayerName, setActivePlayerName] = useState(null);
  const [activeType, setActiveType] = useState(null);

  const [bucketContainerDepth, setBucketContainerDepth] = useState([]);
  const [outContainerDepth, setOutContainerDepth] = useState([]);
  const [showBucket, setShowBucket] = useState(true);
  const [initialShowBucket, setInitialShowBucket] = useState(true);
  const [showControls, setShowControls] = useState(true);

  const [showPrint, setShowPrint] = useState(false);

  const [tabs, setTabs] = useState([]);

  const [currentTab, setCurrentTab] = useState(-1); // Index of the currently displayed tab

  const [fullTeamRoster, setFullTeamRoster] = useState(true);

  const { teamRosterData } = useContext(TeamContext);

  const clearAll = () => {
    setDepthPlayers(fullTeamRoster);
    setTabs([{ containers: [] }]);
    setContainers([]);
  };

  useEffect(() => {
    const resetRosterCharts = async () => {
      const teamRoster = teamRosterData.map((player) => {
        return {
          key: player["full-name"],
          id: player.id,
          position: player.position,
          name: player["full-name"],
          number: player["jersey-number"],
        };
      });
      console.log("teamRosterPlayers", teamRoster);

      setFullTeamRoster(teamRoster);
      setDepthPlayers(teamRoster);
      setDCDataTitle([]);
      setTabs([]);
      setContainers([]);
    };
    resetRosterCharts();
  }, [selectedPath, teamRosterData]);

  const getDepth = () => {
    if (!DCDataTitle || DCDataTitle?.length <= 0) {
      openNotificationWithIcon(
        "warning",
        "Select a Depth Chart to Open",
        "Use the cascader to select the depth chart you want to view.",
        "top"
      );
      return;
    }

    const dataLabel = DCDataTitle?.join(" / ");
    const matchingTab = tabs.find((tab) => tab.label === dataLabel);
    const matchingTabIndex = tabs.indexOf(matchingTab);

    if (matchingTab) {
      switchTab(matchingTabIndex);
      return;
    }

    setLoading(true);

    const fetchDepthChart = async () => {
      const dataLabel = DCDataTitle?.join();

      const depthQuery = doc(
        db,
        "Teams",
        selectedPath,
        "DepthChart",
        dataLabel
      );

      const depthSnapshot = await getDoc(depthQuery);
      const fetchedDepth = [];
      let positionContainers; // Declare currentPosList variable
      let newTabScale;

      if (depthSnapshot.exists()) {
        const data = depthSnapshot.data();
        positionContainers = data?.containers;
        newTabScale = data?.scale;
        setContainers(positionContainers);
        setScale(data?.scale);

        const fetchedData = Object.values(data);

        const fetchedPlayers = fetchedData
          .map((player) => {
            return {
              key: player.name,
              id: player.id,
              name: player.name,
              number: player.number,
              position: player.position,
              odp: player.odp,
              odn: player.odn,
            };
          })
          .filter((player) => player.key !== undefined);

        fetchedDepth.push(...fetchedPlayers);
        console.log("Fetched Players", fetchedPlayers);
      }

      return { fetchedDepth, positionContainers, newTabScale };
    };

    const fetchRosterCharts = async () => {
      const teamRoster = teamRosterData.map((player) => {
        return {
          key: player["full-name"],
          id: player.id,
          position: player.position,
          name: player["full-name"],
          number: player["jersey-number"],
        };
      });
      console.log("teamRoster", teamRoster);

      return teamRoster;
    };

    Promise.all([fetchDepthChart(), fetchRosterCharts()])
      .then(([depthResult, fetchedRoster]) => {
        const { fetchedDepth, positionContainers, newTabScale } = depthResult;

        const filteredTeamRoster = fetchedRoster.map((rosterPlayer) => {
          const depthMatch = fetchedDepth.find(
            (depth) => depth.id === rosterPlayer.id
          );
          if (depthMatch) {
            // Create the new merged object
            return {
              id: rosterPlayer.id,
              key: rosterPlayer.key,
              name: rosterPlayer.name,
              number: rosterPlayer.number,
              position: rosterPlayer.position,
              odn: depthMatch.odn,
              odp: depthMatch.odp,
              // Add other properties you want to merge
              // For properties that don't exist in either object, you might want to handle them differently
            };
          } else {
            return rosterPlayer;
          }
        });
        console.log('filtered', filteredTeamRoster);

        // filteredTeamRoster now contains the new merged objects and the original objects that didn't match

        const dataLabel = DCDataTitle?.join(" / ");
        const isLabelAlreadyExists = tabs.some(
          (tab) => tab.label === dataLabel
        );

        if (!isLabelAlreadyExists) {
          const newTab = {
            label: dataLabel,
            key: tabs.length,
            uploadLabel: DCDataTitle,
            data: filteredTeamRoster,
            scale: newTabScale,
            containers: positionContainers,
          };

          setTabs((prevTabs) => [...prevTabs, newTab]);
          // Find the index of the newly added tab
          const newTabIndex = tabs.length;

          // Set the currentTab to the index of the newly added tab
          setCurrentTab(newTabIndex);
          console.log("Tabs", tabs);
        }

        setDepthPlayers(filteredTeamRoster);
        setLoading(false); // set loading to false after data has been retrieved\
        setShowControls(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
	console.log("Depth Players Updated", depthPlayers);
  }, [depthPlayers]);

  const handleSearchRoster = (e) => {
    const searchValue = e.target.value;
    const depthPlayersCopy = [...depthPlayers];
    console.log("Depth Players", depthPlayersCopy);
    if (!searchValue) {
      const BCDepth = depthPlayers.filter(
        (player) => player.odp === "bucketContainer" || !player.odp
      );

      setBucketContainerDepth(BCDepth);
    } else {
      const filteredBucketContainerDepth = depthPlayersCopy
        .filter((player) => player.odp === "bucketContainer" || !player.odp)
        .filter((player) =>
          player.name.toLowerCase().includes(searchValue.toLowerCase())
        );

      setBucketContainerDepth(filteredBucketContainerDepth);
    }
  };

  const updateGradeTitle = (title) => {
    setDCDataTitle(title);
  };

  const switchTab = (tabIndex) => {
    console.log("tabIndex", tabIndex);
    if (tabIndex === currentTab) {
      return;
    }
    setCurrentTab(tabIndex);

    const newTab = tabs[tabIndex];
    setDCDataTitle(newTab.uploadLabel);
    setDepthPlayers(newTab.data);
    setContainers(newTab.containers);
    setScale(newTab.scale);

    console.log("tabData:", currentTab);
    console.log("tabs:", tabs[tabIndex]);
  };

  const removeTab = (tabIndex) => {
    setTabs((prevTabs) =>
      prevTabs
        .filter((_, index) => index !== tabIndex)
        .map((tab, index) => ({ ...tab, key: index }))
    );

    if (tabIndex === currentTab) {
      const newCurrentTab = tabIndex > 0 ? currentTab - 1 : -1;
      setCurrentTab(newCurrentTab);
      if (newCurrentTab !== -1) {
        const newTab = tabs[newCurrentTab];
        setDCDataTitle(newTab.uploadLabel);
        setDepthPlayers(newTab.data);
        setContainers(newTab.containers);
        setScale(newTab.scale);
      } else {
        setDCDataTitle([]);
        setDepthPlayers(fullTeamRoster);
        setContainers([]);
        setScale(1);
      }
    } else if (tabIndex < currentTab) {
      setCurrentTab(currentTab - 1);
    }
  };

  useEffect(() => {
    const updatedDepthPlayers = depthPlayers.map((player) => ({
      ...player,
      odp:
        player.odp === undefined || player.odp === null
          ? "bucketContainer"
          : player.odp,
    }));

    const BCDepth = updatedDepthPlayers.filter(
      (player) => player.odp === "bucketContainer"
    );

    setBucketContainerDepth(BCDepth);

    const outTempArray = [...depthPlayers];

    const OutDepth = outTempArray.filter(
      (player) => player?.odp === "outContainer"
    );
    setOutContainerDepth(OutDepth);
  }, [depthPlayers, selectedPath]);

  const [containers, setContainers] = useState([]);

  const handleAddContainer = () => {
    // Create a new container with a unique ID
    const newContainer = {
      id: `container-${containers.length + 1}`,
      type: `positionContainer`,
      position: { x: 0, y: 0 }, // Initial position
      disabled: false,
    };

    // Add the new container to the list of containers
    const updatedContainers = [...containers, newContainer];
    setContainers(updatedContainers);

    // Update the current tab with the modified containers
    if (currentTab !== -1) {
      const updatedTabs = [...tabs];
      updatedTabs[currentTab].containers = updatedContainers;
      setTabs(updatedTabs);
    }
  };

  const handleRename = (value, id) => {
    // Update containers array by renaming the container with the specified ID
    const doubleContainer = containers.filter(
      (container) => container.id === value
    );
    console.log("DC", doubleContainer);

    if (doubleContainer.length > 0) {
      alert("Label Taken: Please Choose a different Label");
      return;
    }
    const updatedContainers = containers.map((container) => {
      if (container.id === id) {
        return { ...container, id: value };
      }
      return container;
    });

    // Update depthPlayers array by renaming players with odp === id
    const updatedDepthPlayers = depthPlayers.map((player) => {
      if (player.odp === id) {
        return { ...player, odp: value };
      }
      return player;
    });

    // Update the current tab with the modified containers and depthPlayers
    if (currentTab !== -1) {
      const updatedTabs = [...tabs];
      updatedTabs[currentTab].containers = updatedContainers;
      updatedTabs[currentTab].data = updatedDepthPlayers; // Assuming data holds depthPlayers in your tab structure
      setTabs(updatedTabs);
    }

    // Set the state with the updated arrays
    setContainers(updatedContainers);
    setDepthPlayers(updatedDepthPlayers);
  };

  const handleRemovePosition = (id) => {
    // Update containers array by removing the container with the specified ID
    const updatedContainers = containers.filter(
      (container) => container.id !== id
    );

    // Update depthPlayers array by setting players with odp === id to 'bucketcontainer'
    const updatedDepthPlayers = depthPlayers.map((player) => {
      if (player.odp === id) {
        return { ...player, odp: "bucketContainer" };
      }
      return player;
    });

    // Update the current tab with the modified containers
    if (currentTab !== -1) {
      const updatedTabs = [...tabs];
      updatedTabs[currentTab].containers = updatedContainers;
      setTabs(updatedTabs);
    }

    // Set the state with the updated arrays
    setContainers(updatedContainers);
    setDepthPlayers(updatedDepthPlayers);
  };

  const [disableDrag, setDisableDrag] = useState(false);

  // Calculate depthCanvasWidth and depthCanvasHeight
  const depthCanvas = document.getElementById("depthCanvas");

  const [depthCanvasWidth, setDepthCanvasWidth] = useState(100);
  const [depthCanvasHeight, setDepthCanvasHeight] = useState(100);

  useEffect(() => {
    setDepthCanvasWidth(depthCanvas?.offsetWidth);
    setDepthCanvasHeight(depthCanvas?.offsetHeight);
  }, [depthCanvas]);

  const DraggableContainer = ({
    container,
    depthCanvasWidth,
    depthCanvasHeight,
  }) => {
    const { attributes, listeners, setNodeRef, transform } = useDraggable({
      id: container.id,
      data: { type: container.type },
      disabled: disableDrag,
    });

    const containerDepth = depthPlayers
      .filter((player) => player?.odp === container.id)
      .sort((a, b) => a.odn - b.odn);

    return (
      <div
        ref={setNodeRef}
        {...attributes}
        {...listeners}
        className={`absolute flex flex-col transform transition-all touch-none p-1 w-40 h-fit`}
        style={{
          left: container.position.x * depthCanvasWidth,
          top: container.position.y * depthCanvasHeight,
          transform: `scale(${scale})`,
        }}
      >
        <div className="flex">
          <div className="h-full w-full flex p-3 bg-green-200 rounded-lg">
            <input
              className="h-full text-xl mx-auto w-fit min-w-1 font-bold bg-green-200 text-center border-white"
              onMouseOver={() => setDisableDrag(true)}
              onFocus={() => setDisableDrag(true)}
              onTouchMove={() => setDisableDrag(false)}
              onTouchStart={() => setDisableDrag(true)}
              onBlur={() => setDisableDrag(false)}
              onMouseLeave={() => setDisableDrag(false)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleRename(e.target.value, container.id);
                  setDisableDrag(false);
                }
              }}
              defaultValue={container.id}
            ></input>
          </div>
          <motion.button
            onClick={() => {
              handleRemovePosition(container.id);
              setDisableDrag(true);
            }}
            onTouchStart={() => setDisableDrag(true)}
            onMouseOver={() => setDisableDrag(true)}
            onMouseLeave={() => setDisableDrag(false)}
            className="h-fit w-fit absolute touch-none left-[97.5%] top-3 bg-green-200 hover:bg-red-300 text-slate-400 p-1 text-base rounded-tr-sm"
          >
            X
          </motion.button>
        </div>
        <DCSortableContainer
          className="w-16 h-fit text-center touch-none"
          containerId={container.id}
          items={
            containerDepth.length > 0
              ? containerDepth.filter((player) => player.odp === container.id)
              : [
                  {
                    key: "placeholder",
                    id: container.id,
                    noDrag: true,
                    name: "",
                    number: "",
                    position: "placeholder",
                    odp: container.id,
                    odn: 0,
                  },
                ]
          }
        ></DCSortableContainer>
      </div>
    );
  };

  const handleDragEnd = (event, depthCanvasWidth, depthCanvasHeight) => {
    if (initialShowBucket) {
      setTimeout(() => {
        setShowBucket(true);
      }, 100);
    }

    if (activeType === "positionContainer") {
      const { active, delta } = event;

      if (active && delta) {
        const updatedContainers = containers.map((container) => {
          let newXPercentage, newYPercentage;

          if (container.id === active.id) {
            // Calculate new position based on the delta from the current position
            const newPosX = container.position.x * depthCanvasWidth + delta.x;
            const newPosY = container.position.y * depthCanvasHeight + delta.y;

            // Calculate the new position as a percentage of the depthCanvas dimensions
            newXPercentage = newPosX / depthCanvasWidth;
            newYPercentage = newPosY / depthCanvasHeight;
          } else {
            // Keep the position unchanged for other containers
            newXPercentage = container.position.x;
            newYPercentage = container.position.y;
          }

          return {
            ...container,
            position: {
              x: newXPercentage,
              y: newYPercentage,
            },
          };
        });

        if (currentTab !== -1) {
          const updatedTabs = [...tabs];
          updatedTabs[currentTab].containers = updatedContainers;
          setTabs(updatedTabs);
        }

        setContainers(updatedContainers);
      }
      return;
    }

    console.log("Drag End", event);

    const { active, over } = event;

    const parentDiv = over ? over.data.current.sortable : null;
    console.log("OVER:", over);

    if (over && active.id !== over.id) {
		setDepthPlayers((players) => {
		  const activeIndex = players.findIndex((player) => player.id === active.id);
		  console.log('activeIndex', activeIndex);
		  const overIndex = parentDiv.items.findIndex((player) => player === over.id);
		  console.log('overIndex', overIndex);
	  
		  // Rearrange the players first
		  let updatedPlayers = arrayMove(players, activeIndex, overIndex);
	  
		  if (parentDiv) {
			// Update odp and odn for the active player
			updatedPlayers = updatedPlayers.map((player, index) => {
			  if (player.id === active.id) {
				return {
				  ...player,
				  odp: parentDiv.containerId,
				  odn: index + 1,
				};
			  } else if (player.odp === parentDiv.containerId) {
				// Update odn for other players in the same container
				return {
				  ...player,
				  odn: index + 1
				};
			  }
			  return player;
			});
	  
			console.log('Updated Players in Container', updatedPlayers);
	  
			// Update the current tab with the modified player data
			if (currentTab !== -1) {
			  const updatedTabs = [...tabs];
			  updatedTabs[currentTab].data = updatedPlayers;
			  setTabs(updatedTabs);
			}
	  
		  } else {
			// For drops outside of containers, handle separately if needed
		  }
	  
		  return updatedPlayers;
		});
	  }
	  
	  setActiveId(null);
	  setActivePlayerName(null);
	  console.log("Active:", active);
	  console.log("Over:", over);
  };

  function handleDragStart(event) {
    const playerName = fullTeamRoster.find(
      (item) => item.id === event.active.id
    );
	const playerNumber = playerName?.number
    
	if (playerName?.name) {
      setActivePlayerName((playerNumber ? playerNumber : '') + " " + playerName.name);
    } else {
      setActivePlayerName(event.active.id);
    }
    setActiveId(event.active.id);
    setActiveType(event.active.data.current.type);
    setInitialShowBucket(showBucket);
    setShowBucket(false);
  }

  const handleSaveDepth = async (e) => {
    console.log(DCDataTitle);
    console.log("DepthPlayers", depthPlayers);
    const dataLabel = DCDataTitle ? DCDataTitle.join() : null;
    const uploadableDepth = depthPlayers.map((player) => {
      // Check if any property of the player object is undefined
      for (const key in player) {
        if (player.hasOwnProperty(key) && typeof player[key] === "undefined") {
          // If undefined, update the property to null
          player[key] = null;
        }
      }

      return player;
    });
    console.log("UploadDepth", uploadableDepth);

    // updatedDepthPlayers will contain the modified array with undefined values changed to null

    e.preventDefault();
    try {
      await setDoc(doc(db, "Teams", selectedPath, "DepthChart", dataLabel), {
        ...uploadableDepth,
        containers,
        scale: scale,
        dateUpdated: serverTimestamp(),
      });
	  openNotificationWithIcon(
        "success",
        "Depth Uploaded",
        "",
        "top",
		"",
		2
      );
    } catch (error) {
	  openNotificationWithIcon(
        "error",
        "Unable to Upload",
        error,
        "top",
		"",
		5
      );
      console.log(error);
    }
  };

  const [scale, setScale] = useState(1);

  const handleWheel = (event) => {
    event.preventDefault(); // Prevent default scroll behavior

    // Adjust scale based on scroll direction
    const delta = event.deltaY > 0 ? 0.9 : 1.1; // Adjust as needed
    setScale((scale) => Math.max(0.05, Math.min(3, scale * delta)));
  };

  const handleMarkerDrag = (event) => {
    const clientY = event.touches ? event.touches[0].clientY : event.clientY;
    const offsetY =
      clientY - event.target.parentElement.getBoundingClientRect().top;
    let newScaleValue;

    if (offsetY <= 0) {
      newScaleValue = 3;
    } else if (offsetY >= event.target.parentElement.clientHeight) {
      newScaleValue = 0.05;
    } else {
      newScaleValue =
        3 - (3.99 * offsetY) / event.target.parentElement.clientHeight;
    }

    setScale(Math.max(0.05, Math.min(3, newScaleValue)));
  };

  const handleMarkerDragEnd = (event) => {
    const offsetY =
      event.clientY - event.target.parentElement.getBoundingClientRect().top;
    let newScaleValue;

    if (offsetY <= 0) {
      newScaleValue = 3;
    } else if (offsetY >= event.target.parentElement.clientHeight) {
      newScaleValue = 0.05;
    } else {
      newScaleValue =
        3 - (3.99 * offsetY) / event.target.parentElement.clientHeight;
    }

    setScale(Math.max(0.05, Math.min(3, newScaleValue)));
  };

  const handleScaleReset = () => {
    setScale(1);
  };

  return (
    <motion.div
      className="h-screen w-screen overflow-hidden"
      initial={{ y: -500 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: 1000, opacity: 0 }}
    >
      <DCPrint setShowPrint={setShowPrint} showPrint={showPrint} tabs={tabs} />
      <button
        onClick={() => setShowControls(!showControls)}
        className="absolute z-50 top-20 right-0 font-extrabold text-2xl bg-white text-center rounded-l-lg w-9 h-9"
      >
        <span className="p-1 z-50 m-auto">{showControls ? "" : "<"}</span>
      </button>

      <DndContext
        collisionDetection={pointerWithin}
        onDragStart={handleDragStart}
        onDragEnd={(event) =>
          handleDragEnd(event, depthCanvasWidth, depthCanvasHeight)
        }
        onDragOver={(event) => {
          const { over } = event;
          const parentDivId = over
            ? over.data.current.sortable.containerId
            : null;
          if (parentDivId !== null && parentDivId !== dropContainerId) {
            setDropContainerId(parentDivId);
            console.log("Dragged over div ID:", parentDivId);
          }
        }}
      >
        <Drawer
          title="Controls"
          size="large"
          onClose={() => setShowControls(!showControls)}
          open={showControls && showBucket}
        >
          <div
            className={`absolute ${
              tabs.length > 0 || containers.length > 0 ? "block" : "hidden"
            } bottom-1 left-0 bg-slate-200 z-50 px-1 w-6`}
          >
            <div className="w-full mx-auto font-bold text-black">+</div>
            <div
              className="relative w-full h-64 bg-slate-900 bg-opacity-50 text-black rounded overflow-hidden"
              onTouchMove={handleMarkerDrag}
            >
              <div
                className="absolute left-1/2 transform -translate-x-1/2 w-full text-sm h-6 bg-white rounded cursor-pointer select-none flex justify-center items-center"
                style={{ bottom: `${((scale - 0.05) / 3) * 93}%` }}
                draggable="true"
                onDrag={handleMarkerDrag}
                onDragEnd={handleMarkerDragEnd}
              ></div>
            </div>
            <div className="w-fit mx-auto font-bold text-black">-</div>
            <button
              onClick={handleScaleReset}
              className="absolute bottom-0 left-0 w-fit mx-auto font-bold text-black text-sm"
            >
              Reset
            </button>
          </div>
          <div
            className={`flex flex-col w-full h-full overflow-hidden transition-all`}
          >
            <div className="flex flex-wrap w-full h-fit">
              <div className="w-full md:w-1/2 my-auto pr-10 bg-slate-200 rounded-md shadow-sm">
                <DCTitleCascader updateTitle={updateGradeTitle} />
              </div>
              <motion.button
                className="whitespace-nowrap p-2 m-1 ml-2 mb-2 bg-white shadow-sm shadow-slate-400 rounded-md w-fit text-black text-center font-bold hover:bg-slate-200"
                whileHover={{ scale: 0.9 }}
                whileTap={{ scale: 1.1 }}
                onClick={() => getDepth()}
              >
                Fetch Depth
              </motion.button>
              <motion.button
                className="whitespace-nowrap p-2 m-1 mb-2 bg-white shadow-sm shadow-slate-400 rounded-md w-fit text-black text-center font-bold hover:bg-slate-200"
                whileHover={{ scale: 0.9 }}
                whileTap={{ scale: 1.1 }}
                onClick={() => clearAll()}
              >
                Clear
              </motion.button>
              <motion.button
                className="w-fit whitespace-nowrap p-2 m-1 mb-2 ml-auto mr-8 hover:bg-teal-500 border-teal-500 border-2 text-white bg-slate-600 rounded-md font-bold shadow-gray-700 shadow-sm  disabled:opacity-50"
                whileHover={{ scale: 0.9 }}
                whileTap={{ scale: 1.1 }}
                onClick={(e) => handleSaveDepth(e)}
              >
                Save
              </motion.button>
              <motion.button
                whileHover={{ scale: 1.15 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setShowPrint(!showPrint)}
                className="w-fit mx-auto mb-2"
              >
                <AiFillPrinter className="text-3xl text-black" />
              </motion.button>
            </div>
            <motion.button
              whileHover={{ scale: 0.9 }}
              whileTap={{ scale: 1.1 }}
              onClick={() => handleAddContainer()}
              className="font-bold text-lg text-center w-fit ml-2 px-2 h-9 my-1 rounded-md shadow-sm shadow-slate-400 p-1"
            >
              Add Position
            </motion.button>

            <div
              key="sidebar"
              id="sidebar"
              className={`relative z-40 flex flex-col w-full mx-auto bg-slate-300 rounded-md transition-all h-full shadow-black shadow-md`}
            >
              <div
                className="z-30 w-full content-start rounded-sm flex flex-row flex-wrap h-full scrollbar scrollbar-thumb-yellow-100 scrollbar-track-transparent overflow-y-auto shadow-black md:shadow-inner"
                id="bucketplaceholder"
                key="bucket"
              >
                <div className="sticky flex flex-col md:flex-row top-0 w-full z-30">
                  <div
                    id="bucket"
                    className="container h-7 text-center text-lg cursor-default mr-auto w-64 rounded-br-lg font-bold bg-slate-800 text-yellow-200 shadow-sm shadow-slate-800"
                  >
                    Remaining Roster
                  </div>
                  <div className="z-30 h-7 w-full max-w-64 mr-auto md:mr-0 md:ml-auto text-white content-start rounded-bl-md shadow-sm shadow-slate-800 flex flex-row bg-slate-700 scrollbar scrollbar-thumb-yellow-100 scrollbar-track-transparent overflow-y-auto">
                    <span className="mx-2 my-auto font-semibold">Search:</span>
                    <Input
                      onChange={(e) => handleSearchRoster(e)}
                      className="my-1 mx-auto mr-1"
                    ></Input>
                  </div>
                </div>

                <DCSortableContainer
                  className="flex z-0 h-full w-full"
                  containerId="bucketContainer"
                  items={
                    bucketContainerDepth.length > 0
                      ? bucketContainerDepth
                      : [
                          {
                            key: "BP1",
                            id: "",
                            noDrag: true,
                            name: "",
                            number: "",
                            position: "",
                            odp: "bucketContainer" || undefined,
                            odn: 0,
                          },
                        ]
                  }
                />
              </div>
              <div
                id="outContainer"
                key="out"
                className="relative z-30 w-full content-start mx-auto flex flex-row flex-wrap justify-stretch h-full border-t-2 border-slate-950 scrollbar scrollbar-thumb-white scrollbar-thumb-rounded-xl scrollbar-track-transparent overflow-y-auto"
              >
                <div className="w-full mr-auto sticky top-0 z-30">
                  <div
                    id="out"
                    className="container h-7 w-64 text-center text-red-200 text-lg shadow-black shadow-sm cursor-default rounded-br-lg font-bold bg-slate-800"
                  >
                    Injured / Out
                  </div>
                </div>
                <DCSortableContainer
                  className="flex h-full w-full"
                  containerId="outContainer"
                  items={
                    outContainerDepth.length > 0
                      ? outContainerDepth
                      : [
                          {
                            key: `outContainer`,
                            id: `outContainer`,
                            name: "",
                            number: "",
                            noDrag: true,
                            position: `outplaceholder`,
                            odp: `outContainer`,
                            odn: 0,
                          },
                        ]
                  }
                />
              </div>
            </div>
          </div>
        </Drawer>
        <LogoPageHeader title={"Depth Charts"} />

        <AnimatePresence>
          <div
            className={`${
              !showBucket ? "block" : "hidden"
            } absolute transition-all top-20 right-0 flex flex-col w-fit h-fit z-50`}
          >
            <div className="h-fit">
              <div className="container h-fit text-center text-2xl cursor-default mx-auto w-full rounded-l-lg font-bold bg-yellow-100 shadow-sm shadow-slate-800 text-black">
                <div className="mx-1 text-sm">Remaining Roster</div>
              </div>
              <DCSortableContainer
                className="flex h-10 w-full"
                containerId="bucketContainer"
                key={"bucketContainerDrop"}
                items={[
                  {
                    key: "bucketplaceholder2",
                    id: "bucketplaceholder2",
                    name: "",
                    number: "",
                    noDrag: true,
                    position: "",
                    odp: "bucketContainer",
                    odn: 0,
                  },
                ]}
              />
            </div>
            <div className="h-fit">
              <div
                id="out"
                className="container h-fit text-center text-2xl cursor-default mx-auto w-full rounded-l-lg font-bold bg-red-300 shadow-sm shadow-slate-800 text-black"
              >
                <div className="mx-1 text-sm">Out</div>
              </div>
              <DCSortableContainer
                className="flex h-10 w-full"
                containerId="outContainer"
                key={"outContainer"}
                items={[
                  {
                    key: "outplaceholder2",
                    id: "outplaceholder2",
                    name: "",
                    number: "",
                    noDrag: true,
                    position: "outplaceholder",
                    odp: "outContainer",
                    odn: 0,
                  },
                ]}
              />
            </div>
          </div>
        </AnimatePresence>

        <div className="relative p-2 mx-auto bg-slate-100 bg-opacity-20 w-full sm:w-[95%] overflow-x-auto rounded-lg mb-28">
          <ul className="flex w-full gap-2 mb-[-0.5rem] md:mb-[-0.8rem]">
            <ConfigProvider
              theme={{
                components: {
                  Tabs: {
                    cardBg: "#fefdfb",
                  },
                },
              }}
            >
              <Tabs
                className="h-12 w-fit max-w-full "
                type="editable-card"
                hideAdd
                activeKey={currentTab}
                tabPosition={"top"}
                onEdit={removeTab}
                onChange={switchTab}
                items={tabs}
              />
            </ConfigProvider>
          </ul>

          <div
            id="depthCanvas"
            onWheel={handleWheel} // Attach onWheel event handler
            className="relative h-[65vh] w-full min-w-[80rem] overflow-x-auto bg-slate-300 rounded-md"
          >
            <AnimatePresence>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="flex w-full h-full"
              >
                {containers?.map((container) => (
                  <DraggableContainer
                    key={container.id}
                    id={container.id}
                    type={container.type}
                    container={container}
                    containerId={container.id}
                    depthCanvasWidth={depthCanvasWidth}
                    depthCanvasHeight={depthCanvasHeight}
                  />
                ))}
              </motion.div>
            </AnimatePresence>
          </div>
          <DragOverlay dropAnimation={null}>
            {activeId && activeType !== "positionContainer" ? (
              <div
                value={`Item ${activeId}`}
                className="player-div absolute z-50 cursor-grab h-fit max-h-fit w-fit bg-slate-300 truncate rounded-md border border-slate-400 p-1 text-white"
                style={{ transform: `scale(${scale})` }}
              >
                <Card
                  className="h-12 w-fit truncate text-lg font-bold"
                  size="small"
                >
                  <div className="w-full mx-auto">{activePlayerName}</div>
                </Card>
              </div>
            ) : (
              <div
                className="flex flex-col z-50 bg-green-200 rounded-lg p-1 w-40 h-fit"
                style={{ transform: `scale(${scale})` }}
              >
                <div className="flex">
                  <div className="h-full w-full p-3 font-bold text-center bg-green-200 pl-5 rounded-lg">
                    {activePlayerName}
                  </div>
                  <motion.button className="h-fit w-fit absolute left-[98%] top-3 bg-green-200 hover:bg-red-300 text-slate-400 p-1 text-base rounded-tr-sm">
                    X
                  </motion.button>
                </div>
              </div>
            )}
          </DragOverlay>
        </div>
      </DndContext>
    </motion.div>
  );
}
