import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  GoogleMap,
  Marker,
  MarkerF,
  MarkerClustererF,
  useLoadScript,
} from "@react-google-maps/api";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import {
  AiFillHeart,
  AiFillVideoCamera,
  AiOutlineHeart,
  AiOutlineVideoCamera,
} from "react-icons/ai";
import { LuGraduationCap } from "react-icons/lu";
import { GiRank3 } from "react-icons/gi";
import { RPlaces } from "./RPlaces";
import { RPMRecruitCards } from "./RPMRecruitCards";
import { Spin, Table } from "antd";
import { db, functions } from "../Firebase-config";
import { AnimatePresence, motion } from "framer-motion";
import { httpsCallable } from "firebase/functions";
import { RPMRecruitingProfile } from "./RPMRecruitingProfile";
import { AuthContext } from "../context/AuthContext";
import { PathContext } from "../context/PathContext";
import Geohash from "latlon-geohash";

export function RPlayerMap() {
  const [teamLocations, setTeamLocations] = useState([]);
  const [recruitLocations, setRecruitLocations] = useState([]);
  const [loadingLocations, setLoadingLocations] = useState(false);

  const [recruitingProfileActive, setRecruitingProfileActive] = useState(false);
  const [recruitingProfileID, setRecruitingProfileID] = useState();
  const [favoritedRecruits, setFavoritedRecruits] = useState([]);
  const [showFavRec, setShowFavRec] = useState(false);
  const [usersData, setUsersData] = useState(null);
  const [expandedRow, setExpandedRow] = useState(null);

  const { currentUser } = useContext(AuthContext);
  const { selectedLocation } = useContext(PathContext);

  const getLocations = async () => {
    try {
      setLoadingLocations(true);
      const getRecruitingLocations = httpsCallable(
        functions,
        "getRecruitingLocations"
      );

      // Assuming googleMap is your Google Map instance
      const center = mapRef.current.getCenter();
      const radiusInMiles = 40; // Set your desired radius

      // Calculate Geohash from the center point
      const geohashPrecision = 10; // Choose a suitable precision for your use case
      const geohash = Geohash.encode(
        center.lat(),
        center.lng(),
        geohashPrecision
      );

      // Calculate bounding box based on the center and radius
      const boundingBox = getBoundingBox(
        center.lat(),
        center.lng(),
        radiusInMiles
      );

      // Convert bounding box coordinates to Geohashes for comparison
      const minGeohash = Geohash.encode(
        boundingBox.minLat,
        boundingBox.minLng,
        geohashPrecision
      );
      const maxGeohash = Geohash.encode(
        boundingBox.maxLat,
        boundingBox.maxLng,
        geohashPrecision
      );

      // Pass essential data to the Cloud Function
      const response = await getRecruitingLocations({
        minGeohash,
        maxGeohash,
      });

      console.log("response", response.data);

      if (response.data) {
        // Append new data to the existing team locations, avoiding duplicates
        setTeamLocations((prevData) => {
          const newData = response.data.data.filter(
            (newItem) =>
              !prevData.some((prevItem) => prevItem.id === newItem.id)
          );
          return [...prevData, ...newData];
        });
        setLoadingLocations(false);
      }
    } catch (error) {
      console.error("Error fetching locations:", error);
      setLoadingLocations(false);
    }
  };

  console.log("teamLocations:", teamLocations);

  // Function to calculate bounding box based on center and radius
  function getBoundingBox(centerLat, centerLng, radius) {
    const earthRadiusInMiles = 3959; // Earth radius in miles

    // Convert radius to radians
    const radiusInRadians = radius / earthRadiusInMiles;

    // Convert center coordinates to radians
    const centerLatRad = toRadians(centerLat);
    const centerLngRad = toRadians(centerLng);

    // Calculate min and max latitudes
    const minLat = centerLatRad - radiusInRadians;
    const maxLat = centerLatRad + radiusInRadians;

    // Calculate min and max longitudes
    const minLng = centerLngRad - radiusInRadians / Math.cos(centerLatRad);
    const maxLng = centerLngRad + radiusInRadians / Math.cos(centerLatRad);

    // Convert back to degrees
    return {
      minLat: toDegrees(minLat),
      maxLat: toDegrees(maxLat),
      minLng: toDegrees(minLng),
      maxLng: toDegrees(maxLng),
    };
  }

  // Helper function to convert degrees to radians
  function toRadians(degrees) {
    return degrees * (Math.PI / 180);
  }

  // Helper function to convert radians to degrees
  function toDegrees(radians) {
    return radians * (180 / Math.PI);
  }

  const memoizedRecruitLocations = useMemo(() => {
    // Create a Set to keep track of unique 'id' values
    const uniqueIds = new Set();

    // Filter the original recruitLocations array to ensure unique 'id' values
    const uniqueLocations = recruitLocations.filter((location) => {
      if (!uniqueIds.has(location.Id)) {
        uniqueIds.add(location.Id);
        return true;
      }
      return false;
    });

    return uniqueLocations;
  }, [recruitLocations]);

  // Function to remove a location from the array
  const removeLocation = (locationId) => {
    setRecruitLocations((prevLocations) => {
      const indexToRemove = prevLocations.findIndex(
        (location) => location.Id === locationId
      );

      if (indexToRemove === -1) {
        // Location not found, return the original array
        return prevLocations;
      }

      // Create a new array without the item to be removed
      const updatedLocations = [
        ...prevLocations.slice(0, indexToRemove),
        ...prevLocations.slice(indexToRemove + 1),
      ];

      return updatedLocations;
    });
  };

  const addLocationData = async (locationId) => {
    try {
      const playersByLocationIdQuery = query(
        collection(db, "recruitingProfiles"),
        where("teamId", "==", locationId)
      );
      const recruitsSnapshot = await getDocs(playersByLocationIdQuery);

      // Map the documents to their data
      const recruitsData = recruitsSnapshot.docs.map((doc) => doc.data());
      console.log("Recruits:", recruitsData);

      const locationsData = [...teamLocations].filter(
        (location) => location.id === locationId
      );
      console.log("locationsData", locationsData);

      const transformedLocations = locationsData.map((location) => ({
        name: location.teamName,
        Id: location.id,
        img: location.img,
        latlngAddress: location.latlngAddress,
        recruitingCoach: location.recruitingCoach,
        recruitingEmail: location.recruitingEmail,
        recruitingPhone: location.recruitingPhone,
		address: location.address,

        data: recruitsData,
      }));

      // Update the state without reinitializing it
      setRecruitLocations((prevRecruitLocations) => [
        ...prevRecruitLocations,
        ...transformedLocations,
      ]);

      console.log("recruitLocations", memoizedRecruitLocations); // Access the memoized state
    } catch (error) {
      console.error("Error fetching locations:", error);
    }
  };

  const [libraries] = useState(["places"]);

  const { isLoaded } = useLoadScript({
    // Make Sure to hide API key
    googleMapsApiKey: "AIzaSyD6NvXuF1pWkHVYfaumeolskfIFbMkG6Qc",
    libraries: libraries,
  });

  // set team center with team doc
  const [teamCenter, setTeamCenter] = useState(selectedLocation);
  const [currentLocation, setCurrentLocation] = useState();

  const mapRef = useRef(<GoogleMap />);

  const center = useMemo(() => teamCenter, [teamCenter]);
  const options = useMemo(
    () => ({
      // disableDefaultUI: true,
      clickableIcons: false,
      mapId: "eb7ae20023b88be2",
      styles: [
        {
          featureType: "poi.school",
          elementType: "labels",
          stylers: [{ visibility: "on" }], // Adjust visibility of school labels (on/off)
        },
      ],
    }),
    []
  );
  const onLoad = useCallback((map) => (mapRef.current = map), []);

  const [mapMarkers, setMapMarkers] = useState();

  useEffect(() => {
    if (isLoaded) {
      setMapMarkers(
        <MarkerClustererF>
          {(clusterer) =>
            teamLocations?.map((info, index) => (
              <MarkerF
                className="h-1 w-1 z-50"
                key={index}
                position={info.latlngAddress}
                icon={{
                  url: info.img,
                  scaledSize: new window.google.maps.Size(50, 50),
                  origin: new window.google.maps.Point(0, 0),
                  anchor: new window.google.maps.Point(0, 0),
                  optimized: true,
                }}
                clusterer={clusterer}
                onClick={() => addLocationData(info.id)}
              />
            ))
          }
        </MarkerClustererF>
      );
    }
  }, [isLoaded, teamLocations]);

  const addClose = () => setRecruitingProfileActive(false);
  const addOpen = () => setRecruitingProfileActive(true);

  useEffect(() => {
    if (currentUser) {
      const fetchUserData = async () => {
        try {
          const userDocRef = doc(db, "users", currentUser.uid); // Replace 'yourFirestoreInstance' with your Firestore instance
          const userDocSnapshot = await getDoc(userDocRef);

          if (userDocSnapshot.exists()) {
            // Document exists
            const userData = userDocSnapshot.data();

            // Initialize favoritePlayers as an empty array if it doesn't exist
            if (!userData.favoritePlayers) {
              userData.favoritePlayers = [];
            }

            // Set user data in state
            setUsersData(userData);
          } else {
            // Document does not exist
            setUsersData(null);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
          // Handle the error as needed (e.g., show an error message to the user)
        }
      };

      fetchUserData();
    }
  }, [currentUser]);

  useEffect(() => {
    const queryFavoritePlayers = async () => {
      try {
        if (usersData) {
          const favoritePlayers = usersData.favoritePlayers || [];
          console.log("Favorite players Array:", favoritePlayers);

          const playersQuery = query(
            collection(db, "recruitingProfiles"),
            where("id", "in", favoritePlayers)
          );

          const favoritePlayersSnapshot = await getDocs(playersQuery);
          const favoritePlayersData = favoritePlayersSnapshot.docs.map((doc) =>
            doc.data()
          );

          setFavoritedRecruits(favoritePlayersData);
        } else {
          console.log("User document not found");
        }
      } catch (error) {
        console.error("Error querying favorite players:", error);
      }
    };

    queryFavoritePlayers();
    console.log("FavoritePlayers:", favoritedRecruits);
  }, [usersData]);

  const favoritePlayer = async (id) => {
    if (!usersData) {
      return;
    }

    const isFavorite = usersData.favoritePlayers.includes(id);

    let updatedFavoritePlayers;
    if (isFavorite) {
      updatedFavoritePlayers = usersData.favoritePlayers.filter(
        (playerId) => playerId !== id
      );
    } else {
      updatedFavoritePlayers = [...usersData.favoritePlayers, id];
    }

    // Update the usersData state with the new favoritePlayers array
    setUsersData({ ...usersData, favoritePlayers: updatedFavoritePlayers });

    try {
      // Update the user's document in Firestore with the new favoritePlayers array
      const userDocRef = doc(db, "users", currentUser.uid);
      await updateDoc(userDocRef, {
        favoritePlayers: updatedFavoritePlayers,
      });
      console.log(updatedFavoritePlayers);
    } catch (error) {
      console.error("Error updating user document:", error);
      // Handle the error as needed (e.g., show an error message to the user)
    }
  };

  const selectedPlayer = favoritedRecruits?.find(
    (player) => player.id === recruitingProfileID
  );

  const levelAbbreviations = {
    "Division I FBS": "FBS",
    "Division I FCS": "FCS",
    "Division II": "D2",
    "Division III": "D3",
    NAIA: "NAIA",
    NJCAA: "JUCO",
    "Sprint Football": "Sprint",
  };

  const levelOrder = [
    "Division I FBS",
    "Division I FCS",
    "Division II",
    "Division III",
    "NAIA",
    "NJCAA",
    "Sprint Football",
  ];

  const recruitColumns = [
    {
      key: "favoriteRecruit",
      dataIndex: "favoriteRecruit",
      title: "",
      width: 35,
      fixed: "left",
      render: (_, record) => {
        const id = record.id;
        const isFavorite = usersData?.favoritePlayers.includes(id)
          ? true
          : false;
        return (
          <div className="flex mx-auto">
            <motion.button onClick={() => favoritePlayer(id)}>
              <motion.div
                initial={isFavorite ? "filled" : "outlined"}
                animate={isFavorite ? "filled" : "outlined"}
                variants={{
                  outlined: { scale: 1, opacity: 1 },
                  filled: { scale: 1.2, opacity: 1, color: "#E0245E" },
                }}
                transition={{
                  type: "spring",
                  stiffness: 260,
                  damping: 20,
                }}
              >
                {isFavorite ? (
                  <AiFillHeart className="text-red-500 hover:text-black" />
                ) : (
                  <AiOutlineHeart className="text-black hover:text-red-300" />
                )}
              </motion.div>
            </motion.button>
          </div>
        );
      },
    },
    {
      key: "expectedLOC",
      dataIndex: "expectedLOC",
      title: "",
      width: 55,
      render: (text, record) => {
        const abbreviations = levelOrder
          .filter((value) => record.expectedLOC?.includes(value))
          .map((value) => levelAbbreviations[value]);

        return abbreviations.join("/");
      },
    },
    {
      key: "year",
      dataIndex: "year",
      title: "Year",
      width: 40,
      sorter: (a, b) => a.year.localeCompare(b.year),
    },
    // {
    //   key: "score",
    //   dataIndex: "score",
    //   title: "Grade",
    //   render: (_, record) => {
    //     const id = record.id;
    //     return (
    //       <div className="flex ml-1">
    //         <motion.button onClick={null}>
    //           <GiRank3 className="font-bold text-green-600 scale-150" />
    //         </motion.button>
    //       </div>
    //     );
    //   },
    // },
    {
      key: "position",
      dataIndex: "position",
      title: "Pos",
      width: 40,
      filters: [
        {
          text: "Offense",
          value: "Offense",
          children: [
            {
              text: "QB",
              value: "QB",
            },
            {
              text: "OL",
              value: "OL",
            },
            {
              text: "TE",
              value: "TE",
            },
            {
              text: "RB",
              value: "RB",
            },
            {
              text: "WR",
              value: "WR",
            },
          ],
        },
        {
          text: "Defense",
          value: "Defense",
          children: [
            {
              text: "DL",
              value: "DL",
            },
            {
              text: "LB",
              value: "LB",
            },
            {
              text: "SAF",
              value: "SAF",
            },
            {
              text: "CB",
              value: "CB",
            },
          ],
        },
      ],
      filterMode: "tree",
      onFilter: (value, record) => record.position.includes(value),
    },
    {
      key: "jersey-number",
      dataIndex: "jersey-number",
      title: "#",
      width: 35,
      sorter: (a, b) => a["jersey-number"] - b["jersey-number"],
    },
    {
      key: "full-name",
      dataIndex: "full-name",
      title: "Name",
      width: 150,
      //   ...getColumnSearchProps("full-name"),
    },
    {
      key: "height",
      dataIndex: "height",
      title: "Ht",
      width: 25,
      sorter: (a, b) => a.year.localeCompare(b.year),
    },
    {
      key: "weight",
      dataIndex: "weight",
      title: "Wt",
      width: 25,
      sorter: (a, b) => a.year.localeCompare(b.year),
    },
    {
      key: "filmLink",
      dataIndex: "filmLink",
      title: "Film",
      render: (_, record) => {
        const id = record.id;
        return (
          <div className="flex ml-1">
            <motion.button
              onClick={() => {
                if (expandedRow !== record.id) {
                  setExpandedRow(record.id);
                } else {
                  setExpandedRow(null);
                }
              }}
            >
              <AiOutlineVideoCamera className="opacity-70 hover:opacity-100 text-black scale-125" />
            </motion.button>
          </div>
        );
      },
    },
    {
      key: "recruitingProfile",
      dataIndex: "redshirtrecrutingProfile",
      title: "Recruiting",
      render: (_, record) => {
        const id = record.id;
        return (
          <div className="flex ml-5">
            <motion.button
              onClick={() => {
                {
                  recruitingProfileActive ? addClose() : addOpen();
                }
                setRecruitingProfileID(id);
              }}
            >
              <LuGraduationCap className="opacity-70 hover:opacity-100 text-black scale-125" />
            </motion.button>
          </div>
        );
      },
    },
    {
      key: "Xtwitter",
      dataIndex: "Xtwitter",
      title: "Twitter",
      width: 150,
      editable: true,
      sortable: false,
    },
    {
      key: "email",
      dataIndex: "email",
      title: "Email",
      width: 200,
      sortable: false,
    },
    {
      key: "phone",
      dataIndex: "phone",
      title: "Phone",
      width: 150,
      sortable: false,
    },
  ];

  // Return fuction
  if (!isLoaded) {
    return <Spin size="large" />;
  } else {
    return (
      <div className="flex flex-col-reverse md:flex-row h-full md:h-full overflow-auto w-full py-1">
        <RPMRecruitingProfile
          recruitingProfileActive={recruitingProfileActive}
          handleClose={() => addClose()}
          profileInfo={selectedPlayer ? selectedPlayer : ""}
        />
        <div className="absolute z-40 w-[15rem] lg:w-[27rem] top-0 md:top-[-2.75rem] right-0 bg-slate-100 bg-opacity-20 p-1 rounded-lg rounded-b-none">
          <RPlaces
            setCurrentLocation={(position) => {
              setCurrentLocation(position);
              mapRef.current?.panTo(position);
            }}
          />
        </div>
        <div className="h-fit max-h-[90vh] scroll-smooth overflow-y-auto md:h-full mt-[-1rem] z-30 md:mt-0 md:w-1/2 text-white bg-slate-800 md:bg-transparent">
          <div className="flex flex-col last:pb-52 md:last:pb-12 md:pb-0 pt-12 scroll-smooth overflow-y-auto h-full w-[97%] mx-auto shadow-inner shadow-slate-800 bg-slate-400 p-2 gap-2 scrollbar-w-2 scrollbar scrollbar-thumb-slate-200 scrollbar-thumb-rounded-md scrollbar-track-transparent">
            <AnimatePresence>
              {memoizedRecruitLocations?.map((location, index) => (
                <motion.div
                  initial={{ opacity: 0, y: "100%" }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, x: "-100%" }}
                  key={location.Id}
                >
                  <RPMRecruitCards
                    index={index}
                    location={location}
                    favoritePlayer={favoritePlayer}
                    removeLocation={removeLocation}
                    usersData={usersData}
                  />
                </motion.div>
              ))}
            </AnimatePresence>
          </div>
        </div>
        <div className="relative h-full md:w-1/2">
          <AnimatePresence>
            {loadingLocations ? (
              <motion.div
                initial={{ opacity: 0, scaleY: 0, y: -100 }}
                animate={{ opacity: 1, scaleY: 1, y: 0 }}
                exit={{ opacity: 0, scaleY: 0, y: "-40%" }}
                className="absolute font-bold text-xl top-4 left-0 w-fit right-0 mx-auto z-50 rounded-md shadow-sm shadow-slate-800 bg-slate-200 p-2 justify-center items-center"
              >
                Finding Recruits <Spin size="medium" />
              </motion.div>
            ) : (
              <motion.div
                initial={{ opacity: 0, scaleY: 0, y: -100 }}
                animate={{ opacity: 1, scaleY: 1, y: 0 }}
                exit={{ opacity: 0, scaleY: 0, y: "-40%" }}
                className="absolute font-bold text-xl top-4 left-0 w-fit right-0 mx-auto z-10 rounded-md shadow-sm shadow-slate-800 bg-slate-200 p-2 justify-center items-center"
              >
                <motion.button onClick={() => getLocations()}>
                  Search Area
                </motion.button>
              </motion.div>
            )}
          </AnimatePresence>
          <div className="absolute bottom-[-1rem] md:top-[0rem] md:left-[-100%] z-30 p-1 bg-slate-800 w-1/2 md:w-1/4 h-10 rounded-md">
            <motion.button
              onClick={() => setShowFavRec(!showFavRec)}
              className="w-full h-8 z-30 bg-slate-700 text-center font-semibold hover:text-red-300 text-white rounded-b-md"
            >
              Favorites
            </motion.button>
            <AnimatePresence>
              {showFavRec && (
                <motion.div
                  initial={{ opacity: 0, scaleY: 0, y: "-50%", zIndex: 10 }}
                  animate={{ opacity: 1, scaleY: 1, y: 0, zIndex: 10 }}
                  exit={{ opacity: 0, scaleY: 0, y: "-35%", zIndex: 10 }}
                  key={"favTable"}
                  className="w-[95vw] md:w-[47vw] max-h-[30rem] overflow-y-auto bg-slate-800 rounded-md pt-1 p-2 scrollbar-w-2 scrollbar scrollbar-thumb-slate-200 scrollbar-thumb-rounded-md scrollbar-track-transparent"
                >
                  <Table
                    className="w-full"
                    virtualized
                    size="small"
                    pagination={{ position: ["none"] }}
                    columns={recruitColumns}
                    dataSource={favoritedRecruits}
                    rowKey={(record) => record.id}
                    scroll={{ x: "max-content" }}
                    expandable={{
                      expandedRowKeys: expandedRow ? [expandedRow] : [],
                      onExpand: (expanded, record) => {
                        if (expanded) {
                          setExpandedRow(record.id);
                        } else {
                          setExpandedRow(null);
                        }
                      },
                      rowExpandable: (record) => {
                        if (record.filmLink) {
                          return true;
                        } else {
                          return false;
                        }
                      },
                      expandedRowRender: (record) => {
                        if (record.filmLink) {
                          return (
                            <div className="w-[95%] h-fit max-w-[36rem] mt-1 border aspect-video relative">
                              <div
                                className="absolute top-0 left-0 w-full h-full"
                                dangerouslySetInnerHTML={{
                                  __html: record.filmLink,
                                }}
                              />
                            </div>
                          );
                        }
                      },
                    }}
                  ></Table>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
          <GoogleMap
            mapContainerClassName="h-[25rem] md:h-full w-full rounded-sm "
            zoom={10}
            center={center}
            options={options}
            onLoad={onLoad}
          >
            {mapMarkers}
            {currentLocation && <Marker position={currentLocation} />}
          </GoogleMap>
        </div>
      </div>
    );
  }
}
