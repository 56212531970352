import React, { useState, useEffect, useContext, useRef } from "react";
import { AuthContext } from "../context/AuthContext";
import { PathContext } from "../context/PathContext";
import { motion } from "framer-motion";
import { db } from "../Firebase-config";
import {
  getDocs,
  collection,
  documentId,
  where,
  query,
} from "firebase/firestore";
import PlayerCascader from "../components/PlayerCascader";
import VDStackedBarChart from "../components/VDStackedBarChart";
import VDPieChart from "../components/VDPieChart";
import VDLineChart from "../components/VDLineChart";
import VDMultiDataTitleCascader from "../components/VDMultiDataTitleCascader ";
import VDScoreSheet from "../components/VDScoreSheet";
import {
  Button,
  Drawer,
  Input,
  Space,
  Spin,
  Table,
  Tabs,
  Tag,
  Tooltip,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { openNotificationWithIcon } from "../helperFunctions/openNotificationWithIcon";
import { LogoPageHeader } from "../components/LogoPageHeader";
import { HeaderScrollVisibility } from "../hooks/HeaderScrollVisibility";

export const VisualData = () => {
  const [gradeTitle, setGradeTitle] = useState();
  const [playerNames, setPlayerNames] = useState();
  const [gradeData, setGradeData] = useState([]);
  const [PCData, setPCData] = useState([]);
  const [BGData, setBGData] = useState([]);
  const [LCData, setLCData] = useState([]);
  const [scoreSheetData, setScoreSheetData] = useState([]);
  const [playerProfiles, setPlayerProfiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const { currentUser } = useContext(AuthContext);
  const { selectedPath } = useContext(PathContext);

  const setPlayerButtons = (props) => {
    const playerStrings = props.e.map((player) => player[2]);
    setPlayerNames(playerStrings);
    setPlayerProfiles(props.profileData);
  };

  const updateGradeTitle = (title) => {
    setGradeTitle(title);
  };

  useEffect(() => {
    setGradeData([]);
    setSelectedPositions([]);
    setPCData([]);
    setLCData([]);
    setBGData([]);
  }, [selectedPath]);

  const getData = async () => {
    if (!gradeTitle || !playerNames) {
      openNotificationWithIcon("warning", "Select Players / Events", "", "top");
      return;
    }
    setLoading(true);
    const collectionPath = collection(db, "Teams", selectedPath, "EventData");
    const batches = [];

    const gradeLabel = [...gradeTitle];

    while (gradeLabel.length) {
      // firestore limits batches to 10
      const batch = gradeLabel.splice(0, 10);
      // turn each array into a string to correctly fetch each file
      const textBatch = batch.map((array) => array.join() + " PlayerData");

      // add the batch request to to a queue
      batches.push(
        (async () => {
          const results = await getDocs(
            query(collectionPath, where(documentId(), "in", [...textBatch]))
          );
          return results.docs.map((result) => ({
            id: result.id,
            ...result.data(),
          }));
        })()
      );
    }

    // after all of the data is fetched, return it
    const content = await Promise.all(batches);
    const flattenedData = content.flat();

    // Convert the `createdAt` timestamp to a Date object.
    const convertTimestampToDate = (timestamp) => {
      const seconds = timestamp.seconds;
      const nanoseconds = timestamp.nanoseconds / 1000000000;
      return new Date(seconds * 1000 + nanoseconds);
    };

    // Sort the array of objects by the `createdAt` key in ascending order.
    const orderedData = flattenedData.sort((a, b) => {
      const aDate = convertTimestampToDate(a.createdAt);
      const bDate = convertTimestampToDate(b.createdAt);

      return aDate - bDate;
    });

    console.log("Ordered Dat:", orderedData);

    setGradeData(orderedData);
  };

  const [filteredProfileData, setFilteredProfileData] = useState([]);

  useEffect(() => {
    const modifyData = () => {
      if (!playerNames) {
        return;
      }
      const playerData = gradeData.flatMap((item) => item.playerData);
      console.log("RawPlayerData", playerData);

      // Define a helper function to calculate totals by full name and position
      const calculateTotals = (playerData) => {
        const totals = {};

        for (const obj of playerData) {
          const label = obj.playerId || obj["full-name"];
          const position = obj.position;

          if (!totals[label]) {
            totals[label] = {};
          }

          if (!totals[label][position]) {
            totals[label][position] = {};
          }

          for (const key in obj) {
            if (
              key !== "full-name" &&
              key !== "playerId" &&
              key !== "__proto__" &&
              key !== "gameID" &&
              key !== "name-play" &&
              key !== "position" &&
              key !== "assignment"
            ) {
              const value = parseFloat(obj[key]) || 0;

              // Total by full name and position
              totals[label][position][key] =
                (totals[label][position][key] || 0) + value;
            }
          }
        }

        return totals;
      };

      // Calculate total stats by full name and position
      const fullNameTotals = calculateTotals(playerData);

      console.log("fullNameTotals", fullNameTotals);

      const filteredProfiles = playerProfiles.filter((object) =>
        playerNames.some((name) => object["full-name"].includes(name))
      );

      console.log("filteredProfiles", filteredProfiles);
      setFilteredProfileData(filteredProfiles);

      const combineTotalsWithProfiles = (fullNameTotals, filteredProfiles) => {
        const mergedTotals = {};

        for (const fullName in fullNameTotals) {
          const matchingProfiles = filteredProfiles.filter(
            (profile) =>
              profile.id === fullName || profile["full-name"] === fullName
          );

          for (const profile of matchingProfiles) {
            const playerId = profile.id;

            // If the object matches filteredProfiles.id or full-name, return the object
            if (fullNameTotals[playerId] || fullNameTotals[fullName]) {
              const sourceTotals =
                fullNameTotals[playerId] || fullNameTotals[fullName];

              if (!mergedTotals[playerId]) {
                mergedTotals[playerId] = { ...sourceTotals };
              } else {
                // Merge nested data with the same key
                for (const position in sourceTotals) {
                  if (position !== "position") {
                    if (!mergedTotals[playerId][position]) {
                      mergedTotals[playerId][position] = {
                        ...sourceTotals[position],
                      };
                    } else {
                      for (const stat in sourceTotals[position]) {
                        mergedTotals[playerId][position][stat] =
                          (mergedTotals[playerId][position][stat] || 0) +
                          (sourceTotals[position][stat] || 0);
                      }
                    }
                  }
                }
              }
            }
          }
        }

        return mergedTotals;
      };

      // Example usage:
      const filteredFullNameTotals = combineTotalsWithProfiles(
        fullNameTotals,
        filteredProfiles
      );

      console.log("filteredFullNameTotals", filteredFullNameTotals);

      if (Object.keys(filteredFullNameTotals).length < 1) {
        openNotificationWithIcon(
          "warning",
          "No Data Found",
          "Try selecting other players or games",
          "top"
        );
      }

      const assignmentsByFullNameAndPosition = {};

      playerData.forEach((player) => {
        let playerLabel;
        const playerName = player["full-name"];
        const playerPosition = player.position;

        if (player.Id) {
          playerLabel = player.Id;
        } else {
          const matchingProfile = filteredProfiles.find(
            (profile) => profile["full-name"] === player["full-name"]
          );
          playerLabel = matchingProfile
            ? matchingProfile.id
            : player["full-name"];
        }

        const isPlus = player.assignment === "+";

        if (!assignmentsByFullNameAndPosition[playerLabel]) {
          assignmentsByFullNameAndPosition[playerLabel] = {};
        }

        if (!assignmentsByFullNameAndPosition[playerLabel][playerPosition]) {
          assignmentsByFullNameAndPosition[playerLabel][playerPosition] = {
            playerName: playerName,
            totalAssignments: 0,
            plusAssignments: 0,
            minusAssignments: 0,
            playerId: playerLabel,
          };
        }

        assignmentsByFullNameAndPosition[playerLabel][
          playerPosition
        ].plusAssignments += isPlus ? 1 : 0;
        assignmentsByFullNameAndPosition[playerLabel][
          playerPosition
        ].minusAssignments += isPlus ? 0 : 1;
      });

      // Calculate totalAssignments by adding plusAssignments and minusAssignments
      for (const key in assignmentsByFullNameAndPosition) {
        const positions = assignmentsByFullNameAndPosition[key];
        for (const position in positions) {
          const assignment = positions[position];
          assignment.totalAssignments =
            assignment.plusAssignments + assignment.minusAssignments;
        }
      }

      console.log(
        "AssignByFullNameAndPosition",
        assignmentsByFullNameAndPosition
      );

      const assignmentTotals = assignmentsByFullNameAndPosition;

      const assignments = [];

      // Your existing loop for assignmentsByFullNameAndPosition
      for (const fullName in assignmentTotals) {
        const positions = assignmentTotals[fullName];

        // Iterate through each position for the current player
        for (const position in positions) {
          const totals = positions[position];

          if (playerNames.includes(totals.playerName)) {
            const playerData = {
              "full-name": fullName,
              position: position,
              ...totals,
            };

            assignments.push(playerData);
          }
        }
      }

      console.log("Assignments:", assignments);

      const barGraphData = [];

      // Function to format stats based on player position
      const formatStatsByPosition = (position, positionData) => {
        switch (position) {
          case "DL":
          case "LB":
          case "CB":
          case "SAF":
            return {
              // Defensive Stats
              Tackles: positionData.tackle,
              Assists: positionData.assist,
              Sacks: positionData.sack,
              TFLs: positionData.TFL,
              Hurry: positionData.hurry,
              INT: positionData.interception,
              "Forced Fumbles": positionData["forced-fumble"],
              "Fumble Rec": positionData["fumble-recovery"],
              TDs: positionData.touchdown,
              PBUs: positionData["pass-breakup"],
              MA: positionData.hasOwnProperty("missed-assignment")
                ? positionData["missed-assignment"] * -1
                : undefined,
              MT: positionData["missed-tackle"]
                ? positionData["missed-tackle"] * -1
                : undefined,
              CE: positionData["critical-error"]
                ? positionData["critical-error"] * -1
                : undefined,
              Loaf: positionData["loaf"]
                ? positionData["loaf"] * -1
                : undefined,
            };
          case "QB":
            return {
              COM: positionData.completion,
              INCOM: positionData.incompletion,
              ACC: positionData.accurate,
              INACC: positionData.inaccurate,
              "Completion %": Math.round(
                (positionData.completion /
                  (positionData.completion + positionData.incompletion)) *
                  100
              ),
              "ADJ Completion %": Math.round(
                (positionData.accurate /
                  (positionData.accurate + positionData.inaccurate)) *
                  100
              ),
              TDs: positionData.touchdown,
              Scrambles: positionData.scramble,
              "Explosive Plays": positionData.explosivePlay,
              Receptions: positionData.reception,
              Drops: positionData.drop ? positionData.drop * -1 : undefined,
              MA: positionData.hasOwnProperty("missed-assignment")
                ? positionData["missed-assignment"] * -1
                : undefined,
              INT: positionData.interception
                ? positionData.interception * -1
                : undefined,
              Fumbles: positionData.fumble
                ? positionData.fumble * -1
                : undefined,
              Sacks: positionData.sack ? positionData.sack * -1 : undefined,
              Loaf: positionData["loaf"]
                ? positionData["loaf"] * -1
                : undefined,
            };
          case "OL":
            return {
              Pancakes: positionData.pancake,
              Stonewalls: positionData.stonewall,
              Displacements: positionData.displacement,
              "Key Blocks": positionData.keyBlock,
              "2nd Lvl Blocks": positionData["2LvlBlock"],
              Finishes: positionData.finish,
              TDs: positionData.touchdown,
              MAs: positionData.hasOwnProperty("missed-assignment")
                ? positionData["missed-assignment"] * -1
                : undefined,
              "Off-Balance": positionData.offBalance
                ? positionData.offBalance * -1
                : undefined,
              "Poor-Stance": positionData.stance
                ? positionData.stance * -1
                : undefined,
              Wiffs: positionData.wiff ? positionData.wiff * -1 : undefined,
              Loafs: positionData.loaf ? positionData.loaf * -1 : undefined,
            };
          case "TE":
            return {
              EXP: positionData.explosivePlay,
              "Broken Tackles": positionData.brkTackle,
              REC: positionData.reception,
              TDs: positionData.touchdown,
              Pancakes: positionData.pancake,
              "Extra Efforts": positionData["extra-effort"],
              "Situational Awareness": positionData["sit-awareness"],
              Drops: positionData.drop ? positionData.drop * -1 : undefined,
              Fumbles: positionData.fumble
                ? positionData.fumble * -1
                : undefined,
              Footwork: positionData.footwork
                ? positionData.footwork * -1
                : undefined,
              Pacing: positionData.pacing
                ? positionData.pacing * -1
                : undefined,
              Loafs: positionData.loaf ? positionData.loaf * -1 : undefined,
              MAs: positionData.hasOwnProperty("missed-assignment")
                ? positionData["missed-assignment"] * -1
                : undefined,
            };
          case "RB":
            return {
              EXP: positionData.explosivePlay,
              "Broken Tackles": positionData.brkTackle,
              REC: positionData.reception,
              TDs: positionData.touchdown,
              Pancakes: positionData.pancake,
              "Extra Efforts": positionData["extra-effort"],
              "Situational Awareness": positionData["sit-awareness"],
              Drops: positionData.drop ? positionData.drop * -1 : undefined,
              Fumbles: positionData.fumble
                ? positionData.fumble * -1
                : undefined,
              Footwork: positionData.footwork
                ? positionData.footwork * -1
                : undefined,
              Pacing: positionData.pacing
                ? positionData.pacing * -1
                : undefined,
              Loafs: positionData.loaf ? positionData.loaf * -1 : undefined,
              MAs: positionData.hasOwnProperty("missed-assignment")
                ? positionData["missed-assignment"] * -1
                : undefined,
            };
          case "WR":
            return {
              EXP: positionData.explosivePlay,
              "Broken Tackles": positionData.brkTackle,
              REC: positionData.reception,
              TDs: positionData.touchdown,
              Pancakes: positionData.pancake,
              "Extra Efforts": positionData["extra-effort"],
              "Situational Awareness": positionData["sit-awareness"],
              Drops: positionData.drop ? positionData.drop * -1 : undefined,
              Fumbles: positionData.fumble
                ? positionData.fumble * -1
                : undefined,
              Footwork: positionData.footwork
                ? positionData.footwork * -1
                : undefined,
              Pacing: positionData.pacing
                ? positionData.pacing * -1
                : undefined,
              Loafs: positionData.loaf ? positionData.loaf * -1 : undefined,
              MAs: positionData.hasOwnProperty("missed-assignment")
                ? positionData["missed-assignment"] * -1
                : undefined,
            };
          default:
            // Default formatting for other positions
            return {};
        }
      };

      // Your existing loop for filteredFullNameTotals
      for (const fullName in filteredFullNameTotals) {
        const positions = filteredFullNameTotals[fullName];

        for (const position in positions) {
          const positionData = positions[position];

          // Format stats based on player position
          const formattedStats = formatStatsByPosition(position, positionData);

          // Find the corresponding assignment data
          const matchingAssignment = assignments.find(
            (assignment) =>
              assignment["full-name"] === fullName &&
              assignment.position === position
          );

          barGraphData.push({
            nameId: fullName,
            position: position,
            // Spread the formatted stats
            ...formattedStats,
            // Spread assignment data
            ...(matchingAssignment || {}),
          });
        }
      }

      console.log("BGData:", barGraphData);

      const updatedBarGraphTotals = barGraphData.map((player) => {
        const matchingPlayerProfile = playerProfiles.find(
          (profile) =>
            profile["full-name"] === player.nameId ||
            profile.id === player.nameId
        );

        // Destructure the player object to exclude 'full-name' and 'PlayerName'
        const {
          "full-name": _,
          playerName: __,
          nameId: ___,
          ...playerWithoutNames
        } = player;

        const result = {
          Name: matchingPlayerProfile?.["full-name"],
          group: `${matchingPlayerProfile["full-name"]} (${player.position})`,
          position: player.position,
          img: matchingPlayerProfile?.img,
          playerId: player.playerId,
          ...playerWithoutNames, // Use the destructured object without 'full-name' and 'PlayerName'
          totalAssignments: player.totalAssignments,
          minusAssignments: player.minusAssignments,
          plusAssignments: player.plusAssignments,
          percentAssignment: Math.round(
            (player.plusAssignments / player.totalAssignments) * 100
          ),
          percentAssignment: Math.round(
            (player.plusAssignments / player.totalAssignments) * 100
          ),
        };

        // Remove undefined key-value pairs
        const filteredResult = Object.fromEntries(
          Object.entries(result).filter(([_, value]) => value !== undefined)
        );

        return filteredResult;
      });

      console.log("UBGT", updatedBarGraphTotals);

      updatedBarGraphTotals.sort(
        (a, b) => b.percentAssignment - a.percentAssignment
      );

      setScoreSheetData(updatedBarGraphTotals);

      const filteredBarGraphData = updatedBarGraphTotals.map((player) => {
        const filteredPlayer = {};
        Object.entries(player).forEach(([key, value]) => {
          if (
            key === "group" ||
            (value !== undefined &&
              value !== null &&
              value !== isNaN &&
              value !== 0)
          ) {
            filteredPlayer[key] = value;
          }
        });
        return filteredPlayer;
      });

      console.log("FilteredBGData", filteredBarGraphData);
      setBGData(filteredBarGraphData);
      setPCData(updatedBarGraphTotals);

      const result = [];

      console.log("gradeData", gradeData);

      gradeData.forEach((game) => {
        const playerData = game.playerData;
        const gradeLabel = game.gradeLabel[0];

        const assignmentsByGame = {};

        playerData.forEach((player) => {
          let playerLabel;
          const playerName = player["full-name"];
          const position = player.position;

          if (player.Id) {
            playerLabel = player.Id + position;
          } else {
            const matchingProfile = filteredProfiles.find(
              (profile) => profile["full-name"] === player["full-name"]
            );
            playerLabel = matchingProfile
              ? matchingProfile.id + position
              : player["full-name"];
          }

          const isPlus = player.assignment === "+";

          if (
            !assignmentsByGame[playerLabel] ||
            assignmentsByGame[playerLabel].position !== position
          ) {
            assignmentsByGame[playerLabel] = {
              playerName: playerName,
              totalAssignments: 0,
              plusAssignments: 0,
              minusAssignments: 0,
              playerId: playerLabel,
              position: position,
            };
          }

          assignmentsByGame[playerLabel].plusAssignments += isPlus ? 1 : 0;
          assignmentsByGame[playerLabel].minusAssignments += isPlus ? 0 : 1;
        });

        // Calculate totalAssignments by adding plusAssignments and minusAssignments
        for (const key in assignmentsByGame) {
          const assignment = assignmentsByGame[key];
          assignment.totalAssignments =
            assignment.plusAssignments + assignment.minusAssignments;
        }

        console.log("AssignByGame", assignmentsByGame);

        // Create an array of objects for each player's data
        const playerDataObjects = Object.keys(assignmentsByGame).map(
          (index) => {
            const assignments = assignmentsByGame[index];
            const playerId = assignments.playerId;
            const playerPos = assignments.position;
            const plusAssignment = assignments.plusAssignments;
            const totalAssignment = assignments.totalAssignments;

            return {
              id: assignments.playerName + ` (${playerPos})`,
              playerName: assignments.playerName,
              playerId: playerId,
              position: playerPos,
              color: `hsl(${Math.floor(Math.random() * 360)}, 70%, 50%)`,
              data: [
                {
                  x: gradeLabel,
                  y: Math.round((plusAssignment / totalAssignment) * 100),
                  totAssignment: totalAssignment,
                  posAssignment: plusAssignment,
                },
              ],
            };
          }
        );
        console.log("PDO", playerDataObjects);

        // Add the player data objects to the result array
        playerDataObjects.forEach((playerDataObj) => {
          const fullName = playerDataObj.id;
          const playerId = playerDataObj.playerId;
          const position = playerDataObj.position;

          // Check if a player object with this name already exists in the result array
          const existingPlayerObj = result.find(
            (obj) => obj.id === fullName && obj.position === position
          );

          if (existingPlayerObj) {
            // If the player object exists, add the current game's data to it
            existingPlayerObj.data.push(playerDataObj.data[0]);
          } else {
            // If the player object doesn't exist, create a new object for it and add the current game's data
            result.push({
              id: fullName,
              playerId: playerId,
              playerName: playerDataObj.playerName,
              position: position,
              color: playerDataObj.color,
              data: playerDataObj.data,
            });
          }
        });
      });
      console.log("result", result);

      const filteredResult = result.filter(
        (obj) =>
          filteredProfiles.some(
            (profile) => profile.id + profile.position === obj.playerId
          ) ||
          filteredProfiles.some(
            (profile) => profile["full-name"] === obj.playerName
          )
      );

      const finalResult = filteredResult.sort(
        (profileA, profileB) => profileB.data.length - profileA.data.length
      );

      console.log("filteredResult", finalResult);

      setLCData(filteredResult);
      setLoading(false);
    };
    modifyData();
  }, [gradeData]);

  const PlayerPlayByPlay = () => {
    const flatData = gradeData.flatMap((item) => item.playerData);
    console.log("flatData", flatData);
    console.log("playerNames", filteredProfileData);
    const filteredFlatData = flatData.filter((item) =>
      filteredProfileData.some(
        (profile) =>
          profile["full-name"] === item["full-name"] ||
          profile.id === item.playerId
      )
    );

    // Create a mapping of playerId to 'full-name' in filteredProfileData
    const playerIdToFullNameMap = {};
    filteredProfileData.forEach((profile) => {
      playerIdToFullNameMap[profile.id] = profile["full-name"];
    });

    // Update 'full-name' in filteredFlatData based on playerId
    const updatedFilteredFlatData = filteredFlatData.map((item) => {
      const updatedItem = { ...item };
      if (playerIdToFullNameMap[item.playerId]) {
        updatedItem["full-name"] = playerIdToFullNameMap[item.playerId];
      }
      return updatedItem;
    });

    console.log("updatedFilteredFlatData", updatedFilteredFlatData);

    const PBPData = updatedFilteredFlatData.map((item) => {
      const stringStats = [];

      for (const key in item) {
        if (
          key !== "stringStats" &&
          item[key] !== null &&
          item[key] !== 0 &&
          item[key] !== "" &&
          !isNaN(item[key])
        ) {
          stringStats.push(`${key}: ${item[key]}`);
        }
      }

      item.stringStats = stringStats.join(", ");
      return item;
    });

    const searchInput = useRef(null);
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
	const [expandedRow, setExpandedRow] = useState(null);

	const isMobile = window.innerWidth < 1036; // Check if the screen width is smaller than 768px



    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
      clearFilters();
      setSearchText("");
    };

    const getColumnSearchProps = (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        close,
      }) => (
        <div
          style={{
            padding: 8,
          }}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{
              marginBottom: 8,
              display: "block",
            }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{
                width: 90,
              }}
              className="text-black"
            >
              Search
            </Button>
            <Button
              onClick={() => clearFilters && handleReset(clearFilters)}
              size="small"
              style={{
                width: 90,
              }}
            >
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({
                  closeDropdown: false,
                });
                setSearchText(selectedKeys[0]);
                setSearchedColumn(dataIndex);
              }}
            >
              Filter
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                close();
              }}
            >
              close
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{
            color: filtered ? "#1890ff" : undefined,
          }}
        />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current?.select(), 100);
        }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{
              backgroundColor: "#ffc069",
              padding: 0,
            }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        ) : (
          text
        ),
    });

    const colData = [
      {
        key: "Play",
        dataIndex: "Play",
        title: "Play",
        type: "number",
        width: 65,
        fixed: "left",
      },
      {
        key: "gameID",
        dataIndex: "gameID",
        title: "Game",
        type: "text",
        width: 130,
      },
      {
        key: "Pos",
        dataIndex: "position",
        title: "Pos",
        type: "text",
        width: 60,
      },
      {
        key: "assignment",
        dataIndex: "assignment",
        title: "+/-",
        type: "text",
        width: 60,
        render: (tag) => (
          <span>
            <Tag color={tag === "+" ? "green" : "volcano"} key={tag}>
              {tag}
            </Tag>
          </span>
        ),
      },
      {
        key: "stringStats",
        dataIndex: "stringStats",
        title: "Production",
        type: "text",
        width: 200,
        ...getColumnSearchProps("stringStats"),
      },
      /*Comments*/ 
	  {
        key: "Comments",
        dataIndex: "coach-comments",
        title: "Comments",
        type: "text",
        fixed: "right",
        width: 150,
        ellipsis: {},
        render: (comments) => (
          <Tooltip placement="topLeft" title={comments}>
            {comments}
          </Tooltip>
        ),
      },
    ];

	const expColData = [
		{
		  key: "Play",
		  dataIndex: "Play",
		  title: "Play",
		  type: "number",
		  width: 65,
		  fixed: "left",
		},
		{
		  key: "gameID",
		  dataIndex: "gameID",
		  title: "Game",
		  type: "text",
		  width: 130,
		},
		{
		  key: "Pos",
		  dataIndex: "position",
		  title: "Pos",
		  type: "text",
		  width: 60,
		},
		{
		  key: "assignment",
		  dataIndex: "assignment",
		  title: "+/-",
		  type: "text",
		  width: 60,
		  render: (tag) => (
			<span>
			  <Tag color={tag === "+" ? "green" : "volcano"} key={tag}>
				{tag}
			  </Tag>
			</span>
		  ),
		},
		{
		  key: "stringStats",
		  dataIndex: "stringStats",
		  title: "Production",
		  type: "text",
		  width: 200,
		  ...getColumnSearchProps("stringStats"),
		},
	  ];

    // Group the PBPData by full-name
    const groupedPBPData = PBPData.reduce((acc, item) => {
      acc[item["full-name"]] = acc[item["full-name"]] || [];
      acc[item["full-name"]].push(item);
      return acc;
    }, {});

    const items = Object.keys(groupedPBPData).map((fullName, index) => ({
      key: index.toString(), // Ensure key is unique
      label: fullName,
      children: (
        <div className={`h-fit max-h-full overflow-y-auto w-full`}>
          <Table
            className={`${pbpExpanded ? "h-[65vh]" : "h-[25vh]"} w-full`}
            size="small"
            columns={isMobile ? expColData : colData}
            dataSource={groupedPBPData[fullName]}
            rowKey={(record) => record["name-play"]}
            scroll={{ x: 'max-content', y: "scroll" }}
            sticky={true}
            pagination={{ showSizeChanger: true }}
			expandable={{
				expandedRowKeys: expandedRow ? [expandedRow] : [],
				expandRowByClick: true,
				onExpand: (expanded, record) => {
				  if (expanded) {
					setExpandedRow(record["name-play"]);
				  } else {
					setExpandedRow(null);
				  }
				},
				rowExpandable: (record) => {
				  if (record['coach-comments']?.length > 0 && isMobile) {
					return true;
				  } else {
					return false;
				  }
				},
				expandedRowRender: (record) => {
				  if (record['coach-comments']) {
					return (
					  <div className="w-[95%] h-fit max-h-32 mt-1 relative">
						{record['coach-comments']}
					  </div>
					);
				  }
				},
			  }}
          />
        </div>
      ),
    }));

    // Render a table for each group
    return (
      <div className="w-full h-full">
        <Tabs defaultActiveKey="0" items={items} onChange={null} />
      </div>
    );
  };

  const [selectedPositions, setSelectedPositions] = useState({});
  const [showPBP, setShowPBP] = useState(false);
  const [pbpExpanded, setPBPExpanded] = useState(false);

  const scrollableDivRef = HeaderScrollVisibility();

  return (
    <motion.div
      initial={{
        y: -500,
      }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: 1000, opacity: 0 }}
    >
      <div
        ref={scrollableDivRef}
        className="relative top-0 left-0 h-screen w-screen overflow-y-scroll overflow-x-hidden"
      >
		<div className="sticky z-50 top-[99vh] h-0 w-screen">
        <button
          onClick={() => setShowPBP(!showPBP)}
          className="absolute z-50 bottom-[10px] right-5 font-bold text-lg shadow-sm shadow-black bg-slate-800 hover:bg-slate-600 text-center rounded-t-lg w-fit h-9"
        >
          <span className="p-1 z-20 m-auto text-white">{showPBP ? "" : "^ Play By Play Grades"}</span>
        </button>
		</div>
        <LogoPageHeader title={"Player Stats"} />

        <div className="h-fit pt-2 w-[98%] mx-auto bg-slate-100 bg-opacity-20 rounded-lg rounded-t-none sm:rounded-t-lg">
          <div className="flex mb-4 mx-auto w-[95%]">
            <div className="w-2/3">
              <div className="mb-2">
                <PlayerCascader onPlayerValue={setPlayerButtons} />
              </div>
              <div className="">
                <VDMultiDataTitleCascader updateTitle={updateGradeTitle} />
              </div>
            </div>

            <div className="ml-8 w-fit h-fit self-center bg-slate-200 rounded-md shadow-sm shadow-black p-2">
              <Button
                onClick={getData}
                className="add-profile mx-auto mb-1 h-fit p-2 bg-[#f1efe9] rounded-xl font-bold shadow-gray-700 shadow-sm"
              >
                {!loading && "Update"}
                {loading && (
                  <div className="flex text-black h-7 w-32">
                    <span className="ml-auto">Loading...</span>
                    <Spin className="pt-1 pl-1 mr-auto" />
                  </div>
                )}
              </Button>
            </div>
          </div>
          <Drawer
            title="Player Play by Play"
            // size={pbpExpanded ? "large" : ""}
            extra={
              <Button onClick={() => setPBPExpanded(!pbpExpanded)}>
                Expand
              </Button>
            }
			on
            mask={false}
            placement={"bottom"}
			height={pbpExpanded ? "92vh" : "50vh"}
            onClose={() => setShowPBP(!showPBP)}
            open={showPBP}
          >
            <PlayerPlayByPlay />
          </Drawer>

          <div className="flex flex-col z-20 content-center md:flex-row justify-center flex-wrap last:mb-96">
            <VDScoreSheet
              ScoreData={scoreSheetData}
			  gradeData={gradeData}
              setSelectedPositions={setSelectedPositions}
              selectedPositions={selectedPositions}
            />
            <div className="shadow-md bg-slate-100 rounded-lg m-2 w-[95%] md:w-[40%] h-96">
              <VDPieChart
                PCData={PCData}
                selectedPositions={selectedPositions}
                playerNames={playerNames}
              />
            </div>
            <div className="shadow-md bg-slate-100 rounded-lg m-2 w-[95%] overflow-x-auto md:w-1/2 h-96">
              <VDStackedBarChart
                BGData={BGData}
                selectedPositions={selectedPositions}
              />
            </div>
            <div className="shadow-md bg-slate-100 rounded-lg m-2 w-[95%] overflow-x-auto md:w-5/6 h-[35rem]">
              <VDLineChart
                LCData={LCData}
                selectedPositions={selectedPositions}
              />
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};
