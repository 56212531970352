import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Card } from "antd";

export const DCSortableItem = (props) => {

  const { attributes, listeners, setNodeRef, transform, transition  } =
    useSortable({ id: props.id, disabled: props.disabled });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: 'grab',
    zIndex: 1,
  };
  
  const full_name = props.name; // Replace with your actual full name
	const nameParts = full_name.split(' '); // Split the full name into parts by space
	const firstName = nameParts[0]; // Get the first name
	const lastName = nameParts.slice(1).join(' '); // Get the last name by joining the remaining parts


  return (
    <div 
    className={`player-div flex z-0 cursor-grab bg-slate-300 touch-none h-fit w-full max-w-44 min-w-fit truncate rounded-md p-1 text-white`}
     ref={setNodeRef} style={style} {...attributes} {...listeners} >
      <Card
        className={`${props.index < 1 || props?.containerId === null || props?.containerId === 'bucketContainer' || props?.containerId === 'outContainer' || props?.containerId === undefined  ? "h-12 text-lg font-bold pt-2 max-w-full" : "h-6 text-sm font-semibold -mt-1"} w-full`}
		bordered={props.index < 1 ? true : false}
        size='small'
      >
        <div className="flex my-auto h-fit -mt-3 truncate w-full">
        <div className="w-1/6 text-left font-semibold font-Kanit opacity-70">{`${props.number ? props.number : ''} `}</div>

        <div className="w-5/6 text-left ml-2 mx-auto max-w-24 text-base my-auto font-Kanit">{`${firstName[0]? firstName[0] + '.' : '' } ${lastName ? lastName : ''}`}</div>
        </div>
      </Card>
    </div>
  );
}
