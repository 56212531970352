import React, { useContext, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { HTeamFeed } from "../components/HTeamFeed";
import weatherButton from "../img/weatherButton.jpg";
import scoreboardButton from "../img/scoreboardButton.jpg";
import standingsButton from "../img/standingsButton.jpg";
import EvaluxClearLogo from "../img/EvaluxClearLogo.png";
import { HLatestScoresWidget } from "../components/HLatestScoresWidget";
import { HLatestStandingsWidgetCopy } from "../components/HLatestStandingsWidget copy";
import { PathContext } from "../context/PathContext";
import { LogoPageHeader } from "../components/LogoPageHeader";
import { HeaderScrollVisibility } from "../hooks/HeaderScrollVisibility";
import { NavLink } from "react-router-dom";
// import { TestCascader } from "../components/TestCascader";
import { TeamContext } from "../context/TeamProvider";
import { HBulletinBoard } from "../components/HBulletinBoard";
import { HSchedulePic } from "../components/HSchedulePic";

export function HomeMobile({ proMember }) {
  const [showWeather, setShowWeather] = useState(false);
  const [showLeagueScores, setShowLeagueScores] = useState(false);
  const [showLeagueStandings, setShowLeagueStandings] = useState(false);
  const [showTeamFeed, setShowTeamFeed] = useState(false);
  const [showSchedule, setShowSchedule] = useState(false);
  const [showBulletin, setShowBulletin] = useState(true);

  const { teamSelected } = useContext(TeamContext);

  const { selectedLocation } = useContext(PathContext);
  const mapLat = selectedLocation?.lat
    ? parseFloat(selectedLocation?.lat.toFixed(3))
    : 40.712;
  const mapLng = selectedLocation?.lng
    ? parseFloat(selectedLocation?.lng.toFixed(3))
    : 74.006;

  const scrollableDivRef = HeaderScrollVisibility();

  return (
    <motion.div
      className="h-screen w-full overflow-auto scrollbar-hide"
      initial={{ y: -1500 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: 1000, opacity: 0 }}
      ref={scrollableDivRef}
    >
      {/* <HTeamFeed setShowTeamFeed={setShowTeamFeed} showTeamFeed={showTeamFeed} /> */}
      <div className="h-full w-full mx-auto">
        <LogoPageHeader title={"Daily Operations"} />

        <div className="grid grid-cols-2 py-4 text-center mx-auto h-fit bg-slate-100 bg-opacity-20 rounded-lg rounded-t-none sm:rounded-t-lg">
          <div className="md:col-span-1 col-span-2 mb-2">
            <motion.button
              key="standingsWidget"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="relative overflow-hidden w-full sm:w-[90%] lg:w-[93%] hover:bg-opacity-50 text-lg sm:rounded-lg z-10 font-bold h-16 mt-0 mx-auto bg-slate-950 bg-opacity-80 text-white shadow-sm shadow-black"
              onClick={() => {
                setShowBulletin(!showBulletin);
              }}
            >
              <span className="absolute z-0 w-full h-12 left-0 top-1/4">
                Bulletin Board
              </span>
              {/* <img
                src={standingsButton}
                className="top-0 left-0 h-24 w-full transition-all ease-in-out aspect-auto rounded-lg opacity-0 hover:opacity-50"
                alt="League Standings Graphic"
              /> */}
            </motion.button>

            <AnimatePresence>
              {showBulletin && (
                <motion.div
                  initial={{ opacity: 0, x: "-100%" }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: "100%" }}
                  transition={{ ease: "anticipate" }}
                >
                  <HBulletinBoard />
                </motion.div>
              )}
            </AnimatePresence>
          </div>

          <div className="md:col-span-1 col-span-2">
            <motion.button
              key="scoresWidget"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="relative overflow-hidden w-full sm:w-[90%] lg:w-[95%] hover:bg-opacity-50 text-lg sm:rounded-lg z-20 font-bold h-16 mx-auto bg-slate-950 bg-opacity-80 text-white shadow-sm shadow-black"
              onClick={() => {
                setShowLeagueScores(!showLeagueScores);
              }}
            >
              <span className="absolute z-0 w-full h-12 left-0 top-1/4">
                Latest Scores
              </span>
              <img
                src={scoreboardButton}
                className="top-0 left-0 h-full transition-all ease-in-out sm:h-24 w-full aspect-auto rounded-lg opacity-0 hover:opacity-50"
                alt="Scores Graphic"
              />
            </motion.button>
            <AnimatePresence>
              {showLeagueScores && (
                <motion.div
                  initial={{ opacity: 0, x: "-100%" }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: "100%" }}
                  transition={{ ease: "anticipate" }}
                >
                  <HLatestScoresWidget setShowTeamFeed={setShowTeamFeed} />
                </motion.div>
              )}
            </AnimatePresence>
          </div>
          <div className="md:col-span-1 col-span-2">
            <motion.button
              key="standingsWidget"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="relative overflow-hidden w-full sm:w-[90%] lg:w-[93%] hover:bg-opacity-50 text-lg sm:rounded-lg z-10 font-bold h-16 mt-0 mb-0 mx-auto bg-slate-950 bg-opacity-80 text-white shadow-sm shadow-black"
              onClick={() => {
                setShowLeagueStandings(!showLeagueStandings);
              }}
            >
              <span className="absolute z-0 w-full h-12 left-0 top-1/4">
                League Standings
              </span>
              <img
                src={standingsButton}
                className="top-0 left-0 h-24 w-full transition-all ease-in-out aspect-auto rounded-lg opacity-0 hover:opacity-50"
                alt="League Standings Graphic"
              />
            </motion.button>

            <AnimatePresence>
              {showLeagueStandings && (
                <motion.div
                  initial={{ opacity: 0, x: "-100%" }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: "100%" }}
                  transition={{ ease: "anticipate" }}
                >
                  <HLatestStandingsWidgetCopy />
                </motion.div>
              )}
            </AnimatePresence>
          </div>

          <div className="md:col-span-1 col-span-2">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="relative w-full col-span-2 overflow-hidden sm:w-[90%] text-lg sm:rounded-lg z-10 font-bold h-16 mt-0 mx-auto hover:bg-opacity-50 bg-slate-950 bg-opacity-80 text-white shadow-sm shadow-black"
              onClick={() => setShowWeather(!showWeather)}
            >
              <span className="absolute z-0 w-full h-12 left-0 top-1/4">
                Weather
              </span>
              <img
                src={weatherButton}
                className="top-0 left-0 h-full sm:h-24 sm:w-[87%] w-full transition-all ease-in-out aspect-auto rounded-lg opacity-0 hover:opacity-50"
                alt="Weather Graphic"
              />
            </motion.button>
            <AnimatePresence>
              {showWeather && (
                <motion.div
                  initial={{ opacity: 0, x: "-100%" }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: "100%" }}
                  transition={{ ease: "anticipate" }}
                  className="mx-auto w-fit bg-slate-800 p-2 pt-1 rounded-xl"
                >
                  <iframe
                    className="rounded-xl h-[70vh] w-[90vw] md:h-[35vh] md:w-[45vw] shadow-sm shadow-slate-800"
                    src={`https://embed.windy.com/embed2.html?lat=${mapLat}&lon=${mapLng}&detailLat=${mapLat}&detailLon=${mapLng}&width=650&height=435&zoom=10&level=surface&overlay=rain&product=ecmwf&menu=&message=&marker=&calendar=now&pressure=&type=map&location=coordinates&detail=true&metricWind=mph&metricTemp=%C2%B0F&radarRange=-1`}
                    frameborder="0"
                  ></iframe>
                </motion.div>
              )}
            </AnimatePresence>
          </div>

          <div className="col-span-2">
            <motion.button
              key="standingsWidget"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="relative z-20 overflow-hidden w-full sm:w-[90%] lg:w-[93%] hover:bg-opacity-50 text-lg sm:rounded-lg font-bold h-16 mt-0 mx-auto bg-slate-950 bg-opacity-80 text-white shadow-sm shadow-black"
              onClick={() => {
                setShowSchedule(!showSchedule);
              }}
            >
              <span className="absolute z-0 w-full h-12 left-0 top-1/4">
                Schedule
              </span>
              {/* <img
                src={standingsButton}
                className="top-0 left-0 h-24 w-full transition-all ease-in-out aspect-auto rounded-lg opacity-0 hover:opacity-50"
                alt="League Standings Graphic"
              /> */}
            </motion.button>

            <AnimatePresence>
              {showSchedule && 
			  <motion.div
			  initial={{ opacity: 0, x: "-100%" }}
			  animate={{ opacity: 1, x: 0 }}
			  exit={{ opacity: 0, x: "100%" }}
			  transition={{ ease: "anticipate" }}
			>
			  <HSchedulePic />
			</motion.div>
			  }
            </AnimatePresence>
          </div>

          <NavLink
            className="flex font-bold text-lg bg-opacity-80 shadow-slate-800 text-white sm:rounded-lg shadow-inner hover:shadow-md hover:shadow-slate-900 hover:bg-opacity-100 h-52 mx-1 my-1 bg-slate-950"
            to="/DepthCharts"
          >
            <div className="m-auto font-thin font-Russo">Depth Charts</div>
          </NavLink>
          <NavLink
            className="flex font-bold text-lg bg-opacity-80 shadow-slate-800 text-white sm:rounded-lg shadow-inner hover:shadow-md hover:shadow-slate-900 hover:bg-opacity-100 h-52 mx-1 my-1 bg-slate-950"
            to="/RosterEditor"
          >
            <div className="m-auto font-thin font-Russo">Team Stats</div>
          </NavLink>
          <NavLink
            className="flex font-bold text-lg bg-opacity-80 shadow-slate-800 text-white sm:rounded-lg shadow-inner hover:shadow-md hover:shadow-slate-900 hover:bg-opacity-100 h-52 mx-1 my-1 bg-slate-950"
            to="/VisualData"
          >
            <div className="m-auto font-thin font-Russo">Player Stats</div>
          </NavLink>
          {proMember && (
            <>
              <NavLink
                className="flex font-bold text-lg bg-opacity-80 shadow-slate-800 text-white sm:rounded-lg shadow-inner hover:shadow-md hover:shadow-slate-900 hover:bg-opacity-100 h-52 mx-1 my-1 bg-slate-950"
                to="/GradeSheet"
              >
                <div className="m-auto font-thin font-Russo">Grade Sheet</div>
              </NavLink>
              <NavLink
                className="flex font-bold text-lg bg-opacity-80 shadow-slate-800 text-white sm:rounded-lg shadow-inner hover:shadow-md hover:shadow-slate-900 hover:bg-opacity-100 h-52 mx-1 my-1 bg-slate-950"
                to="/Recruit"
              >
                <div className="m-auto font-thin font-Russo">Recruit</div>
              </NavLink>
            </>
          )}
        </div>
      </div>
    </motion.div>
  );
}
