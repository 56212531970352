import React, { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../context/AuthContext';
import { PathContext } from '../context/PathContext';
import { Cascader, Button, Input, Popconfirm } from 'antd'
import { motion } from "framer-motion"
import { db } from '../Firebase-config'
import { doc, getDoc, writeBatch } from 'firebase/firestore'
import { AnimatePresence } from 'framer-motion'


function DataTitleCascader (props) {
    
  const [dataTitle, setDataTitle] = useState([])
  const [optionBar, setOptionBar] = useState(false)
  const [dataDocTitle, setDataDocTitle] = useState([])

  const {currentUser} = useContext(AuthContext)
  const { selectedPath } = useContext(PathContext);



    const barOpen = () => {setOptionBar(true) && setUpload(dataTitle)}
    const barClosed = async () => {
      const batch = writeBatch(db);
      setOptionBar(false)
      if (upload && Object.entries(upload).length !== 0){
      const docRef = doc(db, 'Teams', selectedPath, 'DataLabels', 'Labels');
        batch.set(docRef, upload)
        const dataDocRef1 = doc(db, 'Teams', selectedPath, 'EventData', dataDocTitle + ' PlayData' );
        const dataDocRef2 = doc(db, 'Teams', selectedPath, 'EventData', dataDocTitle + ' PlayerData' );
        batch.delete(dataDocRef1)
        batch.delete(dataDocRef2)
        try {
          await batch.commit();
          console.log('Batch operation complete');
        } catch (error) {
          console.error('Error executing batch operation:', error);
        }
      }
      console.log(upload)
    }
    
    const handleDeleteOption = async () => {
      let parent = dataTitle;
    
      if (cascaderValue !== undefined) {
        // Find the parent node of the new option
        for (let i = 0; i < cascaderValue.length; i++) {
          let node = parent.find(option => option.value === cascaderValue[i]);
          if (node.children) {
            parent = node.children;
          } else {
            node.children = [];
            parent = node.children;
          }
        }
      }
    
      // Check for duplicates
      if (parent !== undefined && parent.some(option => option.value === newOption)) {
        let node = parent.find(option => option.value === newOption);
    
        // Check for multiple children
        if (node.children && node.children.length > 0) {
          alert("Cannot Delete Folder. Try Deleting all events in the folder first.");
          return;
        }
    
        parent.splice(parent.indexOf(node), 1);
        setDataDocTitle(cascaderValue + ',' + node.value)
        setUpload({dataTitle})
        setDataTitle([...dataTitle])
      } else {
        alert("Label Does Not Exist!");
        return;
      }
    }

    useEffect(() => {
      const getTitleDoc = async () => {
      const docRef = doc(db, 'Teams', selectedPath, 'DataLabels', 'Labels');
      const docSnap = await getDoc(docRef);

      if (docSnap.exists() && docSnap.data().dataTitle !== undefined) {
          console.log("Document data:", docSnap.data());
          setDataTitle(docSnap.data().dataTitle)
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
          setDataTitle([{
            value: 'eg. Year',
            label: 'eg. Year',
            children: []
          }])
        }
     }
    
     getTitleDoc()
    
    },[selectedPath]);

    const [newOption, setNewOption] = useState([])
    const [upload, setUpload] = useState({})
    const [cascaderValue, setCascaderValue] = useState()

    const addOption = async () => {
      
      let parent = dataTitle
      
        if (cascaderValue !== undefined) {
          // Find the parent node of the new option
          for (let i = 0; i < cascaderValue.length; i++) {
            let node = parent.find(option => option.value === cascaderValue[i]);
            if (node.children) {
              parent = node.children;
            } else {
              node.children = [];
              parent = node.children;
            }
          }
        }
      
        // Check for duplicates
        if (parent !== undefined && parent.some(option => option.value === newOption)) {
          alert("Error");
          return;
        }
      
        // Add the new option
        parent.push({
          value: newOption,
          label: newOption,
          children: []
        });

          setUpload({dataTitle})
          setDataTitle([...dataTitle])
    }

    const filter = (inputValue, path) =>
    path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

  return (
    <>
      <div className='flex mx-auto'>
          <Cascader
          className='sm:w-72 h-fit ml-2 shadow-black shadow-sm rounded-lg'
          options={dataTitle}
          size='large'
          showSearch={{filter}}
          onChange={(e) => props.updateTitle(e)}
          showCheckedStrategy='SHOW_CHILD'
          multiple
          changeOnSelect
          maxTagCount="responsive"
          defaultValue={[]}
          placeholder={'Title Select'}
          />

          <Button 
          className='h-fit ml-[-10px]' 
          type='secondary'
          onClick={() => (optionBar ? barClosed() : barOpen())}
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-white">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </Button>
      </div>

      <AnimatePresence>
        {optionBar &&
          <motion.div 
          initial={{ opacity: 0, y: -10, scaleX: 0, scaleY: 0}}
          animate={{ opacity: 1, y: 0, scaleX: 1, scaleY: 1}}
          exit={{ opacity: 0, y: -10, scaleX: 0, scaleY: 0}}
          className='flex mx-auto w-full justify-center align-middle bg-white m-1 rounded-lg'
          >
            <Input.Group compact>
              <Input
              className='w-fit'
              addonBefore={
                <Cascader
                className='h-fit w-52'
                options={dataTitle}
                value={cascaderValue}
                changeOnSelect
                onChange={e => setCascaderValue(e)}
                placeholder="Add Year or Select Labels"
                />
              }
              placeholder="e.g. Week 2"
              onChange={e=>setNewOption(e.target.value)}
              />
            <Button className='text-black' type="primary" onClick={addOption}>Add Label</Button>
            <Popconfirm okType='danger' title="Delete ALL DATA associated with this Label?" onConfirm={handleDeleteOption}>
              <Button className='text-black' type="dashed">Delete Label & Data</Button>                        
            </Popconfirm>
            </Input.Group>
          </motion.div>
        }
      </AnimatePresence>
    </>
  )
}

export default DataTitleCascader