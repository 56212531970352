import { ConfigProvider, Select, Spin, Table } from "antd";
import { motion } from "framer-motion";
import React, { useState, useEffect } from "react";
import axios from "axios";

export function HLatestStandingsWidgetCopy() {
  const [tableData, setTableData] = useState([]);
  const [rawData, setRawData] = useState();
  const [loading, setLoading] = useState(true);

  const FBS = 80;
  const FCS = 81;
  const D2D3 = 35;

  const divisionMapping = [
    { value: FBS, name: "FBS" },
    { value: FCS, name: "FCS" },
    { value: D2D3, name: "D2 / D3" },
  ];

  const [division, setDivision] = useState(FBS);

  const handleDivisionChange = (value) => {
    setDivision(value);
  };

  useEffect(() => {
    const getStandings = async ({
      year = new Date().getFullYear(),
      group = 80,
    }) => {
      const baseUrl = `https://site.web.api.espn.com/apis/v2/sports/football/college-football/standings`;
      // const baseUrl = `https://site.web.api.espn.com/apis/v2/sports/football/nfl/standings`; NFL Standings
      const params = {
        region: "us",
        lang: "en",
        contentorigin: "espn",
        season: year,
        group: group,
      };
      const res = await axios.get(baseUrl, {
        params,
      });
      return res.data;
    };

    const fetchStandingsData = async () => {
      try {
        const result = await getStandings({ year: 2023, group: division });

        console.log("StandingsInfo", result);

        const desiredTypes = [
          "streak",
          "total",
          "homerecord",
          "awayrecord",
          "vsconf",
          "pointsfor",
          "pointsagainst",
          "pointdifferential",
          "vsconf_pointsfor",
          "vsconf_pointsagainst",
          "vsconf_pointdifferential",
        ];

        let conferences = [];

        if (result.children && result.children.length === 2) {
          const d2 = result.children[0];
          console.log(d2);
          const d3 = result.children[1];

          const conference1 = d2.children.map((conf) => {
            let confDataEntries = [];
            let divisionName = null;
            if (conf.standings && conf.standings.entries) {
              confDataEntries = conf.standings.entries.map((entry) => {
                const displayName = entry.team.displayName;
                const displayLogo = entry.team.logos
                  ? entry.team.logos[0].href
                  : null;
                const filteredStats = entry.stats
                  .filter((stat) => desiredTypes.includes(stat.type))
                  .map((stat) => ({
                    type: stat.type,
                    displayValue: stat.displayValue,
                  }));
                return {
                  teamName: displayName,
                  teamLogo: displayLogo,
                  teamRank: entry.team.rank,
                  stats: filteredStats,
                  divisionName,
                };
              });
            } else if (conf.children && conf.children.length > 1) {
              divisionName = conf.name;
              confDataEntries = conf.children.flatMap((childConf) => {
                if (childConf.standings) {
                  return childConf.standings.entries.map((entry) => {
                    const displayName = entry.team.displayName;
                    const displayLogo = entry.team.logos[0].href;
                    const filteredStats = entry.stats
                      .filter((stat) => desiredTypes.includes(stat.type))
                      .map((stat) => ({
                        type: stat.type,
                        displayValue: stat.displayValue,
                      }));
                    return {
                      teamName: displayName,
                      teamLogo: displayLogo,
                      teamRank: entry.team.rank,
                      stats: filteredStats,
                      divisionName: childConf.name,
                    };
                  });
                } else {
                  return [];
                }
              });
            }

            return {
              name: conf.name,
              confData: confDataEntries,
            };
          });

          console.log("D2:", conference1);
          setRawData(conference1);

          const conference2 = d3.children.map((conf) => {
            let confDataEntries = [];
            let divisionName = null;
            if (conf.standings && conf.standings.entries) {
              confDataEntries = conf.standings.entries.map((entry) => {
                const displayName = entry.team.displayName;
                const displayLogo = entry.team.logos
                  ? entry.team.logos[0].href
                  : null;
                const filteredStats = entry.stats
                  .filter((stat) => desiredTypes.includes(stat.type))
                  .map((stat) => ({
                    type: stat.type,
                    displayValue: stat.displayValue,
                  }));
                return {
                  teamName: displayName,
                  teamLogo: displayLogo,
                  teamRank: entry.team.rank,
                  stats: filteredStats,
                  divisionName,
                };
              });
            } else if (conf.children && conf.children.length > 1) {
              divisionName = conf.name;
              confDataEntries = conf.children.flatMap((childConf) => {
                if (childConf.standings) {
                  return childConf.standings.entries.map((entry) => {
                    const displayName = entry.team.displayName;
                    const displayLogo = entry.team.logos[0].href;
                    const filteredStats = entry.stats
                      .filter((stat) => desiredTypes.includes(stat.type))
                      .map((stat) => ({
                        type: stat.type,
                        displayValue: stat.displayValue,
                      }));
                    return {
                      teamName: displayName,
                      teamLogo: displayLogo,
                      teamRank: entry.team.rank,
                      stats: filteredStats,
                      divisionName: childConf.name,
                    };
                  });
                } else {
                  return [];
                }
              });
            }

            return {
              name: conf.name,
              confData: confDataEntries,
            };
          });

          console.log("D3:", conference2);
        } else {
          conferences = result.children.map((conf) => {
            let confDataEntries = [];
            let divisionName = null;
            if (conf.standings) {
              confDataEntries = conf.standings.entries.map((entry) => {
                const displayName = entry.team.displayName;
                const displayLogo = entry.team.logos[0].href;
                const filteredStats = entry.stats
                  .filter((stat) => desiredTypes.includes(stat.type))
                  .map((stat) => ({
                    type: stat.type,
                    displayValue: stat.displayValue,
                  }));
                return {
                  teamName: displayName,
                  teamLogo: displayLogo,
                  teamRank: entry.team.rank,
                  stats: filteredStats,
                  divisionName,
                };
              });
            } else if (conf.children && conf.children.length > 1) {
              divisionName = conf.name;
              confDataEntries = conf.children.flatMap((childConf) => {
                if (childConf.standings) {
                  return childConf.standings.entries.map((entry) => {
                    const displayName = entry.team.displayName;
                    const displayLogo = entry.team.logos[0].href;
                    const filteredStats = entry.stats
                      .filter((stat) => desiredTypes.includes(stat.type))
                      .map((stat) => ({
                        type: stat.type,
                        displayValue: stat.displayValue,
                      }));
                    return {
                      teamName: displayName,
                      teamLogo: displayLogo,
                      teamRank: entry.team.rank,
                      stats: filteredStats,
                      divisionName: childConf.name,
                    };
                  });
                } else {
                  return [];
                }
              });
            }

            return {
              name: conf.name,
              confData: confDataEntries,
            };
          });

          console.log("Conferences:", conferences);
          setRawData(conferences);
        }
      } catch (error) {
        console.error("Error fetching standings data:", error);
        // Handle the error, e.g., show an error message to the user or perform any necessary actions.
        setLoading(false);
      }
    };

    fetchStandingsData();
  }, [division]);

  const columns = [
    {
      title: "",
      children: [
        {
          title: "",
          dataIndex: "teamLogo",
          key: "teamLogo",
          render: (text) => (
            <img src={text} alt="Team Logo" className="w-5 truncate" />
          ),
        },
        {
          title: "",
          dataIndex: "teamRank",
          key: "teamRank",
          render: (text) => <span style={{ fontWeight: 600 }}>{text}</span>,
        },
        {
          title: "",
          dataIndex: "teamName",
          key: "teamName",
        },
      ],
    },
    {
      title: "Overall",
      children: [
        {
          title: "W-L",
          dataIndex: "total",
          key: "total",
          render: (text) => <span style={{ fontWeight: 600 }}>{text}</span>,
          sorter: (a, b) => {
            // Extract win and loss values from the total values
            const [aWins, aLosses] = a.total.split("-").map(Number);
            const [bWins, bLosses] = b.total.split("-").map(Number);

            // Calculate win percentages
            const aWinPercentage = aWins / (aWins + aLosses);
            const bWinPercentage = bWins / (bWins + bLosses);

            // Compare win percentages
            if (aWinPercentage < bWinPercentage) {
              return -1; // Sort in ascending order of win percentage
            } else if (aWinPercentage > bWinPercentage) {
              return 1; // Sort in ascending order of win percentage
            } else {
              return 0; // If both values are equal, maintain current order
            }
          },
          defaultSortOrder: "descend", // Set default sort order to descend
        },

        {
          title: "Home",
          dataIndex: "homerecord",
          key: "homerecord",
        },
        {
          title: "Away",
          dataIndex: "awayrecord",
          key: "awayrecord",
        },
        {
          title: "PF",
          dataIndex: "pointsfor",
          key: "pointsfor",
        },
        {
          title: "PA",
          dataIndex: "pointsagainst",
          key: "pointsagainst",
        },
        // {
        //   title: 'PT-Diff',
        //   dataIndex: 'pointdifferential',
        //   key: 'pointdifferential',
        // },
      ],
    },
    {
      title: "Conference",
      children: [
        {
          title: "W-L",
          dataIndex: "vsconf",
          key: "vsconf",
          render: (text) => <span style={{ fontWeight: 600 }}>{text}</span>,
          sorter: (a, b) => {
            // Extract win and loss values from the vsconf strings
            const [aWins, aLosses] = a.vsconf.split("-").map(Number);
            const [bWins, bLosses] = b.vsconf.split("-").map(Number);

            // Calculate win percentages
            const aWinPercentage = aWins / (aWins + aLosses);
            const bWinPercentage = bWins / (bWins + bLosses);

            // Compare win percentages
            if (aWinPercentage < bWinPercentage) {
              return -1; // Sort in ascending order of win percentage
            } else if (aWinPercentage > bWinPercentage) {
              return 1; // Sort in ascending order of win percentage
            } else {
              // If win percentages are equal, use total as tiebreaker
              if (a.total < b.total) {
                return -1; // Sort in ascending order of total
              } else if (a.total > b.total) {
                return 1; // Sort in ascending order of total
              }
              return 0; // If both values are equal, maintain current order
            }
          },
        },
        {
          title: "PF",
          dataIndex: "vsconf_pointsfor",
          key: "vsconf_pointsfor",
        },
        {
          title: "PA",
          dataIndex: "vsconf_pointsagainst",
          key: "vsconf_pointsagainst",
        },
        // {
        //   title: 'PT-Diff',
        //   dataIndex: 'vsconf_pointdifferential',
        //   key: 'vsconf_pointdifferential',
        // },
        {
          title: "",
          dataIndex: "streak",
          key: "streak",
        },
      ],
    },
  ];

  const maxConferenceNumber = rawData ? rawData.length : 0;
  const [conferenceNumber, setConferenceNumber] = useState(0);

  // Assuming rawData is an array of conferences
  const conferenceOptions = rawData
    ? rawData.map((conference, index) => ({
        name: conference.name,
        value: index,
      }))
    : [];

  // Dropdown change handler
  const handleConferenceChange = (value) => {
    setConferenceNumber(value);
  };

  useEffect(() => {
    // Transform conferences data to match the table structure
    const data = rawData ? [...rawData] : null;
    if (data) {
      const propertyTypes = [
        { key: "vsconf_pointsfor", type: "vsconf_pointsfor" },
        { key: "vsconf_pointsagainst", type: "vsconf_pointsagainst" },
        { key: "vsconf_pointdifferential", type: "vsconf_pointdifferential" },
        { key: "vsconf", type: "vsconf" },
        { key: "streak", type: "streak" },
        { key: "total", type: "total" },
        { key: "homerecord", type: "homerecord" },
        { key: "awayrecord", type: "awayrecord" },
        { key: "pointsfor", type: "pointsfor" },
        { key: "pointsagainst", type: "pointsagainst" },
        { key: "pointdifferential", type: "pointdifferential" },
      ];

      const dataSource = data[conferenceNumber].confData.map((team, index) => {
        const item = {
          key: index,
          teamLogo: team.teamLogo,
          teamRank: team.teamRank,
          teamName: team.teamName,
        };

        propertyTypes.forEach(({ key, type }) => {
          const stat = team.stats.find((stat) => stat.type === type);
          if (stat) {
            item[key] = stat.displayValue;
          }
        });

        return item;
      });

      console.log(dataSource);
      setTableData(dataSource);
      setLoading(false); // set loading to false after data has been retrieved
    }
  }, [rawData, conferenceNumber]);

  return (
    <div className="h-fit w-full -p-2 mx-auto rounded-lg font-Kanit bg-slate-100">
      <h3 className="relative z-10 bg-slate-200 text-white text-center">
        <ConfigProvider
          theme={{
            components: {
              Select: {
                optionSelectedBg: "#555869",
              },
            },
          }}
        >
          <div className="w-full rounded-t-md mt-1 bg-slate-900">
            <Select
              onChange={handleDivisionChange}
              value={division}
              className="mx-auto bg-slate-900 w-2/3"
              dropdownStyle={{
                backgroundColor: "#181c2e",
              }}
              size="small"
              variant="borderless"
            >
              {divisionMapping.map((div, index) => (
                <Select.Option key={index} value={div.value}>
                  <span className="font-Russo font-thin text-lg text-white">
                    {div.name}
                  </span>
                </Select.Option>
              ))}
            </Select>
          </div>
        </ConfigProvider>
      </h3>
      <h4 className="flex relative z-10 rounded-b-sm bg-slate-300 text-white font-semibold">
        <Select
          onChange={handleConferenceChange}
          value={conferenceNumber}
          className="mx-auto w-full p-[2px]"
          size="small"
          variant="borderless"
        >
          {conferenceOptions.map((option, index) => (
            <Select.Option key={index} value={option.value}>
              <span className="font-Russo font-thin">{option.name}</span>
            </Select.Option>
          ))}
        </Select>
      </h4>
      {loading ? ( // render the loading animation while loading is true
        <div className="flex justify-center align-middle items-center min-h-[30rem] w-full">
          <Spin size="large" />
        </div>
      ) : (
        // render the table when loading is false
        <Table
          className="z-0 relative -mt-10 w-fit min-w-full h-fit overflow-y-auto scrollbar-thin scrollbar-track-transparent mx-auto px-2"
          size="small"
          columns={columns}
          dataSource={tableData}
          rowKey={(record) => record.id}
          scroll={{ x: 700 }}
          pagination={false}
        />
      )}
    </div>
  );
}
