import React, { useEffect, useMemo, useState } from "react";
import { ResponsiveLine } from "@nivo/line";

function VDLineChart({ LCData, selectedPositions }) {
  const [containerLCWidth, setContainerLCWidth] = useState('w-full');

  const data = useMemo(() => {
    // Your logic to compute or modify ScoreSheet based on data.ScoreData
    const rawData = LCData ? LCData : [];

    const filteredData = rawData.filter((item) => {
      const playerName = item.playerName;

      // Include the item if:
      // 1. No positions are selected.
      // 2. No selectedPosition playerNames match.
      // 3. A selectedPosition playerName matches and the position matches.
      return (
        !selectedPositions ||
        !selectedPositions[playerName] || // No position is selected for the playerName
        selectedPositions[playerName] === item.position
      );
    });

	console.log("LCData", filteredData)

    return filteredData;
  }, [LCData, selectedPositions]);

  useEffect(() => {
    if (data) {
	const lcWidth = data
        .map((item) => item.data.length) // Extract lengths of data arrays
        .reduce((max, current) => Math.max(max, current), 0) * 8; // Find the maximum length and multiply by 10
      setContainerLCWidth(lcWidth + 'rem');
    }
  }, [data]);

  const seriesColors = [
	"#87D78C",
	"#F98C95",
	"#FBC661",
	"#D6CF61",
	"#AED571",
	"#66D6AA",
	"#57D1C6",
	"#69C9DB",
	"#8EBDE4",
	"#B7AFDF",
	"#D9A0CE",
	"#EF93B4",
	"#F58C77"
  ];

  return (
    <>
      <div className="w-fit h-0 z-20">
        <div className="relative z-20 font-bold shadow-md shadow-slate-600 px-2 top-2 left-2 w-fit rounded-md bg-slate-200">
          Progress Over Time
        </div>
      </div>
      <div
	    style={{ width: `${containerLCWidth}` }} 
	  className={`pl-8 h-full overflow-hidden min-w-[32rem] md:min-w-full`}>
        <ResponsiveLine
          data={data}
		  colors={seriesColors}
          enableSlices="x"
          yScale={{
            type: "linear",
            stacked: false,
            min: 20,
            max: 110,
          }}
          sliceTooltip={({ slice }) => {
            const sortedPoints = slice.points.sort((a, b) => {
              return b.data.y - a.data.y;
            });
            return (
              <div
                style={{
                  background: "white",
                  padding: "9px 12px",
                  border: "1px solid #ccc",
                }}
              >
                {" "}
                {sortedPoints.map((point) => (
                  <div
                    key={point.id}
                    style={{
                      color: point.serieColor,
                      padding: "3px 0",
                    }}
                  >
                    <strong>{point.serieId}</strong> [
                    {`${point.data.posAssignment}/${point.data.totAssignment} = ${point.data.y}`}
                    %]
                  </div>
                ))}
              </div>
            );
          }}
          margin={{ top: 50, right: 150, bottom: 50, left: 60 }}
          xScale={{ type: "point" }}
          // yScale={{
          //     type: 'linear',
          //     min: 30,
          //     max: 105,
          //     stacked: false,
          //     reverse: false
          // }}
          yFormat=" >-.2f"
          axisTop={null}
          axisRight={null}
          axisBottom={{
            orient: "bottom",
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Events",
            legendOffset: 36,
            legendPosition: "middle",
          }}
          axisLeft={{
            orient: "left",
            tickSize: 8,
            tickPadding: 12,
            tickRotation: 0,
            legend: "Percent Assignment",
            legendOffset: -55,
            legendPosition: "middle",
          }}
          pointSize={8}
          enablePointLabel={true}
          pointColor={{ theme: "background" }}
          pointBorderWidth={2}
          pointBorderColor={{ from: "serieColor" }}
          pointLabelYOffset={-12}
          useMesh={true}
          legends={[
            {
              anchor: "bottom-right",
              direction: "column",
              justify: false,
              translateX: 100,
              translateY: 0,
              itemsSpacing: 0,
              itemDirection: "left-to-right",
              itemWidth: 80,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: "circle",
              symbolBorderColor: "rgba(0, 0, 0, .5)",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemBackground: "rgba(0, 0, 0, .03)",
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
        />
      </div>
    </>
  );
}

export default VDLineChart;
