import React, { useRef, useState } from "react";
import { motion } from "framer-motion";
import { Card, Modal } from "antd";
import EvaluxClearLogo from "../img/EvaluxClearLogo.png";
import { useReactToPrint } from "react-to-print";

export function WCPrint({ data, setShowPrint, showPrint }) {
  console.log("WCP-DATA", data);
  const printInfo = data;

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  function groupByColorAndSort(calls) {
    // Group calls by WristColor
    const groups = calls.reduce((acc, call) => {
      acc[call.WristColor] = acc[call.WristColor] || [];
      acc[call.WristColor].push(call);
      return acc;
    }, {});

    // Sort each group in ascending order by WristNum
    for (const color in groups) {
      groups[color].sort((a, b) => a.WristNum - b.WristNum);
    }

    return groups;
  }

  const [WBRows2, setWBRows2] = useState(true)

  const handleWBRows = () => {
	if (WBRows2){
		setWBRows2(false)
	} else {
		setWBRows2(true)
	}
  }

  return (
    <Modal
      className="h-fit pt-[10%] sm:pt-[5%] md:pt-[3%]"
      centered
      width={1300}
      open={showPrint}
      onOk={() => setShowPrint()}
      onCancel={() => setShowPrint()}
      destroyOnClose={true}
      footer={[
        <motion.button
          key="columnSwitch"
          className="p-2 m-1 mb-2 h-fit border rounded-md font-semibold shadow-gray-700 shadow-sm disabled:opacity-50"
          whileHover={{ scale: 0.9 }}
          whileTap={{ scale: 1.1 }}
          onClick={handleWBRows}
        >
          {WBRows2 ? 'Switch to 3 Column' : 'Switch to 2 Column'}
        </motion.button>,
		<motion.button
		key="printButton"
		className="p-2 m-1 mb-2 h-fit hover:bg-teal-500 border-teal-500 border-2 text-white bg-slate-600 rounded-md font-bold shadow-gray-700 shadow-sm disabled:opacity-50"
		whileHover={{ scale: 0.9 }}
		whileTap={{ scale: 1.1 }}
		onClick={handlePrint}
	  >
		Print
	  </motion.button>
      ]}
    >
      <div
        ref={componentRef}
        className="relative px-[3%] mt-5 flex flex-col h-fit min-h-fit w-full mx-auto py-4 bg-white rounded-md"
      >
        {new Date().toLocaleString() + ""}
        <div className="w-full bg-slate-800 text-white text-xl font-bold text-center rounded-t-md">
          {printInfo?.label}
        </div>
        <div className="flex gap-5 mt-3 mx-w-full flex-wrap mx-auto">
          {/* Render each grid for each group of calls */}
          {Object.entries(groupByColorAndSort(printInfo)).map(
            ([color, calls], index) => (
              <div
                className={`flex flex-col mb-4 ${WBRows2 ? 'w-[36rem]' : 'w-[48rem]'} h-[26rem] border border-slate-400`}
                key={index}
              >
                <div className={`text-lg font-bold ${color} w-full h-6`}></div>
                <div className={`grid grid-flow-col grid-rows-15 ${WBRows2 ? 'grid-cols-2' : 'grid-cols-3'} gap-0 h-full`}>
                  {calls.map((call, index) => (
                    <div
                      className={`font-Kanit h-fit border border-slate-400 ${
                        index % 2 === 0 ? "bg-slate-100" : ""
                      }`}
                      key={index}
                    >
                      <div className={`flex h-6 gap-2 w-full`}>
                        <div
                          className={`${call.WristColor} rounded-full h-3 w-3 mt-2 ml-1`}
                        ></div>
                        <div className="font-bold text-lg mr-1 border-r border-slate-400 w-6 pl-0">
                          {call.WristNum}
                        </div>
                        <div className="-ml-2 my-auto whitespace-nowrap overflow-clip">
                          {call.playCall}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </Modal>
  );
}
