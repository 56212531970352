export const UserInputs = [
    {
        id: 'full-name',
        label: 'Full Name',
        type: 'text',
    },
    {
        id: 'jersey-number',
        label: 'Jersey Number',
        type: 'number',
        placeholder: '17...'
    },
    {
        id: 'position',
        label: 'Position',
        type: 'select',
        options: ['', 'QB', 'RB', 'WR', 'TE', 'OL', 'DL', 'LB', 'CB', 'SAF', 'K', 'P'],
    },
	{
        id: 'altPosition',
        label: 'Alt Position',
        type: 'select',
        options: ['', 'QB', 'RB', 'WR', 'TE', 'OL', 'DL', 'LB', 'CB', 'SAF', 'K', 'P'],
    },
    {
        id: 'year',
        label: 'Class',
        type: 'select',
        options: ['', 'Fr', 'So', 'Jr', 'Sr',],
    },
    {
        id: 'redshirt',
        label: 'Redshirt?',
        type: 'select',
        options: ['N', 'Y',],
    },
    {
        id: 'medicalRedshirt',
        label: 'Medical Redshirt?',
        type: 'select',
        options: ['N', 'Y',],
    },
    {
        id: 'sideofball',
        label: 'OFF / DEF / ST',
        type: 'select',
        options: ['', 'OFF', 'DEF', 'ST'],        
    },
    {
        id: 'phone',
        label: 'Phone',
        type: 'text',
        placeholder: '000-687-5309'
    },
    {
        id: 'email',
        label: 'Email',
        type: 'email',
        placeholder: 'JAForMVP@gmail.com...'
    },
	{
        id: 'Xtwitter',
        label: 'X (Twitter)',
        type: 'text',
        placeholder: '@JoshAllenQB'
    },
    {
        id: 'height',
        label: 'Ht',
        type: 'string',
        placeholder: '5\'7...'
    },
    {
        id: 'weight',
        label: 'Wt',
        type: 'string',
        placeholder: '210...'
    },
    {
        id: 'activePlayer',
        label: 'Status',
        type: 'select',
        options: ['Active', 'Inactive'],        
    },
    {
        id: 'highSchool',
        label: 'High School',
        type: 'string',
        placeholder: 'George Washington High School'
    },
    {
        id: 'state',
        label: 'State',
        type: 'select',
        options: [
            'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA',
            'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD',
            'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ',
            'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC',
            'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'
          ],
    },
    {
        id: 'hsgradYear',
        label: 'HS Grad Year',
        type: 'string',
        placeholder: '2023'
    },
]